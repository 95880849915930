import { LinearProgress } from '@mui/material';
import { BlackToWhite, Success } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { Body } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';

const TimeSnackBarStyled = styled.div`
  background-color: ${(props: { color: string }) => props.color};
  border-radius: 6px;
  overflow: hidden;
  box-sizing: border-box;
  .message-padding {
    padding: 1rem;
  }
`;

const TimedSnackBar = forwardRef<
  HTMLDivElement,
  { key: string | number; children: string | React.ReactNode; time: number; color?: string }
>((props, ref) => {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    let countDown = props.time;
    const timer = setInterval(() => {
      if (countDown <= 0) {
        setProgress(0);
        clearInterval(timer);
      }
      countDown -= 100;
      const percent = countDown / props.time;
      setProgress(percent * 100);
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <TimeSnackBarStyled key={props.key} ref={ref} color={props.color || Success.Success01}>
      <Body color={BlackToWhite.White} className="message-padding">
        {props.children}
      </Body>
      <LinearProgress variant="determinate" value={progress} color="info"></LinearProgress>
    </TimeSnackBarStyled>
  );
});

TimedSnackBar.displayName = 'TimedSnackBar';

export default TimedSnackBar;
