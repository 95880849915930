import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { UnacastIcon } from '@unacast-internal/unacast-ui/StyleGuide/UnaIcons/UnacastIcon';
import { Body } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import EmailSentImage from '../icons/EmailSentImage';
import { EmailVerificationSentPageStyled, LocationState } from './EmailVerificationSentPage';
import { getAuth, sendEmailVerification, signOut } from '@firebase/auth';

const EmailVerificationNeededPage = (): React.ReactElement => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const auth = getAuth();
  const email = location.state?.emailForVerification || auth.currentUser?.email || 'you';

  const resendEmail = async () => {
    if (auth.currentUser) {
      const didSend = await sendEmailVerification(auth.currentUser).then(
        () => true,
        () => false,
      );
      history.push({
        pathname: didSend ? '/email-verification-sent' : '/email-verification-failed',
        state: { emailForVerification: email },
      });
    }
  };

  const goHome = () => {
    signOut(auth);
    history.push('/');
  };

  return (
    <EmailVerificationSentPageStyled>
      <UnacastIcon />
      <EmailSentImage height={200} />
      <div className="info">
        <Body>
          Your email address is not verified. Please click the link in the email sent to {email}.
          <br />
          If you have lost the activation email, you may have it resent
        </Body>
      </div>
      <UnaButton styleType="primary" onClick={resendEmail}>
        Resend validation email
      </UnaButton>
      <div>&nbsp;</div>
      <UnaButton styleType="primary" onClick={goHome}>
        Go to homepage
      </UnaButton>
    </EmailVerificationSentPageStyled>
  );
};

export default EmailVerificationNeededPage;
