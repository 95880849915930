import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Elevation2 } from '@unacast-internal/unacast-ui/StyleGuide/Elevation';

export const DetailsStyled = styled(motion.div)`
  background-color: white;
  border-radius: 6px;
  border: none;
  ${Elevation2}
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 400px;
  box-sizing: border-box;

  .top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    word-break: break-all;

    .left-items {
      display: flex;
      flex-direction: row;
    }
    .close-icon:hover,
    .edit-icon:hover {
      cursor: pointer;
    }

    .input-width {
      width: 100%;
    }
  }

  .svg-area {
    padding-top: 1rem;
    width: 125px;
    height: 125px;
    align-self: center;
    padding-bottom: 1rem;
    &:hover {
      cursor: pointer;
    }
  }

  .action-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .secondary-details {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }

  .mapContainer {
    width: 100%;
    position: relative;
  }
`;
