import styled from 'styled-components';

export const LoginEmailStyled = styled.div`
  margin-top: 3.125rem;
  .actions {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .min-height-fix {
    min-height: 86.5px;
  }
`;
