import { Body, Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import React, { useEffect, useState } from 'react';
import { resetPassword } from '../../auth/firebase';
import { ResetPasswordDialogStyled } from './ResetPasswordDialogStyled';
import { Close as CloseIcon } from '@mui/icons-material';
import { Dialog, IconButton, TextField } from '@mui/material';

export interface ResetPasswordDialogProps {
  open: boolean;
  onClose: () => void;
  email?: string | null;
  onCloseAll: () => void;
}

const ResetPasswordDialog = ({
  open,
  onClose,
  email,
  onCloseAll,
}: ResetPasswordDialogProps): JSX.Element => {
  const [input, setInput] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setInput(email || '');
  }, [email]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const onCloseDialogs = () => {
    setShowSuccess(false);
    setShowError(false);
    onCloseAll();
  };

  const onSubmit = () => {
    resetPassword(input).then((res) => {
      res ? setShowError(true) : setShowSuccess(true);
    });
  };

  if (showSuccess || showError)
    return (
      <Dialog open={open} onClose={onCloseDialogs}>
        <ResetPasswordDialogStyled>
          {showError && (
            <IconButton
              style={{ position: 'absolute', right: 20 }}
              onClick={onCloseDialogs}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          )}
          <Heading type="h4">
            {showSuccess ? 'Password reset email sent!' : 'User does not exist'}
          </Heading>
          <Body>
            {showSuccess
              ? 'We’we sent you an email with instructions on how to reset your password.'
              : `No account exists for ${input}. Maybe you signed up using a different email address.`}
          </Body>
          {showSuccess && <UnaButton onClick={onCloseDialogs}>OK</UnaButton>}
        </ResetPasswordDialogStyled>
      </Dialog>
    );

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-reset-password">
      <ResetPasswordDialogStyled>
        <Heading type="h4">Forgot your password?</Heading>
        <Body>
          Enter the email address you want to reset password for. After you receive the email,
          follow the link and you will be able to change your password.
        </Body>
        <TextField
          autoFocus
          required
          value={input}
          margin="dense"
          onChange={onInputChange}
          label="email address"
          type="email"
          variant="outlined"
          fullWidth
        />
        <div className="actions">
          <UnaButton onClick={onSubmit}>Send reset link</UnaButton>
          <UnaButton onClick={onClose}>Cancel</UnaButton>
        </div>
      </ResetPasswordDialogStyled>
    </Dialog>
  );
};

export default ResetPasswordDialog;
