import { Coordinate, PolygonRing } from '@unacast-internal/unacat-js/unacast/byo/v1/byo_service_pb';
import {
  NewBasedOnGeometry,
  POIGeometry as GRPCPOIGeometry,
} from '@unacast-internal/unacat-js/unacast/byo/v1/poi_collection_service_pb';
import {
  MultiPolygon,
  POIFeature,
  POIGeometry,
  PointWithRadius,
  Polygon,
  Position,
} from '../types';

export const PolygonFromPoint = (point: PointWithRadius, points = 64): Polygon => {
  const [longitude, latitude] = point.coordinates;
  const radiusInKm = point.properties.radius * 0.001;

  const ret: Position[] = [];
  const distanceX = radiusInKm / (111.32 * Math.cos((latitude * Math.PI) / 180));
  const distanceY = radiusInKm / 110.574;

  for (let i = 0; i < points; i++) {
    const theta = (i / points) * (2 * Math.PI);
    const x = distanceX * Math.cos(theta);
    const y = distanceY * Math.sin(theta);

    ret.push([longitude + x, latitude + y]);
  }
  ret.push(ret[0]);

  return {
    type: 'Polygon',
    coordinates: [ret],
  };
};

export const POIGeometry__asMultiPolygon = (geo: POIGeometry): MultiPolygon => {
  if (geo.type === 'MultiPolygon') {
    return geo;
  }
  const poly = geo.type === 'Point' ? PolygonFromPoint(geo) : geo;
  return {
    type: 'MultiPolygon',
    coordinates: [poly.coordinates],
  };
};

export const Feature_asNewBasedOnGeometry = (f: POIFeature): NewBasedOnGeometry | undefined => {
  const g = POIGeometry_asGRPCPOIGeometry(f.geometry);
  if (!g) {
    return;
  }

  const geometry = new NewBasedOnGeometry();
  geometry.setGeometry(g);
  geometry.setName(f.properties.name);
  return geometry;
};

export const POIGeometry_asGRPCPOIGeometry = (p: POIGeometry): GRPCPOIGeometry | undefined => {
  switch (p.type) {
    case 'Point':
      const circle = PointWithRadius_asGRPCPOIPointWithRadius(p);
      return new GRPCPOIGeometry().setCircle(circle);
    case 'Polygon':
      const polygon = Polygon_asGRPCPolygon(p);
      return new GRPCPOIGeometry().setPolygon(polygon);
    case 'MultiPolygon':
      const multiPolygon = MultiPolygon_asGRPCMultiPolygon(p);
      return new GRPCPOIGeometry().setMultiPolygon(multiPolygon);
    default:
      return undefined;
  }
};

const PointWithRadius_asGRPCPOIPointWithRadius = (
  p: PointWithRadius,
): GRPCPOIGeometry.PointWithRadius => {
  const circle = new GRPCPOIGeometry.PointWithRadius();
  const centroid = new Coordinate();
  centroid.setLat(p.coordinates[1].toString());
  centroid.setLon(p.coordinates[0].toString());
  circle.setRadius(Math.round(p.properties.radius));
  circle.setCentroid(centroid);
  return circle;
};

const position_asPolygonRings = (p: Position[][]): PolygonRing[] => {
  return p.map((r) => {
    const ring: PolygonRing = new PolygonRing();

    const coordinates: Coordinate[] = r.map((c) => {
      const coordinate = new Coordinate();
      coordinate.setLat(c[1].toString());
      coordinate.setLon(c[0].toString());
      return coordinate;
    });

    ring.setCoordinatesList(coordinates);
    return ring;
  });
};

const Polygon_asGRPCPolygon = (p: Polygon): GRPCPOIGeometry.Polygon => {
  const polygon = new GRPCPOIGeometry.Polygon();
  const rings = position_asPolygonRings(p.coordinates);
  polygon.setRingsList(rings);
  return polygon;
};

const MultiPolygon_asGRPCMultiPolygon = (p: MultiPolygon): GRPCPOIGeometry.MultiPolygon => {
  const multiPolygon = new GRPCPOIGeometry.MultiPolygon();

  const polygons: GRPCPOIGeometry.Polygon[] = p.coordinates.map((polygon) => {
    const poly = new GRPCPOIGeometry.Polygon();
    poly.setRingsList(position_asPolygonRings(polygon));
    return poly;
  });

  multiPolygon.setPolygonsList(polygons);
  return multiPolygon;
};
