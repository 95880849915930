import React, { useEffect, useMemo, useState } from 'react';
import { TextInput } from '@unacast-internal/unacast-ui/Inputs';
import { AddGoogleMap } from '../AddGoogleMap/AddGoogleMap';
import styled from 'styled-components';
import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { POIFeature, POIGeometry } from '../../types';
import {
  geoJsonToGooglePath,
  getPOIArea,
  googleShapeToGeoJson,
  validateFeature,
} from '../../../helpers/geography';
import { UnaAlert } from '@unacast-internal/unacast-ui/UnaAlert';
import { POIError } from '../../../unacatInteraction/PoiCollectionService';
import { statusStyles } from '../GeoJsonToMapShape';

const NewLocationStyled = styled.div`
  .header-spacing {
    margin-bottom: 1rem;
  }
  .alert-spacing {
    margin-bottom: 1rem;
  }
  .action-area {
    margin-top: 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .poly-details {
    margin-bottom: 1rem;
  }
`;

interface NewLocationModalProps {
  onSave: (f: POIFeature) => boolean;
  onClose: () => void;
  currentEditingPOI?: POIFeature | null;
  defaultMapCenter?: google.maps.LatLng;
}

export const NewLocationModal = ({
  onSave,
  onClose,
  currentEditingPOI,
  defaultMapCenter,
}: NewLocationModalProps): React.ReactElement => {
  const [placeName, setPlaceName] = useState(currentEditingPOI?.properties.name || '');
  const [editingGeometry, setEditingGeometry] = useState<POIGeometry | undefined>(
    currentEditingPOI?.geometry,
  );
  const [nameErrorText, setNameErrorText] = useState<string>();
  const [geoErrorText, setGeoErrorText] = useState<string>();

  const errors = useMemo((): Record<string, POIError> | undefined => {
    if (editingGeometry) {
      setGeoErrorText(undefined);

      const geo: POIGeometry = editingGeometry as POIGeometry;
      const newFeature: POIFeature = {
        type: 'Feature',
        id: placeName,
        properties: { name: placeName },
        geometry: geo,
      };
      return validateFeature(placeName, newFeature);
    }

    return undefined;
  }, [editingGeometry, placeName]);

  useEffect(() => {
    if (currentEditingPOI) {
      setPlaceName(currentEditingPOI.properties.name || '');
    }
  }, [currentEditingPOI]);

  const saveFeature = () => {
    const nameError = !placeName ? 'You must enter a name' : undefined;
    const geoError = !editingGeometry ? 'You must set geometry for your location' : undefined;

    setNameErrorText(nameError);
    setGeoErrorText(geoError);

    //return if any errors
    if (
      geoError ||
      nameError ||
      (errors && Object.values(errors).find((error) => error.severity === 'error'))
    ) {
      return;
    }

    if (editingGeometry) {
      const geo: POIGeometry = editingGeometry as POIGeometry;
      const newFeature: POIFeature = {
        type: 'Feature',
        id: currentEditingPOI?.id || placeName,
        properties: { name: placeName },
        geometry: geo,
      };

      if (!onSave(newFeature)) {
        setNameErrorText('Your place name has to be unique');

        return;
      }
      setNameErrorText(undefined);
      setGeoErrorText(undefined);

      setEditingGeometry(undefined);
    }
  };

  return (
    <NewLocationStyled>
      <Heading type="h2" className="header-spacing">
        Add a new location
      </Heading>
      <TextInput
        label="Location Name"
        type="text"
        onBlur={(e) => {
          setNameErrorText(undefined);
          setPlaceName(e.currentTarget.value);
        }}
        value={placeName}
        onChange={(e) => {
          setNameErrorText(undefined);
          setPlaceName(e.currentTarget.value);
        }}
        error={nameErrorText !== undefined}
        helperText={nameErrorText}
      />
      <div className="poly-details">
        <Heading type="h3">
          <b> Area in meters&sup2;: </b>
          {editingGeometry &&
            Math.ceil(
              getPOIArea({
                type: 'Feature',
                geometry: editingGeometry,
                properties: { name: '1' },
                id: '1',
              }),
            ).toLocaleString()}
        </Heading>
      </div>
      {errors && editingGeometry && (
        <UnaAlert
          severity={errors[currentEditingPOI?.properties.name || placeName].severity}
          title={errors[currentEditingPOI?.properties.name || placeName].message}
          className="alert-spacing"
        ></UnaAlert>
      )}
      {geoErrorText !== undefined && (
        <UnaAlert severity="error" title={geoErrorText} className="alert-spacing"></UnaAlert>
      )}
      <AddGoogleMap
        editingGeometry={editingGeometry ? geoJsonToGooglePath(editingGeometry) : undefined}
        setEditingGeometry={(shape) =>
          setEditingGeometry(shape ? googleShapeToGeoJson(shape) : undefined)
        }
        polygonStyle={
          errors
            ? statusStyles[errors[currentEditingPOI?.properties.name || placeName].severity]
            : statusStyles['ok']
        }
        defaultMapCenter={defaultMapCenter}
      />

      <div className="action-area">
        <UnaButton styleType="secondary" onClick={onClose}>
          Cancel
        </UnaButton>

        <UnaButton onClick={saveFeature}>Add</UnaButton>
      </div>
    </NewLocationStyled>
  );
};
