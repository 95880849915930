import { POIFeature } from '../types';
import { ParseResponse } from './fileTranslatorHook';
import { isGeoJSONFeatureCollection, POIGeometry_fromGeoJSONGeometry } from './geojsonOperations';

/**
 * We expect the .geojson-files to contain a POICollection (our strictification of
 * a GeoJSON-object), most likely downloaded from this service for storage.
 *
 * In the future, we may want to accept a GeoJSON FeatureCollection and generate or
 * in other ways populate the fields that may be missing and filter/transform away
 * the Geometry-types we do not support.
 */

export const parseGeoJSONFile = (data: string): ParseResponse => {
  const potentialCollection = JSON.parse(data);

  if (!isGeoJSONFeatureCollection(potentialCollection)) {
    throw new Error('File content is not correct GeoSJON-format');
  }
  const features = potentialCollection.features.map((f): POIFeature => {
    const idCandidates: Array<string | number | undefined> = [
      f.id,
      f.properties?.id,
      f.properties?.name,
    ];
    const id = idCandidates.reduce((p, c) => (p === undefined ? c : p)); // Get first defined id-candidate

    if (id === undefined) {
      throw new Error('Feature must have at least a name-property or an id');
    }
    const geometry = POIGeometry_fromGeoJSONGeometry(f.geometry);
    if (geometry === null) {
      throw new Error('Unsupported geometry: ' + f.type);
    }
    return {
      type: 'Feature',
      id: id.toString(),
      geometry,
      properties: { name: (f.properties?.name || id).toString() },
    };
  });
  return { status: 'success', features, type: 'geoJson' };
};
