import React, { useContext, useEffect, useMemo, useState } from 'react';
import { LeanMetric } from '@unacast-internal/unacat-js/unacast/v2/metric/metric_pb';
import { useCurrentBillingAccount } from './BillingAccountContext';
import { metricWithAccess } from '../helpers/metricHelpers';
import { AuthContext } from './AuthContext';
import { ErrorHandlerContext } from './ErrorHandlerContext';
import { ListMetricsRequest } from '@unacast-internal/unacat-js/unacast/v2/catalog/catalog_service_pb';
import { getAllMetrics } from '../helpers/paginatedMetricsCall';
import { matchPath, useLocation } from 'react-router-dom';

const useMetrics = (billingAccount: string | null, catalogId: string): LeanMetric[] => {
  const { authorizationToken } = useContext(AuthContext);
  const { logError } = useContext(ErrorHandlerContext);

  const [metricList, setMetricList] = useState<LeanMetric[]>([]);

  useEffect(() => {
    if (billingAccount && authorizationToken) {
      const requestV2 = new ListMetricsRequest()
        .setCatalogId(catalogId)
        .setPageSize(100)
        .setAvailabilityFilterList([0, 1])
        .setBillingContext(billingAccount);

      getAllMetrics(
        requestV2,
        authorizationToken,
        (response) => setMetricList(response),
        (err, error) => logError({ ...err, ...error }, true),
      );
    }
  }, [catalogId, authorizationToken, logError, setMetricList, billingAccount]);
  return metricList;
};

interface MetricsProps {
  availableMetrics: LeanMetric[];
}

type matchPathParams = {
  params?: {
    catalogId?: string;
  };
} | null;

const MetricsContext = React.createContext<MetricsProps>({
  availableMetrics: [],
});
export const MetricsContextConsumer = MetricsContext.Consumer;

export const useActiveMetrics = (): LeanMetric[] => useContext(MetricsContext).availableMetrics;

export const MetricsProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { pathname } = useLocation();
  const match: matchPathParams = matchPath(pathname, { path: '/c/:catalogId' });
  const catalogId = match?.params?.catalogId || 'rwg';
  const currentBillingAccount = useCurrentBillingAccount();
  const metrics = useMetrics(currentBillingAccount, catalogId);
  const availableMetrics = useMemo(() => metricWithAccess(metrics), [metrics]);

  return <MetricsContext.Provider value={{ availableMetrics }}>{children}</MetricsContext.Provider>;
};
