import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { Body, Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { StoredPoiCollection } from '../../unacatInteraction/PoiCollectionService';
import styled from 'styled-components';
import { POIManagementMap } from '../components/POICollectionManagerMap/POICollectionManagerMap';
import { useGetCollectionHook } from '../dataManagement/useGetCollections';
import { useMadeToOrderNavigation } from '../utils/madeToOrderNavigation';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { usePublishCollectionVersionHook } from '../dataManagement/usePublishCollectionVersion';
import { ConfirmationDialog } from '../components/Modals/ConfirmationDialog';
import { motion } from 'framer-motion';
import { LocationRecord } from '../dataManagement/poiCollectionStore';
import { POIItem } from '@unacast-internal/unacat-js/unacast/byo/v1/poi_collection_service_pb';
import { POIFeature__fromPOIItem } from '../../helpers/geography';
import { UnaLinearProgress } from '@unacast-internal/unacast-ui/UnaLinearProgress';
import { usePOICollectionStoreSimple } from '../dataManagement/POICollectionStoreConnected/poiCollectionStoreSimple';
import { useBreadCrumbs } from '../../contexts/BreadCrumbContext';
import { BlackToWhite } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { CopyableText } from '@unacast-internal/unacast-ui/CopyableText/CopyableText';

const EditCollectionStyled = styled(motion.div)`
  border-radius: 6px;
  box-sizing: border-box;

  .header-spacing {
    margin-bottom: 0.5rem;
  }

  .action-area {
    display: flex;
    justify-content: space-between;
  }
`;

//Without this being async, the loading convertion gets exponentially longer as the list grows
const LocationRecord__fromPOIItems = async (items: POIItem.AsObject[]): Promise<LocationRecord> => {
  const record: LocationRecord = {};
  await Promise.all(
    items.map((i) => {
      return new Promise(async (res) => {
        const el = await POIFeature__fromPOIItem(i);
        if (el.properties.name) {
          record[el.properties.name] = el;
        } else {
          record[el.id] = el;
        }
        res(el);
      });
    }),
  );

  return record;
};

export const EditCollectionPage = () => {
  const { setCrumbs } = useBreadCrumbs();
  const { collectionId } = useParams<{ collectionId: string }>();
  const [showPublishDialog, setShowPublishDialog] = useState<boolean>(false);
  const [locations, updatePOICollection, idFromStore] = usePOICollectionStoreSimple();

  const [collectionState, getCollection] = useGetCollectionHook();
  const [publishState, triggerPublishCollection] = usePublishCollectionVersionHook();

  const [collectionDetails, setCollectionDetails] = useState<StoredPoiCollection>();

  const navTo = useMadeToOrderNavigation();

  const onClickPublish = () => {
    setShowPublishDialog(true);
  };

  const onClickConfirmPublish = () => {
    setShowPublishDialog(false);
    triggerPublishCollection(collectionId);
  };

  const isPublishDisabled = () => {
    return (
      showPublishDialog ||
      !collectionDetails ||
      publishState.status === 'pending' ||
      publishState.status === 'succeeded'
    );
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    setCrumbs([
      { text: 'Home', url: '/' },
      { text: 'Data', url: '/c/rwg' },
      { text: 'Custom Locations', url: '/made-to-order' },
      { text: 'Collections', url: '/made-to-order/collections' },
      { text: 'Details', url: '/made-to-order/collections/' + collectionId },
      { text: 'Edit', url: window.location.pathname },
    ]);
  }, [collectionId, setCrumbs]);

  useEffect(() => {
    if (collectionState.status === 'initial') {
      getCollection(collectionId);
    } else if (collectionState.status === 'succeeded') {
      setCollectionDetails(collectionState.data);
    } else if (collectionState.status === 'failed') {
      enqueueSnackbar('There was an error getting your collection', {
        variant: 'error',
      });
    }
  }, [collectionId, enqueueSnackbar, collectionState, getCollection]);

  useEffect(() => {
    if (publishState.status === 'succeeded') {
      enqueueSnackbar('Your collection was published', {
        variant: 'success',
      });
    } else if (publishState.status === 'failed') {
      enqueueSnackbar('There was an error publishing your collection', {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, publishState]);

  useEffect(() => {
    if (collectionDetails) {
      LocationRecord__fromPOIItems(collectionDetails?.featureCollection).then((res) => {
        updatePOICollection(() => {
          return res;
        });
      });
    }
  }, [collectionDetails, updatePOICollection]);

  return (
    <EditCollectionStyled initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {collectionDetails ? (
        <>
          <Heading type="h2" className="header-spacing">
            {collectionDetails.details.name}
          </Heading>
          <div>
            <Body color={BlackToWhite.Gray03}>
              <b>Locations:</b> {Object.keys(locations).length}
            </Body>
          </div>
          {publishState.status === 'succeeded' && (
            <>
              <Body color={BlackToWhite.Gray03}>Big Query Reference:</Body>
              <CopyableText valueToCopy={publishState.data.storageReference}>
                <Body type="small01" color={BlackToWhite.Gray03}>
                  {publishState.data.storageReference}
                </Body>
              </CopyableText>
            </>
          )}
          <POIManagementMap collection={collectionDetails}></POIManagementMap>

          <div className="action-area">
            <UnaButton
              onClick={() => navTo.collectionDetails(collectionId)}
              styleType="secondary"
              icon={<ArrowBackIcon></ArrowBackIcon>}
              iconPos="start"
            >
              Back To Details
            </UnaButton>
            {/* Commented out for now until we can publish */}
            <UnaButton onClick={onClickPublish} disabled={isPublishDisabled()}>
              Publish Collection
            </UnaButton>
          </div>
          <ConfirmationDialog
            isOpen={showPublishDialog}
            onClose={() => setShowPublishDialog(false)}
            onConfirm={onClickConfirmPublish}
            text={`Are you sure you want to publish "${collectionDetails?.details.name}" now?`}
          ></ConfirmationDialog>
        </>
      ) : (
        <UnaLinearProgress></UnaLinearProgress>
      )}
    </EditCollectionStyled>
  );
};
