import 'firebase/auth';

import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SESSION_TOKEN_KEY } from '../config';
import { setTokenExpiration } from '../helpers/tokenHelper';
import { ErrorHandlerContext } from './ErrorHandlerContext';
import { getIamServicePromiseClient, getSubscriptionServicePromiseClient } from '../rpc';
import {
  CreateUserRequest,
  GetUserRequest,
} from '@unacast-internal/unacat-js/unacast/iam/v1/iam_service_pb';
import { IamUser, UserClaims } from '@unacast-internal/unacat-js/unacast/iam/v1/iam_user_pb';
import { IamService } from '../unacatInteraction/IamService';
import { AuthContext } from './AuthContext';
import { StatusCode } from 'grpc-web';
import { ListBillingAccountsRequest } from '@unacast-internal/unacat-js/unacast/subscription/v1/subscription_service_pb';
import { BillingAccount } from '@unacast-internal/unacat-js/unacast/subscription/v1/billing_account_pb';
import { getIndustrySolutionFromPath } from '../helpers/industrySolution';
import { errorHandler } from '../helpers/errorHandler';
import { getAuth, onAuthStateChanged, signOut, User as FirebaseUser } from '@firebase/auth';

function findBillingAccountsFromIamClaims(userClaims: UserClaims): Array<string> {
  return userClaims
    .getClaimStringsList()
    .map((claim) => claim.split('.'))
    .filter((claimParts) => claimParts.length === 2 && claimParts[0] === 'bacc')
    .map((claimParts) => claimParts[1]);
}

const getIamUserForUser = async (email: string, idToken: string): Promise<IamUser> => {
  const client = getIamServicePromiseClient();
  try {
    const headers = { Authorization: 'bearer ' + idToken };
    return await client.getCurrentUser(new GetUserRequest().setEmail(email), headers);
  } catch (e) {
    if (e.code === StatusCode.NOT_FOUND) {
      // We are handling here the case in which a user already has an account, most likely through neighborhood insights, but does not have an account in the system (IamUser)
      // If this is the case, the account is created here before continuing with the normal login flow
      return await client.createUser(new CreateUserRequest().setEmail(email));
    }
    throw e;
  }
};

type BillingAccountArray = BillingAccount.AsObject[];
const getBillingAccountsForUser = async (
  iamUser: IamUser,
  idToken: string,
  catalogId: string,
): Promise<BillingAccountArray> => {
  const claims = iamUser.getClaims();
  if (claims === undefined || findBillingAccountsFromIamClaims(claims).length === 0) {
    return [];
  }

  const subscriptionService = getSubscriptionServicePromiseClient();
  const request = new ListBillingAccountsRequest();
  request.setPageSize(800);
  request.setLimitToCurrentUser(true);
  request.setFilterOutBaccsWithNoAccessToCatalog(catalogId);
  return subscriptionService
    .listBillingAccounts(request, { Authorization: 'bearer ' + idToken })
    .then((response) => response.toObject().billingAccountsList)
    .catch((err) => {
      errorHandler(err);
      return [];
    });
};

export const storeUserSolutions = async (
  email: string,
  idToken: string,
  newActiveUserProductsPerBillingAccount: Record<string, Record<string, string[]>>,
): Promise<void> => {
  try {
    const service = IamService.fromIdToken(idToken);
    await service.storeUserSolutions(email, newActiveUserProductsPerBillingAccount);
  } catch (err) {
    errorHandler(err);
  }
};

export const storeHideFeedbackFormUntil = async (
  email: string,
  AuthorizationHeader: string, // TODO:  idToken: string, but 'bearer ' is included in value from context
  // idToken: string,
  hideFeedbackFormUntil: number,
): Promise<void> => {
  try {
    const service = IamService.fromAuthHeader(AuthorizationHeader);
    await service.storeHideFeedbackFormUntil(email, hideFeedbackFormUntil);
  } catch (err) {
    errorHandler(err);
  }
};

type UserProductsManager = {
  getValues: () => Record<string, Record<string, string[]>>;
  addSolution: (billingAccount: string, industry: string, solution: string) => Promise<void>;
  removeSolution: (billingAccount: string, industry: string, solution: string) => Promise<void>;
};

export type CurrentUserState =
  | { status: 'loading'; user: null }
  | { status: 'notLoggedIn'; user: null }
  | { status: 'missingEmailVerification'; user: FirebaseUser }
  | { status: 'noBillingAccuonts'; user: FirebaseUser }
  | { status: 'noIamObject'; user: FirebaseUser; error?: Error }
  | {
      status: 'ok';
      user: FirebaseUser;
      iamUser: IamUser;
      billingAccounts: BillingAccountArray;
      userProducts: UserProductsManager;
    };

const calculateNewStateFromUser = async (
  user: FirebaseUser | null,
  catalogId: string,
): Promise<CurrentUserState> => {
  if (!user) {
    return { status: 'notLoggedIn', user: null };
  }
  if (!user.emailVerified) {
    return { status: 'missingEmailVerification', user };
  }

  const authToken = await user.getIdToken().catch(() => null);
  if (user.email === null || authToken === null) {
    return { status: 'notLoggedIn', user: null }; // TODO: Define this error-state better?
  }

  let iamUser: IamUser;
  try {
    iamUser = await getIamUserForUser(user.email, authToken); // TODO simplify with .catch(() => null)?
  } catch (error) {
    return { status: 'noIamObject', user, error };
  }

  const billingAccounts = await getBillingAccountsForUser(iamUser, authToken, catalogId);
  if (billingAccounts.length === 0) {
    return { status: 'noBillingAccuonts', user };
  }

  const getUserObject = (): Record<string, Record<string, string[]>> =>
    JSON.parse(iamUser.getSettings()?.getUserObject() || '{}');
  const doUpdateUserObjectSolutions = async (
    billingAccount: string,
    industry: string,
    nextSolutionProvider: (solutions: readonly string[]) => string[],
  ) => {
    const current = getUserObject();
    const newActiveUserProductsPerBillingAccount: Record<string, Record<string, string[]>> = {
      ...current,
      [billingAccount]: {
        ...(current[billingAccount] || {}),
        [industry]: nextSolutionProvider(current[billingAccount]?.[industry] || []),
      },
    };
    await storeUserSolutions(iamUser.getEmail(), authToken, newActiveUserProductsPerBillingAccount);
    iamUser.getSettings()?.setUserObject(JSON.stringify(newActiveUserProductsPerBillingAccount));
  };
  const addSolutionToUserObject = (billingAccount: string, industry: string, solution: string) =>
    doUpdateUserObjectSolutions(billingAccount, industry, (s) =>
      s.includes(solution) ? [...s] : [...s, solution],
    );
  const removeSolutionFromUserObject = (bAccount: string, industry: string, solution: string) =>
    doUpdateUserObjectSolutions(bAccount, industry, (s) => s.filter((v) => v !== solution));

  const userProducts: UserProductsManager = {
    getValues: getUserObject,
    addSolution: addSolutionToUserObject,
    removeSolution: removeSolutionFromUserObject,
  };

  // TODO: Figure if this is needed and how to potentially restructure
  localStorage.setItem(SESSION_TOKEN_KEY, authToken);
  setTokenExpiration();
  return { status: 'ok', user, iamUser, billingAccounts, userProducts };
};

type Props = {
  state: CurrentUserState;
  onLogout: () => void;
};
const UserContext = React.createContext<Props>({
  state: { status: 'loading', user: null },
  onLogout: () => undefined,
});

export const useCurrentUserLoggedInStatus = (): boolean =>
  useContext(UserContext).state.status === 'ok';
export const useCurrentFirebaseUser = (): FirebaseUser | null => useContext(UserContext).state.user;
type CurrentUserInfo = {
  email: string;
  companyName: string;
  fullName: string;
  hideFeedbackFormUntil: number;
};

export const useCurrentUserRoles = (): string[] => {
  const { state } = useContext(UserContext);
  if (state.status === 'ok') {
    const claims = state.iamUser.getClaims()?.getClaimStringsList();
    const roles = claims?.filter((claim) => claim.includes('catrole') || claim.includes('admin'));
    return roles || [];
  }
  return [];
};
export const useCurrentUserInfo = (): CurrentUserInfo | null => {
  const { state } = useContext(UserContext);
  return state.status === 'ok'
    ? {
        email: state.iamUser.getEmail(),
        companyName: state.iamUser.getCompanyName(),
        fullName: state.iamUser.getFullName(),
        hideFeedbackFormUntil: state.iamUser.getSettings()?.getHideFeedbackFormUntil() || 0,
      }
    : null;
};
export const useCurrentBillingAccounts = (): BillingAccount.AsObject[] => {
  const { state } = useContext(UserContext);
  return state.status === 'ok' ? state.billingAccounts : [];
};
export const useCurrentUserLogoutMethod = (): (() => void) => useContext(UserContext).onLogout;
export const useActiveUserProducts = (): Record<string, Record<string, string[]>> => {
  const { state } = useContext(UserContext);
  return state.status === 'ok' ? state.userProducts.getValues() : {};
};
export const useUserProductsManager = (): UserProductsManager | null => {
  const { state } = useContext(UserContext);
  return state.status === 'ok' ? state.userProducts : null;
};

export const UserProvider: React.FC = ({ children }) => {
  const { setAuthHeader } = useContext(AuthContext);
  const [state, setState] = React.useState<CurrentUserState>({ status: 'loading', user: null });
  const { catalogId } = useParams<{ catalogId: string }>();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      setState(await calculateNewStateFromUser(user, catalogId));
    });
  }, [catalogId, setState]);

  const onLogout = async () => {
    const auth = getAuth();
    await signOut(auth);
    setState({ status: 'notLoggedIn', user: null });
    setAuthHeader(''); // TOD: Why?
  };

  return (
    <UserContext.Provider
      value={{
        state: state,
        onLogout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export const UserStateChangeSideeffects: React.FC = ({ children }) => {
  const { state } = useContext(UserContext);
  const history = useHistory();
  const { logError } = useContext(ErrorHandlerContext);
  const { refreshAuthToken } = useContext(AuthContext);
  const { catalogId } = useParams<{ catalogId: string }>();

  const prevStateRef = React.useRef<CurrentUserState>(); // TODO: Is needed?
  useEffect(() => {
    if (prevStateRef.current?.status === state.status) {
      return; // Only respond to status-changes
    }

    switch (state.status) {
      case 'notLoggedIn':
        if (prevStateRef.current && prevStateRef.current.status !== 'loading') {
          history.push('/login');
        }
        break;
      case 'missingEmailVerification':
        const path = history.location.pathname;
        if (path === '/email-handler') break; //Do not re-send emailVerification when in process of validating email
        if (path === '/signup' && prevStateRef.current?.status === 'notLoggedIn') break; // User creation is self managed
        // TODO: Handle missing emailVerification in `PrivateRoute` or somewhere to avoid redirects
        history.push({
          pathname: '/email-verification-needed',
          state: { emailForVerification: state.user.email },
        });
        break;
      case 'noBillingAccuonts':
        logError(new Error('could not find billing account for user ' + state.user.email), false);
        history.push('/invite-only');
        break;
      case 'noIamObject':
        if (state.error?.message === 'insufficient permission granted') {
          history.push(`/c/${catalogId}`); // TODO: Understand why we do this
        } else {
          logError(new Error('Failed creating your account, please contact us'), true);
          history.push('/invite-only');
        }
        break;
      case 'ok':
        refreshAuthToken();
        const { location } = history;
        if (location.pathname === '/login' || location.pathname === '/signup') {
          const paramMatch = location.search.match(/[?&]next=([^&]+)(&|$)/);
          const gotoAfter = paramMatch ? decodeURIComponent(paramMatch[1]) : '/';
          const billingAccount = state.billingAccounts[0]?.id;
          const industrySolution = getIndustrySolutionFromPath(gotoAfter);
          if (billingAccount && industrySolution) {
            const { industry, solution } = industrySolution;
            state.userProducts.addSolution(billingAccount, industry, solution);
          }
          history.push(gotoAfter);
        } else {
          history.push(location.pathname + location.search);
        }
        break;
    }

    prevStateRef.current = state;
  }, [state, prevStateRef, history, logError, refreshAuthToken]);

  /**
   * Ensure that the app tries to fetch existing user before continuing rendering the app
   */
  return <>{state.status !== 'loading' && children}</>;
};

/** @deprecated use PureUserProvider in the App and UserStateChangeSideeffects for the relevant routes */
export const UserProviderWithRedirects: React.FC = ({ children }) => (
  <UserProvider>
    <UserStateChangeSideeffects>{children}</UserStateChangeSideeffects>
  </UserProvider>
);
