import * as Sentry from '@sentry/browser';

export const errorHandler = (error: Error, errorInfo?: string): void => {
  errorInfo &&
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo);
      });
    });
  Sentry.captureException(error);
};

export { Sentry };
