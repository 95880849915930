import AddIcon from '@mui/icons-material/Add';
import centroid from '@turf/centroid';
import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { Modal } from '@unacast-internal/unacast-ui/Organisms';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  POICollection_fromLocationRecord,
  validateGeometryFeatures,
} from '../../../helpers/geography';
import {
  withAddedFeature,
  withoutFeature,
  withUpdatedFeature,
} from '../../dataManagement/poiCollectionActions';
import { usePOICollectionStore } from '../../dataManagement/poiCollectionStore';
import { isPOIFeature, POICollection, POIFeature } from '../../types';
import { downloadPOICollectionAsGeojson } from '../../utils/downloadBlob';
import { AddNewCollectionFromFile } from '../CollectionOnboarding/AddNewCollectionFlow';
import { EmptyCollectionPrompt } from '../EmptyCollectionPrompt';
import { NewLocationModal } from '../Modals/NewLocationModal';
import { MultiFeatureGoogleMap } from '../MultiFeatureGoogleMap/MultiFeatureGoogleMap';
import { SampleFileModal } from '../SampleFileModal/SampleFileModal';
import { UploadPOICollectionButton } from '../UploadPOICollectionButton';
import { ReportCollectionManagerMapStyled } from './ReportCollectionManagerMap.styles';

type ModalType = 'ADD_FEATURE' | 'SHOW_FORMAT_TYPE';

/**
 *  Interactions for managing a collection of Points of Interest
 * COUPLED TO <POICollectionStoreProvider></POICollectionStoreProvider>
 * @param collectionName
 * @returns
 */

export const ReportCollectionManagerMap = ({ collectionName }: { collectionName: string }) => {
  const [collections, updatePOICollection] = usePOICollectionStore();
  const currentCollection = collections[collectionName];
  const [geometryErrors, setGeometryErrors] = useState<Record<string, string[]>>();
  const [editingFeatureId, setEditingFeatureId] = useState<string>();
  const [deletedFeatures, setDeletedFeatures] = useState<POIFeature[]>();
  const [mapCenter, setMapCenter] = useState<google.maps.LatLng>();

  const [modalType, setModalType] = useState<ModalType>();
  const [currentFileName, setCurrentFileName] = useState<string>();
  const [fileToParse, setFileToParse] = useState<ArrayBuffer>();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const appendFile = (result: ArrayBuffer, fileName: string) => {
    setCurrentFileName(fileName);
    setFileToParse(result);
  };

  const editingFeature = useMemo((): POIFeature | undefined => {
    if (currentCollection && editingFeatureId) {
      return currentCollection[editingFeatureId];
    }
  }, [collections, currentCollection, editingFeatureId]);

  useEffect(() => {
    if (currentCollection) {
      setGeometryErrors(validateGeometryFeatures(Object.values(currentCollection)));
    }
  }, [currentCollection]);

  const mapCollection = useMemo((): POICollection | undefined => {
    return currentCollection && Object.values(currentCollection).length > 0
      ? POICollection_fromLocationRecord(currentCollection)
      : undefined;
  }, [currentCollection]);

  const extendCurrentCollection = useCallback((features: POIFeature[]) => {
    features.forEach(
      (newFeat) => (newFeat.properties.centroid = centroid(newFeat).geometry.coordinates),
    );
    updatePOICollection(collectionName, (f) => features.reduce(withAddedFeature, f));
  }, []);

  const saveNewFeature = (feature: POIFeature): boolean => {
    try {
      if (!isPOIFeature(feature) || (currentCollection && currentCollection[feature.id])) {
        return false;
      }
      feature.properties.centroid = centroid(feature).geometry.coordinates;

      updatePOICollection(collectionName, (f) => withAddedFeature(f, feature));
      setModalType(undefined);
      setDeletedFeatures(undefined);
      setEditingFeatureId(undefined);
      enqueueSnackbar(`'${feature.id}' successfully added to collection`, {
        variant: 'success',
      });
      return true;
    } catch {
      return false;
    }
  };

  const onDelete = () => {
    if (deletedFeatures) {
      const snackbarKey = enqueueSnackbar(`Your features have been removed from your report.`, {
        variant: 'success',
        action: (
          <UnaButton
            onClick={() => {
              updatePOICollection(collectionName, (f) =>
                deletedFeatures.reduce(withAddedFeature, f),
              );
              closeSnackbar(snackbarKey);
            }}
          >
            Undo
          </UnaButton>
        ),
      });
    }
  };

  const deleteFeature = (featureId): void => {
    if (currentCollection) {
      const deletingFeature = currentCollection[featureId];

      updatePOICollection(collectionName, (f) => withoutFeature(f, featureId));

      setDeletedFeatures(deletingFeature ? [deletingFeature] : undefined);
      setEditingFeatureId(undefined);
    }
  };

  const deleteCollection = (): void => {
    if (currentCollection) {
      const newDeletedFeatures = Object.values(currentCollection);
      //Creates a new, empty collection
      updatePOICollection(collectionName, () => ({}));
      setDeletedFeatures(newDeletedFeatures);
    }
  };

  const editLocation = (feature: POIFeature): boolean => {
    if (!(currentCollection && currentCollection[feature.id])) {
      enqueueSnackbar(`Feature ${feature.id} could not be updated`, {
        variant: 'error',
      });
      return false;
    }
    try {
      feature.properties.centroid = centroid(feature).geometry.coordinates;

      updatePOICollection(collectionName, (f) => withUpdatedFeature(f, feature));
      enqueueSnackbar(`Feature ${feature.id} edited successfully`, {
        variant: 'success',
      });
      return true;
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`Feature ${feature.id} could not be updated`, {
        variant: 'error',
      });
      return false;
    }
  };

  useEffect(onDelete, [closeSnackbar, deletedFeatures, enqueueSnackbar]);

  const modals = useMemo(
    (): Record<ModalType, JSX.Element> => ({
      ADD_FEATURE: (
        <NewLocationModal
          onSave={saveNewFeature}
          onClose={() => setModalType(undefined)}
          defaultMapCenter={mapCenter}
        />
      ),
      SHOW_FORMAT_TYPE: <SampleFileModal isOpen onClose={() => setModalType(undefined)} />,
    }),
    [],
  );

  return (
    <ReportCollectionManagerMapStyled>
      <>
        <div className="format-info-link">
          <UnaButton
            onClick={() => setModalType('SHOW_FORMAT_TYPE')}
            styleType="info"
            className="highlight-button"
          >
            *Read more about Supported Files
          </UnaButton>
        </div>
        <div className="temp-map-area">
          {mapCollection ? (
            <>
              <MultiFeatureGoogleMap
                onEditFeature={editLocation}
                deleteFeature={deleteFeature}
                featureCollection={mapCollection}
                collectionName={collectionName} // unused in this case
                errors={geometryErrors}
                setMapCenter={setMapCenter}
                topLevelActions={{
                  Download: () => downloadPOICollectionAsGeojson(mapCollection),
                  Delete: () => deleteCollection(),
                }}
              />
              <div className="map-add-button">
                {/* TODO: EXTRACT UPLOAD BUTTON TO COMPONENT */}
                <UploadPOICollectionButton
                  onComplete={appendFile}
                  text={currentCollection ? 'Append Location file' : 'Add Location File'}
                ></UploadPOICollectionButton>

                <UnaButton
                  onClick={() => setModalType('ADD_FEATURE')}
                  icon={<AddIcon />}
                  iconPos="end"
                >
                  Add Location
                </UnaButton>
              </div>
            </>
          ) : (
            <EmptyCollectionPrompt
              onComplete={appendFile}
              onDrawClick={() => setModalType('ADD_FEATURE')}
            ></EmptyCollectionPrompt>
          )}
        </div>
      </>

      <Modal
        isOpen={modalType !== undefined}
        onClose={() => {
          setModalType(undefined);
        }}
      >
        {modalType ? modals[modalType] : <></>}
      </Modal>
      {fileToParse && currentFileName && (
        <AddNewCollectionFromFile
          onComplete={(newColl: POICollection) => {
            extendCurrentCollection(newColl.features);
            setFileToParse(undefined);
            setCurrentFileName(undefined);
          }}
          fileToParse={fileToParse}
          fileName={currentFileName}
        />
      )}
    </ReportCollectionManagerMapStyled>
  );
};
