import {
  ListMetricsLeanResponse,
  ListMetricsRequest,
} from '@unacast-internal/unacat-js/unacast/v2/catalog/catalog_service_pb';
import { getCatalogServiceV2Client } from '../rpc';
import grpcWeb from 'grpc-web';
import { LeanMetric } from '@unacast-internal/unacat-js/unacast/v2/metric/metric_pb';

export async function getAllMetrics(
  request: ListMetricsRequest,
  authorizationToken,
  callback: (m: LeanMetric[]) => void,
  onError,
) {
  const catalogServiceV2 = getCatalogServiceV2Client();
  let morePagesAvailable = true;
  let nextPageToken = '';
  const fullList: LeanMetric[] = [];

  while (morePagesAvailable) {
    try {
      if (nextPageToken) request.setPageToken(nextPageToken);
      // eslint-disable-next-line no-loop-func
      const list = await new Promise((resolve: (list: LeanMetric[]) => void, rejects) => {
        catalogServiceV2.listMetricsLean(
          request,
          { Authorization: authorizationToken },
          (error: grpcWeb.Error, response: ListMetricsLeanResponse) => {
            if (!error) {
              nextPageToken = response.getNextPageToken();
              resolve(response.getMetricsList());
            } else {
              const err = new Error(error.code.toString());
              rejects(err);
            }
          },
        );
      });
      if (list) fullList.push(...list);
    } catch (error) {
      const err = new Error(error.code.toString());
      morePagesAvailable = false;
      onError(err, error);
    }
    morePagesAvailable = !!nextPageToken;
  }
  callback(fullList);
}
