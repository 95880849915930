import { useEffect } from 'react';
import { BringYourOwnPOIService } from '../../unacatInteraction/BringYourOwnPOIService';
import {
  AuthParams,
  authDecoratedFetchingHook,
  createAsyncFetchingHook,
} from '../../unacatInteraction/fetchingHook';

export const useStoredCollectionService = authDecoratedFetchingHook(
  createAsyncFetchingHook(({ authHeader, billingAccount }: AuthParams, id: string) => {
    const service = new BringYourOwnPOIService(authHeader, billingAccount);
    return service.getStoredCollection(id);
  }),
);

export const useStoredCollection = (id: string) => {
  const [state, fetch] = useStoredCollectionService();
  useEffect(() => {
    fetch(id);
  }, [fetch, id]);
  return state;
};
