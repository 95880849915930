import { FeatureRecord_asCollection } from '../dataManagement/poiCollectionActions';
import { LocationRecord } from '../dataManagement/poiCollectionStore';
import { POICollection } from '../types';

export const downloadBlob = (blob: Blob, fileName: string): void => {
  const href = URL.createObjectURL(blob);
  downloadURL(href, fileName);
  URL.revokeObjectURL(href);
};

export const downloadURL = (href: string, fileName: string): void => {
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const downloadLocationsAsGeojson = (
  record: LocationRecord,
  collectionName = 'locations',
): void => {
  const collection: POICollection = FeatureRecord_asCollection(record);
  const json = JSON.stringify(collection);
  const blob = new Blob([json], { type: 'application/json' });
  downloadBlob(blob, collectionName + '.geojson');
};

export const downloadPOICollectionAsGeojson = (
  collection: POICollection,
  collectionName = 'locations',
): void => {
  const json = JSON.stringify(collection);
  const blob = new Blob([json], { type: 'application/json' });
  downloadBlob(blob, collectionName + '.geojson');
};

export const downloadMetricVersionAsCSV = async (
  content: string,
  metricID: string,
): Promise<void> => {
  downloadBlob(new Blob([content], { type: 'text/csv' }), metricID + '.csv');
};
