import React, { useContext, useState } from 'react';

import { TextInput, MultilineTextInput } from '@unacast-internal/unacast-ui/Inputs';
import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useCurrentFirebaseUser } from '../../contexts/UserContext';
import { submitContactUsForm } from '../../http/hubSpot';
import { ErrorHandlerContext } from '../../contexts/ErrorHandlerContext';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';

const DialogStyled = styled(Dialog)`
  .content {
    display: flex;
    flex-flow: column;
    padding: 0 35px;
    min-width: 300px;
  }

  .actions {
    justify-content: space-between;
    padding: 16px 35px;
  }
`;

const ContactUsDialog = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}): JSX.Element => {
  const user = useCurrentFirebaseUser();
  const [name, setName] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const { logError } = useContext(ErrorHandlerContext);

  const setDefaultState = () => {
    setName('');
    setCompany('');
    setComment('');
  };

  const sendMessage = () => {
    user?.email &&
      submitContactUsForm(user?.email, name, company, comment, 'Data Portal Contact Us').catch(
        (e) => logError({ name: e.code.toString(), ...e }, true),
      );
    setDefaultState();
    handleClose();
  };

  return (
    <DialogStyled onClose={handleClose} open={open}>
      <DialogTitle>Contact us</DialogTitle>
      <motion.div className="content">
        <TextInput disabled={true} label="Email" type="input" value={user?.email} />
        <TextInput
          label="Name"
          type="input"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextInput
          label="Company Name"
          type="input"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />
        <MultilineTextInput
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          label="How can we help?"
          rows={4}
        />
      </motion.div>
      <DialogActions className="actions">
        <UnaButton isBorderless={true} styleType={'secondary'} onClick={handleClose}>
          Cancel
        </UnaButton>
        <UnaButton
          styleType="primary"
          onClick={() => sendMessage()}
          icon={<ArrowForwardIcon />}
          iconPos="end"
        >
          Send
        </UnaButton>
      </DialogActions>
    </DialogStyled>
  );
};

export default ContactUsDialog;
