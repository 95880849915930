import { PoiCollectionService } from '../../unacatInteraction/PoiCollectionService';
import {
  authDecoratedFetchingHook,
  AuthParams,
  createAsyncFetchingHook,
} from '../../unacatInteraction/fetchingHook';
import { Market } from '../types';

const useCreateCollection = createAsyncFetchingHook(
  async (
    { authHeader, billingAccount }: AuthParams,
    name: string,
    market: Market,
  ): Promise<{ collectionId: string }> => {
    const service = new PoiCollectionService(authHeader, billingAccount);
    return await service.createNewCollection(name, market);
  },
);

export const useCreateCollectionHook = authDecoratedFetchingHook(useCreateCollection);
