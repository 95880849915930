import type { FeatureCollection, Geometry } from 'geojson';
import { POIGeometry } from '../types';
import gjv from 'geojson-validation';

export const isGeoJSONFeatureCollection = (obj: unknown): obj is FeatureCollection =>
  typeof obj === 'object' && gjv.valid(obj, 'FeatureCollection');

export const isGeoJSONGeometry = (obj: unknown): obj is Geometry =>
  typeof obj === 'object' && gjv.valid(obj, 'Geometry');

const make2d = ([x, y]: number[]): [number, number] => [x, y];

//CAN BE OFFLOADED TO WKT.redd.toJson
export const POIGeometry_fromGeoJSONGeometry = (g: Geometry): POIGeometry | null => {
  switch (g.type) {
    case 'Point':
      //TODO:
      //We shouldn't calculate this on our end,
      // this sould be done in the call to the Parcel api
      const radius = (g as { properties?: { radius?: number } }).properties?.radius || 30;
      return {
        type: 'Point',
        coordinates: make2d(g.coordinates),
        properties: { radius },
      };

    case 'Polygon':
      return {
        type: 'Polygon',
        coordinates: g.coordinates.map((ring) => ring.map(make2d)),
      };

    case 'MultiPolygon':
      return {
        type: 'MultiPolygon',
        coordinates: g.coordinates.map((polygon) => polygon.map((ring) => ring.map(make2d))),
      };
    default:
      return null;
  }
};
