import { BringYourOwnPOIService, ReportType } from '../../unacatInteraction/BringYourOwnPOIService';
import { v4 as uuidv4 } from 'uuid';
import {
  authDecoratedFetchingHook,
  AuthParams,
  createAsyncFetchingHook,
} from '../../unacatInteraction/fetchingHook';
import { Market, POICollection } from '../types';

export type DraftReport = {
  reportName?: string;
  clientName?: string;
  reportType?: ReportType;
  selectedMetrics?: { value: string; label: string }[];
  startDate?: Date;
  endDate?: Date;
  collection?: POICollection;
  metrics?: string[];
  market?: Market;
};

const useStoreDraftReport = createAsyncFetchingHook(
  async (
    { authHeader, billingAccount }: AuthParams,
    reportDetails: string,
    pois: string,
    idToUpdate?: string, //Used for updating a currently existing draft
  ): Promise<string> => {
    const service = new BringYourOwnPOIService(authHeader, billingAccount);
    const uniqueID: string = idToUpdate || uuidv4();
    try {
      await service.storeDraftReport(uniqueID, reportDetails || '', pois);
      return uniqueID;
    } catch (e) {
      throw e;
    }
  },
);

export const useStoreDraftReportHook = authDecoratedFetchingHook(useStoreDraftReport);
