import { useMemo, useState } from 'react';

import { Menu } from '@unacast-internal/unacast-ui/Menu';
import { MenuItem } from '@unacast-internal/unacast-ui/MenuItem';
import { BillingAccount } from '@unacast-internal/unacat-js/unacast/subscription/v1/billing_account_pb';
import { useHistory, useParams } from 'react-router-dom';
import { useBillingAccountContext } from '../contexts/BillingAccountContext';
import {
  useCurrentFirebaseUser,
  useCurrentUserLoggedInStatus,
  useCurrentUserLogoutMethod,
  useCurrentUserRoles,
} from '../contexts/UserContext';
import styled from 'styled-components';
import { ClickAwayListener } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { useNowNavigation } from '../components/hooks/useNowNavigation';

const AccountsListStyled = styled.div`
  max-height: 240px;
  overflow-y: scroll;
`;

const UserMenuContainer = ({
  billingAccountList,
}: {
  billingAccountList: BillingAccount.AsObject[];
}): JSX.Element => {
  const { currentBillingAccount, billingAccounts, setCurrentBillingAccount } =
    useBillingAccountContext();
  const onLogout = useCurrentUserLogoutMethod();
  const user = useCurrentFirebaseUser();
  const loggedIn = useCurrentUserLoggedInStatus();
  const userRoles = useCurrentUserRoles();
  const { catalogId } = useParams<{ catalogId: string }>();

  const navTo = useNowNavigation(catalogId || 'rwg');

  const [show, setShow] = useState(false);
  const history = useHistory();

  const onChange = (value) => {
    if (value === 'logOut') {
      onLogout();
    } else {
      history.push('/');
      setCurrentBillingAccount(value);
    }
  };

  const currentBaccName = useMemo(() => {
    if (!currentBillingAccount) return '';
    const name = billingAccountList.find((item) => item.id === currentBillingAccount)?.displayName;
    if (name && name.length > 48) return `${name?.substring(0, 45)}...`;
    return name;
  }, [billingAccountList, currentBillingAccount]);

  //TODO: expose these through some hook from the user context?
  const hasUserSalesRole = useMemo(() => {
    return userRoles.includes(`catrole.${catalogId}.2`);
  }, [catalogId, userRoles]);

  const hasUserDataRole = useMemo(() => {
    return userRoles.includes(`catrole.${catalogId}.1`);
  }, [catalogId, userRoles]);

  const hasUserAdminRole = useMemo(() => {
    return userRoles.includes(`catrole.${catalogId}.20`) || userRoles.includes('admin');
  }, [catalogId, userRoles]);

  return (
    <ClickAwayListener onClickAway={() => setShow(false)}>
      <Menu text={`${user?.displayName || user?.email || ''}, ${currentBaccName}`} isOpen={show}>
        <AccountsListStyled>
          {billingAccounts.length > 1 &&
            billingAccounts.map((bacc) => (
              <MenuItem
                key={bacc.id}
                text={
                  billingAccountList.find((item) => item.id === bacc.id)?.displayName || bacc.id
                }
                value={bacc.id}
                icon={bacc.id === currentBillingAccount ? <CheckIcon /> : undefined}
                onClick={() => {
                  onChange(bacc.id);
                  setShow(false);
                }}
              />
            ))}
        </AccountsListStyled>
        {loggedIn && (
          <>
            <MenuItem
              hasBorder={billingAccounts.length > 1}
              text="Your account"
              value="account"
              onClick={() => {
                history.push('/account');
              }}
            />
            {hasUserDataRole || hasUserAdminRole ? (
              <MenuItem
                text="Data Operations"
                value="dataOperations"
                onClick={() => {
                  history.push(`/ops/${catalogId ?? 'rwg'}/data?bacc=${currentBillingAccount}`);
                }}
              />
            ) : null}
            {hasUserSalesRole || hasUserAdminRole || hasUserDataRole ? (
              <>
                <MenuItem
                  text="Access Management"
                  value="accessmanagement"
                  onClick={() => {
                    navTo.operations.accessManagement();
                  }}
                />
                {/* <MenuItem
                  text="Sales Operations"
                  value="salesOperations"
                  onClick={() => {
                    history.push(`/ops/${catalogId ?? 'rwg'}/sales?bacc=${currentBillingAccount}`);
                  }}
                /> */}
              </>
            ) : null}
            <MenuItem text="Log out" value="logOut" onClick={() => onChange('logOut')} />
          </>
        )}
      </Menu>
    </ClickAwayListener>
  );
};

export default UserMenuContainer;
