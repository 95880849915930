import React from 'react';
import styled from 'styled-components';
import { Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';

const TermsStyled = styled.div`
  margin-top: 2rem;
  width: 70%;
  min-width: 600px;
  font-family: Lato, sans-serif;
  margin-left: auto;
  margin-right: auto;
`;

export const TermsAndConditionsPage = (): JSX.Element => {
  return (
    <TermsStyled>
      <Heading type="h1">
        <span className="c16">Unacast Data Portal Terms of Service</span>
      </Heading>
      <p className="c5">
        <span className="c2">Effective Date: March 12, 2021</span>
      </p>
      <Heading type="h2">
        <span className="c3">Introduction</span>
      </Heading>
      <p className="c5 c10">
        <span className="c2"></span>
      </p>
      <p className="c5">
        <span className="c2">
          PLEASE READ THESE TERMS OF SERVICE CAREFULLY BEFORE USING OR OBTAINING ANY DATA,
          MATERIALS, INFORMATION, PRODUCTS OR SERVICES THROUGH THIS PORTAL.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          These Terms of Service (&ldquo;Terms&rdquo;) govern your use of our Unacast Data Portal
          available at console.unacast.com (the &ldquo;Portal&rdquo;) and related products and
          services, including any content or information provided as part of or through the Portal
          or such related products, services or websites (collectively with the Portal, the
          &ldquo;Services&rdquo;), which are owned or operated by Unacast, Inc., a Delaware
          corporation operating under the name Unacast (&ldquo;Unacast&rdquo;, &ldquo;we&rdquo;,
          &ldquo;our&rdquo; or &ldquo;us&rdquo;).
        </span>
      </p>
      <p className="c1">
        <span className="c6">Our Privacy Policy, available at&nbsp;</span>
        <span className="c7">
          <a
            className="c13"
            href="https://www.google.com/url?q=https://www.unacast.com/privacy%23policy&amp;sa=D&amp;source=editors&amp;ust=1615902536300000&amp;usg=AOvVaw3X_N059tgDiku6HrhQuYyn"
          >
            https://www.unacast.com/privacy#policy
          </a>
        </span>
        <span className="c2">
          , is incorporated by reference into these Terms. Please read these Terms and the Privacy
          Policy carefully before you access the Services, as these Terms form a binding legal
          agreement between you and Unacast.
        </span>
      </p>
      <p className="c5 c10">
        <span className="c2"></span>
      </p>
      <p className="c5">
        <span className="c2">
          These Terms may apply to you individually, the business or other legal entity user you
          represent, or both. If you are entering into these Terms, or using the Portal or Services,
          on behalf of a company or other legal entity, you hereby represent and warrant that you
          have the authority to enter into these Terms on behalf of such entity. By ordering,
          accessing, registering for or using the Services, you: (1) acknowledge that you have read
          and understand these Terms; (2) agree to be bound by them in their entirety, and (3) are
          entering into a legally binding agreement with us. As used in these Terms and unless
          separately identified as applicable to either an individual or entity, &ldquo;you&rdquo;
          and &ldquo;your&rdquo; refer to both you individually and the entity on behalf of which
          you are entering into these Terms. In the event you have entered into a separate written
          agreement with Unacast regarding the Services that contemplates terms that are
          inconsistent with these Terms, the written agreement shall control and these Terms will
          not apply to you to the extent inconsistent with such written agreement, or, if such
          written agreement is contemplated to be in lieu of these Terms, then these Terms shall not
          apply at all.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          IF YOU DO NOT AGREE TO ALL OF THESE TERMS, DO NOT USE THE SERVICES. YOUR USE OF OUR
          SERVICES REQUIRES YOUR ACCEPTANCE OF THESE TERMS AS THEY MAY BE AMENDED FROM TIME TO TIME,
          INCLUDING THE POLICIES INCORPORATED BY REFERENCE HEREIN.
        </span>
      </p>
      <Heading type="h3">
        <span className="c3">1. Overview of Services; Services Content</span>
      </Heading>
      <p className="c5 c10">
        <span className="c2"></span>
      </p>
      <p className="c5">
        <span className="c6">
          1.1. Overview of Services. The Services may include access to various data products, as
          well as related applications. In addition to these Terms, each data product or application
          may describe its own restrictions and limitations (
        </span>
        <span className="c0">e.g.</span>
        <span className="c2">
          &nbsp;redistribution restrictions and API usage limitations) during the selection process,
          and such terms are hereby incorporated into these Terms by reference to the extent you
          select or otherwise access or use the corresponding data product or application.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          1.2. Services Content. All content made available through the Services (including, without
          limitation, all information and infrastructure, associated tools, data, user information,
          newsletters, blog posts, general text, graphics, and their selection and arrangement,
          collectively, the &ldquo;Services Content&rdquo;) is the proprietary information of
          Unacast. No Services Content may be modified, copied, distributed, reproduced,
          republished, downloaded, posted, transmitted, or sold in any form or by any means, in
          whole or in part, without Unacast&rsquo;s prior written permission in each instance.
          Provided that you are eligible for use and access of the Services and subject to the
          conditions of these Terms, you are granted a limited license to access the Services
          Content and to download or print a copy of any portion of the Services Content to which
          you have properly gained access solely for your own use, provided that you keep all
          copyright or other proprietary notices intact. You may not upload or republish Services
          Content on any Internet or Extranet Portal or incorporate the information in any other
          publicly available database or compilation, and any other use of the Services Content is
          strictly prohibited. You agree to abide by all additional copyright notices or
          restrictions contained in the Services Content.
        </span>
      </p>
      <Heading type="h3">
        <span className="c3">2. Data Products</span>
      </Heading>
      <p className="c1 c10">
        <span className="c3"></span>
      </p>
      <p className="c5">
        <span className="c2">
          2.1. Overview. In and through the Portal, you may order certain data products or
          applications included in the Services Content. Each data product and application may have
          its own specific terms applicable to such data product or application (&ldquo;Product
          Terms&rdquo;), which are set forth in corresponding links presented with the data product
          on the Portal and also made available for review during the ordering process, and such
          Product Terms are hereby incorporated into and made a part of these Terms by this
          reference and you automatically agree to such terms by ordering or otherwise accessing the
          corresponding data product or application. In the event of a conflict between these Terms
          and Product Terms, the Product Terms shall control.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          The data products and applications may include data made available by Unacast or
          third-party licensors distributing data through Unacast and the Unacast Data Applications
          available on the Portal (collectively, &ldquo;Unacast Data&rdquo;). &ldquo;Unacast Data
          Applications&rdquo; means the Unacast API, downloads from the Portal, and other web or
          software services or applications developed by Unacast and made available to you in and
          through the Portal that utilize or interact with the Unacast Data, as well as any related
          documentation, source code, applications and other materials related thereto.
        </span>
      </p>
      <p className="c5">
        <span className="c6">
          2.2. Non-Redistribution, Professional Use Parameters. With respect to all Unacast Data
          identified as being provided with no redistribution rights, you may not redistribute such
          Unacast Data. Your use of such Unacast Data is exclusively for professional research
          purposes and may not be utilized to build an application intended for use by end users
          other than for you. You may not share your access to the Unacast Data with other
          individuals. You must disclose in any printed or shared document containing the Unacast
          Data either the Unacast logo or reference that the data is from Unacast. For example, you
          may use the data to create reports (
        </span>
        <span className="c0">e.g</span>
        <span className="c6">
          . analyst reports), provided that the data is presented in a static manner and repackaged
          in a form that adds additional information or analysis of the data beyond basic sharing of
          the data itself in isolation. You may not, however, include Unacast Data identified as
          being provided with no redistribution rights in any application or otherwise provide or
          display the data to a third-party in a dynamic manner (
        </span>
        <span className="c0">i.e.</span>
        <span className="c2">
          &nbsp;frequently updating the data being displayed (through technological means relating
          to an API call or otherwise) as opposed to presenting the data within a certain set once
          as it exists at a specific moment in time). In the event the Product Terms include
          additional or conflicting terms with respect to the non-redistribution use parameters,
          such Product Terms shall apply and supersede these Terms where they conflict.
        </span>
      </p>
      <p className="c5">
        <span className="c6">
          2.3. Non-Redistribution, Non-Professional Use Parameters. With respect to all Unacast Data
          identified as being provided with no redistribution rights, you may not redistribute such
          Unacast Data. Your use of such Unacast Data is exclusively for internal, personal or
          research purposes and may not be utilized to build an application intended for use by end
          users other than for you. You may not utilize the Unacast Data for professional purposes
          for which you are compensated. You may not share your access to the Unacast Data with
          other individuals. You must disclose in any printed or shared document containing the
          Unacast Data either the Unacast logo or reference that the data is from Unacast. For
          example, you may use the data to create reports (
        </span>
        <span className="c0">e.g.</span>
        <span className="c6">
          &nbsp;analyst reports), provided that the data is presented in a static manner and
          repackaged in a form that adds additional information or analysis of the data beyond basic
          sharing of the data itself in isolation. You may not, however, include Unacast Data
          identified as being provided with no redistribution rights in any application or otherwise
          provide or display the data to a third-party in a dynamic manner (
        </span>
        <span className="c0">i.e.</span>
        <span className="c2">
          &nbsp;frequently updating the data being displayed (through technological means relating
          to an API call or otherwise) as opposed to presenting the data within a certain set once
          as it exists at a specific moment in time). In the event the Product Terms include
          additional or conflicting terms with respect to the non-redistribution use parameters,
          such Product Terms shall apply and supersede these Terms where they conflict.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          2.4. Redistribution Use Parameters. With respect to all Unacast Data identified as being
          provided with redistribution rights, you are permitted to receive, process, and display
          the Unacast Data in your own applications to individual end users of your applications,
          provided you or your End Users use the data in such a way as to not resell or otherwise
          commercially exploit the Unacast Data (provided that you may commercialize your
          applications so long as such commercialization and the demand for such application is not
          directly or primarily tied to the Unacast Data itself and material, substantial
          functionality and/or analysis is provided in connection with such Unacast Data). You are
          also permitted to systematically query the API with respect to such data (subject to
          applicable API Call Limits). You must disclose in your application, website or software
          (on any page where Unacast Data is used) either the Unacast logo or reference that the
          data is from Unacast. In the event the Product Terms include additional or conflicting
          terms with respect to the redistribution use parameters, such Product Terms shall apply
          and supersede these Terms where they conflict.
        </span>
      </p>
      <p className="c5">
        <span className="c6">
          2.5. Restrictions. Regardless of whether the data is redistributable, you shall not
          repackage or resell the Unacast Data or Unacast Data Applications in any form, including,
          without limitation, bulk form (subject to your limited rights to use and display the data
          as set forth in Section 2.2, Section 2.3, or Section 2.4 as applicable, and provided
          further that an entire data feed may be used within a report provided it is repackaged and
          accompanied by additional analysis as opposed to general republishing). If you reach your
          API Call Limit for your subscription (which is set forth in the applicable Product Terms),
          your service will be shut off until the next day, which resets at 12:00am Eastern Standard
          Time US. Additionally, as noted above, your access and use of each Unacast data product or
          application is subject to any restrictions and limitations (
        </span>
        <span className="c0">e.g.</span>
        <span className="c2">
          &nbsp;redistribution restrictions and API usage limitations) set forth in the Product
          Terms for each such data feed or application.
        </span>
      </p>
      <Heading type="h3">
        <span className="c3">3. Beta Products</span>
      </Heading>
      <p className="c1">
        <span className="c11">
          3.1 Beta Product Availability. From time to time Unacast may make available Unacast Data
          Applications in beta, as indicated in the Portal (&ldquo;Beta Products&rdquo;). Although
          made available, Beta Products are still being developed, tested and evaluated. Beta
          Products have not been released for sale, distribution or any use by the general public.
        </span>
      </p>
      <p className="c1 c10">
        <span className="c11"></span>
      </p>
      <p className="c1">
        <span className="c11">
          3.2. No Warranty. BETA PRODUCTS ARE PROVIDED TO YOU &quot;AS IS&quot; WITHOUT WARRANTY OF
          ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTY OF
          MERCHANTABILITY, NON-INFRINGEMENT OR FITNESS FOR A PARTICULAR PURPOSE. USE OF BETA
          PRODUCTS IS ENTIRELY AT YOUR OWN RISK. BETA PRODUCTS SHOULD UNDER NO CIRCUMSTANCES BE USED
          IN CONNECTION WITH SENSITIVE AND/OR VALUABLE USE CASES. IF YOU ARE IN ANY DOUBT, PLEASE DO
          NOT USE BETA PRODUCTS.
        </span>
      </p>
      <p className="c1 c10">
        <span className="c11"></span>
      </p>
      <p className="c1">
        <span className="c11">
          3.3 No liability. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, UNACAST WILL NOT BE
          LIABLE TO YOU FOR ANY DAMAGES ARISING OUT OF THE USE OR INABILITY TO USE BETA PRODUCTS
          (INCLUDING BUT NOT LIMITED TO LOSS OF DATA OR DATA BEING RENDERED INACCURATE OR LOSSES
          SUSTAINED BY YOU OR THIRD PARTIES OR A FAILURE TO USE BETA PRODUCTS. IN NO EVENT SHALL
          UNACAST&rsquo;S LIABILITY EXCEED THE PRICE YOU PAID FOR BETA PRODUCTS, IF APPLICABLE.
        </span>
      </p>
      <p className="c1 c10">
        <span className="c11"></span>
      </p>
      <p className="c1">
        <span className="c14">
          3.4. WE EXPRESSLY REPRESENT THAT BETA PRODUCTS ARE NOT FINAL PRODUCTS AND, AS SUCH, MAY
          CONTAIN VARIOUS ERRORS AND DEFECTS. WE DO NOT RECOMMEND USING BETA PRODUCTS FOR PURPOSES
          OF EVERYDAY BUSINESS USES.
        </span>
      </p>
      <p className="c1 c10">
        <span className="c3"></span>
      </p>
      <Heading type="h3">
        <span className="c3">4. Your Eligibility; Your Responsibility</span>
      </Heading>
      <p className="c5 c10">
        <span className="c2"></span>
      </p>
      <p className="c5">
        <span className="c2">
          To be eligible to use the Services, you represent and warrant that you: (i) are at least
          18 years of age, or otherwise over the age of majority in the jurisdiction in which you
          reside; (ii) are not currently restricted from the Services and are not otherwise
          prohibited from having an account related thereto; (iii) will only provide accurate
          information to Unacast; (iv) have full power and authority to enter into these Terms and
          doing so will not violate any other agreement to which you are a party; and (v) will not
          violate any rights of Unacast or a third party. If you are entering into these Terms on
          behalf of a legal entity of which you are an employee or agent, you represent and warrant
          that you have all necessary rights and authority to bind such legal entity.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          You assume all responsibility for your use of, and access to, the Services. Accounts are
          for a single user, company or other legal entity, as applicable. Any multiple-party use,
          other than individual use on behalf of a company or other legal entity, is prohibited. For
          example, sharing a login between non-entity individual users is prohibited.
        </span>
      </p>
      <Heading type="h3">
        <span className="c3">5. Your Account</span>
      </Heading>
      <p className="c5">
        <span className="c2">
          5.1. Accuracy. By registering for our Services, you represent and warrant that all
          information you submit to us is true, accurate, current and complete and that you will
          promptly notify us in writing if your information changes. It is your responsibility to
          keep your account and profile information accurate and updated. We are not responsible for
          any disputes or claims related to any inaccurate, incomplete, or untimely information
          provided by you to us.
        </span>
      </p>
      <p className="c5">
        <span className="c6">
          5.2. Privacy. To use our Services, you must register with us and submit certain personal
          information. You expressly agree that we may collect, disclose, store and otherwise use
          your information in accordance with the terms of the Unacast Privacy Policy, available at{' '}
        </span>
        <span className="c7">
          <a
            className="c13"
            href="https://www.google.com/url?q=https://www.unacast.com/privacy%23policy&amp;sa=D&amp;source=editors&amp;ust=1615902536308000&amp;usg=AOvVaw1_DmzhBCuqRBuOxk-wweQh"
          >
            https://www.unacast.com/privacy#policy
          </a>
        </span>
        <span className="c6">.</span>
      </p>
      <p className="c5">
        <span className="c2">
          5.3. Your Account. With regard to your account, you agree to: (i) keep your password
          secure and confidential; (ii) not permit others to use your account; (iii) not use the
          accounts of others; (iv) not transfer your account to another party; and (v) notify us of
          any actual or suspected unauthorized use of your account. You are responsible for any
          activity occurring under your account. You shall not: (i) select a username already used
          by another person; (ii) use a username in which another person has rights without such
          person&rsquo;s authorization; or (iii) use a username or password that Unacast, in its
          sole discretion, deems offensive or inappropriate. Unacast reserves the right to deny
          creation of your account based on Unacast&rsquo;s inability to verify the authenticity of
          your registration information.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          5.4. Feedback. You may from time to time identify problems, solutions to identified
          problems, provide suggestions, comments or other feedback related to our Services or
          otherwise relating to Unacast (&ldquo;Feedback&rdquo;) to Unacast. You acknowledge and
          agree that all Feedback is and shall be given entirely voluntarily and Unacast shall be
          free to use or disclose such Feedback for any purpose. You further acknowledge and agree
          that your Feedback does not contain confidential or proprietary information and you are
          not entitled to any compensation or reimbursement of any kind from Unacast under any
          circumstances relating to such Feedback.
        </span>
      </p>
      <Heading type="h3">
        <span className="c3">6. Personal Use; Limited License; Ownership</span>
      </Heading>
      <p className="c5 c10">
        <span className="c2"></span>
      </p>
      <p className="c5">
        <span className="c2">
          Subject to the terms and conditions herein, Unacast grants you a limited, revocable,
          non-transferable, non-sublicensable, non-exclusive license and right to access the
          Services through a generally available mobile device, web browser or Unacast-authorized
          website to view content and information, including Unacast Data, and otherwise use the
          Services to the extent intended and permitted by the functionality thereof. This license
          is personal to you, and you may not resell our Services or Unacast Data, permit other
          users access to our Services through your account, or use the Services to host content for
          others. You may not copy or download any content from the Services except with the prior
          written approval of Unacast. You acknowledge that, except as otherwise expressly provided,
          these Terms are solely between you and Unacast.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          Furthermore, without the prior written approval of Unacast, you may not distribute,
          publicly perform or display, lease, sell, transmit, transfer, publish, edit, copy, create
          derivative works from, rent, sub-license, distribute, decompile, disassemble, reverse
          engineer or otherwise make unauthorized use of the Services. Any commercial use not
          expressly authorized is prohibited. You agree not to remove, obscure, or alter copyright,
          patent, trademark, or other proprietary rights notices affixed to the Services. Your
          rights are subject to your compliance with these Terms as well as any other agreements
          applicable to the Services you are using. The Services provided by Unacast are licensed,
          not sold. The Services, and all copies of the Services, are owned by Unacast or its
          third-party licensors and are protected by various intellectual property laws, including,
          without limitation, copyright and trade secret laws. Unacast reserves all rights not
          expressly granted to you herein. You agree that you have no right to any Unacast trademark
          or service mark and may not use any such mark in any way unless expressly authorized by
          Unacast.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          Making unauthorized copies or distribution of Portal content or otherwise violating these
          Terms may result in the termination of your Unacast account, prohibition on use of the
          Services, and further legal action. Unacast reserves the right to limit your use of or
          access to the Services, in its sole discretion in order to maintain the performance and
          availability of the Services and to enforce these Terms of Service.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          Unacast is not liable for the loss, corruption, alteration or removal of any content
          transmitted using our Services. By using our Services, you expressly waive the right to
          seek damages and agree to hold Unacast harmless for any such loss, alteration, corruption
          or removal. You acknowledge and agree that you are solely responsible for retaining all
          records and reconciling all transaction information relating to your use of the Services.
        </span>
      </p>
      <Heading type="h3">
        <span className="c3">7. Fees; Payment Terms</span>
      </Heading>
      <p className="c5">
        <span className="c2">
          If you purchase any Services that we offer for a fee (&ldquo;Paid Services&rdquo;), you
          agree to pay the applicable fees for the Paid Services when due plus all related taxes.
          All applicable taxes are calculated based on the billing information you provide us at the
          time of purchase. Unless otherwise denoted, all fees are assessed in U.S. dollars. You
          also agree that Unacast and its third-party service providers providing payment processing
          services may store your payment information. We may charge your payment information for
          subsequent charges you authorize, such as account upgrades or other special charges
          authorized by you. If the payment method you use with us reaches its expiration date and
          you do not edit the applicable information or cancel such Paid Service, you authorize us
          to continue billing that payment method and you remain responsible for any uncollected
          amounts. If you purchase a subscription to a Paid Service, you will be billed for your
          first month immediately upon purchasing a subscription account. Unless otherwise set forth
          on an applicable ordering document incorporating these Terms, the Services are billed in
          advance and are non-refundable. Your subscription account shall automatically renew,
          provided that you may cancel the subscription any time before the end of the current
          billing period and the cancellation will take effect on the next billing period. You shall
          retain access to such Paid Services from the time you cancel until the start of the next
          billing period, but you will not receive a refund or credit for any days remaining in your
          current billing period. You agree to reimburse us for all collection costs and interest
          for any overdue amounts.
        </span>
      </p>
      <p className="c5" id="h.gjdgxs">
        <span className="c2">
          Failure to pay may result in the termination of your subscription. You may cancel or
          suspend your Paid Services through your account or by contacting Unacast at
          info@unacast.com. Unless expressly stated to the contrary, we do not guarantee refunds for
          lack of usage, dissatisfaction or any other reason.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          Paid Services may be subject to additional terms, in addition to these Terms, related to
          the provision of the Paid Service.
        </span>
      </p>
      <Heading type="h3">
        <span className="c3">8. Acceptable Use Policy</span>
      </Heading>
      <p className="c5">
        <span className="c2">
          You agree to comply with all applicable laws and regulations in connection with your use
          of the Services. You may not use our Services for any illegal purposes, including without
          limitation any uses that would constitute a criminal offense, give rise to civil
          liability, or otherwise violate any local, state, national or international law or
          regulation. In particular, the following is a representative, non-exhaustive list of acts
          that are prohibited:
        </span>
      </p>
      <ul className="c9 lst-kix_list_13-0 start">
        <li className="c12 li-bullet-0">
          <span className="c2">
            Acts that may materially and adversely affect the quality of other users&rsquo;
            experience;
          </span>
        </li>
        <li className="c12 li-bullet-0">
          <span className="c2">
            Actual or attempted unauthorized use or sabotage of any computers, machines or networks;
          </span>
        </li>
        <li className="c12 li-bullet-0">
          <span className="c2">
            Introducing malicious programs into the Services, network or servers (e.g. viruses,
            worms, Trojan horses, etc.);
          </span>
        </li>
        <li className="c12 li-bullet-0">
          <span className="c2">
            Engaging in any monitoring or interception of data not intended for you without
            authorization;
          </span>
        </li>
        <li className="c12 li-bullet-0">
          <span className="c2">
            Attempting to circumvent authentication or security of any host, network, or account
            without authorization;
          </span>
        </li>
        <li className="c12 li-bullet-0">
          <span className="c2">
            Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the
            source code for any underlying intellectual property used to provide the Services, or
            any part thereof;
          </span>
        </li>
        <li className="c12 li-bullet-0">
          <span className="c2">
            Adapt, modify or create derivative works based on the Services, technology underlying
            the Services, or other users&rsquo; content, in whole or part;
          </span>
        </li>
        <li className="c12 li-bullet-0">
          <span className="c2">
            Duplicate, license, sublicense, publish, broadcast, transmit, distribute, perform,
            display, sell, rebrand, or otherwise transfer information found on the Services except
            as permitted in these Terms, or as expressly authorized by Unacast in writing;
          </span>
        </li>
        <li className="c12 li-bullet-0">
          <span className="c2">
            Using any method, software or program designed to collect identity information,
            authentication credentials, or other information;
          </span>
        </li>
        <li className="c12 li-bullet-0">
          <span className="c2">Falsifying user identification information;</span>
        </li>
        <li className="c12 li-bullet-0">
          <span className="c2">
            Using the Services for anything other than lawful purposes including, but not limited
            to, intentionally or unintentionally violating any applicable local, state, national or
            international law; or
          </span>
        </li>
        <li className="c12 li-bullet-0">
          <span className="c2">
            Impersonating any person or entity, including, but not limited to, a Unacast
            representative, or falsely stating or otherwise misrepresenting your affiliation with a
            person or entity.
          </span>
        </li>
      </ul>
      <Heading type="h3">
        <span className="c3">9. Right to Restrict or Terminate Access</span>
      </Heading>
      <p className="c5">
        <span className="c2">
          Unacast may deny or restrict your access to all or part of the Services without notice in
          its reasonable discretion if it deems that you have engaged in any conduct or activities
          that Unacast in its reasonable discretion believes violates the letter or spirit of any of
          these Terms. If Unacast denies or restricts your access to the Services because of such a
          violation, you shall have no right to obtain any refund or credit for the subscriptions
          fees you have paid.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          You may terminate these Terms by terminating your use of the Services and any related
          account. Unacast may terminate these Terms for any reason or no reason at any time. In the
          event that these Terms or the Services are terminated, you acknowledge and agree that you
          will continue to be bound by these Terms. Following termination, you shall immediately
          cease use of the Services and any license granted to you under any agreement related to
          your use of the Services shall immediately terminate. Unacast will not be liable to you or
          any third party as a result of the termination of these Terms or the Services or for any
          actions taken by Unacast pursuant to these Terms as a result of such termination. Without
          limiting the generality of the foregoing, Unacast will not be liable to you or any third
          party for damages, compensation, or reimbursement relating to your use of the Services, or
          the termination thereof.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          Any sections or terms which by their nature should survive or are otherwise necessary to
          enforce the purpose of these Terms, will survive the termination of these Terms and
          termination of the Services. Termination of these Terms or the Services does not relieve
          you from your obligation to pay Unacast any amounts owed to Unacast.
        </span>
      </p>
      <Heading type="h3">
        <span className="c3">10. Security</span>
      </Heading>
      <p className="c5">
        <span className="c2">
          You acknowledge and agree that you are solely responsible for protecting your password and
          other personal information and for the consequences of not protecting such data. Access to
          our Services and to certain online transactions may involve the use of identification
          numbers, passwords, payment accounts or other individualized nonpublic information
          (&ldquo;Private Documentation&rdquo;). You shall use your best efforts to prevent
          unauthorized use of our Services, your account, or of any Private Documentation, and shall
          promptly report to Unacast any suspected unauthorized use or other breach of security. You
          shall be responsible for any unauthorized use of your account, identification numbers or
          passwords. Unacast shall not be liable for any unauthorized use of payment accounts. If
          the computer system on which you accessed the Service is sold or transferred to another
          party, you warrant and represent that you will delete all cookies and software files
          obtained through use of the Services.
        </span>
      </p>
      <Heading type="h3">
        <span className="c3">11. Disclaimers</span>
      </Heading>
      <p className="c5">
        <span className="c2">
          Actual service coverage, speeds, locations and quality may vary. Unacast will attempt to
          provide the Services at all times, except for limited periods for maintenance and repair.
          However, the Services may be subject to unavailability for a variety of factors beyond our
          control including emergencies, third-party service failures, transmission, equipment or
          network problems or limitations, interference, signal strength, and may be interrupted,
          limited or curtailed. Delays or omissions may occur. We are not responsible for data,
          messages or pages lost, not delivered, delayed or misdirected because of interruptions or
          performance issues with the Services or communications services or networks. We may impose
          usage or Services limits, suspend the Services, or block certain kinds of usage in our
          sole discretion to protect users or the Services. The accuracy and timeliness of data
          received is not guaranteed.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          Neither Unacast nor its other users will be liable for any loss or damage caused by a
          reliance on information obtained in any content in the Services.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES AND ALL PORTAL CONTENT ARE
          PROVIDED ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS, WITHOUT
          WARRANTIES OF ANY KIND, EXPRESS, STATUTORY OR IMPLIED, INCLUDING WITHOUT LIMITATION, ANY
          IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, CUSTOM,
          TRADE, QUIET ENJOYMENT, NONINFRINGEMENT, AVAILABILITY OR ACCURACY OF INFORMATION. UNACAST
          DOES NOT WARRANT THAT THE SERVICES WILL BE AVAILABLE, WILL MEET YOUR REQUIREMENTS OR WILL
          OPERATE IN AN UNINTERRUPTED, ERROR-FREE OR COMPLETELY SECURE MANNER OR THAT ERRORS OR
          DEFECTS WILL BE CORRECTED. UNACAST DOES NOT MAKE ANY REPRESENTATIONS, WARRANTIES, OR
          CONDITIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE SERVICES, IN TERMS OF THEIR
          ACCURACY, RELIABILITY, TIMELINESS, COMPLETENESS, OR OTHERWISE.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF IMPLIED WARRANTIES OR
          CONDITIONS, OR ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE
          LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. IN SUCH EVENT, UNACAST&rsquo;S WARRANTIES
          AND CONDITIONS WITH RESPECT TO THE SERVICES WILL BE LIMITED TO THE GREATEST EXTENT
          PERMITTED BY APPLICABLE LAW IN SUCH JURISDICTION.
        </span>
      </p>
      <Heading type="h3">
        <span className="c3">12. Limitation of Liability</span>
      </Heading>
      <p className="c5">
        <span className="c2">
          UNDER NO CIRCUMSTANCES WILL UNACAST, ITS AFFILIATES, EMPLOYEES, AGENTS, REPRESENTATIVES,
          LICENSORS OR OTHER THIRD PARTY PARTNERS (&ldquo;UNACAST PARTIES&rdquo;) BE LIABLE TO YOU
          OR ANY OTHER PERSON FOR ANY INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL, EXEMPLARY OR
          CONSEQUENTIAL DAMAGES ARISING OUT OF THE USE, INABILITY TO USE, OR THE RESULTS OF USE OF
          OUR SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY
          OTHER LEGAL THEORY; INCLUDING WITHOUT LIMITATION DAMAGES RESULTING FROM LOST PROFITS, LOST
          DATA, LOSS OF BUSINESS OR BUSINESS INTERRUPTION, WHETHER DIRECT OR INDIRECT, ARISING OUT
          OF THE USE, INABILITY TO USE, OR THE RESULTS OF USE OF OUR SERVICES, WHETHER BASED ON
          WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY. YOUR SOLE AND
          EXCLUSIVE REMEDY UNDER THIS AGREEMENT SHALL BE FOR YOU TO DISCONTINUE YOUR USE OF THE
          SERVICES.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          A UNACAST PARTY&rsquo;S TOTAL CUMULATIVE LIABILITY SHALL IN NO EVENT EXCEED THE GREATER
          OF: (A) THE AMOUNT YOU PAID UNACAST FOR YOUR USE OF THE SERVICES IN THE PRIOR THREE (3)
          MONTHS; AND (B) THE SUM OF ONE HUNDRED (100) US DOLLARS.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          SOME STATES OR JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY.
          IN SUCH STATES OR JURISDICTIONS, THE UNACAST PARTIES&rsquo; LIABILITY TO YOU SHALL BE
          LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF
          WARRANTIES, OR EXCLUSION OF DAMAGES IS TO ALLOCATE THE RISKS OF THIS AGREEMENT BETWEEN THE
          PARTIES. THIS ALLOCATION IS REFLECTED IN THE PRICING OFFERED BY UNACAST TO YOU AND IS AN
          ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE
          PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. THE
          LIMITATIONS IN THIS SECTION 12 WILL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE
          OF ANY LIMITED REMEDY IN THESE TERMS.
        </span>
      </p>
      <Heading type="h3">
        <span className="c3">13. Indemnity</span>
      </Heading>
      <p className="c5">
        <span className="c2">
          You agree to defend, indemnify and hold the Unacast Parties harmless from any claim or
          demand, including reasonable attorneys&rsquo; fees, made by any third party arising out of
          or relating to (i) any violation of these Terms by you; (ii) your Content or any other
          content or material you submit or otherwise transmit through our Services; (iii) your
          violation of any rights of another; or (iv) your use of the Services. Unacast reserves the
          right, at its own expense, to assume the exclusive defense and control of any matter
          otherwise subject to defense by you.
        </span>
      </p>
      <Heading type="h3">
        <span className="c3">14. Dispute Resolution</span>
      </Heading>
      <p className="c5">
        <span className="c2">
          Any dispute, controversy or claim arising out of or in connection with these Terms, or the
          breach, termination or invalidity thereof, shall be settled by arbitration administered by
          the American Arbitration Association under its Commercial Arbitration Rules, and judgment
          on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction
          thereof. The seat of arbitration shall be New York, United States. The number of
          arbitrators shall be one (1). The language to be used in the arbitral proceedings shall be
          English. Nothing contained in these Terms shall deny either Party the right to seek
          injunctive or other equitable relief from a court of competent jurisdiction in the context
          of a bona fide emergency or prospective irreparable harm and such an action may be filed
          and maintained notwithstanding any ongoing arbitration proceeding.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          THE PARTIES TO THESE TERMS KNOWINGLY, VOLUNTARILY, AND INTENTIONALLY WAIVE (TO THE EXTENT
          PERMITTED BY APPLICABLE LAW) ANY RIGHT THAT EITHER PARTY MAY HAVE TO A TRIAL BY JURY OF
          ANY DISPUTE ARISING UNDER OR RELATING TO THESE TERMS.
        </span>
      </p>
      <Heading type="h3">
        <span className="c3">15. Electronic Notices and Disclosures</span>
      </Heading>
      <p className="c5">
        <span className="c2">
          You acknowledge and agree that Unacast may provide notices and other disclosures to you
          electronically by posting such notices or other disclosures on Unacast&rsquo;s website or
          by emailing it to you at any email address provided to Unacast by you. Such notices or
          other disclosures shall be considered received by you following the posting on the website
          or twenty-four (24) hours following the email being sent to you, as applicable. Any such
          electronic notice or other disclosure shall have the same effect and meaning as if it had
          been provided to you as a paper copy.
        </span>
      </p>
      <Heading type="h3">
        <span className="c3">16. Changes to the Terms</span>
      </Heading>
      <p className="c5">
        <span className="c2">
          We may add to, change or remove any part of these Terms, at any time without prior notice
          to you other than listing of a later effective date than the one set forth at the top of
          these Terms. Such modification shall be effective immediately upon posting at the Portal.
          As your next visit to the Portal or use of the Services may be governed by different
          Terms, we encourage you to look for a new effective date on these Terms when you visit the
          Portal or use the Services. It is your responsibility to check these Terms periodically
          for changes. If we make any material changes to these Terms, we will endeavor to provide
          registered users with additional notice of any changes, such as at your e-mail address of
          record or when you log-in to your account.
        </span>
      </p>
      <p className="c5">
        <span className="c2">
          Your use or continued use of the Services following the posting or notice of any changes
          to these Terms or any other posted policies shall constitute your acceptance of the
          changed Terms or policies.
        </span>
      </p>
      <Heading type="h3">
        <span className="c3">17. Third-Party Terms &amp; Content</span>
      </Heading>
      <p className="c5">
        <span className="c2">
          From time to time, the Services may contain references or links to third-party materials
          not controlled by Unacast or its suppliers or licensors. Unacast provides such information
          and links as a convenience to you and should not be considered endorsements of such
          websites or any content, products or information offered on such websites. You acknowledge
          and agree that Unacast is not responsible for any aspect of the information or content
          contained in any third-party materials or on any third-party websites accessible or linked
          to the Services. You are responsible for evaluating whether you want to access or use a
          third-party website. Accordingly, if you decide to use third party websites, you do so at
          your own risk. You should review any applicable terms or privacy policy of a third-party
          website before using it or sharing any information.
        </span>
      </p>
      <Heading type="h3">
        <span className="c3">18. Miscellaneous</span>
      </Heading>
      <p className="c5">
        <span className="c2">
          These Terms, along with any rules, guidelines, or policies published on the Unacast
          homepage constitute the entire agreement between Unacast and you with respect to your use
          of our Services. If there is any conflict between the Terms and any other rules or
          instructions posted on the Services, the Terms shall control. No amendment to these Terms
          by you by shall be effective unless acknowledged in writing by Unacast. Notwithstanding
          the foregoing, Unacast reserves the right, in its sole discretion, to modify these Terms
          or the policies referenced herein at any time as set forth above. These Terms shall be
          governed by, and construed in accordance with, the laws of the state of New York, without
          reference to its choice of law rules. You agree that Unacast shall be entitled to apply
          for injunctive remedies or other equitable relief in any jurisdiction. Subject to any
          applicable law to the contrary, you agree that any cause of action arising out of or
          related to the use of our Services must be commenced within one (1) year after the cause
          of action accrues, or such action will be permanently barred. If any portion of these
          Terms is found to be unenforceable or invalid for any reason, that provision will be
          limited or eliminated to the minimum extent necessary so that the rest of these Terms will
          otherwise remain in full force and effect. You may not assign your rights or obligations
          under these Terms without the prior written consent of Unacast. Unacast&rsquo;s failure to
          insist upon or enforce any provision of these Terms shall not be construed as a waiver of
          any provision or right. Any sections or terms which by their nature should survive or are
          otherwise necessary to enforce the purpose of these Terms, will survive the termination of
          these Terms and termination of the Services. All headings included in these Terms are
          included for convenience only, and shall not be considered in interpreting these Terms.
          These Terms do not limit any rights that Unacast may have pursuant to any intellectual
          property laws or any other laws. All rights and remedies available to Unacast, pursuant to
          these Terms or otherwise, at law or in equity, are cumulative and not exclusive of any
          other rights or remedies that may be available to Unacast. In no event shall you seek or
          be entitled to rescission, injunctive or other equitable relief, or to enjoin or restrain
          the operation of the Services, or any other materials issued in connection therewith, or
          exploitation of the Services or any content or other material used or displayed through
          the Services. Except as otherwise expressly set forth herein, there shall exist no right
          of any person, other than you and Unacast, to claim a beneficial interest in these Terms
          or any rights occurring by virtue of these Terms. No independent contractor relationship,
          partnership, joint venture, employer-employee or franchise relationship is created by
          these Terms.
        </span>
      </p>
      <p className="c5">
        <span className="c6">
          If you have any questions, complaints, or claims, you may contact Unacast at
          info@unacast.com.
        </span>
      </p>
      <p className="c10 c18">
        <span className="c17"></span>
      </p>
      <div>
        <p className="c10 c15">
          <span className="c4"></span>
        </p>
        <p className="c8 c10">
          <span className="c4"></span>
        </p>
      </div>
    </TermsStyled>
  );
};
