import { BringYourOwnPOIService } from '../../unacatInteraction/BringYourOwnPOIService';
import {
  authDecoratedFetchingHook,
  AuthParams,
  createAsyncFetchingHook,
} from '../../unacatInteraction/fetchingHook';

export const useReportList = authDecoratedFetchingHook(
  createAsyncFetchingHook(async ({ authHeader, billingAccount }: AuthParams) => {
    const service = new BringYourOwnPOIService(authHeader, billingAccount);
    const reportList = await service.getReportList();
    return reportList;
  }),
);

export const useReportDetails = authDecoratedFetchingHook(
  createAsyncFetchingHook(async ({ authHeader, billingAccount }: AuthParams, id: string) => {
    const service = new BringYourOwnPOIService(authHeader, billingAccount);
    const reportDetails = await service.getReportDetails(id);
    return reportDetails;
  }),
);
