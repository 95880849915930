import React from 'react';

import { Route, Switch, Redirect } from 'react-router';
import { GetDraftContainer } from '../components/containers/GetDraftContainer';
import { GetReportContainer } from '../components/containers/GetReportContainer';
import { POICollectionStoreProvider } from '../dataManagement/poiCollectionStore';
import {
  DraftInfo__fromReport,
  DraftInfo__fromStoredCollection,
} from '../../unacatInteraction/BringYourOwnPOIService';
import { CollectionsPage } from './CollectionsPage';
import { CreateReportPage } from './CreateReportPage/CreateReportPage';
import { MadeToOrderLayout } from './MadeToOrderLayout';
import { CollectionDetailsPage } from './CollectionDetailsPage/CollectionDetailsPage';
import { NewCollectionPage } from './NewCollectionPage';
import { ReportDetailsPage } from './ReportDetailsPage/ReportDetailsPage';
import { ReportListPage } from './ReportListPage/ReportListPage';
import { EditCollectionPage } from './EditCollectionPage';
import { accountCanPublish } from '../utils/featureToggle';
import { POICollectionStoreProviderSimple } from '../dataManagement/POICollectionStoreConnected/poiCollectionStoreSimple';
import { GetStoredCollectionContainer } from '../components/containers/GetStoredCollectionContainer';

export const MadeToOrder: React.FC = () => <MadeToOrderRoutes />;

const MadeToOrderRoutes = (): React.ReactElement => {
  const canPublish = accountCanPublish();

  return (
    <Switch>
      <Route exact path="/made-to-order">
        <MadeToOrderLayout>
          {canPublish ? (
            <ReportListPage></ReportListPage>
          ) : (
            <Redirect exact from="/made-to-order" to="/made-to-order/collections" />
          )}
        </MadeToOrderLayout>
      </Route>
      <Route exact path="/made-to-order/collections">
        <MadeToOrderLayout>
          <CollectionsPage></CollectionsPage>
        </MadeToOrderLayout>
      </Route>
      <Route exact path="/made-to-order/collections/create">
        <MadeToOrderLayout>
          <NewCollectionPage></NewCollectionPage>
        </MadeToOrderLayout>
      </Route>
      <Route exact path="/made-to-order/collections/edit/:collectionId">
        <MadeToOrderLayout>
          <POICollectionStoreProviderSimple>
            <EditCollectionPage></EditCollectionPage>
          </POICollectionStoreProviderSimple>
        </MadeToOrderLayout>
      </Route>
      <Route exact path="/made-to-order/collections/:collectionId">
        <MadeToOrderLayout>
          <CollectionDetailsPage></CollectionDetailsPage>
        </MadeToOrderLayout>
      </Route>

      <Route exact path="/made-to-order/create/from_template/:reportId">
        <MadeToOrderLayout>
          <POICollectionStoreProvider>
            <GetReportContainer
              renderProp={(r) => <CreateReportPage reportDraft={DraftInfo__fromReport(r)} />}
            ></GetReportContainer>
          </POICollectionStoreProvider>
        </MadeToOrderLayout>
      </Route>
      <Route exact path="/made-to-order/create/from_stored_version/:versionId">
        <MadeToOrderLayout>
          <POICollectionStoreProvider>
            <GetStoredCollectionContainer
              renderProp={(c) => (
                <CreateReportPage reportDraft={DraftInfo__fromStoredCollection(c)} />
              )}
            ></GetStoredCollectionContainer>
          </POICollectionStoreProvider>
        </MadeToOrderLayout>
      </Route>
      <Route exact path="/made-to-order/create/from_draft/:draftId">
        <MadeToOrderLayout>
          <POICollectionStoreProvider>
            <GetDraftContainer
              renderProp={(r) => <CreateReportPage reportDraft={r} />}
            ></GetDraftContainer>
          </POICollectionStoreProvider>
        </MadeToOrderLayout>
      </Route>
      <Route exact path="/made-to-order/create">
        <MadeToOrderLayout>
          <POICollectionStoreProvider>
            <CreateReportPage />
          </POICollectionStoreProvider>
        </MadeToOrderLayout>
      </Route>
      <Route exact path="/made-to-order/:reportId">
        <MadeToOrderLayout>
          <GetReportContainer
            renderProp={(r) => <ReportDetailsPage {...r}></ReportDetailsPage>}
          ></GetReportContainer>
        </MadeToOrderLayout>
      </Route>
    </Switch>
  );
};
