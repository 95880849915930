import { fetchAndActivate, getString } from '@firebase/remote-config';
import { remoteConfig } from '../auth/firebase';

remoteConfig.settings.minimumFetchIntervalMillis = 10000;

const fetchFeatureToggles = () =>
  fetchAndActivate(remoteConfig).catch((err) => {
    console.error('failed to fetch and activate remote config', err);
  });
fetchFeatureToggles().catch((err) => {
  console.error('failed to fetch feature toggels', err);
});

remoteConfig.defaultConfig = {};

export const featureForBillingAccount = (billingAccountId: string, featureKey: string): boolean => {
  return (
    getString(remoteConfig, featureKey)
      .split(',')
      .filter((key) => {
        return key === 'all' || key === billingAccountId;
      }).length > 0
  );
};
