import { MoreVert } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import ClickAwayListener from '@mui/base/ClickAwayListener';

const IconStyled = styled.span`
  height: fit-content;
  &:hover {
    cursor: pointer;
  }
`;

export const OverflowMenu = ({ actions }: { actions: Record<string, () => void> }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <>
        <IconStyled onClick={handleMenuClick}>
          <MoreVert />
        </IconStyled>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={anchorEl !== null}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {Object.keys(actions).map((key) => {
            return (
              <MenuItem
                onClick={() => {
                  actions[key]();
                  handleClose();
                }}
                key={key}
              >
                {key}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    </ClickAwayListener>
  );
};
