// MadeToOrder/utils/reportHelpers.ts

import {ReportType} from '../../unacatInteraction/BringYourOwnPOIService';

enum ReportLengthLimitation {
    Quarter,
    Year,
    None,
  }

  export const getDateLimitation = (len: number): ReportLengthLimitation => {
    if (len > 1000) {
      return ReportLengthLimitation.Quarter;
    }
    if (len > 100) {
      return ReportLengthLimitation.Year;
    }
    return ReportLengthLimitation.None;
  }
  
  export const isAdheringToReportLengthLimitation = (report: any, dateLimitation:ReportLengthLimitation): boolean => {
  
    const monthSpan = 
      (report.endDate.getFullYear() - report.startDate.getFullYear()) * 12 +
      (report.endDate.getMonth() - report.startDate.getMonth()) +
      (report.endDate.getDate() > report.startDate.getDate() ? 1 : 0);
    
    if (report.reportType == ReportType.RECURRING){ // for recurring reports
      return true;
    }

    switch (dateLimitation) {
      case ReportLengthLimitation.Quarter:
        return monthSpan >= 0 && monthSpan <= 3;
      case ReportLengthLimitation.Year:
        return monthSpan >= 0 && monthSpan <= 12;
      case ReportLengthLimitation.None:
      default:
        return monthSpan >= 0;
    }
  };
  