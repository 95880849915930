import { FormControlLabel, styled, Switch, SwitchProps } from '@mui/material';
import React from 'react';
import { Elevation1 } from '@unacast-internal/unacast-ui/StyleGuide/Elevation';

const SwitchStyled = styled(Switch)`
  && {
    height: 32px;
    padding: 0;
    padding-right: 12px;
    padding-left: 6px;
  }
  .MuiSwitch-thumb {
    height: 28px;
    width: 1rem;
    border-radius: 6px;
    ${Elevation1};
  }
  .MuiSwitch-switchBase {
    padding: 0;
    padding-top: 2px;
    padding-right: 8px;
    padding-left: 8px;
  }
`;

const FormControlLabelStyled = styled(FormControlLabel)`
  && {
    margin-left: 0;
  }
`;

interface UnaSwitchProps extends SwitchProps {
  label?: string;
}

export const UnaSwitch = ({ label, ...restProps }: UnaSwitchProps) => {
  return (
    <FormControlLabelStyled
      control={<SwitchStyled {...restProps}></SwitchStyled>}
      label={label}
      labelPlacement="start"
    />
  );
};
