import { Modal } from '@unacast-internal/unacast-ui/Organisms';
import { Tabs } from '@unacast-internal/unacast-ui/Tabs';
import React, { useEffect, useState } from 'react';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { Body, Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ReactJson from 'react-json-view';
import { motion } from 'framer-motion';
import { FileModalContentStyled, SampleFileModalStyled } from './SampleFileModalStyled';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import { useFetchSampleDocs } from '../../../components/hooks/useSanityCMSClient';
import { useQueryState } from 'react-router-use-location-state';
import { LightTooltip } from '@unacast-internal/unacast-ui/LightTooltip/LightTooltip';

export const SampleFileModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}): React.ReactElement => {
  const [docs, setDocs] = useState<unknown[]>();
  const apiState = useFetchSampleDocs();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [fileContent, setFileContent] = useState<{ [id: string]: any | undefined }>({});

  //---------------------------------
  //--Once docs have been set
  //--get the content of their associated files for display
  //---------------------------------
  useEffect(() => {
    if (docs) {
      docs.forEach(async (doc: any) => {
        //Check if the file has no associated content, retrieve it
        if (!fileContent[doc.fileType]) {
          await fetchFileContent(doc);
        }
      });
    }
  }, [docs, fileContent]);

  const fetchFileContent = async (fileInfo) => {
    if (fileInfo.SampleFileURL) {
      fetch(fileInfo.SampleFileURL, {
        method: 'GET',
        headers: {
          'Content-Type': 'text/plain',
        },
      })
        .then(async (response) => {
          if (fileInfo.fileType.toLowerCase() === '.geojson') {
            setFileContent({
              [fileInfo.fileType]: JSON.parse(await response.text()),
              ...fileContent,
            });
          } else {
            setFileContent({ [fileInfo.fileType]: await response.text(), ...fileContent });
          }
        })
        .catch((e) => alert(e.message));
    }
  };

  useEffect(() => {
    if (apiState.status === 'succeeded') {
      setDocs(apiState.data);
    }
  }, [apiState]);

  return (
    <SampleFileModalStyled>
      <Modal isOpen={isOpen} onClose={onClose}>
        <FileModalContentStyled
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          {docs && apiState.status !== 'failed' && (
            <>
              <Heading type="h2" className="modal-header">
                File Upload Formats
              </Heading>
              <Body className="modal-description">
                Here are some examples of the types of files we accept:
              </Body>
              <Tabs
                isSmall={true}
                tabs={docs.map((doc: any) => doc.fileType as string)}
                selectedTab={selectedTab}
                onChange={(v) => setSelectedTab(v)}
                renderPanels={docs.map((doc: any) => (
                  <motion.div
                    key={doc.fileType}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="sample-file-info"
                  >
                    <Body>{doc.fileDescription}</Body>
                    {doc.fileType.toLowerCase() !== '.shp' && (
                      <div className="content-container">
                        <div className="file-content">
                          {fileContent && doc.fileType.toLowerCase() === '.geojson' ? (
                            <ReactJson
                              src={fileContent[doc.fileType] || {}}
                              theme="grayscale:inverted"
                            />
                          ) : fileContent ? (
                            fileContent[doc.fileType]
                          ) : (
                            ''
                          )}
                        </div>
                        <LightTooltip title="Download this sample">
                          <a href={`${doc.SampleFileURL}?dl=`} className="download-area">
                            <FileDownloadIcon className="download-icon"></FileDownloadIcon>
                          </a>
                        </LightTooltip>
                      </div>
                    )}
                  </motion.div>
                ))}
              ></Tabs>
            </>
          )}
          {apiState.status === 'failed' && (
            <>
              <MoodBadIcon></MoodBadIcon>
              <Heading>
                Whoops, looks like there was an error getting this info. Try Again later.
              </Heading>
            </>
          )}
        </FileModalContentStyled>
      </Modal>
    </SampleFileModalStyled>
  );
};

export const SampleFileModalWithTooltip = (): React.ReactElement => {
  const [isFileModalOpen, setIsFileModalOpen] = useQueryState<boolean>('isFileModalOpen', false);

  return (
    <SampleFileModalStyled>
      <LightTooltip title="Click to see the types of files we accept" placement="right">
        <HelpCenterIcon
          onClick={() => setIsFileModalOpen(true)}
          className="file-info-icon"
        ></HelpCenterIcon>
      </LightTooltip>
      <SampleFileModal isOpen={isFileModalOpen} onClose={() => setIsFileModalOpen(false)} />
    </SampleFileModalStyled>
  );
};
