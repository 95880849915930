import React from 'react';

import styled from 'styled-components';
import { Navy } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { MadeToOrderSideBar } from '../components/MadeToOrderSideBar';
import TopBar from '../../components/TopBar';
import { useLocation } from 'react-router-dom';

const MadeToOrderLayoutStyled = styled.div`
  min-height: 100vh;
  position: relative;
  display: grid;
  grid-template-areas:
    'nav head head'
    'nav load load'
    'nav main main'
    'nav main main';
  grid-template-rows: 6rem auto 1fr 4rem;
  grid-template-columns: 280px 1fr;
  background-color: ${Navy.Navy10};

  .content {
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    position: relative;
    grid-area: main;
  }

  header {
    grid-area: head;
  }
`;

export const MadeToOrderLayout = ({ children }): React.ReactElement => {
  const location = useLocation();
  return (
    <MadeToOrderLayoutStyled>
      <TopBar isSubPage={true} />
      <MadeToOrderSideBar
        activeSection={location.pathname.includes('collections') ? 'collections' : 'reports'}
      ></MadeToOrderSideBar>
      <div className="content"> {children}</div>
    </MadeToOrderLayoutStyled>
  );
};
