import React from 'react';
import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { Body, Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { UnacastSlider } from '@unacast-internal/unacast-ui/Inputs/Slider/Slider';
import { RadioButtonGroup } from '@unacast-internal/unacast-ui/Inputs';
import { MIN_RADIUS_CUSTOM_POI, MAX_RADIUS_CUSTOM_POI } from '../../../helpers/geography';

type GetPolygonLookupPreferenceProps = {
  onConfirm: () => void;
  onCancel: () => void;
  setUseParcelLookup: (r: boolean) => void;
  radius: number;
  useParcelLookup: boolean;
  hasRadius: boolean;
  setFallbackRadius: (n: number) => void;
};

const GetPolygonLookupPreferenceStyled = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .inner-content {
    width: 65%;
    text-align: center;

    .header-spacing {
      margin-bottom: 2rem;
    }
    .text-spacing {
      margin-bottom: 1.5rem;
    }
  }

  .slider-container {
    width: 60%;
    margin-top: 2rem;
  }

  .button-group {
    margin-top: 4rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const GetPolygonLookupPreference = ({
  onConfirm,
  onCancel,
  setUseParcelLookup,
  radius,
  useParcelLookup,
  hasRadius,
  setFallbackRadius,
}: GetPolygonLookupPreferenceProps): React.ReactElement => {
  return (
    <GetPolygonLookupPreferenceStyled>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="inner-content">
        <Heading type="h2" className="header-spacing">
          How would you like to generate your areas?
        </Heading>

        <Body className="text-spacing">
          Parcel lookup can be more accurate, but large sets can take a long time to process. Try
          Radius for quicker results.
        </Body>
        <RadioButtonGroup
          legend=""
          selectedValue={useParcelLookup ? 'parcel' : 'radius'}
          onChange={(lookup) => {
            setUseParcelLookup(lookup.value.includes('parcel'));
          }}
          radioValues={[
            {
              value: 'parcel',
              label: 'Parcel',
            },
            {
              value: 'radius',
              label: `Radius`,
            },
          ]}
        />
      </motion.div>
      {!useParcelLookup && !hasRadius && (
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1, transition: { type: 'linear' } }}
          className="slider-container"
        >
          <UnacastSlider
            label="Select a fallback Radius"
            defaultValue={radius}
            onChange={setFallbackRadius}
            min={MIN_RADIUS_CUSTOM_POI}
            //We don't want the max radius to be quite so large for bulk sets
            max={MAX_RADIUS_CUSTOM_POI * 0.75}
            step={5}
          />
        </motion.div>
      )}
      <div className="button-group">
        <UnaButton onClick={onCancel}>Cancel</UnaButton>
        <UnaButton onClick={onConfirm} styleType="secondary">
          OK
        </UnaButton>
      </div>
    </GetPolygonLookupPreferenceStyled>
  );
};
