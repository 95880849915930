import { Body, Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { UnacastIcon } from '@unacast-internal/unacast-ui/StyleGuide/UnaIcons/UnacastIcon';
import React from 'react';
import { SmallScreenPageStyled } from './SmallScreenPage.styles';

const SmallScreenPage = () => {
  return (
    <SmallScreenPageStyled>
      <UnacastIcon />
      <Heading type="h3" className="title">
        Unacast Now is currently not supported on mobile
      </Heading>
      <Body className="subtitle">
        Please use the desktop version, or contact us at{' '}
        <a className="email-link" href="mailto:talktous@unacast.com">
          talktous@unacast.com
        </a>
      </Body>
      <svg
        className="unacast-icon"
        viewBox="0 0 8 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.70067 6.76723L4.16898 8.75861L0.697144 6.76723V2.66379L4.16898 0.612061L7.70067 2.66379V6.76723Z"
          fill="#FF8000"
        />
      </svg>
    </SmallScreenPageStyled>
  );
};

export { SmallScreenPage };
