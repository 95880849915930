import { BlackToWhite, Primary } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import styled from 'styled-components';

export const lineLayout = {
  'line-cap': 'round',
};

export const linePaint = {
  'line-color': Primary.Primary01,
  'line-width': 2,
  'line-dasharray': [4, 4],
  'line-opacity': 0.8,
};

export const linePaintFaded = {
  'line-color': Primary.Primary02,
  'line-width': 2,
  'line-dasharray': [4, 4],
  'line-opacity': 0.3,
};

export const fillPaint = {
  'fill-color': Primary.Primary01, // blue color fill: ;
  'fill-opacity': 0.5,
};

export const fillPaintFaded = {
  'fill-color': Primary.Primary02, // blue color fill: ;
  'fill-opacity': 0.15,
};

export const markerPaint = {
  'circle-color': Primary.Primary01,
  'circle-stroke-width': 2,
  'circle-stroke-color': '#fff',
  'circle-stroke-opacity': 1,
};

export const MapStyled = styled.div`
  display: flex;
  flex-direction: column;
  .polygon-action-area {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
  }

  .slider-container {
    width: 50%;
  }
  .map-area {
    .map-control {
      .svg-position {
        position: absolute;
      }
      position: absolute;
      bottom: 0.5rem;
      left: 0.5rem;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 4px;
      width: 2rem;
      height: 2rem;
      border-radius: 6px;
      box-shadow: 0 0 2px rgba(40, 41, 61, 0.04), 0 4px 4px rgba(80, 148, 175, 0.16);

      background-color: ${BlackToWhite.White};

      &:hover {
        cursor: pointer;
      }
    }
    position: relative;
    .map-container {
      width: 635px;
      height: 400px;
    }

    .geocoding-search {
      position: absolute;
      top: 16px;
      left: 16px;
      min-width: 300px;
    }
  }
`;
