import React from 'react';
import { Link } from 'react-router-dom';
import { UnaTooltip } from '@unacast-internal/unacast-ui/UnaTooltip';

import styled from 'styled-components';
import { BlackToWhite, Denim, Primary } from '@unacast-internal/unacast-ui/StyleGuide/Colors';

interface NavProps {
  text: string;
  href: string;
  icon?: (val: string) => React.ReactElement;
  defaultActive?: boolean;
  activeOption?: string;
  disabled?: boolean;
}

export const ExpandableNavItem = ({
  text,
  href,
  icon,
  defaultActive,
  disabled,
}: NavProps): JSX.Element => {
  return (
    <NavItemStyled isActive={defaultActive || false} disabled={disabled}>
      <div className="main-nav-item">
        {icon && icon('currentColor')}
        {disabled ? (
          <UnaTooltip
            title={
              'You do not have access to this dataset. Get in touch with us if you want to get access to this dataset'
            }
            placement="right"
          >
            <p>{text}</p>
          </UnaTooltip>
        ) : (
          <Link to={href}>{text}</Link>
        )}
      </div>
    </NavItemStyled>
  );
};

interface StyleProp {
  isActive: boolean;
  disabled?: boolean;
}

export const NavItemStyled = styled.div<StyleProp>`
  position: relative;
  background-color: ${(props: StyleProp) => {
    if (props.isActive) {
      return BlackToWhite.White;
    }
    return 'transparent';
  }};
  border-top: none;
  height: 60px;
  box-sizing: border-box;
  border-radius: 6px;
  .main-nav-item {
    color: ${(props: StyleProp) => {
      return props.disabled && BlackToWhite.Gray02 + '!important';
    }};
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    ${(props: StyleProp) => {
      if (!props.disabled) {
        return `
           &:hover {
            color: ${Primary.Primary01} !important;
            div {
              color: ${Primary.Primary01} !important;
            }
            a {
              color: ${Primary.Primary01} !important;
            }
            cursor: pointer;
          };
        `;
      }
    }};

    &,
    a {
      text-decoration: none;
      color: ${(props: StyleProp) => (props.isActive ? Primary.Primary01 : Denim.Denim01)};
    }

    .bold {
      font-weight: bold;
    }

    svg {
      margin-left: 1.2rem;
      margin-right: 0.5rem;
    }
  }
`;
