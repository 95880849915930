import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetDraftDetailsHook } from '../../dataManagement/useGetDraftReportsHook';
import { ReportType } from '../../../unacatInteraction/BringYourOwnPOIService';
import { UnaLinearProgress } from '@unacast-internal/unacast-ui/UnaLinearProgress';
import { ReportDraft } from '../../pages/CreateReportPage/CreateReportPage';
import { useQueryState } from 'react-router-use-location-state';

export const GetDraftContainer = ({
  renderProp,
}: {
  renderProp: (r: ReportDraft) => JSX.Element;
}) => {
  const { draftId } = useParams<{ draftId: string }>();
  const [currentDraftState, getCurrentDraft] = useGetDraftDetailsHook();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //Report type is loaded as a query param
  const [, setReportType] = useQueryState<ReportType>('reportType', ReportType.ONE_TIME);
  const [reportDraft, setReportDraft] = useState<ReportDraft>();

  //------------------------------
  //---Get any preloaded data needed from either a draft or previous report----------
  //------------------------------
  useEffect(() => {
    if (draftId) {
      getCurrentDraft(draftId);
    }
  }, [draftId, getCurrentDraft]);

  useEffect(() => {
    if (draftId && currentDraftState.status === 'succeeded') {
      const draftInfo = currentDraftState.data.info;
      draftInfo.reportType !== undefined && setReportType(draftInfo.reportType);

      setReportDraft({
        ...draftInfo,
        collection: currentDraftState.data.collection,
        draftId: currentDraftState.data.id,
      });
    } else if (currentDraftState.status === 'failed') {
      enqueueSnackbar(currentDraftState.error.message, { variant: 'error' });
    }
  }, [currentDraftState, draftId, enqueueSnackbar, setReportType]);

  //---------------------------------------------------------

  return reportDraft ? renderProp(reportDraft) : <UnaLinearProgress></UnaLinearProgress>;
};
