import { LatLonItem } from '../../unacatInteraction/PolygonCreationService';
import { POIFeature } from '../types';
import { CSVRowTransformer, KeyIndex } from './csvOperations';
import { ParseResponse } from './fileTranslatorHook';

/**
 * We'll accept a CSV-files with at least name, lat and lon-columns, and optionally
 * a radius-column as descriptions on how to handle fallback to circular POIs.
 * Fom these we'll generate LatLonItems.
 */
export const parseLatLongItemsFromCSVFile = (
  indexes: KeyIndex,
  linesGen: CSVRowTransformer,
): LatLonItem[] => {
  for (const key of ['name', 'lat', 'lon']) {
    if (indexes[key] === undefined) {
      throw new Error('Missing key in csv-data: ' + key);
    }
  }

  const hasRadius = indexes['radius'] !== undefined;
  return linesGen(
    (values): LatLonItem => ({
      name: values[indexes.name],
      lat: Number.parseFloat(values[indexes.lat]),
      lon: Number.parseFloat(values[indexes.lon]),
      radius: hasRadius ? Number.parseFloat(values[indexes.radius]) : undefined,
    }),
  );
};

export const LatLonItem_asPointWithRadius = (
  { name, lat, lon, radius }: LatLonItem,
  defaultRadius: number,
): POIFeature => ({
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: [lon, lat],
    properties: { radius: radius || defaultRadius },
  },
  id: name,
  properties: { name },
});

/**
 * We'll accept a CSV-files with at least name, lat and lon-columns, and optionally
 * a radius-column as descriptions of circular POIs.
 * Fom these we'll generate LatLonItems and convert to PointWithRadius-features.
 */
export const parseLatLongCSVFile = (
  indexes: KeyIndex,
  linesGen: CSVRowTransformer,
): ParseResponse => {
  const items = parseLatLongItemsFromCSVFile(indexes, linesGen);

  const getFeatures = (getRadius: (item: LatLonItem) => number): POIFeature[] =>
    items.map(
      (item): POIFeature => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [item.lon, item.lat],
          properties: { radius: getRadius(item) },
        },
        id: item.name,
        properties: { name: item.name },
      }),
    );

  if (indexes.radius === undefined) {
    return {
      status: 'partial',
      type: 'csv',
      missing: 'radius',
      provide: (radius: number) => ({
        status: 'success',
        type: 'csv',
        features: getFeatures(() => radius),
      }),
    };
  }

  return {
    status: 'success',
    features: getFeatures(({ radius }) => radius as number),
    type: 'csv',
  };
};
