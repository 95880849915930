import React, { createContext, useContext, useMemo, useState } from 'react';
import { POIFeature } from '../../types';

export type Location = POIFeature & { id: string };
export type LocationRecord = Record<string, Location>;

export type CollectionUpdater = (
  updateFunc: (c: Readonly<LocationRecord>) => LocationRecord | undefined,
) => void;

export type CollectionDeleter = (collectionID: string) => void;

const POICollectionStoreContext = createContext<
  [Locations: LocationRecord, CollectionUpdater: CollectionUpdater, CollectionID?: string]
>([{}, () => undefined, '']);

export const POICollectionStoreProviderSimple: React.FC = ({ children }) => {
  //hook to get list of collections for billing account
  const [collectionId, setCollectionId] = useState<string>();
  const [locations, setLocations] = useState<LocationRecord>({});
  const updateCollection = useMemo(
    (): CollectionUpdater => (updateFunc) => {
      setLocations((values) => {
        const { ...current } = values;
        const nextCurrent = updateFunc(current || {});
        return nextCurrent ? nextCurrent : current;
      });
    },
    [setLocations],
  );

  const P = POICollectionStoreContext.Provider;
  return <P value={[locations, updateCollection, collectionId]}>{children}</P>;
};

export const usePOICollectionStoreSimple = (): [
  Locations: LocationRecord,
  CollectionUpdater: CollectionUpdater,
  CollectionID?: string,
] => {
  return useContext(POICollectionStoreContext);
};
