import { Denim, Navy } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import styled from 'styled-components';

export const SideBarStyled = styled.div`
  grid-area: nav;
  background-color: ${Navy.Navy09};
  padding-left: 2rem;
  padding-right: 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 101;
  position: sticky;
  top: 0;

  .metrics {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  hr {
    border: none;
    border-bottom: 1px ${Denim.Denim06} solid;
    margin-bottom: 2rem;
    width: 100%;
    margin-top: 4rem;
  }

  .side-header-spacing {
    margin-bottom: 2rem;
  }

  .option-spacing {
    margin-bottom: 1rem;
  }

  .logo-area {
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
`;
