import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { Body, Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import styled from 'styled-components';
import { UploadPOICollectionButton } from './UploadPOICollectionButton';
import { SampleFileModal } from './SampleFileModal/SampleFileModal';

const EmptyPromptStyled = styled.div`
  padding-top: 1rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .content-spacing {
    padding-bottom: 1rem;
    text-align: center;
  }

  .fit-button {
    width: fit-content;
  }

  .highlight-button {
    padding-bottom: 1rem;
  }
`;

export const EmptyCollectionPrompt = ({
  onDrawClick,
  onComplete,
}: {
  onDrawClick: (s: string) => void;
  onComplete: (result: ArrayBuffer, fileName: string) => void;
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <EmptyPromptStyled>
      <Heading className="content-spacing" type="h2">
        Add Locations By
      </Heading>

      <div className="fit-button">
        <UploadPOICollectionButton
          text="Adding a File"
          onComplete={onComplete}
        ></UploadPOICollectionButton>
      </div>
      <UnaButton onClick={() => setShowModal(true)} styleType="info" className="highlight-button">
        *Read more about Supported Files
      </UnaButton>
      <Body className="content-spacing">or</Body>
      <UnaButton onClick={() => onDrawClick('ADD_FEATURE')} icon={<AddIcon />} iconPos="end">
        Drawing an Area
      </UnaButton>
      <SampleFileModal isOpen={showModal} onClose={() => setShowModal(false)} />
    </EmptyPromptStyled>
  );
};
