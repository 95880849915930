import React from 'react';
import styled from 'styled-components';
import { Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';

const InviteOnlyPageStyled = styled.div`
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h3 {
    padding-top: 2rem;
  }

  a {
    color: #007fff !important;
  }
`;

const InviteOnlyPage = (): JSX.Element => {
  return (
    <InviteOnlyPageStyled>
      <Heading type="h3">This product is currently only open to invited users.</Heading>{' '}
      <Heading type="h3">
        {' '}
        Please{' '}
        <a href="https://www.unacast.com/solutions/data-portal" target="_blank" rel="noreferrer">
          contact us
        </a>{' '}
        for more information.
      </Heading>
    </InviteOnlyPageStyled>
  );
};

export default InviteOnlyPage;
