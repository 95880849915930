import React from 'react';
import { BlackToWhite, Info } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import styled from 'styled-components';
import { Elevation1 } from '@unacast-internal/unacast-ui/StyleGuide/Elevation';
import { motion } from 'framer-motion';
import { LightTooltip } from '@unacast-internal/unacast-ui/LightTooltip/LightTooltip';

type MapControlStyleProps = {
  top?: string;
  bottom?: string;

  left?: string;

  right?: string;
  isSelected?: boolean;
};
const MapControlStyled = styled(motion.span)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  width: 2rem;
  height: 2rem;
  border-radius: 6px;
  ${({ bottom, top, left, right }: MapControlStyleProps) =>
    (bottom || top || left || right) && 'position: absolute;'}

  ${Elevation1}
  ${({ bottom }: MapControlStyleProps) => (bottom ? `bottom: ${bottom};` : '')}
${({ top }: MapControlStyleProps) => (top ? `top: ${top};` : '')}

${({ left }: MapControlStyleProps) => (left ? `left: ${left};` : '')}

${({ right }: MapControlStyleProps) => (right ? `right: ${right};` : '')}

  background-color: ${({ isSelected }: MapControlStyleProps) =>
    isSelected ? `${Info.Info01}` : `${BlackToWhite.White}`};

  &:hover {
    cursor: pointer;
  }
`;

type MapControlProps = {
  onClick: () => void;
  icon: React.ReactNode;
  tooltipText: string;
  top?: string;
  bottom?: string;

  left?: string;

  right?: string;
  isSelected?: boolean;
};

export const MapControl = ({
  onClick,
  top,
  bottom,
  left,
  right,
  icon,
  tooltipText,
  isSelected = false,
}: MapControlProps) => {
  return (
    <MapControlStyled
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      onClick={onClick}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 1 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      isSelected={isSelected}
    >
      <LightTooltip title={tooltipText} placement="top">
        {icon}
      </LightTooltip>
    </MapControlStyled>
  );
};
