import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { Denim } from '@unacast-internal/unacast-ui/StyleGuide/Colors';

const FormControlStyled = styled(FormControl)`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const SelectStyled = styled(Select)`
  background-color: #fff;
  border-color: ${Denim.Denim09};

  &&.MuiOutlinedInput-notchedOutline {
    border-color: ${Denim.Denim06};
  }
  &&.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${Denim.Denim05};
  }
`;

type MultiSelectProps = {
  options: string[];
  onChange: (event: SelectChangeEvent<string[]>) => void;
  value: string[];
  label: string;
};

export const MultiSelect = ({
  options,
  onChange,
  value,
  label,
}: MultiSelectProps): React.ReactElement => {
  return (
    <FormControlStyled>
      <InputLabel id="metric-select-label">{label}</InputLabel>
      <SelectStyled
        labelId="metric-select-label"
        multiple
        value={value}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected: string[]) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {selected.map((v) => (
              <Chip key={v} label={v} />
            ))}
          </Box>
        )}
      >
        {options.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </SelectStyled>
    </FormControlStyled>
  );
};
