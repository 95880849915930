import { getApps, initializeApp } from '@firebase/app';
import { getAuthorizationHeaderIfLoggedIn, getIamServicePromiseClient } from '../rpc';
import {
  CreateUserRequest,
  GetUserRequest,
} from '@unacast-internal/unacat-js/unacast/iam/v1/iam_service_pb';
import { IamUser } from '@unacast-internal/unacat-js/unacast/iam/v1/iam_user_pb';
import firebaseOptions from './firebaseOptions';
import { storeUserSolutions } from '../contexts/UserContext';
import {
  applyActionCode,
  AuthError,
  browserLocalPersistence,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  Unsubscribe,
  User,
  UserCredential,
  verifyPasswordResetCode,
} from '@firebase/auth';
import { getRemoteConfig } from '@firebase/remote-config';
import { submitNewUserForm } from '../http/hubSpot';

export const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.addScope('https://www.googleapis.com/auth/plus.login');
googleAuthProvider.setCustomParameters({
  prompt: 'select_account',
});

if (!getApps().length) {
  initializeApp(firebaseOptions);
}

export const remoteConfig = getRemoteConfig();
remoteConfig.settings = {
  minimumFetchIntervalMillis: 1,
  fetchTimeoutMillis: 30000,
};

export const login = (): Promise<void> =>
  signInWithPopup(getAuth(), googleAuthProvider).catch((error) => {
    console.error('firebase.getAuth().signInWithPopup(googleAuthProvider)', error);
    return error;
  });

export const logout = (): Promise<void> => signOut(getAuth());

export const createUser = (email: string, password: string): Promise<UserCredential> =>
  createUserWithEmailAndPassword(getAuth(), email, password);

export const loginEmail = (email: string, password: string): Promise<UserCredential> =>
  signInWithEmailAndPassword(getAuth(), email, password);

export const getIamUser = async (): Promise<IamUser | undefined> => {
  const iamServicePromiseClient = getIamServicePromiseClient();
  const userEmail = getAuth().currentUser?.email;
  if (!userEmail) {
    throw new Error('');
  }
  const authResponse = await getAuthorizationHeaderIfLoggedIn();
  if (!authResponse) {
    const err = new Error('could not get authorization token');
    handleError(err.message, err);
    throw err;
  }
  try {
    const iamUser = await iamServicePromiseClient.getCurrentUser(
      new GetUserRequest().setEmail(userEmail),
      {
        Authorization: authResponse.Authorization,
      },
    );
    return iamUser;
  } catch (err) {
    handleError('Cannot get iamUser by Email', err);
  }
};

export const getUser = (): User | null => {
  const { currentUser: user } = getAuth();
  return user;
};

type ClaimProps = Array<string>;

export const getClaims = async (): Promise<ClaimProps | undefined> => {
  const user = await getIamUser();
  if (user) {
    return user.getClaims()?.getClaimStringsList();
  }
  return undefined;
};

export const getDefaultBillingAccount = async (): Promise<string | null> => {
  const claims = await getClaims();
  if (claims) {
    const billingAccounts = claims
      .map((claim) => claim.split('.'))
      .filter((claimParts) => claimParts.length === 2 && claimParts[0] === 'bacc')
      .map((claimParts) => claimParts[1]);
    return billingAccounts.length > 0 ? billingAccounts[0] : null;
  }
  return null;
};

export const authStateChanged = (): Unsubscribe => {
  return onAuthStateChanged(getAuth(), (user) => {
    return user ? true : false;
  });
};

export const resetPassword = (email: string): Promise<unknown> => {
  return sendPasswordResetEmail(getAuth(), email)
    .then((res) => res)
    .catch((error) => {
      handleError('firebase.getAuth().sendPasswordResetEmail(email)', error);
      return error;
    });
};

type PasswordSignupResponse =
  | { status: 'success'; user: User | null }
  | { status: 'error'; error: AuthError };
export const emailPasswordSignUp = async (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  company: string,
  utmFields: Record<string, string>,
  initialProductSetup?: { industry: string; solution: string },
): Promise<PasswordSignupResponse> => {
  await setPersistence(getAuth(), browserLocalPersistence);
  try {
    const { user } = await createUserWithEmailAndPassword(getAuth(), email, password);
    const iamUser = await getIamServicePromiseClient().createUser(
      new CreateUserRequest()
        .setEmail(email)
        .setCompanyName(company)
        .setFullName(`${firstName} ${lastName}`),
    );
    await submitNewUserForm(
      email,
      firstName,
      lastName,
      company,
      utmFields.source,
      utmFields.medium,
      utmFields.campaign,
    );
    if (user && initialProductSetup) {
      // TODO: Add to CreateUserRequest; handle backend
      const bacc = (await getDefaultBillingAccount()) || '_default';
      const userObject = {
        [bacc]: { [initialProductSetup.industry]: [initialProductSetup.solution] },
      };
      iamUser.getSettings()?.setUserObject(JSON.stringify(userObject));
      await storeUserSolutions(email, await user.getIdToken(), userObject);
    }
    // Probable next-step for this user is to send an email for validation. URL-config etc makes that a responsibility for caller
    return { status: 'success', user };
  } catch (error) {
    switch (error.code) {
      case 'auth/email-already-in-use':
        handleError(`Email address ${email} is already in use.`, error);
        break;
      case 'auth/invalid-email':
        handleError(`Email address ${email} is invalid.`, error);
        break;
      default:
        handleError('firebase.getAuth().createUserWithEmailAndPassword(email, password)', error);
    }
    return { status: 'error', error }; // or re-throw?
  }
};

type EmailPasswordLoginResponse =
  | { status: 'success'; user: UserCredential }
  | { status: 'error'; error: AuthError };
export const emailPasswordLogin = async (
  email: string,
  password: string,
): Promise<EmailPasswordLoginResponse> => {
  await setPersistence(getAuth(), browserLocalPersistence);
  try {
    return {
      status: 'success',
      user: await signInWithEmailAndPassword(getAuth(), email, password),
    };
  } catch (error) {
    switch (error.code) {
      case 'auth/invalid-email':
        handleError(`Email address ${email} is invalid.`, error);
        break;
      case 'auth/too-many-requests':
        handleError(`Too many attempts, try again later.`, error);
        break;
      case 'auth/wrong-password':
        handleError(`Email or password is wrong.`, error);
        break;
      case 'auth/user-not-found':
        handleError(`This user was not found, try signing up first`, error);
        break;
      default:
        handleError('firebase.getAuth().signInWithEmailAndPassword(email, password)', error);
    }
    return { status: 'error', error };
  }
};

export const verifyEmail = (opCode: string): Promise<void> => applyActionCode(getAuth(), opCode);

export const initiatePasswordReset = (oopCode: string): Promise<string> =>
  verifyPasswordResetCode(getAuth(), oopCode);
export const executePasswordReset = (oopCode: string, newPassword: string): Promise<void> =>
  confirmPasswordReset(getAuth(), oopCode, newPassword);

const handleError = function (method: string, error: Error) {
  console.error(
    `Firebase ${method} failed, error code ${error.name} error message ${error.message}`,
  );
};
