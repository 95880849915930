import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { RadioButtonGroup } from '@unacast-internal/unacast-ui/Inputs';
import { BlackToWhite } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { Body, Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Market } from '../types';

const PromptStyled = styled.div`
  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  background-color: ${BlackToWhite.White};
  border-radius: 6px;
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(80, 148, 175, 0.16);
  width: 650px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 650px;

  .spacing {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
`;

export const GetMarketPrompt = ({ onComplete }: { onComplete: (m: Market) => void }) => {
  const [market, setMarket] = useState<Market>('international');
  return (
    <PromptStyled>
      <Heading type="h2">Where are your locations?</Heading>
      <Body type="small01">*Your region determines the types of insights we can provide</Body>
      <div className="spacing">
        <RadioButtonGroup
          legend=""
          selectedValue={market}
          onChange={(newMarket) => {
            setMarket(newMarket.value as Market);
          }}
          radioValues={[
            {
              value: 'international',
              label: 'International',
            },
            {
              value: 'us',
              label: 'United States',
            },
          ]}
        />
      </div>
      <UnaButton onClick={() => void onComplete(market)}>Confirm</UnaButton>
    </PromptStyled>
  );
};
