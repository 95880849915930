export type KeyIndex = Record<string, number>;
export type CSVRowTransformer = <T>(mapper: (row: string[]) => T) => T[];

const splitRow = (row: string, delimiter: string): string[] => {
  const normal = '([^"' + delimiter + ']+)(?:' + delimiter + '|$)'; // Normal row (anything not " or separator up to separator or end)
  const escaped = '("(?:[^"]|"")+")(?:' + delimiter + '|$)'; // wrapped in quotes. (Quotes in escaped string is escaped to double-quotes)
  const regexp = new RegExp('(?:' + normal + '|' + escaped + ')', 'g');
  const results: string[] = [];
  let match: RegExpExecArray | null;
  while ((match = regexp.exec(row))) {
    // Two capturing groups in regexp (1: normal, 2: escaped)
    if (match[1] !== undefined) {
      results.push(match[1]);
    } else {
      results.push(
        match[2]
          .substring(1, match[2].length - 1) // Remove enclosing quotes
          .replace(/""/g, '"'), // Replace escaped quotes with normal quotes
      );
    }
  }
  return results;
};

export const initCSV = (content: string, delimiter = ','): [KeyIndex, CSVRowTransformer] => {
  const [headers, ...lines] = content.split(/\r?\n/);

  // Handle newline at end of file
  if (lines[lines.length - 1] === '') {
    lines.pop();
  }

  /* Sometimes newlines occur inside quoted values (ie excel does this for json-data in exports).
   * These lines must be re-combined before processing
   * Lines containing an odd number of quote-marks must be combined with the following line untill it has even number.
   * Strategy: Look at the last line, if it has even number of quotemarks, join it with the previous line.
   * Continue forward untill all lines have been visited */
  let i = lines.length;
  while (--i > 0) {
    const quotemarks = (lines[i].match(/"/g) || []).length;
    if (quotemarks % 2 === 1) {
      lines.splice(i - 1, 2, lines[i - 1] + '\n' + lines[i]);
    }
  }

  const headerDict: KeyIndex = {};
  headers.split(delimiter).forEach((key, index) => {
    headerDict[key] = index;
  });

  const mapping: CSVRowTransformer = (mapper) => lines.map((v) => mapper(splitRow(v, delimiter)));

  return [headerDict, mapping];
};
