import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';

import packageJson from '../package.json';
import './index.css';
import './fonts/Lato-Regular.ttf';
import './fonts/Lato-Bold.ttf';
import './fonts/Roboto-Regular.ttf';
import './fonts/SourceCodePro-Regular.ttf';
import App from './App';

import ignoreErrorList from './helpers/ignoreErrorList';

Sentry.init({
  dsn: 'https://126513d274c54ebcb80588de616b6b76@o366058.ingest.sentry.io/5191772',
  environment: process.env.REACT_APP_ENV,
  release: `${packageJson.name}@${packageJson.version}-${process.env.REACT_APP_GIT_SHA}`,
  whitelistUrls: ['portal.unacast.com/static/js'],
  ignoreErrors: ignoreErrorList,
  enabled: Boolean(process.env.REACT_APP_ENV),
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
