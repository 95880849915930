import { History } from 'history';
import { useHistory } from 'react-router-dom';
import { ReportType } from '../../unacatInteraction/BringYourOwnPOIService';

class MadeToOrderNavigation {
  public constructor(private history: History) {}

  homePage() {
    this.history.push('/made-to-order');
  }

  collectionsPage() {
    this.history.push('/made-to-order/collections');
  }

  editCollection(collectionId: string) {
    this.history.push('/made-to-order/collections/edit/' + collectionId);
  }

  collectionDetails(collectionId: string) {
    this.history.push('/made-to-order/collections/' + collectionId);
  }

  newCollection() {
    this.history.push('/made-to-order/collections/create');
  }

  reportDetails(reportId: string) {
    this.history.push('/made-to-order/' + reportId);
  }
  createReport(reportType?: ReportType) {
    this.history.push({
      pathname: '/made-to-order/create',
      search: `?reportType=${reportType}`,
    });
  }

  continueDraft(draftId: string, reportType?: ReportType) {
    this.history.push({
      pathname: '/made-to-order/create/from_draft/' + draftId,
      search: `?reportType=${reportType}`,
    });
  }

  createReportFromTemplate(reportId: string, reportType?: ReportType) {
    this.history.push({
      pathname: '/made-to-order/create/from_template/' + reportId,
      search: `?reportType=${reportType}`,
    });
  }

  createReportFromStoredVersion(versionId: string, reportType?: ReportType) {
    this.history.push({
      pathname: '/made-to-order/create/from_stored_version/' + versionId,
      search: `?reportType=${reportType}`,
    });
  }

  metricDetailsPage(catalogId: string, metricId: string) {
    const bacc = new URLSearchParams(this.history.location.search).get('bacc');
    window.open(`/c/${catalogId}/product/made-to-order/${metricId}?bacc=${bacc}`, '_blank');
  }
}

export const useMadeToOrderNavigation = (): MadeToOrderNavigation => {
  const history = useHistory();
  return new MadeToOrderNavigation(history);
};
