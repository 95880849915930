import {
  AppendCollectionChunkRequest,
  Coordinate,
  CreateReportFromStoredCollectionRequest,
  CreateStoredCollectionFromChunksRequest,
  CreateStoredCollectionRequest,
  CreateStoredCollectionResponse,
  DraftReport,
  DraftReportIdentifier,
  InitChunkedCollectionUploadRequest,
  ListMetricsRequest,
  ListReportsRequest,
  MetricReference,
  MultiPolygon,
  Period,
  PointOfInterest,
  Polygon,
  PolygonRing,
  ReadMetricReportRequest,
  Region,
  ReportDetails,
  ReportDetailsRequest,
  ReportStatus as ProtoReportStatus,
  ReportType as ProtoReportType,
  GetStoredCollectionRequest,
  StoredCollectionVersion as ProtoStoredCollectionVersion,
} from '@unacast-internal/unacat-js/unacast/byo/v1/byo_service_pb';
import { MetricVersion } from '@unacast-internal/unacat-js/unacast/v2/metric/metric_pb';
import { MetricValue } from '@unacast-internal/unacat-js/unacast/v2/metric/metric_value_pb';
import { parseISO } from 'date-fns';
import { Market, POICollection } from '../MadeToOrder/types';
import { toDate, toDatepd } from '../mappers/PeriodMapper';
import { getBringYourOwnPoiServicePromiseClient } from '../rpc';
import { featureForBillingAccount } from '../helpers/featureToggle';

export type POI = {
  name: string;
  coordinates: [number, number][][][] /* = GeoJSON.MultiPolygon.coordinates */;
};
export type PoiRegion = 'us' | 'international';

export type AvailableMetricsByRegion = Record<'us' | 'international', string[]>;
export type MetricForPOI = { metricId: string; csvResult: string };
export type MetricsForPOI = MetricForPOI[];
export type POICollectionReference = { id: string; storageReference: string; versionId: string };
export type StoredCollectionVersion = ProtoStoredCollectionVersion.AsObject;

export enum ReportStatus {
  UNDEFINED,
  PENDING,
  RUNNING,
  DELAYED,
  SUCCEEDED,
  FAILED,
  EXPIRED,
}

export enum ReportType {
  ONE_TIME,
  RECURRING,
}

export type MetricResult = {
  metric: string;
  storageReference: string;
  unacatCatalogId: string;
  unacatMetricId: string;
};

export type Report = {
  id: string;
  region: PoiRegion;
  createdBy: string;
  reportName?: string;
  clientName?: string;
  createdTime: Date;
  expireTime?: Date;
  storageReference?: string;
  billingAccountID: string;
  poiCollectionVersionID: string;
  poiCollectionVersionName: string;
  status: ReportStatus;
  statusTooltipText?: string;
  startDate: Date;
  endDate: Date;
  metrics: string[];
  actionsDisabled?: boolean;
  reportType?: ReportType;
  resultsList: MetricResult[];
  itemCount: number;
};

export type ReportForCreation = {
  reportName: string;
  clientName: string;
  metrics: string[];
  startDate: Date;
  endDate: Date;
  collectionReference: string;
  market: Market;
  reportType: ReportType;
};

export const reportTypeToString = (reportType: ReportType) => {
  switch (reportType) {
    case ReportType.ONE_TIME:
      return 'One Time';
    case ReportType.RECURRING:
      return 'Recurring';
    default:
      return '';
  }
};

const ReportStatusMapper = (status: number, expiryTime: number): ReportStatus => {
  switch (status) {
    case ProtoReportStatus.UNDEFINED:
      return ReportStatus.UNDEFINED;
    case ProtoReportStatus.PENDING:
      return ReportStatus.PENDING;
    case ProtoReportStatus.DELAYED:
      return ReportStatus.DELAYED;
    case ProtoReportStatus.RUNNING:
      return ReportStatus.RUNNING;
    case ProtoReportStatus.SUCCEEDED:
      if (expiryTime > 0 && new Date() > new Date(expiryTime * 1000)) return ReportStatus.EXPIRED;
      return ReportStatus.SUCCEEDED;
    case ProtoReportStatus.FAILED:
      return ReportStatus.FAILED;
    default:
      return ReportStatus.FAILED;
  }
};

const ReportTypeMapper = (type: number): ReportType => {
  switch (type) {
    case ProtoReportType.ONE_TIME:
      return ReportType.ONE_TIME;
    case ProtoReportType.RECURRING:
      return ReportType.RECURRING;
    default:
      return ReportType.ONE_TIME;
  }
};

export type DraftInfo = {
  reportName: string;
  clientName: string;
  reportType?: ReportType;
  startDate?: Date;
  endDate?: Date;
  collectionLength?: number;
  metrics: string[];
  market?: Market;
  createdTime: Date;
  collectionReference?: string;
};

export type Draft = { id: string; info: DraftInfo; collection?: POICollection };

export const getUnacatRef = (
  results: MetricResult[],
  metric: string,
): { catalogId: string; metricId: string } | undefined => {
  const result = results.find((r) => r.metric === metric);
  return result
    ? { catalogId: result?.unacatCatalogId, metricId: result?.unacatMetricId }
    : undefined;
};

const Report__fromReportDetails = (reportDetails: ReportDetails): Report => {
  const status = ReportStatusMapper(reportDetails.getReportStatus(), reportDetails.getExpiryTime());
  const statusTooltipText =
    status === ReportStatus.FAILED && reportDetails.getErrorMessage()
      ? 'Report creation failed: ' + reportDetails.getErrorMessage()
      : undefined; // Override if there is a message.
  const reportType = ReportTypeMapper(reportDetails.getReportType());
  const obsPeriod = reportDetails.getObservationPeriod();
  reportDetails.getItemCount();

  return {
    id: reportDetails.getReportId(),
    storageReference: reportDetails.getStorageReference(),
    itemCount: reportDetails.getItemCount(),
    reportType,
    reportName: reportDetails.getReportName(),
    clientName: reportDetails.getClientName(),
    createdBy: reportDetails.getCreatedBy(),
    actionsDisabled: status !== ReportStatus.SUCCEEDED,
    billingAccountID: reportDetails.getBillingContext(),
    region: reportDetails.getRegion() === Region.US ? 'us' : 'international',
    poiCollectionVersionID: reportDetails.getPoiCollectionReference(),
    poiCollectionVersionName: reportDetails.getPoiCollectionReference(),
    status,
    statusTooltipText,
    resultsList: reportDetails.getResultsList().map((result) => result.toObject()),
    startDate: toDate(obsPeriod?.getStart() || toDatepd(new Date(0))),
    endDate: toDate(obsPeriod?.getEnd() || toDatepd(new Date(0))),
    metrics: reportDetails.getMetricsList(),
    createdTime: new Date(reportDetails.getCreatedTime() * 1000),
    expireTime:
      reportDetails.getExpiryTime() > 0
        ? new Date(reportDetails.getExpiryTime() * 1000)
        : undefined,
  };
};

const Draft__fromDraftReport = (draftReport: DraftReport): Draft => {
  const parsedDraft = {
    id: draftReport.getReportId(),
    info: JSON.parse(draftReport.getInfo()),
    collection: JSON.parse(draftReport.getDetails()),
  };

  parsedDraft.info.startDate && (parsedDraft.info.startDate = parseISO(parsedDraft.info.startDate));
  parsedDraft.info.endDate && (parsedDraft.info.endDate = parseISO(parsedDraft.info.endDate));
  parsedDraft.info.createdTime &&
    (parsedDraft.info.createdTime = parseISO(parsedDraft.info.createdTime));

  return parsedDraft;
};

export const DraftInfo__fromReport = (report: Report): DraftInfo => {
  const newDraft: DraftInfo = {
    reportName: report.reportName || '',
    clientName: report.clientName || '',
    reportType: report.reportType,
    startDate: report.startDate,
    endDate: report.endDate,
    collectionLength: report.itemCount,
    metrics: report.metrics,
    market: report.region,
    createdTime: report.createdTime,
    collectionReference: report.poiCollectionVersionID,
  };

  return newDraft;
};

export const DraftInfo__fromStoredCollection = (colleciton: StoredCollectionVersion): DraftInfo => {
  const newDraft: DraftInfo = {
    // market: 'us', // TODO: Derive this from the collection
    clientName: '',
    metrics: [],
    reportName: colleciton.versionName,
    collectionLength: colleciton.itemCount,
    createdTime: new Date(Date.parse(colleciton.createdTime)),
    collectionReference: colleciton.versionId,
  };

  return newDraft;
};

const PointOfInterestFromPOI = (poi: POI): PointOfInterest =>
  new PointOfInterest()
    .setName(poi.name)
    .setPolygon(
      new MultiPolygon().setPolygonsList(
        poi.coordinates.map((polygon) =>
          new Polygon().setRingsList(
            polygon.map((ring) =>
              new PolygonRing().setCoordinatesList(
                ring.map(([lon, lat]) =>
                  new Coordinate().setLon(lon.toString(10)).setLat(lat.toString(10)),
                ),
              ),
            ),
          ),
        ),
      ),
    );

export const convertToRegion = (poiRegion: PoiRegion): Region => {
  switch (poiRegion) {
    case 'us':
      return Region.US;
    case 'international':
      return Region.NON_US;
    default:
      return Region.NOT_DEFINED;
  }
};

const NUMBER_OF_POIS_BEFORE_CHUNKING = 1500;
const MAX_NUMBER_OF_POIS_PER_CHUNK = 1000;

export class BringYourOwnPOIService {
  constructor(private authHeader: string, private billingAccountID: string) { }

  private getHeaders = () => ({ Authorization: this.authHeader });

  public async getAvailableMetrics(): Promise<AvailableMetricsByRegion> {
    const byoPoiService = getBringYourOwnPoiServicePromiseClient();

    const request = new ListMetricsRequest().setRegionsList([Region.US, Region.NON_US]);

    const result: AvailableMetricsByRegion = { us: [], international: [] };
    const response = await byoPoiService.listMetrics(request);
    response.getMetricRefsRegionsList().forEach((v) => {
      switch (v.getRegion()) {
        case Region.US:
          result.us = v.getMetricRefsList().map((m) => m.getMetricId());
          break;
        case Region.NON_US:
          result.international = v.getMetricRefsList().map((m) => m.getMetricId());
          break;
      }
    });

    // for now hide Traffic Trends Day
    result.us = result.us.filter((m) => m !== 'traffic_trends_day');

    return result;
  }

  public async getDraftReportList(): Promise<Array<{ id: string; info: string }>> {
    const values = await getBringYourOwnPoiServicePromiseClient().listDraftReports(
      new DraftReportIdentifier(), // Needs something that can be serialized as proto-message in leu of proptbuf.Empty
      this.getHeaders(),
    );
    return values.getReportsList().map((r) => ({ id: r.getReportId(), info: r.getInfo() }));
  }
  public async getDraftReport(id: string): Promise<Draft> {
    const report = await getBringYourOwnPoiServicePromiseClient().getDraftReport(
      new DraftReportIdentifier().setReportId(id),
      this.getHeaders(),
    );

    return Draft__fromDraftReport(report);
  }
  public async storeDraftReport(id: string, info: string, details: string): Promise<void> {
    await getBringYourOwnPoiServicePromiseClient().storeDraftReport(
      new DraftReport().setReportId(id).setInfo(info).setDetails(details),
      this.getHeaders(),
    );
  }
  public async deleteDraftReport(id: string): Promise<void> {
    await getBringYourOwnPoiServicePromiseClient().deleteDraftReport(
      new DraftReportIdentifier().setReportId(id),
      this.getHeaders(),
    );
  }

  public async createReportFromCollectionReference(
    reportDraft: ReportForCreation,
  ): Promise<boolean> {
    const {
      reportName,
      clientName,
      collectionReference,
      startDate,
      endDate,
      market,
      reportType,
      metrics,
    } = reportDraft;

    const byoPoiService = getBringYourOwnPoiServicePromiseClient();
    const request = new CreateReportFromStoredCollectionRequest()
      .setReportType(reportType as ProtoReportType)
      .setBillingContext(this.billingAccountID)
      .setReportName(reportName)
      .setClientName(clientName)
      .setMetricRefsList(metrics.map((m) => new MetricReference().setMetricId(m)))
      .setObservationPeriod(new Period().setStart(toDatepd(startDate)).setEnd(toDatepd(endDate)))
      .setPoiCollectionReference(collectionReference)
      .setRegion(convertToRegion(market));
    try {
      await byoPoiService.prepareReportFromStoredCollection(request, this.getHeaders());
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  public async getReportValidationCSV(reportId: string): Promise<{ csv: string }> {
    const byoPoiService = getBringYourOwnPoiServicePromiseClient();
    const request = new ReportDetailsRequest()
      .setBillingContext(this.billingAccountID)
      .setReportId(reportId);
    const response = await byoPoiService.readReportValidation(request, this.getHeaders());
    return { csv: response.getCsvResult() };
  }

  public async getReportCSVByMetricId(
    reportId: string,
    metricId: string,
  ): Promise<{ csv: string; metricId: string }> {
    const byoPoiService = getBringYourOwnPoiServicePromiseClient();
    const request = new ReadMetricReportRequest()
      .setBillingContext(this.billingAccountID)
      .setMetricId(metricId)
      .setReportId(reportId);
    const response = await byoPoiService.readMetricReport(request, this.getHeaders());
    return { csv: response.getCsvResult(), metricId: response.getMetricId() };
  }

  public async getReportMetricById(
    reportId: string,
    metricId: string,
  ): Promise<{ metricVersion: MetricVersion; values: MetricValue[] }> {
    const byoPoiService = getBringYourOwnPoiServicePromiseClient();
    const request = new ReadMetricReportRequest()
      .setBillingContext(this.billingAccountID)
      .setMetricId(metricId)
      .setReportId(reportId);
    const response = await byoPoiService.readMetricReport2(request, this.getHeaders());
    const metricVersion = response.getMetricVersion();
    // Technically `metricVersion` can be undefined. Value will be included in a well-formed response
    if (metricVersion === undefined) {
      throw new Error('Got empty metricVersion-payload from backend. Should never happen');
    }
    return { metricVersion, values: response.getValuesList() };
  }

  public async getReportList(): Promise<Report[]> {
    const byoPoiService = getBringYourOwnPoiServicePromiseClient();
    const request = new ListReportsRequest().setBillingContext(this.billingAccountID);

    const response = await byoPoiService.listReports(request, this.getHeaders());
    return response.getReportsList().map(Report__fromReportDetails);
  }

  public async getReportDetails(reportID: string): Promise<Report> {
    const byoPoiService = getBringYourOwnPoiServicePromiseClient();
    const response = await byoPoiService.getReportDetails(
      new ReportDetailsRequest().setBillingContext(this.billingAccountID).setReportId(reportID),
      this.getHeaders(),
    );
    return Report__fromReportDetails(response);
  }

  public async archiveReport(reportID: string): Promise<void> {
    const byoPoiService = getBringYourOwnPoiServicePromiseClient();
    await byoPoiService.archiveMetricReport(
      new ReportDetailsRequest().setBillingContext(this.billingAccountID).setReportId(reportID),
      this.getHeaders(),
    );
  }

  public async getStoredCollection(collectionVersionID: string): Promise<StoredCollectionVersion> {
    const byoPoiService = getBringYourOwnPoiServicePromiseClient();
    const resp = await byoPoiService.getStoredCollection(
      new GetStoredCollectionRequest()
        .setBillingContext(this.billingAccountID)
        .setCollectionVersionId(collectionVersionID),
      this.getHeaders(),
    );
    resp.getVersion();
    const version = resp.getVersion();
    if (version === undefined) {
      throw new Error('Got empty version-payload from backend. Should never happen on OK-response');
    }
    return version.toObject();
  }

  public async storePois(locations: POI[]): Promise<POICollectionReference> {
    const byoPoiService = getBringYourOwnPoiServicePromiseClient();
    const pois = locations.map(PointOfInterestFromPOI);

    let response: CreateStoredCollectionResponse;
    if (pois.length > NUMBER_OF_POIS_BEFORE_CHUNKING) {
      const { chunkRef } = (
        await byoPoiService.initChunkedCollectionUpload(
          new InitChunkedCollectionUploadRequest().setBillingContext(this.billingAccountID),
          this.getHeaders(),
        )
      ).toObject();

      const chunkCount = Math.ceil(pois.length / MAX_NUMBER_OF_POIS_PER_CHUNK);
      const chunkSize = Math.ceil(pois.length / chunkCount);
      for (let i = 0; i < chunkCount; i++) {
        await byoPoiService.appendCollectionChunk(
          new AppendCollectionChunkRequest()
            .setBillingContext(this.billingAccountID)
            .setChunkRef(chunkRef)
            .setPoisList(pois.slice(chunkSize * i, chunkSize * (i + 1))),
          this.getHeaders(),
        );
      }

      response = await byoPoiService.createStoredCollectionFromChunks(
        new CreateStoredCollectionFromChunksRequest()
          .setBillingContext(this.billingAccountID)
          .setChunkRef(chunkRef),
        this.getHeaders(),
      );
    } else {
      const request = new CreateStoredCollectionRequest()
        .setBillingContext(this.billingAccountID)
        .setPoisList(pois);
      response = await byoPoiService.createStoredCollection(request, this.getHeaders());
    }
    // TODO: Use non-deprecated value
    return {
      id: response.getCollectionReferenceCombined(),
      storageReference: response.getStorageReference(),
      versionId: response.getCollectionVersionId(),
    };
  }
}
