export type Industry = 'retail'; // | 'real-estate' | 'finance-solutions' | 'large-multinationals';
export const industryType = 'retail'; // +'|real-estate|finance|large-commodities';
export const isIndustry = (s: string | undefined | null): s is Industry =>
  typeof s === 'string' && industryType.split('|').includes(s);

export type Solution = 'site-selection';
export const solutionType = 'site-selection';
export const isSolution = (s: string | undefined | null): s is Solution =>
  typeof s === 'string' && solutionType.split('|').includes(s);

export const getIndustrySolutionFromPath = (
  path: string,
): { industry: Industry; solution: Solution } | undefined => {
  const [, , , industry, solution] = path.split('/'); // ´/c/:catalog/:industry/:solution
  if (isIndustry(industry) && isSolution(solution)) {
    return { industry, solution };
  }
};
