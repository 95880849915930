import { BringYourOwnPOIService } from '../../unacatInteraction/BringYourOwnPOIService';
import {
  authDecoratedFetchingHook,
  AuthParams,
  createAsyncFetchingHook,
} from '../../unacatInteraction/fetchingHook';

const useArchiveReport = createAsyncFetchingHook(
  async ({ authHeader, billingAccount }: AuthParams, reportToDeleteId: string): Promise<void> => {
    const service = new BringYourOwnPOIService(authHeader, billingAccount);
    service.archiveReport(reportToDeleteId);
  },
);

export const useArchiveReportHook = authDecoratedFetchingHook(useArchiveReport);
