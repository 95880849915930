import {
  Coordinate,
  LatLonToPolygonRequest,
  PointRadius,
} from '@unacast-internal/unacat-js/unacast/byo/v1/byo_service_pb';
import { getBringYourOwnPoiServicePromiseClient } from '../rpc';
import { POIFeature } from '../MadeToOrder/types';

export type LatLonItem = {
  name: string;
  lat: number;
  lon: number;
  radius?: number;
};

export class PolygonCreationService {
  constructor(private authHeader: string, private billingAccountID: string) {}

  private getHeaders = () => ({ Authorization: this.authHeader });

  public async getPolygonsForLatLons(
    items: LatLonItem[],
    fallbackRadius?: number,
  ): Promise<POIFeature[]> {
    const byoPoiService = getBringYourOwnPoiServicePromiseClient();

    const request = new LatLonToPolygonRequest()
      .setBillingContext(this.billingAccountID)
      .setPointRadiiList(
        items.map((v) => {
          const fbRadius = v.radius || fallbackRadius;
          const p = new PointRadius()
            .setName(v.name)
            .setCoordinate(new Coordinate().setLat(v.lat.toString()).setLon(v.lon.toString()));
          if (fbRadius) {
            p.setFallbackRadius(fbRadius);
          }
          return p;
        }),
      );

    const response = await byoPoiService.latLonToPolygon(request, this.getHeaders());
    return response.getPoisList().map((v): POIFeature => {
      return {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            (v.getPolygon()?.getPolygonsList()[0].getRingsList()[0].getCoordinatesList() || []).map(
              (c) => [Number.parseFloat(c.getLon()), Number.parseFloat(c.getLat())],
            ),
          ],
        },
        id: v.getName(),
        properties: {
          name: v.getName(),
        },
      };
    });
  }
}
