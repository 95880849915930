import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useReportDetails } from '../../dataManagement/reportListHook';
import { Report, ReportType } from '../../../unacatInteraction/BringYourOwnPOIService';
import { UnaLinearProgress } from '@unacast-internal/unacast-ui/UnaLinearProgress';
import { useQueryState } from 'react-router-use-location-state';

export const GetReportContainer = ({ renderProp }: { renderProp: (r: Report) => JSX.Element }) => {
  const { reportId } = useParams<{ reportId: string }>();
  const [reportDetailsState, getReportDetails] = useReportDetails();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //Report type is loaded as a query param
  const [, setReportType] = useQueryState<ReportType>('reportType', ReportType.ONE_TIME);

  //------------------------------
  //---Get any preloaded data needed from either a draft or previous report----------
  //------------------------------

  useEffect(() => {
    if (reportId) {
      getReportDetails(reportId);
    }
  }, [reportId, getReportDetails]);

  useEffect(() => {
    if (reportDetailsState.status === 'succeeded') {
      const reportInfo = reportDetailsState.data;
      reportInfo.reportType !== undefined && setReportType(reportInfo.reportType);
    }
    if (reportDetailsState.status === 'failed') {
      enqueueSnackbar(reportDetailsState.error.message, { variant: 'error' });
    }
  }, [enqueueSnackbar, reportDetailsState, setReportType]);

  //---------------------------------------------------------

  return reportDetailsState.status === 'succeeded' ? (
    renderProp(reportDetailsState.data)
  ) : (
    <UnaLinearProgress></UnaLinearProgress>
  );
};
