import { POICollection } from '../types';
import { Location, LocationRecord } from './poiCollectionStore';

export const withAddedFeature = (current: LocationRecord, feature: Location): LocationRecord => {
  if (current[feature.id] !== undefined) {
    // eslint-disable-next-line no-console
    console.warn(new Error('A feature with id ' + feature.id + ' already exist in collection'));
  }
  return { ...current, [feature.id]: feature };
};

export const withUpdatedFeature = (current: LocationRecord, feature: Location): LocationRecord => {
  if (!current[feature.id]) {
    throw new Error('A feature with id ' + feature.id + ' does not exist in collection');
  }
  return { ...current, [feature.id]: feature };
};

export const withoutFeature = (current: LocationRecord, id: string): LocationRecord => {
  const { [id]: removed, ...remaining } = current;
  return remaining;
};

export const FeatureRecord_asCollection = (r: LocationRecord): POICollection => ({
  type: 'FeatureCollection',
  features: Object.values(r),
});
