import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { BlackToWhite } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { Body } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import {
  getLatLngBoundsForFeatures,
  CoordinateToLatLng,
  getPOIArea,
} from '../../../helpers/geography';
import mapMarkerPointIcon from '../../icons/MapMarkerPoint.svg';
import { POIFeature, Position } from '../../types';
import { GeoJsonToMapShape } from '../GeoJsonToMapShape';
import { UnaSwitch } from '../UnaSwitch';
import { DetailsStyled } from './FeatureDetailsPanelStyled';
import { TextUpdateField } from './TextUpdateField';

type FeatureDetailsPanelProps = {
  feature: POIFeature;
  onClose: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  onClick: () => void;
  hasError?: boolean;
  isEditingChecked?: boolean;
  onSetNewName?: (val: string) => boolean;
};

// styles used for google map
const containerStyle = {
  position: 'relative',
  height: '100%',
  zIndex: 0,
} as React.CSSProperties;

const googleMapOptions = {
  disableDefaultUI: true,
  disableDoubleClickZoom: true,
  scrollwheel: false,
  draggable: false,
  keyboardShortcuts: false,
  clickableIcons: false,
  mapId: '4cde2ee1e9b041f7',
  maxZoom: 16,
};

export const FeatureDetailsPanel = ({
  feature,
  onClose,
  onEdit,
  onDelete,
  onClick,
  hasError,
  isEditingChecked = false,
  onSetNewName,
}: FeatureDetailsPanelProps): React.ReactElement => {
  const [googleMap, setMap] = useState<google.maps.Map>();

  const onLoad = useCallback(
    function callback(map) {
      map.fitBounds(getLatLngBoundsForFeatures(feature));
      setMap(map);
    },
    [feature],
  );

  useEffect(() => {
    googleMap && googleMap.fitBounds(getLatLngBoundsForFeatures(feature));
  }, [feature, googleMap]);

  return (
    <DetailsStyled
      initial={{ opacity: 0, x: -200 }}
      animate={{ opacity: 1, x: 0, transition: { type: 'linear' } }}
      exit={{ opacity: 0, x: -200 }}
    >
      <div className="top-row">
        <div className="left-items">
          {!onEdit || !onSetNewName ? (
            <Body>{feature.properties.name}</Body>
          ) : (
            <TextUpdateField
              defaultValue={feature.properties.name}
              onSubmitName={onSetNewName}
            ></TextUpdateField>
          )}
        </div>
        <div className="close-icon">
          <CloseIcon htmlColor={BlackToWhite.Gray01} fontSize="small" onClick={onClose}></CloseIcon>
        </div>
      </div>
      <Tooltip title="Click to marker or geometry to navigate" placement="bottom" arrow={true}>
        <div className="mapContainer">
          <GoogleMap
            id="overlay-view-example"
            mapContainerStyle={containerStyle}
            center={CoordinateToLatLng((feature.properties.centroid || [0, 0]) as Position)}
            options={googleMapOptions}
            onLoad={onLoad}
          >
            <div className="svg-area">
              <Marker
                icon={mapMarkerPointIcon}
                position={CoordinateToLatLng((feature.properties.centroid || [0, 0]) as Position)}
                onClick={onClick}
              />
              <GeoJsonToMapShape feature={feature} onClick={onClick}></GeoJsonToMapShape>
            </div>
          </GoogleMap>
        </div>
      </Tooltip>
      <div className="secondary-details">
        <Body type="small01" color={BlackToWhite.Gray03}>
          <b> Area in meters&sup2;: </b> {Math.ceil(getPOIArea(feature)).toLocaleString()}
        </Body>
      </div>
      <div className="action-row">
        {onEdit && (
          <UnaSwitch
            onChange={onEdit}
            name="edit"
            checked={isEditingChecked}
            label="Edit"
          ></UnaSwitch>
        )}
        {onDelete && (
          <UnaButton onClick={onDelete} icon={<DeleteIcon></DeleteIcon>}>
            Delete
          </UnaButton>
        )}
      </div>
    </DetailsStyled>
  );
};
