import { BlackToWhite } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import React, { useMemo } from 'react';
import {
  ReportStatus,
  ReportType,
  reportTypeToString,
} from '../../../unacatInteraction/BringYourOwnPOIService';
import { Body, Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { CreationDetails } from '../CreationDetails';
import { ReportStatusIcon } from '../ReportStatusIcon';
import { CopyableText } from '@unacast-internal/unacast-ui/CopyableText/CopyableText';
import { OverflowMenu } from '../../../components/OverflowMenu';
import { ReportTimeRangeText } from '../ReportTimeRangeText';
import { MadeToOrderReportCardStyled } from './MadeToOrderReportCard.styles';

type MadeToOrderReportCardProps = {
  status: ReportStatus;
  statusTooltipText?: string;
  id: string;
  clientName?: string;
  createdTime?: Date;
  createdBy?: string;
  deliveryType?: string;
  metrics?: string[];
  locationCount?: number;
  menuActions?: Record<string, () => void>;
  startDate?: Date;
  endDate?: Date;
  storageReference?: string;
  reportType?: ReportType;
  isLargeCard?: boolean;
  isAdheringToReportLengthLimitation?: boolean;
};

export const MadeToOrderReportCard = ({
  status,
  statusTooltipText,
  id,
  clientName,
  createdTime,
  createdBy,
  deliveryType,
  metrics,
  locationCount,
  menuActions,
  startDate,
  endDate,
  storageReference,
  reportType,
  isLargeCard,
  isAdheringToReportLengthLimitation,
}: MadeToOrderReportCardProps): React.ReactElement => {
  const getCardActions = () => {
    if (menuActions) {
      if (!isLargeCard) {
        return <OverflowMenu actions={menuActions}></OverflowMenu>;
      }

      return (
        <div className="action-area">
          {Object.keys(menuActions).map((key) => {
            return (
              <button
                onClick={() => {
                  menuActions[key]();
                }}
                key={key}
                className="action-area__button"
              >
                <Body className="action_text"> {key}</Body>
              </button>
            );
          })}
        </div>
      );
    }
  };

  //Lists various details of the metric attributes tied
  //to the report
  const metricsList = useMemo(() => {
    if (metrics)
      return (
        <div className="metrics-section">
          <Body>Metrics {locationCount ? `for ${locationCount} locations` : ''}:</Body>
          {reportType !== undefined && startDate && (
            <ReportTimeRangeText
              reportType={reportType}
              startDate={startDate}
              endDate={endDate}
            ></ReportTimeRangeText>
          )}

          {/* WILL hopefully be replaced by graphs in scrollable section */}
          {metrics.map((i) => (
            <Body type={isLargeCard ? 'body' : 'small01'} color={BlackToWhite.Gray03} key={i}>
              {i}
            </Body>
          ))}
        </div>
      );
  }, [metrics, locationCount, startDate, endDate]);

  return (
    <MadeToOrderReportCardStyled isLargeCard={isLargeCard}>
      <div className="top-row">
        <div className="title-with-icon">
          <ReportStatusIcon
            status={status}
            tooltipText={statusTooltipText}
            size={isLargeCard ? 'large' : undefined}
          ></ReportStatusIcon>
          <Heading type={isLargeCard ? 'h2' : 'h3'} className="title-space">
            {id}
            {!isAdheringToReportLengthLimitation && <span className="large-report-label">Large Report</span>}
          </Heading>
        </div>
        {getCardActions()}
      </div>
      <div className="report-details">
        {createdTime && (
          <div className="details-item">
            <CreationDetails
              createdBy={createdBy}
              creationDate={createdTime}
              clientName={clientName}
            ></CreationDetails>
          </div>
        )}
        {reportType !== undefined && (
          <Body
            type={isLargeCard ? 'body' : 'small01'}
            color={BlackToWhite.Gray03}
            className="details-item"
          >
            <b>Report Type:</b> {reportTypeToString(reportType)}
          </Body>
        )}
      </div>
      {storageReference && (
        <div className="details-item report-details">
          <Body type="small01" color={BlackToWhite.Gray03}>
            <b>BQ Reference: </b>
          </Body>
          <CopyableText valueToCopy={storageReference}>
            <Body type="small01" color={BlackToWhite.Gray03}>
              {storageReference}
            </Body>
          </CopyableText>
        </div>
      )}
      {!isLargeCard && metrics && metricsList}
    </MadeToOrderReportCardStyled>
  );
};
