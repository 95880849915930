import { Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { MultilineTextInput } from '@unacast-internal/unacast-ui/Inputs';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../contexts/AuthContext';
import { Snackbar } from '@mui/material';

const ApiKeyPageStyled = styled.div`
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h3 {
    padding-top: 2rem;
  }

  a {
    color: #007fff !important;
  }
`;

const ApiKeyPage = (): JSX.Element => {
  const { authorizationToken, refreshAuthToken } = useContext(AuthContext);
  const [open, setOpen] = useState<boolean>(false);
  const [key, setKey] = useState<string>();

  useEffect(() => {
    // Refreshing the Auth token every 45 minute as it expires after one hour
    const interval = setInterval(() => {
      refreshAuthToken();
    }, 2700000);

    return () => clearInterval(interval);
  }, [refreshAuthToken]);

  useEffect(() => {
    if (authorizationToken) {
      setKey(authorizationToken.replace('bearer ', ''));
    }
  }, [authorizationToken]);

  const copyToClipboard = (apiKey) => {
    setOpen(true);
    navigator.clipboard.writeText(apiKey);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!authorizationToken) {
    return <></>;
  }

  return (
    <ApiKeyPageStyled>
      <Heading>API-KEY</Heading>
      <p>Here is your API-KEY to interact with our python client</p>
      <br />
      {key && (
        <motion.div
          whileHover={{
            scale: 1.1,
            transition: { duration: 0.3 },
          }}
          whileTap={{ scale: 1 }}
          onClick={() => copyToClipboard(key)}
        >
          <MultilineTextInput
            value={key}
            label="Click the textbox to copy"
            disabled={true}
            rows={6}
          />
        </motion.div>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        message={`The API is key copied to your clipboard`}
        key={'top center'}
      />
    </ApiKeyPageStyled>
  );
};

export default ApiKeyPage;
