import { PoiCollectionService } from '../../unacatInteraction/PoiCollectionService';
import {
  authDecoratedFetchingHook,
  AuthParams,
  createAsyncFetchingHook,
} from '../../unacatInteraction/fetchingHook';
import { PublishCollectionVersionResponse } from '@unacast-internal/unacat-js/unacast/byo/v1/poi_collection_service_pb';

const usePublishCollectionVersion = createAsyncFetchingHook(
  async (
    { authHeader, billingAccount }: AuthParams,
    collectionId: string,
  ): Promise<PublishCollectionVersionResponse.AsObject> => {
    const service = new PoiCollectionService(authHeader, billingAccount);

    return await service.publishCollectionVersion(collectionId);
  },
);

export const usePublishCollectionVersionHook = authDecoratedFetchingHook(
  usePublishCollectionVersion,
);
