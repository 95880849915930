import styled from 'styled-components';

export const LoginDialogStyled = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 16rem;
  width: 360px;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: transparent;

  .privacy {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 0.5rem;
  }

  .or-display {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    .line {
      width: 5rem;
      border-bottom: 1px solid #000000;
      height: 0px;
    }

    .or {
      margin: 10px;
    }
  }

  .google-icon {
    margin-right: 0.5rem;
  }
`;
