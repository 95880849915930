export default {
  apiKey: 'AIzaSyBAH1AdlzZs6l6reHMi_ReoigY7fNgLwbU',
  authDomain: 'console.unacast.com',
  databaseURL: 'https://uc-data-portal.firebaseio.com',
  projectId: 'uc-data-portal',
  storageBucket: 'uc-data-portal.appspot.com',
  messagingSenderId: '395961143451',
  appId: '1:395961143451:web:79733073d270dc6600b63e',
  measurementId: 'G-547D30YGJL',
};
