import { FileUpload } from '@mui/icons-material';
import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { readFileContent } from '../utils/readFile';

const acceptedFiles = '.geojson,.json,.csv,.shp';

export const UploadPOICollectionButton = ({
  text,
  onComplete,
}: {
  text: string;
  onComplete: (result: ArrayBuffer, fileName: string) => void;
}) => {
  const readAndAppendFile = ([file]: File[]) => {
    if (file) {
      readFileContent(file).then((content) => onComplete(content, file.name));
    }
  };
  const {
    getRootProps,
    getInputProps,
    open: openDragAndDrop,
  } = useDropzone({
    accept: acceptedFiles,
    noClick: true,
    noKeyboard: true,
    onDrop: readAndAppendFile,
  });

  return (
    <div {...getRootProps()}>
      <UnaButton onClick={openDragAndDrop} icon={<FileUpload />} iconPos="end">
        {text}
      </UnaButton>
      <input {...getInputProps()} />
    </div>
  );
};
