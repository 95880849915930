import { wktToGeoJSON } from '../../helpers/geography';
import { POIFeature } from '../types';
import { CSVRowTransformer, KeyIndex } from './csvOperations';
import { ParseResponse } from './fileTranslatorHook';
import { isGeoJSONGeometry, POIGeometry_fromGeoJSONGeometry } from './geojsonOperations';

export const parseGeometryCSVFile = (
  indexes: KeyIndex,
  linesGen: CSVRowTransformer,
): ParseResponse => {
  const hasId = indexes.name !== undefined || indexes.id !== undefined;
  const hasGeo = indexes.geometry !== undefined || indexes.geom !== undefined;

  if (!hasId) {
    throw new Error('CSV must have at least one of name or id columns');
  }

  if (!hasGeo) {
    throw new Error('Missing key in csv-data: geometry');
  }

  const idIndex = indexes.id !== undefined ? indexes.id : indexes.name;
  const geoIndex = indexes.geometry !== undefined ? indexes.geometry : indexes.geom;

  const getFeatures = (getRadius: (line: string[]) => number): POIFeature[] =>
    linesGen((values): POIFeature => {
      const id = values[idIndex];
      const name = values[indexes.name !== undefined ? indexes.name : indexes.id];
      const geometryValue = wktToGeoJSON(values[geoIndex]);
      if (!isGeoJSONGeometry(geometryValue)) {
        throw new Error(`Geometry for ${id} is not a valid GeoJSON or WKT Geometry`);
      }

      const geometry = POIGeometry_fromGeoJSONGeometry(geometryValue);
      if (geometry === null) {
        console.error('rejected geometry:', geometryValue);
        throw new Error(`Encountered unknown geometry (${geometryValue.type}) for id ${id}`);
      }
      return {
        type: 'Feature',
        geometry,
        id,
        properties: { name },
      };
    });

  return {
    status: 'success',
    type: 'csv',
    features: getFeatures((row: string[]) => Number.parseInt(row[indexes.radius], 10)),
  };
};
