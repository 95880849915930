import { BringYourOwnPOIService } from '../../unacatInteraction/BringYourOwnPOIService';
import {
  authDecoratedFetchingHook,
  AuthParams,
  createAsyncFetchingHook,
} from '../../unacatInteraction/fetchingHook';

const useDeleteDraftReport = createAsyncFetchingHook(
  async ({ authHeader, billingAccount }: AuthParams, reportToDeleteId: string): Promise<void> => {
    const service = new BringYourOwnPOIService(authHeader, billingAccount);
    service.deleteDraftReport(reportToDeleteId);
  },
);

export const useDeleteDraftReportHook = authDecoratedFetchingHook(useDeleteDraftReport);
