import React, { useContext, useState } from 'react';
import {
  DimensionFilter,
  DimensionValue,
} from '@unacast-internal/unacat-js/unacast/metric/v1/dimension_pb';
import { useHistory, useLocation } from 'react-router-dom';
import { SearchDimensionValuesRequest } from '@unacast-internal/unacat-js/unacast/catalog/v1/query_service_pb';
import { ErrorHandlerContext } from './ErrorHandlerContext';
import { AuthContext } from './AuthContext';
import { getQueryServicePromiseClient } from '../rpc';
import { DimensionSpec, Metric } from '@unacast-internal/unacat-js/unacast/metric/v1/metric_pb';

type Context = {
  loadDimensionList: (metric: Metric) => void;
  dimensionLists: { [component: string]: DimensionValue.AsObject[] };
  setSelectedDimension: (metricID: string, dimensionID: string, dimensionValue: string[]) => void;
  getSelectedDimension: (metricID: string, dimensionID: string) => string[];
  clearSelectedDimension: (metric: Metric) => void;
  getSelectedDimensionFilter: (metricID: string) => { [dimensionID: string]: DimensionFilter };
  metricSelectedDimensionFilter: { [metricID: string]: { [dimensionID: string]: DimensionFilter } };
};

export const DimensionContext = React.createContext({} as Context);

export const DimensionProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const { logError } = useContext(ErrorHandlerContext);
  const { authorizationToken } = useContext(AuthContext);

  const [dimensionLists, setDimensionLists] = useState<{
    [component: string]: DimensionValue.AsObject[];
  }>({});

  const [metricSelectedDimensionFilter, setMetricSelectedDimensionFilter] = useState<{
    [metricID: string]: { [dimensionID: string]: DimensionFilter };
  }>({});

  const queryService = getQueryServicePromiseClient();

  const loadDimensionList = (metric: Metric) => {
    const dimensionsList = metric.getSpec()?.getDimensionsList();
    if (!dimensionsList || dimensionsList.length === 0) {
      return;
    }

    fetchDimensionList(metric.getId(), dimensionsList, metric.getCatalogId()).catch((error) => {
      error.message = 'failed to get dimension list -- ' + error.message;
      logError({ name: error.code.toString(), ...error }, true);
    });
  };

  const clearSelectedDimension = (metric: Metric) => {
    if (!metricSelectedDimensionFilter[metric.getId()]) {
      return;
    }

    metricSelectedDimensionFilter[metric.getId()] = {};

    metric
      .getSpec()
      ?.getDimensionsList()
      .forEach((dimSpec) => query.delete(dimSpec.getDimensionId()));
    history.replace({ search: query.toString() });
  };

  async function getDimensionList(catalogID: string, dimensionID: string) {
    if (authorizationToken) {
      const request = new SearchDimensionValuesRequest();
      request.setCatalogId(catalogID);
      request.setDimensionId(dimensionID);
      request.setQuery('*');
      request.setPageSize(300);

      try {
        const searchDimensionValuesResponse = await queryService.searchDimensionValues(request, {
          Authorization: authorizationToken,
        });
        return searchDimensionValuesResponse
          .getDimensionValuesList()
          .map((dimValue) => dimValue.toObject());
      } catch (error) {
        error.message = 'failed to search for dimensions -- ' + error.message;
        logError({ name: error.code.toString(), ...error }, true);
      }
    }
  }

  const setSelectedDimension = (
    metricID: string,
    dimensionID: string,
    dimensionValues: string[],
  ) => {
    if (dimensionValues.length === 0) {
      const metricSelectedDimensionFilterElement = metricSelectedDimensionFilter[metricID];
      delete metricSelectedDimensionFilterElement[dimensionID];
    } else {
      const filter = new DimensionFilter();
      filter.setDimensionId(dimensionID);
      filter.setValuesList([...filter.getValuesList(), ...dimensionValues]);

      if (!metricSelectedDimensionFilter[metricID]) {
        metricSelectedDimensionFilter[metricID] = {};
      }

      metricSelectedDimensionFilter[metricID][dimensionID] = filter;
    }

    setMetricSelectedDimensionFilter({
      ...metricSelectedDimensionFilter,
    });

    query.delete(dimensionID);
    dimensionValues.forEach((value) => {
      query.append(dimensionID, value);
    });
    history.replace({ search: query.toString() });
  };

  const getSelectedDimension = (metricID: string, dimensionID: string) => {
    if (
      !metricSelectedDimensionFilter[metricID] ||
      !metricSelectedDimensionFilter[metricID][dimensionID]
    ) {
      return [];
    }

    return metricSelectedDimensionFilter[metricID][dimensionID].getValuesList();
  };

  async function fetchDimensionList(
    metricID: string,
    dimensionList: Array<DimensionSpec>,
    catalogID: string,
  ) {
    // Make sure that we have fetched all the dimension lists
    const dimensionMap: { [component: string]: DimensionValue.AsObject[] } = {};
    for (const dim of dimensionList) {
      const alreadyFetchedDimensionList = dimensionLists[dim.getDimensionId()];
      if (alreadyFetchedDimensionList) {
        dimensionMap[dim.getDimensionId()] = alreadyFetchedDimensionList;
      } else {
        const dimList = await getDimensionList(catalogID, dim.getDimensionId());
        if (dimList) {
          dimensionMap[dim.getDimensionId()] = dimList;
        }
      }
    }

    setDimensionLists({ ...dimensionLists, ...dimensionMap });
  }

  const getSelectedDimensionFilter = (
    metricID: string,
  ): { [dimensionID: string]: DimensionFilter } => {
    if (!metricSelectedDimensionFilter[metricID]) {
      return {};
    }
    return metricSelectedDimensionFilter[metricID];
  };

  return (
    <DimensionContext.Provider
      value={{
        getSelectedDimension,
        setSelectedDimension,
        loadDimensionList,
        clearSelectedDimension,
        dimensionLists,
        metricSelectedDimensionFilter,
        getSelectedDimensionFilter,
      }}
    >
      {children}
    </DimensionContext.Provider>
  );
};
