import React, { useState } from 'react';
import { TextInput, UnaDateRangePicker } from '@unacast-internal/unacast-ui/Inputs';

import { DateRangePickerStyled } from '@unacast-internal/unacast-ui/Inputs/UnaDateRangePicker/UnaDateRangePicker.styles';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { Market } from '../types';
import { Body } from '@unacast-internal/unacast-ui/StyleGuide/Typography';

import { TextField } from '@mui/material';
import styled from 'styled-components';
import { LightTooltip } from '@unacast-internal/unacast-ui/LightTooltip/LightTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { BlackToWhite } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { MAX_INPUT_CHARACTERS } from '../pages/CreateReportPage/CreateReportPage';
import { FeatureToggleHelper } from '../../components/FeatureToggle';
import { ReportType } from '../../unacatInteraction/BringYourOwnPOIService';
import { MetricSelector } from './MetricSelector';

const CreateInputGroupStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  align-content: center;
  grid-auto-rows: minmax(min-content, max-content);
  .choose-metrics {
    grid-column: span 2;
    position: relative;
    display: flex;
    flex-direction: column;
    .select-all {
      position: absolute;
      top: 0;
      right: 0;
    }
    .MuiAutocomplete-inputRoot {
      flex-wrap: wrap;
    }
    .MuiChip-root {
      margin-bottom: 0.5rem;
    }
  }
  .switch-input {
    &-title {
      margin-bottom: calc(0.5rem + 18px);
    }
  }

  .date-picker {
    display: flex;
  }
`;

type InputProps = {
  reportType: ReportType;
  market: Market;
  reportName?: string;
  setReportName: (name: string) => void;
  clientName?: string;
  setClientName: (name: string) => void;
  startDate: Date;
  setStartDate: (d: Date) => void;
  endDate: Date;
  setEndDate: (d: Date) => void;
  dateInformationText?: string;
  selectedMetrics: { value: string; label: string }[];
  setSelectedMetrics: (sm: { value: string; label: string }[]) => void;
};

const toggleFlagForUSA = 'byoMarketSelector';

export const CreateReportPageInputGroup = ({
  reportType,
  setReportName,
  reportName,
  market,
  clientName,
  setClientName,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dateInformationText,
  selectedMetrics,
  setSelectedMetrics,
}: InputProps) => {
  const { isFeatureToggleActive } = FeatureToggleHelper();

  const [reportNameHelper, setReportNameHelper] = useState<string | null>(null);
  const [clientNameHelper, setClientNameHelper] = useState<string | null>(null);

  const setInputWithValidation = (
    value: string,
    inputSetter: (name: string) => void,
    helpSetter: (val: string | null) => void,
  ) => {
    if (value.length > MAX_INPUT_CHARACTERS) {
      helpSetter(`Max ${MAX_INPUT_CHARACTERS} characters`);

      const maxAllowedFromValue = value.slice(0, MAX_INPUT_CHARACTERS);

      inputSetter(maxAllowedFromValue);
      return;
    }

    helpSetter(null);
    inputSetter(value);
  };

  return (
    <CreateInputGroupStyled
      className={isFeatureToggleActive(toggleFlagForUSA) ? ' inputs-with-market' : ''}
    >
      <TextInput
        type="text"
        label="Report Name"
        helperText={reportNameHelper ?? '*required'}
        value={reportName}
        onChange={(evt) =>
          setInputWithValidation(evt.target.value, setReportName, setReportNameHelper)
        }
      ></TextInput>
      <TextInput
        label="Client"
        type="text"
        helperText={clientNameHelper ?? '*required'}
        value={clientName}
        onChange={(evt) =>
          setInputWithValidation(evt.target.value, setClientName, setClientNameHelper)
        }
      ></TextInput>
      <>
        {reportType !== ReportType.RECURRING ? (
          <div className="date-picker">
            <UnaDateRangePicker
              toDateValue={endDate}
              fromDateValue={startDate}
              minDate={new Date(2019, 0)}
              maxDate={new Date(Date.now())}
              handleFromChange={setStartDate}
              handleToChange={setEndDate}
            ></UnaDateRangePicker>
            {dateInformationText && (
              <div className="info-icon">
                <LightTooltip title={dateInformationText} placement="right" arrow>
                  <InfoOutlinedIcon htmlColor={BlackToWhite.Gray02}></InfoOutlinedIcon>
                </LightTooltip>
              </div>
            )}
          </div>
        ) : (
          <DateRangePickerStyled>
            <div className={'date-picker-container'}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Body type="body">From date</Body>
                <DatePicker
                  value={startDate}
                  onChange={(newStartDate, value) => {
                    newStartDate && setStartDate(newStartDate);
                  }}
                  minDate={new Date(2019, 0)}
                  maxDate={new Date(Date.now())}
                  disableFuture
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          </DateRangePickerStyled>
        )}
        <div className="choose-metrics">
          <MetricSelector
            selectedMetrics={selectedMetrics}
            onChange={setSelectedMetrics}
            market={market}
            helperText="*required"
          />
        </div>
      </>
    </CreateInputGroupStyled>
  );
};
