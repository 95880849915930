import React, { ErrorInfo } from 'react';
import { errorHandler } from '../helpers/errorHandler';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ErrorPage } from '../pages/ErrorPage';

type State = {
  error: Error | null;
};
class ErrorBoundary extends React.Component<RouteComponentProps, State> {
  public state: State = { error: null };

  static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (this.state.error?.message === error.message && this.state.error?.name === error.name) {
      return;
    }

    errorHandler(error, errorInfo.componentStack);
  }

  resetError = () => {
    this.setState({ error: null });
  };

  render() {
    if (this.state.error !== null) {
      return <ErrorPage error={this.state.error} resetError={this.resetError} />;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
