import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import React from 'react';

import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

const TextFieldStyled = styled(TextField)`
  background-color: white;
  border-radius: 6px;
  border: none;
  box-shadow: 0 0 2px rgba(40, 41, 61, 0.04), 0 4px 4px rgba(80, 148, 175, 0.16);
  .MuiOutlinedInput-root {
    border-radius: 6px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export const SearchAutoComplete: React.FC<{
  changeHandler: (val: unknown) => void;
  onInputChange?: (val: string) => void;
  label?: string;
  autoOptions: unknown[];
  disableClearable?: boolean;
  loading?: boolean;
  placeholder?: string;
  defaultValue?: string;
}> = ({
  changeHandler,
  autoOptions,
  onInputChange,
  label,
  disableClearable,
  loading = false,
  placeholder,
  defaultValue,
}) => {
  return (
    <Autocomplete
      onChange={(_, newValue) => {
        autoOptions && changeHandler(newValue);
      }}
      loading={loading}
      filterOptions={(x) => x}
      defaultValue={defaultValue}
      onInputChange={(_, newInputValue) => {
        onInputChange && onInputChange(newInputValue);
      }}
      disableClearable={disableClearable}
      clearIcon={null}
      options={autoOptions}
      renderInput={(params) => (
        <TextFieldStyled
          {...params}
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
