import {
  authDecoratedFetchingHook,
  AuthParams,
  createAsyncFetchingHook,
} from '../../unacatInteraction/fetchingHook';
import { PoiCollectionService } from '../../unacatInteraction/PoiCollectionService';

const useDeleteCollection = createAsyncFetchingHook(
  async (
    { authHeader, billingAccount }: AuthParams,
    collectionToDeleteID: string,
  ): Promise<void> => {
    const service = new PoiCollectionService(authHeader, billingAccount);
    return await service.deleteCollection(collectionToDeleteID);
  },
);

export const useDeleteCollectionHook = authDecoratedFetchingHook(useDeleteCollection);
