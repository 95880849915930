import { POIFeature } from '../types';
import { CSVRowTransformer, KeyIndex } from './csvOperations';
import { ParseResponse } from './fileTranslatorHook';

export const parseAddressCSVFile = (
  indexes: KeyIndex,
  linesGen: CSVRowTransformer,
): ParseResponse => {
  const idIndex = indexes.id !== undefined ? indexes.id : indexes.name;
  const addressIndex = indexes.address;

  if (idIndex === undefined) {
    throw new Error('CSV must have at least one of name or id columns');
  }
  if (addressIndex === undefined) {
    throw new Error('Missing key in csv-data: address');
  }

  const getFeatures = (getRadius: (line: string[]) => number): POIFeature[] =>
    linesGen((values): POIFeature => {
      const id = values[idIndex];
      const name = values[indexes.name !== undefined ? indexes.name : indexes.id];
      const address = values[addressIndex];

      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [0, 0], // Set to [0,0] as address has not been resolved.
          properties: { radius: getRadius(values), address },
        },
        id,
        properties: { name },
      };
    });

  return {
    status: 'success',
    type: 'csv',
    features: getFeatures((row: string[]) => Number.parseInt(row[indexes.radius], 10)),
  };
};
