import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import { MapStyled } from './AddGoogleMap.styles';
import { SearchAutoCompleteLocation } from '../SearchAutoCompleteLocation';
import mapMarkerIcon from '../../icons/MapMarkerPoint.svg';

import { UnaLinearProgress } from '@unacast-internal/unacast-ui/UnaLinearProgress';
import { DrawingManagerLoader } from '../DrawingManagerLoader';
import { GOOGLE_MAP_KEY } from '../../../constants';
import { googleLibraries } from '../../../pages/Layout';

export type GoogleShape =
  | google.maps.Polygon
  | google.maps.Circle
  | google.maps.Marker
  | google.maps.Polyline
  | google.maps.Rectangle;

export interface MapProps {
  editingGeometry?: GoogleShape;
  isInteractive?: boolean;
  setEditingGeometry: React.Dispatch<GoogleShape | undefined>;
  polygonStyle?: any;
  defaultMapCenter?: google.maps.LatLng | google.maps.LatLngLiteral;
}

const googleMapOptions = {
  streetViewControl: false,
  mapId: '4cde2ee1e9b041f7',
  controlSize: 32,
  disableDefaultUI: true,
  zoomControl: true,
  mapTypeControl: true,
  minZoom: 2,
};

const mapStyle = {
  height: '500px',
  width: '900px',
};

const getShapeBounds = (shape: GoogleShape): google.maps.LatLngBounds | undefined | null => {
  if (shape instanceof google.maps.Circle) {
    return shape.getBounds();
  } else if (shape instanceof google.maps.Polygon) {
    const bounds = new google.maps.LatLngBounds();

    if (shape.getPaths().getLength() > 1) {
      shape
        .getPaths()
        .getArray()
        .forEach((path) => path.forEach((point) => bounds.extend(point)));
    } else {
      shape
        .getPath()
        .getArray()
        .forEach((point) => bounds.extend(point));
    }

    return bounds;
  }

  return undefined;
};

const DEFAULT_CENTER = {
  lat: 51.500899680251,
  lng: -0.12467135614968297,
};

//TODO: REMOVE EDITING LOGIC
export const AddGoogleMap = ({
  editingGeometry,
  setEditingGeometry,
  polygonStyle,
  defaultMapCenter = DEFAULT_CENTER,
}: MapProps): React.ReactElement => {
  const { isLoaded } = useJsApiLoader({
    mapIds: ['4cde2ee1e9b041f7', 'b148e3cc084c77fc'],
    googleMapsApiKey: GOOGLE_MAP_KEY,
    libraries: googleLibraries.libraries,
  });

  const [googleMap, setMap] = useState<google.maps.Map>();

  const [geometryCoordinates, setGeometryCoordinates] = useState<
    google.maps.LatLng | google.maps.LatLngLiteral | undefined
  >(editingGeometry ? getShapeBounds(editingGeometry)?.getCenter() : undefined);

  const onLoad = useCallback(
    function callback(map: google.maps.Map) {
      map.setCenter(defaultMapCenter);

      setMap(map);
    },
    [defaultMapCenter],
  );

  //Unfortunately the layer style has to be updated explicitely
  useEffect(() => {
    editingGeometry?.setOptions(polygonStyle);
  }, [editingGeometry, polygonStyle]);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(undefined);
  }, []);

  //----------------------
  //NEW FUNC
  const onDrawingComplete = (newShape: GoogleShape) => {
    expandMapToFit(newShape);

    setEditingGeometry(newShape);
  };

  const expandMapToFit = (newShape: GoogleShape) => {
    const newShapeBounds = getShapeBounds(newShape);
    if (newShapeBounds && googleMap) {
      const currentBounds = googleMap.getBounds();
      if (
        (currentBounds && !currentBounds.contains(newShapeBounds.getNorthEast())) ||
        !currentBounds?.contains(newShapeBounds.getSouthWest())
      )
        googleMap.fitBounds(newShapeBounds);
    }
  };

  const setNewLocation = (newLatLng: google.maps.LatLng) => {
    //Need to reset the map geometry when the location changes
    googleMap?.panTo(newLatLng);
    googleMap?.setZoom(17);
    setGeometryCoordinates(newLatLng);
    setEditingGeometry(undefined);
  };

  return (
    <MapStyled>
      {isLoaded ? (
        <div className="map-area">
          <GoogleMap
            mapContainerStyle={mapStyle}
            zoom={12}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
              ...googleMapOptions,
              mapTypeControlOptions: {
                mapTypeIds: ['roadmap', 'hybrid'],
                position: google.maps.ControlPosition.BOTTOM_LEFT,
              },
            }}
          >
            {geometryCoordinates && <Marker icon={mapMarkerIcon} position={geometryCoordinates} />}
            {googleMap && (
              <DrawingManagerLoader
                onDrawingComplete={onDrawingComplete}
                defaultShape={editingGeometry}
                map={googleMap}
              ></DrawingManagerLoader>
            )}
          </GoogleMap>

          <div className="geocoding-search">
            <SearchAutoCompleteLocation changeHandler={setNewLocation} />
          </div>
        </div>
      ) : (
        <UnaLinearProgress></UnaLinearProgress>
      )}
    </MapStyled>
  );
};
