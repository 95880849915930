import { DrawingManager } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import { GoogleShape } from './AddGoogleMap/AddGoogleMap';
import { EditPolygonStyle, PolygonStyle, statusStyles } from './GeoJsonToMapShape';
import { MapControl } from './MapControl';
import PanToolAltOutlined from '@mui/icons-material/PanToolAltOutlined';
import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import styled from 'styled-components';
import { BlackToWhite } from '@unacast-internal/unacast-ui/StyleGuide/Colors';

const DrawControlsStyled = styled.div`
  display: flex;
  flex-direction: row;
  width: 115px;
  justify-content: space-between;
  position: absolute;
  bottom: 0.4rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`;

type DrawModes = 'pan' | 'polygon' | 'circle';

export const DrawingManagerLoader = ({
  onDrawingComplete,
  defaultShape,
  map,
  polygonStatus = 'edit',
}: {
  onDrawingComplete: (shape?: GoogleShape) => void;
  defaultShape?: GoogleShape;
  map: google.maps.Map;
  polygonStatus?: 'warning' | 'error' | 'ok' | 'edit';
}) => {
  const [loadedShape, setLoadedShape] = useState(defaultShape);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [drawer, setDrawer] = useState<google.maps.drawing.DrawingManager>();
  const [drawingMode, setDrawingMode] = useState<DrawModes>('pan');

  useEffect(() => {
    if (isLoaded) {
      setLoadedShape((prevShape) => {
        if (prevShape) prevShape.setMap(null);
        defaultShape && defaultShape.setMap(map);
        defaultShape && selectGeo(defaultShape);
        return defaultShape;
      });
    }
    return () => {
      defaultShape?.setMap(null);
    };
  }, [defaultShape, isLoaded, map]);

  useEffect(() => {
    if (loadedShape) {
      loadedShape.setOptions(statusStyles[polygonStatus]);
    }
  }, [loadedShape, polygonStatus]);

  const handleOverlayComplete = (e: google.maps.drawing.OverlayCompleteEvent) => {
    if (e.overlay) {
      loadedShape?.setMap(null);
      const newShape: GoogleShape = e.overlay;
      newShape.setMap(map);
      selectGeo(newShape);
      setLoadedShape(newShape);
      onDrawingComplete(newShape);
    } else {
      onDrawingComplete(undefined);
    }
  };

  const setEditable = (shape, to?) => {
    shape.setEditable(to || true);
  };

  const selectGeo = (geo: GoogleShape) => {
    geo.setOptions(statusStyles[polygonStatus]);
    google.maps.event.addListener(geo, 'click', () => {
      setEditable(geo);
    });

    //add circle events
    if (geo instanceof google.maps.Circle) {
      google.maps.event.addListener(geo, 'radius_changed', () => {
        onDrawingComplete(geo);
      });
      google.maps.event.addListener(geo, 'center_changed', () => {
        onDrawingComplete(geo);
      });
    }

    //Add polygon events
    if (geo instanceof google.maps.Polygon) {
      geo.getPaths().forEach((path) => {
        google.maps.event.addListener(path, 'insert_at', () => {
          onDrawingComplete(geo);
        });
        google.maps.event.addListener(path, 'remove_at', () => {
          onDrawingComplete(geo);
        });
        google.maps.event.addListener(path, 'set_at', () => {
          onDrawingComplete(geo);
        });
      });
    }
    setEditable(geo);
  };

  const onLoad = (dm: google.maps.drawing.DrawingManager) => {
    setDrawer(dm);
    setIsLoaded(true);
  };

  const onUnmount = (dm: google.maps.drawing.DrawingManager) => {
    if (loadedShape) {
      loadedShape.setMap(null);
      setEditable(loadedShape, false);
    }
  };

  const onSetDrawMode = (overlay: google.maps.drawing.OverlayType | null, mode: DrawModes) => {
    drawer?.setDrawingMode(overlay);
    setDrawingMode(mode);
  };
  return (
    <>
      <DrawingManager
        onOverlayComplete={handleOverlayComplete}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          drawingControlOptions: {
            position: google.maps.ControlPosition.BOTTOM_CENTER,
            drawingModes: [],
          },
          circleOptions: {
            ...EditPolygonStyle,
          },
          polygonOptions: {
            ...EditPolygonStyle,
            editable: true,
          },
        }}
      ></DrawingManager>
      <DrawControlsStyled>
        <MapControl
          onClick={() => onSetDrawMode(null, 'pan')}
          icon={
            <PanToolAltOutlined
              htmlColor={drawingMode === 'pan' ? BlackToWhite.White : undefined}
            ></PanToolAltOutlined>
          }
          tooltipText="Stop Drawing"
          isSelected={drawingMode === 'pan'}
        ></MapControl>
        <MapControl
          onClick={() => onSetDrawMode(google.maps.drawing.OverlayType.POLYGON, 'polygon')}
          icon={
            <HexagonOutlinedIcon
              htmlColor={drawingMode === 'polygon' ? BlackToWhite.White : undefined}
            ></HexagonOutlinedIcon>
          }
          tooltipText="Draw a Shape"
          isSelected={drawingMode === 'polygon'}
        ></MapControl>
        <MapControl
          onClick={() => onSetDrawMode(google.maps.drawing.OverlayType.CIRCLE, 'circle')}
          icon={
            <CircleOutlinedIcon
              htmlColor={drawingMode === 'circle' ? BlackToWhite.White : undefined}
            ></CircleOutlinedIcon>
          }
          tooltipText="Draw a Circle"
          isSelected={drawingMode === 'circle'}
        ></MapControl>
      </DrawControlsStyled>
    </>
  );
};
