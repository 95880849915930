import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { BreadCrumbs, Crumbs } from '@unacast-internal/unacast-ui/BreadCrumbs';
import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { BlackToWhite, Navy } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { Body } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { UnacastIcon } from '@unacast-internal/unacast-ui/StyleGuide/UnaIcons/UnacastIcon';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import UserMenuContainer from '../containers/UserMenuContainer';
import { useBreadCrumbs } from '../contexts/BreadCrumbContext';
import { useCurrentBillingAccounts, useCurrentUserLoggedInStatus } from '../contexts/UserContext';
import { Elevation2 } from '@unacast-internal/unacast-ui/StyleGuide/Elevation';

interface StyleProps {
  isSubPage: boolean;
  isAtTop: boolean;
  showLogo?: boolean;
}

export const topBarHeight = '4rem';

export const TopBarStyled = styled.header`
  ${Elevation2}
  position: sticky;
  top: 0;
  box-sizing: border-box;
  z-index: 100;
  width: 100%;
  height: ${topBarHeight};
  background-color: ${Navy.Navy10};
  opacity: 1;
  padding: 0 2rem;
  & > * {
    box-sizing: border-box;
  }
  transition: border 0.2s linear;
  /* border-bottom: ${(props: StyleProps) =>
    !props.isAtTop
      ? `1px solid ${BlackToWhite.Gray04}`
      : props.isSubPage
      ? `1px solid ${BlackToWhite.Gray06}`
      : '1px solid #fff'}; */
  &.with-bottom-top-bar {
    height: 6.5rem;
  }
  .bold {
    font-weight: bold;
  }
  .center {
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .top-top-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: ${topBarHeight};

    .logo {
      cursor: pointer;
    }
    .menu {
      height: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      .menu__item {
        height: 100%;
        padding: 0 1rem;
        display: flex;
        cursor: pointer;
        &.active,
        &:hover {
          background-color: #d5d5d5;
        }
      }
    }
    .user-info {
      display: flex;
      gap: 30px;
      margin-left: auto;
      align-items: center;
    }
  }
  .bottom-top-bar {
  }
`;

const TopBar = ({
  isSubPage,
  showLogo,
}: {
  isSubPage: boolean;
  showLogo?: boolean;
}): JSX.Element => {
  const history = useHistory();
  const billingAccounts = useCurrentBillingAccounts();
  const loggedIn = useCurrentUserLoggedInStatus();
  const { crumbs } = useBreadCrumbs();
  const [isAtTop, setIsAtTop] = useState(true);

  useScrollPosition(
    ({ currPos }) => {
      const atTop = currPos.y === 0;
      if (atTop !== isAtTop) setIsAtTop(atTop);
    },
    [isAtTop],
  );

  const getDefaultBreadCrumbOptions = () => {
    const options: Crumbs[] = [];
    options.push({ text: 'Home', url: `/` });

    return options;
  };

  return (
    <TopBarStyled
      isSubPage={isSubPage}
      showLogo={showLogo}
      isAtTop={isAtTop}
      className={(isSubPage && 'with-bottom-top-bar') || ''}
    >
      <div className="top-top-bar">
        {showLogo && (
          <div className="logo" onClick={() => history.push(`/`)}>
            <UnacastIcon width={140}></UnacastIcon>
          </div>
        )}
        {!loggedIn && (
          <UnaButton
            styleType="primary"
            onClick={() => {
              history.push('/signup');
            }}
          >
            <Body className="bold">Sign Up</Body>
          </UnaButton>
        )}
        {loggedIn ? (
          <>
            <div className="user-info">
              <UserMenuContainer billingAccountList={billingAccounts} />
            </div>
          </>
        ) : (
          <UnaButton
            styleType="secondary"
            isBorderless={true}
            onClick={() => {
              history.push('/login');
            }}
          >
            <Body className="bold">Log in</Body>
          </UnaButton>
        )}
      </div>
      <div className="bottom-top-bar">
        {isSubPage && (
          <BreadCrumbs crumbs={crumbs.length > 0 ? crumbs : getDefaultBreadCrumbOptions()} />
        )}
      </div>
    </TopBarStyled>
  );
};

export default TopBar;
