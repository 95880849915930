import React from 'react';
import { RouteChildrenProps } from 'react-router';
import { useCurrentBillingAccount } from '../contexts/BillingAccountContext';
import { featureForBillingAccount } from '../helpers/featureToggle';

const FeatureToggle = ({
  toggleKey,
  children,
  waitingForDataRender = () => {
    return <></>;
  },
  noAccessRender = () => {
    return <></>;
  },
}: {
  toggleKey: string;
  children:
    | ((
        props: RouteChildrenProps<{ [x: string]: string | undefined } | undefined>,
      ) => React.ReactNode)
    | React.ReactNode;
  waitingForDataRender?: () => React.ReactElement;
  noAccessRender?: () => React.ReactElement;
}): JSX.Element => {
  const currentBillingAccount = useCurrentBillingAccount();

  if (!currentBillingAccount) return waitingForDataRender();

  if (!featureForBillingAccount(currentBillingAccount, toggleKey)) {
    return noAccessRender();
  }

  return <>{children}</>;
};

export const FeatureToggleHelper = (): {
  isFeatureToggleActive: (toggleKey: string) => boolean;
} => {
  const currentBillingAccount = useCurrentBillingAccount();
  //TODO: value should be wrapped in a 'useMemo'
  return {
    isFeatureToggleActive: (toggleKey: string) => {
      if (currentBillingAccount) {
        return featureForBillingAccount(currentBillingAccount, toggleKey);
      }
      return false;
    },
  };
};

export default FeatureToggle;
