import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { Modal } from '@unacast-internal/unacast-ui/Organisms';
import { BlackToWhite, Primary } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { motion } from 'framer-motion';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import LoadingSnackBar from '../../components/LoadingSnackBar';
import { MadeToOrderReportCard } from '../../components/MadeToOrderReportCard/MadeToOrderReportCard';
import { ReportMetricCard } from '../../components/MadeToOrderReportList/ReportMetricCard/ReportMetricCard';
import { ConfirmationDialog } from '../../components/Modals/ConfirmationDialog';
import { DeliveryDetailsModal } from '../../components/Modals/DeliveryDetailsModal';
import { ReportTimeRangeText } from '../../components/ReportTimeRangeText';
import { useFetchAndDownloadReport } from '../../dataManagement/downloadReportHook';
import { useArchiveReportHook } from '../../dataManagement/useArchiveReport';
import { useMadeToOrderNavigation } from '../../utils/madeToOrderNavigation';
import {
  getDateLimitation,
  isAdheringToReportLengthLimitation as isAdheringToReportLengthLimitationHelper,
} from '../../utils/reportHelpers';
import {
  MetricResult,
  PoiRegion,
  Report,
  ReportStatus,
  getUnacatRef,
} from '../../../unacatInteraction/BringYourOwnPOIService';
import { ReportDetailsPageStyled } from './ReportDetailsPage.styles';
import {
  AuthParams,
  authDecoratedFetchingHook,
  createAsyncFetchingHook,
} from 'src/unacatInteraction/fetchingHook';
import { CreateDataDeliveryResponse } from '@unacast-internal/unacat-js/unacast/v2/catalog/data_delivery_service_pb';
import { DataDeliveryService } from 'src/unacatInteraction/DataDeliveryService';
import { useCurrentUserRoles } from 'src/contexts/UserContext';

enum ReportLengthLimitation {
  Quarter,
  Year,
  None,
}

const useCreateDataDeliveryForBQ = authDecoratedFetchingHook(
  createAsyncFetchingHook(
    async (
      { authHeader, billingAccount }: AuthParams,
      catalogId: string,
      tableId: string,
      metricId: string,
    ): Promise<CreateDataDeliveryResponse> => {
      const service = new DataDeliveryService(authHeader, billingAccount);

      const catalogBacc = 'cf95b8i23akg009pi850';
      const unacastBacc = 'bvcel5223akg00a0m0og';

      if (![catalogBacc, unacastBacc].includes(billingAccount)) {
        throw new Error('Operation not supported for this billing account');
      }

      const storageAccessID =
        unacastBacc === billingAccount ? 'cjtfqpa23akg00a8s320' : 'cjtfolq23akg00a8s2i0';
      return service.CreateDataDeliveryForBQ(catalogId, tableId, metricId, storageAccessID);
    },
  ),
);

export const ReportDetailsPage = (report: Report) => {
  const downloadReport = useFetchAndDownloadReport();
  const [archiveReportState, archiveReport] = useArchiveReportHook();
  const [showDeliveryModal, setShowDeliveryModal] = useState<boolean>(false);
  const [deliveryStatus, triggerDelivery] = useCreateDataDeliveryForBQ();
  const hasIndexedMetric = useMemo(() => {
    return report.resultsList.length > 0 && report.resultsList[0].unacatCatalogId;
  }, [report]);

  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState<boolean>(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const navTo = useMadeToOrderNavigation();

  const userRoles = useCurrentUserRoles();
  const isSolutionSpecialist = userRoles.includes('catrole.rwg.11') || userRoles.includes('admin');

  useEffect(() => {
    if (deliveryStatus.status === 'succeeded') {
      enqueueSnackbar(
        `${deliveryStatus.data.getDelivery()?.toObject().deliverySourceId}: exported successfully`,
        { variant: 'success' },
      );
    } else if (deliveryStatus.status === 'failed') {
      enqueueSnackbar(deliveryStatus.error.message, {
        variant: 'error',
      });
    }
  }, [deliveryStatus, enqueueSnackbar]);

  const startDeliveringMetrics = (metricsToDeliver: string[]) => {
    setShowDeliveryModal(false);

    //Loop through each of the metrics and trigger a delivery
    metricsToDeliver.forEach((metric) => {
      const result = report.resultsList.find((r) => r.metric === metric);

      if (result) {
        const tableId = `${result.metric}_${report.id}`;
        triggerDelivery(result.unacatCatalogId, tableId, result.unacatMetricId);
      }
    });
  };

  useEffect(() => {
    if (archiveReportState.status === 'succeeded') {
      enqueueSnackbar('Report Deleted', { variant: 'success' });
      navTo.homePage();
    } else if (archiveReportState.status === 'failed') {
      enqueueSnackbar(archiveReportState.error.message, {
        variant: 'error',
      });
    }
  }, [archiveReportState]);

  const downloadWithNotification = async (Id, region, metric) => {
    const loadingNotification = enqueueSnackbar(`Downloading ${metric}`, {
      variant: 'info',
      content: (key, message) => (
        <LoadingSnackBar key={key} color={Primary.Primary01}>
          {message}
        </LoadingSnackBar>
      ),
      anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
    });
    const unacatRef = getUnacatRef(report.resultsList, metric);
    downloadReport(Id, region, metric, unacatRef)
      .then(() => closeSnackbar(loadingNotification))
      .catch((err) => {
        closeSnackbar(loadingNotification);
        enqueueSnackbar(`${err.message} for: ${metric}`, {
          variant: 'error',
        });
      });
  };

  const dateLimitation: ReportLengthLimitation = useMemo(() => {
    const len = report?.itemCount || 0;
    return getDateLimitation(len);
  }, [report.itemCount]);

  const isAdheringToReportLengthLimitation = useMemo(
    () => isAdheringToReportLengthLimitationHelper(report, dateLimitation),
    [report.reportType, dateLimitation, report.startDate, report.endDate],
  );

  const getMenuActionsByStatus = (
    status: ReportStatus,
    metrics: string[],
    id: string,
    region: PoiRegion,
  ): Record<string, () => void> | undefined => {
    if (status === ReportStatus.SUCCEEDED) {
      let actions: Record<string, () => void> = {
        'Use As Template': () => navTo.createReportFromTemplate(id),
        Delete: () => {
          setShowArchiveConfirmation(true);
        },
      };
      if (isAdheringToReportLengthLimitation) {
        actions = {
          ...actions,
          'Download all': () => {
            metrics.forEach((metric) => {
              downloadWithNotification(id, region, metric);
            });
            // Validations are only created for international metrics
            if (region === 'international') {
              downloadWithNotification(id, region, '__validations');
            }
          },
        };
        if (hasIndexedMetric) {
          actions['Download all'] = () => {
            metrics.forEach((metric) => {
              downloadWithNotification(id, region, metric);
            });
            // Validations are only created for international metrics
            if (region === 'international') {
              downloadWithNotification(id, region, '__validations');
            }
          };
        }
      }
      if (isSolutionSpecialist) {
        actions.Export = () => {
          setShowDeliveryModal(true);
        };
      }
      return actions;
    }

    return undefined;
  };

  const getMetricActions = (
    metric: string,
    Id: string,
    region: PoiRegion,
    metricResult?: MetricResult,
  ): Record<string, () => void> => {
    const catalogId = metricResult?.unacatCatalogId;
    const metricId = metricResult?.unacatMetricId;

    const actions: Record<string, () => void> = {};

    if (isAdheringToReportLengthLimitation) {
      if (catalogId && metricId && metricResult) {
        actions.Download = () => downloadWithNotification(Id, region, metric);
        // TODO: Uncomment when appropriate access management is in place
        // As of right now user both might not have access, and lack the ability to see "experimental" which these are
        // Details: () => {
        //   navTo.metricDetailsPage(catalogId, metricId);
        // },
      } else {
        actions.Download = () => downloadWithNotification(Id, region, metric);
      }
    }
    return actions;
  };

  const downloadConfidenceScores = (id: string, region: PoiRegion) => {
    downloadWithNotification(id, region, '__validations');
  };

  return (
    <ReportDetailsPageStyled
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.6 } }}
    >
      <>
        <MadeToOrderReportCard
          {...report}
          id={report.reportName || report.id}
          isAdheringToReportLengthLimitation={isAdheringToReportLengthLimitation}
          locationCount={report.itemCount}
          menuActions={getMenuActionsByStatus(
            report.status,
            report.metrics,
            report.id,
            report.region,
          )}
          isLargeCard
        ></MadeToOrderReportCard>
        <div className="metric-list">
          <div className="metric-row">
            <div>
              <Heading type="h3">Metrics for your {report.itemCount} location(s):</Heading>
              {report.reportType !== undefined && (
                <ReportTimeRangeText
                  reportType={report.reportType}
                  startDate={report.startDate}
                  endDate={report.endDate}
                  color={BlackToWhite.Gray02}
                  type="body"
                ></ReportTimeRangeText>
              )}
            </div>
            {report.region === 'international' && report.status === ReportStatus.SUCCEEDED && (
              <UnaButton
                styleType="secondary"
                onClick={() => downloadConfidenceScores(report.id, report.region)}
              >
                Download Confidence Report
              </UnaButton>
            )}
          </div>
          {report.metrics.map((m) => (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.8 } }}
              key={m}
              className="metric-card-spacing"
            >
              <ReportMetricCard
                id={m}
                menuActions={
                  report.status === ReportStatus.SUCCEEDED
                    ? getMetricActions(
                        m,
                        report.id,
                        report.region,
                        report.resultsList.find((r) => r.metric === m),
                      )
                    : {}
                }
              ></ReportMetricCard>
            </motion.div>
          ))}
        </div>
        <Modal isOpen={showDeliveryModal} onClose={() => setShowDeliveryModal(false)}>
          <DeliveryDetailsModal
            onCancel={() => setShowDeliveryModal(false)}
            onConfirm={startDeliveringMetrics}
            metrics={report.metrics}
          ></DeliveryDetailsModal>
        </Modal>
        <ConfirmationDialog
          isOpen={showArchiveConfirmation}
          onClose={() => setShowArchiveConfirmation(false)}
          onConfirm={() => {
            archiveReport(report.id);
            setShowArchiveConfirmation(false);
          }}
          text="Are you sure you wish to delete this report?"
        ></ConfirmationDialog>
      </>
    </ReportDetailsPageStyled>
  );
};
