import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryState } from 'react-router-use-location-state';
import { BillingAccount } from '@unacast-internal/unacat-js/unacast/subscription/v1/billing_account_pb';
import { useCurrentBillingAccounts, useCurrentUserLoggedInStatus } from './UserContext';
import { Subscription } from '@unacast-internal/unacat-js/unacast/subscription/v1/subscription_pb';
import { AuthContext } from './AuthContext';
import { getSubscriptionServicePromiseClient } from '../rpc';
import { ListSubscriptionsRequest } from '@unacast-internal/unacat-js/unacast/subscription/v1/subscription_service_pb';

type Props = {
  currentBillingAccount: string | null;
  billingAccounts: BillingAccount.AsObject[];
  subscriptions: Subscription.AsObject[];
  setCurrentBillingAccount: (string) => void;
};

const BillingAccountContext = React.createContext<Props>({
  currentBillingAccount: null,
  billingAccounts: [],
  subscriptions: [],
  setCurrentBillingAccount: () => null,
});

const rootPathsThatNeedBacc = ['c', 'unacast-where', 'made-to-order'];

export const BillingAccountProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const billingAccounts = useCurrentBillingAccounts();
  const loggedIn = useCurrentUserLoggedInStatus();
  const [preferredBillingAccount, setPreferredBillingAccount] = useState<string | null>(null);
  const rootPath = history.location.pathname.split('/')[1];
  const [billingAccountFromUrl, setBillingAccountFromUrl] = useQueryState('bacc', '');

  const billingAccount =
    billingAccountFromUrl ||
    preferredBillingAccount ||
    billingAccounts.filter((v) => !v.displayName.includes('@'))[0]?.id ||
    billingAccounts[0]?.id;

  useEffect(() => {
    if (
      rootPathsThatNeedBacc.includes(rootPath) &&
      !billingAccounts.some(({ id }) => id === billingAccount)
    ) {
      history.push('/page-not-found');
    }
  }, [billingAccount, history, billingAccounts, rootPath]);

  useEffect(() => {
    if (billingAccount !== preferredBillingAccount) {
      setPreferredBillingAccount(billingAccount);
    }
  }, [billingAccountFromUrl, preferredBillingAccount]);

  useEffect(() => {
    if (billingAccount !== billingAccountFromUrl) {
      setBillingAccountFromUrl(billingAccount);
    }
  }, [billingAccount, billingAccountFromUrl, setBillingAccountFromUrl]);

  useEffect(() => {
    if (!loggedIn) {
      setBillingAccountFromUrl('');
      setPreferredBillingAccount(null);
    }
  }, [loggedIn, setBillingAccountFromUrl]);

  const [subscriptions, setSubscriptions] = useState<Subscription.AsObject[]>([]);
  const { authorizationToken } = useContext(AuthContext);

  useEffect(() => {
    if (authorizationToken && billingAccount) {
      const subscriptionService = getSubscriptionServicePromiseClient();
      const request = new ListSubscriptionsRequest()
        .setBillingAccountFilterList([billingAccount])
        .setShowExpired(false)
        .setPageSize(1000);

      subscriptionService
        .listSubscriptions(request, { Authorization: authorizationToken })
        .then((response) => {
          setSubscriptions(
            response.toObject().subscriptionsList.filter((s) => s.resourcesList.length > 0),
          );
        })
        .catch((err) => {
          console.error(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingAccount, authorizationToken]);

  return (
    <BillingAccountContext.Provider
      value={{
        currentBillingAccount: billingAccount,
        billingAccounts: billingAccounts,
        subscriptions: subscriptions,
        setCurrentBillingAccount: setBillingAccountFromUrl,
      }}
    >
      {children}
    </BillingAccountContext.Provider>
  );
};

export const useBillingAccountContext = (): Props => useContext(BillingAccountContext);
export const useCurrentBillingAccount = (): string | null =>
  useContext(BillingAccountContext).currentBillingAccount;
