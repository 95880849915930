import { UnaButton } from '@unacast-internal/unacast-ui/Button';

import { Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePOICollectionPublisher } from '../../dataManagement/generateStoredCollectionHook';
import { usePrepareReport } from '../../dataManagement/prepareReportHook';
import {
  DraftInfo,
  ReportType,
  reportTypeToString,
} from '../../../unacatInteraction/BringYourOwnPOIService';
import { POICollection, POIFeature } from '../../types';
import { AnimateVariants, CreateReportPageStyled } from './CreateReportPage.styles';
import { usePOICollectionStore } from '../../dataManagement/poiCollectionStore';
import { Info, Success, Warning } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { CircularProgress } from '@mui/material';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { useStoreDraftReportHook } from '../../dataManagement/useStoreDraftReportHook';
import { useMadeToOrderNavigation } from '../../utils/madeToOrderNavigation';
import { getDateLimitation, 
  isAdheringToReportLengthLimitation as isAdheringToReportLengthLimitationHelper
 } from '../../utils/reportHelpers';
import centroid from '@turf/centroid';
import { AnimatePresence, motion } from 'framer-motion';
import TimedSnackBar from '../../components/TimedSnackBar';
import { useDeleteDraftReportHook } from '../../dataManagement/useDeleteDraftReportHook';
import { withAddedFeature } from '../../dataManagement/poiCollectionActions';
import { CreateReportPageInputGroup } from '../../components/CreateReportPageInputGroup';
import { GetMarketPrompt } from '../../components/GetMarketPrompt';
import { FeatureToggleHelper } from '../../../components/FeatureToggle';
import { useCurrentBillingAccount } from '../../../contexts/BillingAccountContext';
import { ReportCollectionManagerMap } from '../../components/ReportCollectionManagerMap/ReportCollectionManagerMap';
import { useReportDraftState } from '../../components/hooks/useReportDraftState';
import { POICollection_fromLocationRecord } from '../../../helpers/geography';
import { useCurrentUserRoles } from 'src/contexts/UserContext';
import { UnaAlert } from '@unacast-internal/unacast-ui/UnaAlert';

enum ReportLengthLimitation {
  Quarter,
  Year,
  None,
}
const dateLimitiationText: Record<ReportLengthLimitation, string> = {
  [ReportLengthLimitation.Quarter]:
    'For more than 1000 locations, time range is restricted to one quarter of data',
  [ReportLengthLimitation.Year]:
    'For more than 100 locations, time range is restricted to one year of data',
  [ReportLengthLimitation.None]: '',
};

export const MAX_INPUT_CHARACTERS = 250;
export const MIN_INPUT_CHARACTERS = 1;

//TODO: FIND SOMEWHERE TO PUT THIS
const getIconByStatus = (status: string) => {
  switch (status) {
    case 'succeeded':
      return <CheckCircleIcon style={{ color: Success.Success01 }}></CheckCircleIcon>;
    case 'failed':
      return <ErrorIcon style={{ color: Warning.Warning01 }}></ErrorIcon>;
    case 'initial':
      return <UnpublishedIcon style={{ color: '#ff9800' }}></UnpublishedIcon>;
    case 'pending':
      return <CircularProgress size="1.5rem"></CircularProgress>;
    default:
      return <></>;
  }
};

const promptForMarketSelector = 'byoMarketSelector';

const ORDER_COLLECTION_NAME = 'OrderCollection';

export interface ReportDraft extends DraftInfo {
  reportId?: string;
  draftId?: string;
  collection?: POICollection;
}

/**
 * CreateReportPage Handles retreiving data from the user to initate report creation
 * @param reportDraft
 * @returns
 */
export const CreateReportPage = ({
  reportDraft,
}: {
  reportDraft?: ReportDraft;
}): React.ReactElement => {
  const { isFeatureToggleActive } = FeatureToggleHelper();
  const currentBillingAccount = useCurrentBillingAccount();

  //------------------------------------------------------------
  //-----------State values that reflect report properties
  //--------------------------------------------------------------
  const { reportDraftValues, reportDraftSetters } = useReportDraftState(reportDraft);

  //All of the user's collections
  const [collections, updatePOICollection] = usePOICollectionStore();
  //-----------------------------------------------------------------------------
  //---------END REPORT PROPS---------------------------------------
  //-----------------------------------------------------------------------------

  const [, publishPOIs] = usePOICollectionPublisher();
  const [, deleteDraft] = useDeleteDraftReportHook();

  const userRoles = useCurrentUserRoles();
  const isSolutionSpecialist = userRoles.includes('catrole.rwg.11') || userRoles.includes('admin');

  const [draftReportState, publishReportDraft] = useStoreDraftReportHook();
  const [generatedReportState, triggerReportGen] = usePrepareReport();
  const [currentDraftId, setCurrentDraftId] = useState<string | undefined>(reportDraft?.draftId);

  //UI Button States
  const [canSaveDraft] = useState<boolean>(true);
  const [reportSaved, setReportSaved] = useState<boolean>(false);

  const currentCollection = collections[ORDER_COLLECTION_NAME];

  useEffect(() => {
    if (currentCollection === undefined) {
      updatePOICollection(ORDER_COLLECTION_NAME, () => ({}));
    }
  }, []);

  const navTo = useMadeToOrderNavigation();

  const dateLimitation: ReportLengthLimitation = useMemo(() => {
    const len = reportDraft?.collectionLength || (currentCollection ? Object.keys(currentCollection).length : 0);
    return getDateLimitation(len);
  }, [currentCollection]);

  const isAdheringToReportLengthLimitation = useMemo(() => isAdheringToReportLengthLimitationHelper(reportDraftValues, dateLimitation), [
    reportDraftValues.reportType,
    dateLimitation,
    reportDraftValues.startDate,
    reportDraftValues.endDate,
  ]);

  const isValidInput = useCallback((): boolean => {
    const isClientNameValid =
      reportDraftValues.clientName.length >= MIN_INPUT_CHARACTERS &&
      reportDraftValues.clientName.length <= MAX_INPUT_CHARACTERS;

    const isReportNameValid =
      reportDraftValues.reportName.length >= MIN_INPUT_CHARACTERS &&
      reportDraftValues.reportName.length <= MAX_INPUT_CHARACTERS;

    const isReportMetricsValid = reportDraftValues.selectedMetrics.length > 0;

    return isClientNameValid && isReportNameValid && isReportMetricsValid;
  }, [
    reportDraftValues.clientName.length,
    reportDraftValues.reportName.length,
    reportDraftValues.selectedMetrics.length,
  ]);

  const canSaveReport = useMemo(() => {
    return (
      !reportSaved &&
      ((currentCollection && Object.values(currentCollection).length > 0) ||
        reportDraft?.collectionReference) &&
      reportDraftValues.market &&
      isValidInput()
    );
  }, [
    currentCollection,
    isAdheringToReportLengthLimitation,
    reportDraft?.collectionReference,
    reportDraftValues.market,
    reportSaved,
    isValidInput,
  ]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //-----------------------------------------------------
  //-----------COLLECTION ACTIONS -----------------------
  //-----------------------------------------------------
  const extendCurrentCollection = useCallback((features: POIFeature[]) => {
    features.forEach(
      (newFeat) => (newFeat.properties.centroid = centroid(newFeat).geometry.coordinates),
    );
    updatePOICollection(ORDER_COLLECTION_NAME, (f) => features.reduce(withAddedFeature, f));
  }, []);

  //----------------------------------------------------------
  //----------END COLLECTION ACTIONS------------------------
  //--------------------------------------------------------

  useEffect(() => {
    if (reportDraft?.collection) {
      extendCurrentCollection(reportDraft.collection.features);
    }
  }, [reportDraft, extendCurrentCollection]);

  //can't use the feature toggle until the billingAccount has been loaded, then the default value of
  //market determines if the user is prompted to select one
  useEffect(() => {
    if (reportDraft?.market) {
      reportDraftSetters.setMarket(reportDraft?.market);
    } else {
      reportDraftSetters.setMarket(
        isFeatureToggleActive(promptForMarketSelector) ? undefined : 'international',
      );
    }
  }, [currentBillingAccount, reportDraft?.market]);

  useEffect(() => {
    if (draftReportState.status === 'succeeded') {
      setCurrentDraftId(draftReportState.data);
      enqueueSnackbar(`Draft successfully created`, {
        variant: 'success',
      });
    } else if (draftReportState.status === 'failed') {
      enqueueSnackbar('There was an error saving your draft', {
        variant: 'error',
      });
    }
  }, [draftReportState, enqueueSnackbar]);

  useEffect(() => {
    if (generatedReportState.status === 'pending') {
      if (currentDraftId) {
        deleteDraft(currentDraftId);
        setCurrentDraftId(undefined);
      }

      enqueueSnackbar(
        'Your Order is being created. You will be redirected to the listing page...',
        {
          variant: 'success',
          //Added a little extra time due to animation transitions
          autoHideDuration: 4300,
          content: (key, message) => (
            <TimedSnackBar key={key} time={4000} color={Info.Info01}>
              {message}
            </TimedSnackBar>
          ),
          onExited: () => {
            navTo.homePage();
          },
        },
      );
    }
  }, [generatedReportState.status]);

  //-------------------------------------------------------------------------------
  //----This triggers a report to be created
  //----if the POIs needs to be published, it's done here
  //----if not, a report is triggered to be generated
  //-------------------------------------------------------------------------------
  const publishAndGenerateReport = async () => {
    const { reportName, clientName, startDate, endDate, market, reportType } = reportDraftValues;

    let collectionReference: string | undefined;
    if (reportDraft?.collectionReference && reportDraftValues.market) {
      collectionReference = reportDraft.collectionReference;
    } else if (currentCollection && Object.values(currentCollection)) {
      const pubState = await publishPOIs(Object.values(currentCollection));
      if (pubState.status === 'succeeded') {
        collectionReference = pubState.data.versionId;
      }
    }
    if (collectionReference) {
      triggerReportGen({
        reportName,
        clientName,
        metrics: reportDraftValues.selectedMetrics.map((m) => m.value),
        startDate,
        endDate,
        collectionReference,
        market: market || 'international',
        reportType,
      });
    } else {
      setReportSaved(false);
      enqueueSnackbar('there was an error starting your order...', {
        variant: 'error',
      });
    }
  };

  const onSubmitDraft = () => {
    enqueueSnackbar('Saving your draft...', { variant: 'info' });

    const { reportName, clientName, startDate, endDate, reportType, market } = reportDraftValues;

    //parse all fields
    //convert to json
    const reportDetails: DraftInfo = {
      reportName,
      clientName,
      startDate,
      endDate,
      metrics: reportDraftValues.selectedMetrics.map((m) => m.value),
      reportType,
      market,
      collectionLength:
        (currentCollection && Object.keys(currentCollection).length) ||
        reportDraft?.collectionLength ||
        0,
      createdTime: new Date(Date.now()),
      collectionReference: reportDraft?.collectionReference,
    };
    //If undefined, creates new draft, if (ID) update that
    publishReportDraft(
      JSON.stringify(reportDetails),
      JSON.stringify(
        currentCollection ? POICollection_fromLocationRecord(currentCollection) : undefined,
      ),
      currentDraftId,
    );
  };

  const onSubmitReport = () => {
    if (canSaveReport) {
      setReportSaved(true);

      publishAndGenerateReport();
    }
  };

  const onLargeReportSubmit = () => {
    if (window.confirm('Are you sure you want to submit a large report?')) {
      onSubmitReport();
    }
  };

  return (
    <CreateReportPageStyled initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Heading type="h2">
        Create a new {reportTypeToString(reportDraftValues.reportType)} report
        {getIconByStatus(generatedReportState.status)}
      </Heading>
      <AnimatePresence>
        {reportDraftValues.market ? (
          <motion.div
            initial={'hidden'}
            animate={'show'}
            exit={'hidden'}
            variants={AnimateVariants}
            className="create-content"
          >
            <CreateReportPageInputGroup
              {...reportDraftValues}
              market={reportDraftValues.market}
              {...reportDraftSetters}
              dateInformationText={dateLimitiationText[dateLimitation]}
            ></CreateReportPageInputGroup>

            {!isAdheringToReportLengthLimitation && (
              <UnaAlert severity="warning" className="alert-spacing" title="Large Report">
                {isSolutionSpecialist
                  ? 'Submitting this job will incur tangible processing costs'
                  : `${dateLimitiationText[dateLimitation]}. Only Solution Specialists are allowed to submit report of this size.`}
              </UnaAlert>
            )}

            {reportDraft?.collectionReference ? (
              <div className="poi-collection-header">
                <Heading type="h2">
                  Using {reportDraft.collectionLength} location(s) from &quot;
                  {reportDraft.reportName || reportDraft.reportId || reportDraft.draftId}&quot;
                </Heading>
              </div>
            ) : (
              <ReportCollectionManagerMap
                collectionName={ORDER_COLLECTION_NAME}
              ></ReportCollectionManagerMap>
            )}

            <div className="actions">
              <UnaButton
                styleType="secondary"
                size="large"
                onClick={onSubmitDraft}
                disabled={!canSaveDraft}
              >
                Save Draft
              </UnaButton>
              <UnaButton
                styleType="primary"
                size="large"
                disabled={
                  // badReport || (largeReport && !solutionSpecialist)
                  !canSaveReport || (!isAdheringToReportLengthLimitation && !isSolutionSpecialist)
                }
                onClick={isAdheringToReportLengthLimitation ? onSubmitReport : onLargeReportSubmit}
              >
                Create Report
              </UnaButton>
            </div>
          </motion.div>
        ) : (
          <motion.div
            initial={'hidden'}
            animate={'show'}
            exit={'hidden'}
            variants={AnimateVariants}
            className="market-prompt-placement"
          >
            <GetMarketPrompt onComplete={reportDraftSetters.setMarket}></GetMarketPrompt>
          </motion.div>
        )}
      </AnimatePresence>
    </CreateReportPageStyled>
  );
};
