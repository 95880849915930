import { GoogleMap, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Feature } from '@turf/helpers';
import { centroid } from '@turf/turf';
import {
  CoordinateToLatLng,
  geoJsonToGooglePath,
  getLatLngBoundsForFeatures,
  getPOIArea,
  googleShapeToGeoJson,
  validateFeature,
} from '../../../helpers/geography';
import mapMarkerPointIcon from '../../icons/MapMarkerPoint.svg';

import mapMarkerWarningIcon from '../../icons/MapMarkerWarning.svg';

import mapClusterIcon from '../../icons/MapCluster.svg';
import mapClusterErrorIcon from '../../icons/MapClusterError.svg';
import mapClusterWarningIcon from '../../icons/MapClusterWarning.svg';

import { GoogleMapsOverlay } from '@deck.gl/google-maps';
import AllOutIcon from '@mui/icons-material/AllOut';
import { Popover, PopoverPosition } from '@mui/material';
import { BlackToWhite } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { Body } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { AnimatePresence } from 'framer-motion';
import { useSnackbar } from 'notistack';
import { OverflowMenu } from '../../../components/OverflowMenu';
import { GOOGLE_MAP_KEY } from '../../../constants';
import { PolygonFromPoint } from '../../dataManagement/featureConvertion';
import { googleLibraries } from '../../../pages/Layout';
import { POIError } from '../../../unacatInteraction/PoiCollectionService';
import { POICollection, POIFeature, Position } from '../../types';
import { GoogleShape } from '../AddGoogleMap/AddGoogleMap';
import { DrawingManagerLoader } from '../DrawingManagerLoader';
import { FeatureDetailsPanel } from '../FeatureDetailsPanel/FeatureDetailsPanel';
import { GeoJsonToMapShape } from '../GeoJsonToMapShape';
import { MapErrorSummary } from '../MapComponents/MapErrorSummary';
import { MapControl } from '../MapControl';
import { SearchAutoCompletePOIs } from '../SearchAutoCompletePOIs';
import { MultiFeatureGoogleMapStyled } from './MultiFeatureGoogleMap.styles';

type MultiFeatureGoogleMapProps = {
  featureCollection: POICollection | undefined;
  collectionName: string;
  onEditFeature?: (feat: POIFeature) => boolean;
  deleteFeature: (selectedFeatureId: string) => void;
  errors?: Record<string, string[]> | Record<string, POIError>;
  layers?: GoogleMapsOverlay;
  setSelected?: (selectedFeature: POIFeature | undefined) => void;
  setMapCenter?: (mc: google.maps.LatLng | undefined) => void;
  topLevelActions?: Record<string, () => void>;
  showErrorList?: boolean;
  defaultSelectedFeatureId?: string;
  defaultSelectedFeature?: POIFeature;
};

const mapStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '6px',
};

const googleMapOptions = {
  streetViewControl: false,
  mapId: '4cde2ee1e9b041f7',
  controlSize: 32,
  disableDefaultUI: true,
  zoomControl: true,
  mapTypeControl: true,
  minZoom: 2,
};

const clusterStyle = {
  url: mapClusterIcon,
  textColor: BlackToWhite.White,
  height: 46,
  width: 46,
  fontFamily: 'Lato',
  textSize: 16,
  fontWeight: 'bold',
};

const clusterErrorStyle = {
  url: mapClusterErrorIcon,
  textColor: BlackToWhite.White,
  height: 46,
  width: 46,
  fontFamily: 'Lato',
  textSize: 16,
  fontWeight: 'bold',
};

const clusterWarningStyle = {
  url: mapClusterWarningIcon,
  textColor: BlackToWhite.White,
  height: 46,
  width: 46,
  fontFamily: 'Lato',
  textSize: 16,
  fontWeight: 'bold',
};

/**
 * This map renders a list of POIFeatures as markers on a map
 * and renders a polygon when one of those is selected
 * @param MultiFeatureGoogleMapProps
 * @returns
 */
export const MultiFeatureGoogleMap = ({
  featureCollection,
  collectionName,
  onEditFeature,
  deleteFeature,
  errors,
  layers,
  setSelected,
  setMapCenter,
  topLevelActions,
  defaultSelectedFeatureId,
  defaultSelectedFeature,
}: MultiFeatureGoogleMapProps): React.ReactElement => {
  const { isLoaded } = useJsApiLoader({
    mapIds: ['4cde2ee1e9b041f7', 'b148e3cc084c77fc'],
    googleMapsApiKey: GOOGLE_MAP_KEY,
    libraries: googleLibraries.libraries,
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [selectedError, setSelectedError] = useState<POIError>();

  //Only trigger a recalculation if the collection changes
  //we don't want the map bounds to change while someone is
  //editing or deleting, better experience
  const collectionBounds = useMemo(() => {
    if (featureCollection !== undefined && isLoaded) {
      return getLatLngBoundsForFeatures(featureCollection);
    }
  }, [collectionName, isLoaded]);

  const [googleMap, setMap] = useState<google.maps.Map>();

  const [anchorPosition, setAnchorPosition] = useState<PopoverPosition>({
    left: 0,
    top: 0,
  });
  const [hoveredFeature, setHoveredFeature] = useState<Feature | null>(null);
  const [selectedFeatureId, setSelectedFeatureId] = useState<string | undefined>(
    defaultSelectedFeatureId,
  );
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const SHOW_POLYGON_ZOOM_LEVEL = 14;

  const mapCenter = useMemo(() => {
    return featureCollection
      ? CoordinateToLatLng(centroid(featureCollection).geometry.coordinates as Position)
      : CoordinateToLatLng([-0.12467135614968297, 51.500899680251]);
  }, [featureCollection]);

  const onLoad = useCallback(
    function callback(map) {
      if (collectionBounds) {
        map.fitBounds(collectionBounds);
        setMap(map);
      } else if (mapCenter) {
        map.setCenter(mapCenter);
        setMap(map);
      }
      layers && layers.setMap(map);
    },
    [collectionBounds, mapCenter, layers],
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(undefined);
  }, []);

  const setGoogleMapBounds = useCallback(
    (bounds: google.maps.LatLngBounds) => {
      googleMap?.fitBounds(bounds);
    },
    [googleMap],
  );

  const selectedFeature = useMemo(() => {
    if (defaultSelectedFeature) {
      return defaultSelectedFeature;
    }

    return featureCollection?.features.find(
      (f) => f.properties.name === selectedFeatureId || f.id === selectedFeatureId,
    );
  }, [featureCollection, selectedFeatureId, defaultSelectedFeature]);

  const googleShapeFeature = useMemo(
    () => selectedFeature && geoJsonToGooglePath(selectedFeature.geometry),
    [selectedFeature?.geometry, selectedFeature],
  );

  useEffect(() => {
    setIsEditing(false);
    setSelectedError(undefined);
  }, [selectedFeatureId]);

  useEffect(() => {
    setSelectedFeatureId(defaultSelectedFeatureId);
  }, [defaultSelectedFeatureId]);

  //If there is only a single location, pre select it
  useEffect(() => {
    if (featureCollection?.features.length === 1) {
      setSelectedFeatureId(featureCollection.features[0].properties.name);
      setSelected && setSelected(featureCollection.features[0]);
    }
  }, [featureCollection, setSelected]);

  useEffect(() => {
    if (collectionBounds && googleMap) {
      setGoogleMapBounds(collectionBounds);
      setMap(googleMap);
    }
  }, [collectionBounds, googleMap, setGoogleMapBounds]);

  useEffect(() => {
    if (selectedFeature) {
      if (selectedFeature.geometry.type === 'Point') {
        const circleBounds = getLatLngBoundsForFeatures({
          type: 'Feature',
          geometry: PolygonFromPoint(selectedFeature.geometry),
          properties: {},
        });
        setGoogleMapBounds(circleBounds);
        return;
      }

      const featureBounds = getLatLngBoundsForFeatures(selectedFeature);

      setGoogleMapBounds(featureBounds);
    }
  }, [selectedFeature, setGoogleMapBounds]);

  const onMouseEnterFeature = (feature, evt) => {
    setAnchorPosition({ left: evt.clientX, top: evt.clientY - 22 });
    setHoveredFeature(feature);
  };

  const onMouseLeaveFeature = () => {
    setHoveredFeature(null);
  };

  const onDetailPolygonClick = () => {
    if (selectedFeature) {
      setGoogleMapBounds(getLatLngBoundsForFeatures(selectedFeature));
    }
  };

  const onCloseDetails = () => {
    setSelectedFeatureId(undefined);
    setSelected && setSelected(undefined);
  };

  const onDrawingComplete = (shape: GoogleShape) => {
    if (!selectedFeature || !onEditFeature) {
      return;
    }
    const newFeat: POIFeature = {
      type: 'Feature',
      id: selectedFeature?.id,
      geometry: googleShapeToGeoJson(shape),
      properties: selectedFeature?.properties,
    };
    const err = validateFeature(newFeat.properties.name, newFeat);
    if (err) {
      setSelectedError(err[newFeat.properties.name]);

      if (err[newFeat.properties.name].severity === 'warning') {
        enqueueSnackbar(err[newFeat.properties.name].message, { variant: 'warning' });
      }

      if (err[newFeat.properties.name].severity === 'error') {
        enqueueSnackbar(err[newFeat.properties.name].message, { variant: 'error' });
        return;
      }
    } else {
      setSelectedError(undefined);
    }

    onEditFeature(newFeat);
  };

  return (
    <MultiFeatureGoogleMapStyled>
      {isLoaded && collectionBounds && (
        <GoogleMap
          mapContainerStyle={mapStyle}
          zoom={5}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onCenterChanged={() => setMapCenter && setMapCenter(googleMap?.getCenter())}
          options={{
            ...googleMapOptions,
            mapTypeControlOptions: {
              mapTypeIds: ['roadmap', 'hybrid'],
              position: google.maps.ControlPosition.BOTTOM_LEFT,
            },
          }}
        >
          {googleMap && isEditing && onEditFeature && googleShapeFeature && selectedFeature && (
            <DrawingManagerLoader
              onDrawingComplete={onDrawingComplete}
              defaultShape={googleShapeFeature}
              map={googleMap}
              polygonStatus={selectedError ? selectedError.severity : undefined}
            ></DrawingManagerLoader>
          )}
          <MarkerClusterer
            maxZoom={SHOW_POLYGON_ZOOM_LEVEL}
            zoomOnClick={true}
            styles={[clusterStyle, clusterErrorStyle, clusterWarningStyle]}
            imagePath={mapClusterIcon}
            gridSize={40}
            calculator={(markers, num) => {
              let hasError = false;
              markers.forEach((m) => {
                if (errors && errors[m.getTitle() || 0]) hasError = true;
              });
              return {
                text: markers.length.toString(),
                index: hasError ? 3 : 1,
                title: '',
              };
            }}
            minimumClusterSize={2}
          >
            {(clusterer) => (
              <>
                {featureCollection?.features.map((location) => (
                  <Marker
                    key={location.properties.name}
                    icon={
                      errors && errors[location.properties.name]
                        ? mapMarkerWarningIcon
                        : mapMarkerPointIcon
                    }
                    onClick={() => {
                      setSelectedFeatureId(location.properties.name);
                      setSelected && setSelected(location);
                    }}
                    onMouseOver={(p) => onMouseEnterFeature(location, p.domEvent)}
                    onMouseOut={onMouseLeaveFeature}
                    position={CoordinateToLatLng(
                      (location.properties.centroid || [0, 0]) as Position,
                    )}
                    clusterer={clusterer}
                  />
                ))}
              </>
            )}
          </MarkerClusterer>
          {featureCollection && (
            <>
              <MapControl
                onClick={() => setGoogleMapBounds(getLatLngBoundsForFeatures(featureCollection))}
                tooltipText="Reset Zoom"
                icon={<AllOutIcon></AllOutIcon>}
                bottom="6rem"
                right=".5rem"
              ></MapControl>
              <div className="search-input map-search">
                <SearchAutoCompletePOIs
                  changeHandler={(val: string[]) => {
                    if (val.length < 1) {
                      setSelectedFeatureId(undefined);
                    } else {
                      const clickedFeat = featureCollection.features.find(
                        (f) => f.id === val[0] || f.properties.name === val[0],
                      );

                      setSelectedFeatureId(clickedFeat?.properties.name);
                      setSelected && clickedFeat && setSelected(clickedFeat);
                    }
                  }}
                  features={featureCollection.features}
                />
              </div>
            </>
          )}
          {errors && typeof Object.values(errors)[0] === 'string' && (
            <MapErrorSummary
              errors={errors as Record<string, string[]>}
              position={{ top: 16, left: 332 }}
              onClickError={(id: string) => {
                setSelectedFeatureId(id);
                onDetailPolygonClick();
              }}
            ></MapErrorSummary>
          )}

          {topLevelActions && (
            <div className="overflow-actions-container">
              <OverflowMenu actions={topLevelActions}></OverflowMenu>
            </div>
          )}
          {selectedFeature && !isEditing && (
            <GeoJsonToMapShape
              feature={selectedFeature}
              onClick={onDetailPolygonClick}
              polygonStatus={errors && errors[selectedFeature.id] && 'warning'}
            ></GeoJsonToMapShape>
          )}
        </GoogleMap>
      )}
      <AnimatePresence>
        {selectedFeature && (
          <div className="details-position">
            <FeatureDetailsPanel
              feature={selectedFeature}
              onClose={onCloseDetails}
              onEdit={onEditFeature ? () => setIsEditing(!isEditing) : undefined}
              onDelete={() => {
                setSelectedFeatureId(undefined);
                deleteFeature(selectedFeature?.id);
              }}
              onClick={onDetailPolygonClick}
              hasError={errors && errors[selectedFeature.id] !== undefined}
              isEditingChecked={isEditing}
              onSetNewName={
                onEditFeature
                  ? (newName: string) =>
                      onEditFeature({
                        ...selectedFeature,
                        properties: {
                          name: newName,
                          centroid: selectedFeature.properties.centroid,
                        },
                      })
                  : undefined
              }
            ></FeatureDetailsPanel>
          </div>
        )}
      </AnimatePresence>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={!!hoveredFeature}
        anchorPosition={anchorPosition}
        anchorReference="anchorPosition"
        transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        PaperProps={{
          sx: {
            p: 1,
          },
        }}
      >
        {hoveredFeature && (
          <div>
            <Body>Location: {hoveredFeature.properties?.name || hoveredFeature.id}</Body>
            <Body type="small01" color={BlackToWhite.Gray01}>
              Area:{` `} {Math.ceil(getPOIArea(hoveredFeature as POIFeature)).toLocaleString()}
              m&sup2;
            </Body>
          </div>
        )}
      </Popover>
    </MultiFeatureGoogleMapStyled>
  );
};
