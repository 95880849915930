import * as google_protobuf_field_mask_pb from 'google-protobuf/google/protobuf/field_mask_pb';
import { getIamServicePromiseClient } from '../rpc';
import { IamUser, UserSettings } from '@unacast-internal/unacat-js/unacast/iam/v1/iam_user_pb';
import {
  GetUserRequest,
  UpdateUserSettingsRequest,
} from '@unacast-internal/unacat-js/unacast/iam/v1/iam_service_pb';

export type ServiceIamUser = IamUser;

export type UserProductsPerBillingAccunt = Record<string, Record<string, string[]>>;

const getUpdateMaskForField = (field: string): google_protobuf_field_mask_pb.FieldMask => {
  const updateMask = new google_protobuf_field_mask_pb.FieldMask();
  updateMask.addPaths(field);
  return updateMask;
};

export class IamService {
  constructor(private authHeader: string) {}

  static fromIdToken = (idToken: string): IamService => new IamService('bearer ' + idToken);
  static fromAuthHeader = (authHeader: string): IamService => new IamService(authHeader);

  private getHeaders = () => ({ Authorization: this.authHeader });

  public getUser = (email: string): Promise<ServiceIamUser> => {
    return getIamServicePromiseClient().getUser(
      new GetUserRequest().setEmail(email),
      this.getHeaders(),
    );
  };

  public storeUserSolutions = (
    email: string,
    newActiveUserProductsPerBillingAccount: UserProductsPerBillingAccunt,
  ): Promise<ServiceIamUser> => {
    const userObj = JSON.stringify(newActiveUserProductsPerBillingAccount);
    const request = new UpdateUserSettingsRequest()
      .setEmail(email)
      .setUpdateMask(getUpdateMaskForField('user_object'))
      .setUserSettings(new UserSettings().setUserObject(userObj));

    return getIamServicePromiseClient().updateUserSettings(request, this.getHeaders());
  };

  public storeHideFeedbackFormUntil = (
    email: string,
    hideFeedbackFormUntil: number,
  ): Promise<ServiceIamUser> => {
    const request = new UpdateUserSettingsRequest()
      .setEmail(email)
      .setUpdateMask(getUpdateMaskForField('hide_feedback_form_until'))
      .setUserSettings(new UserSettings().setHideFeedbackFormUntil(hideFeedbackFormUntil));

    return getIamServicePromiseClient().updateUserSettings(request, this.getHeaders());
  };
}
