import React, { useState } from 'react';
import { SearchAutoComplete } from './SearchAutoComplete';

type Props = {
  changeHandler: (val: unknown) => void;
};

export const SearchAutoCompleteLocation = ({ changeHandler }: Props): JSX.Element => {
  const [autoOptions, setAutoOptions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const googleService = new google.maps.places.AutocompleteService();
  const googleGeocoder = new google.maps.Geocoder();

  const onSuggestions = (predictions: google.maps.places.AutocompletePrediction[], status) => {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      setAutoOptions(predictions);
      setIsLoading(false);
    }
  };

  const onLocationSelected = (selectedLocationName: string) => {
    const selectedLocation = autoOptions.find(
      (option) => option.description === selectedLocationName,
    );
    if (selectedLocation?.place_id)
      googleGeocoder.geocode({ placeId: selectedLocation.place_id as string }, (results) => {
        if (results) {
          const location = results[0].geometry.location;
          changeHandler(location);
        }
      });
  };

  const onInputChange = (inputValue: string) => {
    if (inputValue.length > 2) {
      setIsLoading(true);
      googleService.getPlacePredictions({ input: inputValue }, onSuggestions);
    }
  };
  return (
    <SearchAutoComplete
      placeholder="Search for location"
      changeHandler={onLocationSelected}
      loading={isLoading}
      onInputChange={onInputChange}
      autoOptions={autoOptions ? autoOptions.map((f) => f.description) : []}
      disableClearable
    />
  );
};
