// Since firebase token has 1 hour expiration limit, we set X minutes before to be sure that its refreshed
const sessionRefreshThreshold = 55; // minutes

let sessionExpiration: string | null = null;

export const setTokenExpiration = (): void => {
  sessionExpiration = new Date(new Date().getTime() + sessionRefreshThreshold * 60000)
    .toISOString()
    .split('T')[0];
};

export const getTokenExpiration = (): string | null => {
  return sessionExpiration;
};

export const differenceInMinutes = (expirationTime: string): number => {
  const expired = Date.parse(expirationTime);
  const now = new Date().getTime();
  return Math.floor((expired - now) / 6000);
};
