import { Primary } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import styled from 'styled-components';

export const SmallScreenPageStyled = styled.div`
  &,
  * {
    box-sizing: border-box;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 1.75rem 3.625rem;
  position: relative;
  overflow: hidden;
  .title,
  .subtitle {
    text-align: center;
  }
  .title {
    margin-top: 5rem;
  }
  .subtitle {
    margin-top: 3rem;
  }
  .email-link {
    color: ${Primary.Primary01};
  }
  .unacast-icon {
    position: absolute;
    left: -7rem;
    bottom: 1.25rem;
    width: 40vh;
    height: 40vh;
  }
`;
