import shp from 'shpjs';
import { ParseResponse } from './fileTranslatorHook';
import { POIGeometry_fromGeoJSONGeometry } from './geojsonOperations';

export const parseShapeFileToGeoJson = (content: ArrayBuffer, fileName: string): ParseResponse => {
  try {
    const parsed = shp.parseShp(content);
    const features = parsed.map((f, idx) => {
      return {
        type: 'Feature',
        id: `${fileName}_${idx}`,
        geometry: POIGeometry_fromGeoJSONGeometry(f),
        properties: { name: `${fileName}_${idx}` },
      };
    });
    return { status: 'success', features, type: 'shapefile' };
  } catch (e) {
    return { status: 'error', message: e.message, type: 'shapefile' };
  }
};
