import React from 'react';
import { Body, Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { CreationDetails } from '../CreationDetails';
import { CollectionCardStyled } from './CollectionCard.styles';

import { BlackToWhite } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { Card } from '@unacast-internal/unacast-ui/Card/Card';
import { PoiCollectionDetails } from '../../../unacatInteraction/PoiCollectionService';

interface CollectionCardProps extends PoiCollectionDetails {
  cardAction?: (collectionId?: string) => void;
}

export const CollectionCard = ({
  name,
  id,
  createdTime,
  createdBy,
  cardAction,
  itemCount,
}: CollectionCardProps): React.ReactElement => {
  const handleCardClick = (event) => {
    event.stopPropagation();
    cardAction && cardAction(id);
  };

  return (
    <Card isClickable={!!cardAction} onClick={handleCardClick}>
      <CollectionCardStyled>
        <div>
          <div className="top-row">
            <div className="title-with-icon">
              <Heading type={'h3'} className="title-space">
                {name}
              </Heading>
            </div>
          </div>
          <div className="report-details">
            {createdTime && (
              <div className="details-item">
                <CreationDetails createdBy={createdBy} creationDate={createdTime}></CreationDetails>
              </div>
            )}
            <Body type="small01" className="details-count" color={BlackToWhite.Gray03}>
              <b>Locations:</b> {itemCount}
            </Body>
          </div>
        </div>
      </CollectionCardStyled>
    </Card>
  );
};
