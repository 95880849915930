import { Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { HyperLinkNormal } from '@unacast-internal/unacast-ui/StyleGuide/Navigation';
import React, { useState } from 'react';
import styled from 'styled-components';
import LoginDialog from '../components/login/LoginDialog';

const InactiveUserPageStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    margin-top: 8rem;
    width: 45%;
    display: flex;
    flex-direction: column;
    max-width: 480px;

    h1 {
      margin-bottom: 4.3rem;
    }

    .action-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 80%;
      align-self: center;
    }
  }
`;

export const InactiveUserPage = (): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <InactiveUserPageStyled>
      <div className="container">
        <Heading type="h1">You’ve been logged out because you were inactive for a while.</Heading>
        <div className="action-row">
          <UnaButton onClick={openModal}>Log in again</UnaButton>
          <HyperLinkNormal to="/">Go to the homepage</HyperLinkNormal>
        </div>
        <LoginDialog open={showModal} onClose={closeModal} preType={'login'} />{' '}
      </div>
    </InactiveUserPageStyled>
  );
};
