import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { Modal } from '@unacast-internal/unacast-ui/Organisms';
import { Body, Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import React from 'react';
import styled from 'styled-components';

const DialogStyled = styled.div`
  text-align: center;
  .text-spacing {
    margin-top: 1.5rem;
  }
  .action-area {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    .accept-button {
      margin-left: 1rem;
    }
  }
`;

export const ConfirmationDialog = ({
  text,
  detailText,
  onClose,
  onConfirm,
  isOpen,
}: {
  text: string;
  detailText?: string;
  onClose: () => void;
  onConfirm: () => void;
  isOpen: boolean;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <DialogStyled>
        <Heading type="h2">{text}</Heading>
        {detailText && <Body className="text-spacing">{detailText}</Body>}
        <div className="action-area">
          <UnaButton styleType="secondary" onClick={onClose}>
            Cancel
          </UnaButton>
          <UnaButton onClick={onConfirm} className="accept-button">
            Accept
          </UnaButton>
        </div>
      </DialogStyled>
    </Modal>
  );
};
