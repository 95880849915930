import { CheckBox } from '@unacast-internal/unacast-ui/CheckBox';
import { RadioButtonGroup, RadioButtonValue, TextInput } from '@unacast-internal/unacast-ui/Inputs';
import { Primary } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { Body } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { AnimatePresence, motion } from 'framer-motion';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useCurrentUserInfo } from '../../../contexts/UserContext';
import { useFetchAndDownloadReport } from '../../dataManagement/downloadReportHook';
import { useReportList } from '../../dataManagement/reportListHook';
import { useArchiveReportHook } from '../../dataManagement/useArchiveReport';
import { useMadeToOrderNavigation } from '../../utils/madeToOrderNavigation';
import {
  getDateLimitation,
  isAdheringToReportLengthLimitation as isAdheringToReportLengthLimitationHelper,
} from '../../utils/reportHelpers';
import {
  Report,
  ReportStatus,
  PoiRegion,
  ReportType,
  getUnacatRef,
  MetricResult,
} from '../../../unacatInteraction/BringYourOwnPOIService';
import LoadingSnackBar from '../LoadingSnackBar';
import { MadeToOrderReportCard } from '../MadeToOrderReportCard/MadeToOrderReportCard';
import { ConfirmationDialog } from '../Modals/ConfirmationDialog';
import { MadeToOrderReportListStyled } from './MadeToOrderReportList.styles';

type reportSorting = 'date' | 'status' | 'createdBy';
const sortMethods: Record<reportSorting, (a: any, b: any) => number> = {
  date: (a, b) => b.createdTime.getTime() - a.createdTime.getTime(),
  status: (a, b) => a.status.valueOf() - b.status.valueOf(),
  createdBy: (a, b) => a.createdBy.toUpperCase().localeCompare(b.createdBy.toUpperCase()),
};

const radioValues = [
  { label: 'Creation Date', value: 'date' },
  { label: 'Status', value: 'status' },
  { label: 'Created By', value: 'createdBy' },
];

export const MadeToOrderReportList = () => {
  const [reportsState, refreshList] = useReportList();
  const [archiveReportState, archiveReport] = useArchiveReportHook();

  const downloadReport = useFetchAndDownloadReport();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [sortBy, setSortBy] = useState<RadioButtonValue>(radioValues[0]);
  const [filterOnUser, setFilterOnUser] = useState<boolean>(true);
  const [filterOnExpired, setFilterOnExpired] = useState<boolean>(true);
  const [filterHideOneTime, setFilterHideOneTime] = useState<boolean>(false);
  const [reportIdToDelete, setReportIdToDelete] = useState<string>();

  const [filteredList, setFilteredList] = useState<Report[]>();
  const [searchValue, setSearchValue] = useState<string>();

  const userInfo = useCurrentUserInfo();
  const navTo = useMadeToOrderNavigation();

  const [filterOnLargeReports, setFilterOnLargeReports] = useState(false);
  const [largeReportsIdsState, setLargeReportsIdsState] = useState(new Set<string>());

  useEffect(() => {
    if (archiveReportState.status === 'succeeded') {
      enqueueSnackbar('Report Deleted', { variant: 'success' });
      refreshList();
    } else if (archiveReportState.status === 'failed') {
      enqueueSnackbar(archiveReportState.error.message, {
        variant: 'error',
      });
    }
  }, [archiveReportState, enqueueSnackbar, refreshList]);

  const shouldRefresh = useMemo(() => {
    return (
      reportsState.status !== 'succeeded' ||
      reportsState.data.some(
        ({ status }) =>
          status === ReportStatus.PENDING ||
          status === ReportStatus.RUNNING ||
          status === ReportStatus.DELAYED,
      )
    );
  }, [reportsState]);

  useEffect(() => {
    let refreshInterval;
    if (shouldRefresh) {
      refreshList();
      refreshInterval = setInterval(() => {
        refreshList();
      }, 10000);
    }
    return () => clearInterval(refreshInterval);
  }, [shouldRefresh, refreshList]);

  const downloadWithNotification = async (Id, region, metric, metricResults) => {
    const loadingNotification = enqueueSnackbar(`Downloading ${metric}`, {
      variant: 'info',
      content: (key, message) => (
        <LoadingSnackBar key={key} color={Primary.Primary01}>
          {message}
        </LoadingSnackBar>
      ),
      anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
    });
    downloadReport(Id, region, metric, getUnacatRef(metricResults, metric))
      .then(() => closeSnackbar(loadingNotification))
      .catch((err) => {
        closeSnackbar(loadingNotification);
        enqueueSnackbar(`${err.message} for: ${metric}`, {
          variant: 'error',
        });
      });
  };

  const getMenuActionsByStatus = (
    status: ReportStatus,
    metrics: string[],
    id: string,
    region: PoiRegion,
    metricResults: MetricResult[],
  ): Record<string, () => void> | undefined => {
    if (status === ReportStatus.SUCCEEDED) {
      const actions = {
        'Use As Template': () => navTo.createReportFromTemplate(id),
        'View Details': () => navTo.reportDetails(id),
        Delete: () => {
          setReportIdToDelete(id);
        },
      };

      if (largeReportsIdsState.has(id)) {
        actions['Download all'] = () => {
          metrics.forEach((metric) => {
            downloadWithNotification(id, region, metric, metricResults);
          });
          if (region === 'international') {
            downloadWithNotification(id, region, '__validations', metricResults);
          }
        };
      }

      return actions;
    }
    if (
      status === ReportStatus.PENDING ||
      status === ReportStatus.RUNNING ||
      status === ReportStatus.DELAYED
    ) {
      return {
        'View Details': () => navTo.reportDetails(id),
      };
    }
    if (status === ReportStatus.EXPIRED) {
      return {
        Delete: () => {
          setReportIdToDelete(id);
        },
      };
    }
    return undefined;
  };

  //Handles filtering and sorting for the UI List
  useEffect(() => {
    if (reportsState.status === 'succeeded') {
      const newLargeReportsIds = new Set<string>();
      setFilteredList(
        reportsState.data
          .filter((report) => {
            const len = report?.itemCount || 0;
            const dateLimitation = getDateLimitation(len);
            const isLarge = isAdheringToReportLengthLimitationHelper(report, dateLimitation);
            if (isLarge) {
              newLargeReportsIds.add(report.id);
            }
            const remove =
              (filterOnUser && report.createdBy !== userInfo?.email) ||
              (filterOnExpired && report.status === ReportStatus.EXPIRED) ||
              (filterHideOneTime && report.reportType === ReportType.ONE_TIME) ||
              (filterOnLargeReports && isAdheringToReportLengthLimitationHelper(report, dateLimitation)) ||
              (searchValue &&
                !report.clientName?.toLowerCase().includes(searchValue) &&
                searchValue &&
                !report.reportName?.toLowerCase().includes(searchValue));
            return !remove;
          })
          .sort(sortMethods[sortBy.value]),
      );
      setLargeReportsIdsState(newLargeReportsIds);
    }
  }, [
    filterOnUser,
    filterOnExpired,
    filterHideOneTime,
    filterOnLargeReports,
    userInfo?.email,
    sortBy,
    reportsState,
    searchValue,
  ]);

  return (
    <MadeToOrderReportListStyled>
      <div className="search-input">
        <TextInput
          type="search"
          label="Search"
          placeholder="Client or Report"
          value={searchValue}
          onChange={(e) => setSearchValue(e.currentTarget.value)}
        ></TextInput>
      </div>
      <div className="input-spacing">
        <div className="filter-group">
          <div className="checkbox-group">
            <CheckBox
              onChange={() => setFilterOnUser((prev) => !prev)}
              checked={filterOnUser}
            ></CheckBox>
            <Body type="small02">Show my reports</Body>
          </div>
          <div className="checkbox-group">
            <CheckBox
              onChange={() => setFilterOnExpired((prev) => !prev)}
              checked={filterOnExpired}
            ></CheckBox>
            <Body type="small02">Hide Expired Reports</Body>
          </div>
          <div className="checkbox-group">
            <CheckBox
              onChange={() => setFilterHideOneTime((prev) => !prev)}
              checked={filterHideOneTime}
            ></CheckBox>
            <Body type="small02">Hide One-Time Reports</Body>
          </div>
          <div className="checkbox-group">
            <CheckBox
              onChange={() => setFilterOnLargeReports((prev) => !prev)}
              checked={filterOnLargeReports}
            ></CheckBox>
            <Body type="small02">Show Large Reports</Body>
          </div>
        </div>
        <RadioButtonGroup
          selectedValue={sortBy.value}
          legend="Sort By"
          radioValues={radioValues}
          onChange={setSortBy}
        ></RadioButtonGroup>
      </div>

      <div className="reports">
        <AnimatePresence>
          {filteredList &&
            filteredList.map(
              ({ id, reportName, status, metrics, region, itemCount, resultsList, ...rest }) => (
                <motion.div
                  key={id}
                  layout
                  className="card-container"
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0, transition: { delay: 0.5, type: 'easeIn' } }}
                  exit={{ opacity: 0 }}
                  transition={{
                    type: 'easeIn',
                  }}
                >
                  <MadeToOrderReportCard
                    id={reportName || id}
                    isAdheringToReportLengthLimitation={largeReportsIdsState.has(id)}
                    status={status}
                    metrics={metrics}
                    {...rest}
                    locationCount={itemCount}
                    menuActions={getMenuActionsByStatus(status, metrics, id, region, resultsList)}
                  ></MadeToOrderReportCard>
                </motion.div>
              ),
            )}
        </AnimatePresence>
        <ConfirmationDialog
          isOpen={reportIdToDelete !== undefined}
          onClose={() => setReportIdToDelete(undefined)}
          onConfirm={() => {
            reportIdToDelete && archiveReport(reportIdToDelete);
            setReportIdToDelete(undefined);
          }}
          text={`Are you sure you wish to delete this report?`}
        ></ConfirmationDialog>
      </div>
    </MadeToOrderReportListStyled>
  );
};
