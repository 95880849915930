/* eslint-disable prettier/prettier */// The eslint do not match the prettier formatting
import {
    GetDataProductListingRequest,
    ListDataProductListingsRequest,
    ListDataProductListingsResponse,
} from '@unacast-internal/unacat-js/unacast/catalog/v1/data_product_listing_service_pb';
import type { DataProductListing } from '@unacast-internal/unacat-js/unacast/catalog/v1/data_product_listing_pb';
import { getDataProductListingServiceClient } from 'src/rpc';

const sku = 'license_via_access_management';
export class DataProductListingService {
    constructor(private authHeader: string) { }

    private getHeaders = () => ({ Authorization: this.authHeader });


    public async listDataProductListings(
        req: ListDataProductListingsRequest.AsObject,
    ): Promise<ListDataProductListingsResponse> {
        const dataProductListingService = getDataProductListingServiceClient();
        const protoReq = new ListDataProductListingsRequest()
            .setFilter(req.filter)
            .setBillingContext(req.billingContext)
            .setPageSize(req.pageSize)
            .setPageToken(req.pageToken);

        const response = await dataProductListingService.listDataProductListings(
            protoReq,
            this.getHeaders(),
        );
        return response;
    }
}
