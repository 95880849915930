import styled from 'styled-components';

export const ReportListPageStyled = styled.div`

.header-row{
  display: flex;
  flex-direction: row;
    justify-content: space-between;
  }

  }
  .drafts-heading{
    margin-top: 1rem;
  }
  .centered {
    display: flex;
    box-sizing: border-box;
    padding-top: 240px;
    justify-content: center;
    text-align: center;
    .line-height {
      line-height: 6rem !important;
    }

    .large-text {
      font-family: 'Lato', sans-serif;
      font-size: 3rem;
      font-weight: bold;
    }
  }

  .empty-drafts{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    margin-top: 2rem;
    width: fit-content;
 
  }
`;
