import { BrowserRouter as Router } from 'react-router-dom';

import { StyledEngineProvider } from '@mui/material';
import { Info, Success } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { SnackbarProvider } from 'notistack';
import { useMemo } from 'react';
import { createGlobalStyle } from 'styled-components';
import ErrorBoundary from './components/ErrorBoundary';
import { useIsMobile } from './components/hooks/useIsMobile';
import { AuthProvider } from './contexts/AuthContext';
import { BillingAccountProvider } from './contexts/BillingAccountContext';
import { BreadCrumbProvider } from './contexts/BreadCrumbContext';
import { CustomLoginProvider } from './contexts/CustomLoginContext';
import { DimensionProvider } from './contexts/DimensionContext';
import { ErrorHandlerProvider } from './contexts/ErrorHandlerContext';
import { UserProviderWithRedirects } from './contexts/UserContext';
import Layout from './pages/Layout';
import { SmallScreenPage } from './pages/SmallScreenPage';

const GlobalStyle = createGlobalStyle`
  .snack-success{
    background-color: ${Success.Success01} !important;
  }
  .snack-info{
    background-color: ${Info.Info01} !important;
  }
`;

//Various Providers to handle sharing specific functionality and data between child components
const Providers = ({ children }) => {
  return (
    //'injectFirst' helps ensure that 'styled-components' loads before mui
    <StyledEngineProvider injectFirst>
      <GlobalStyle />
      {/* Snackbar Provider allows notifications to be presented to the user from any child components */}
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        preventDuplicate
        classes={{
          variantSuccess: 'snack-success',
          variantInfo: 'snack-info',
        }}
        maxSnack={10}
      >
        {/* Handles errors, logs and conditionally displays error to user */}
        <ErrorHandlerProvider>
          <AuthProvider>
            <UserProviderWithRedirects>
              <BillingAccountProvider>
                <DimensionProvider>
                  <CustomLoginProvider>
                    <BreadCrumbProvider>{children}</BreadCrumbProvider>
                  </CustomLoginProvider>
                </DimensionProvider>
              </BillingAccountProvider>
            </UserProviderWithRedirects>
          </AuthProvider>
        </ErrorHandlerProvider>
      </SnackbarProvider>
    </StyledEngineProvider>
  );
};

const App = (): JSX.Element => {
  const isMobile = useIsMobile();

  const AppContent = useMemo(() => {
    return !isMobile ? (
      <Providers>
        <Layout />
        {/* <FeedbackFormPlacement /> Deactivated until we have someone to handle feedback */}
      </Providers>
    ) : (
      <SmallScreenPage />
    );
  }, [isMobile]);

  return (
    <Router>
      <ErrorBoundary>{AppContent}</ErrorBoundary>
    </Router>
  );
};

export default App;
