import { BlackToWhite } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import styled from 'styled-components';

export const AnimateVariants = {
  hidden: { y: 100, opacity: 0 },
  show: {
    y: 0,
    opacity: 1,
    transition: { type: 'easeIn', delay: 0.3, duration: 0.6 },
  },
};

export const CollectionDetailsPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  .details-card {
    margin: 1rem 0;
    background-color: ${BlackToWhite.White};
    box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(80, 148, 175, 0.16);
    border-radius: 6px;
    padding: 2rem;
    box-sizing: border-box;
    .header-spacing {
      margin-bottom: 1rem;
    }
    .action-prompt {
      display: flex;
      flex-direction: column;
      align-items: center;
      .prompt-spacing {
        margin-bottom: 1rem;
      }
    }
  }
`;
