import { DateTime } from 'luxon';
import { Cadence } from '@unacast-internal/unacat-js/unacast/metric/v1/metric_pb';
import { Period } from '@unacast-internal/unacat-js/unacast/metric/v1/metric_value_pb';
import { Date as DatePb } from '@unacast-internal/unacat-js/unacast/unatype/date_pb';

export const getLuxonWithDate = (d: DatePb | undefined): DateTime => {
  return DateTime.fromObject(d?.toObject() || DateTime.local());
};

export const getPeriodText = (p: Period | undefined): string => {
  if (!p) return '';
  const startDate = p.getStart();
  const start = startDate ? shortReadableDate(toDate(startDate)) : '';
  const endDate = p.getEnd();
  const end = endDate ? shortReadableDate(toDate(endDate)) : '';
  return `${start} to ${end}`;
};

export const getDefaultPeriod = (startDate?: DatePb): Period => {
  const today = new Date();
  const sd = startDate || new DatePb().setYear(2018).setMonth(1).setDay(1);
  return new Period().setStart(sd).setEnd(
    new DatePb()
      .setYear(today.getUTCFullYear())
      .setMonth(today.getUTCMonth() + 1)
      .setDay(today.getUTCDate()),
  );
};

function newDateOrDefaultPeriodFilter(
  periodFilter: Period,
  calculateStartDateFunction: (endDate: Date) => Date,
) {
  const initialEndDateUnacast = periodFilter.getEnd();
  const initialStartDateUnacast = periodFilter.getStart();
  if (initialEndDateUnacast && initialStartDateUnacast) {
    const endDate = toDate(initialEndDateUnacast);

    const newStartDate = calculateStartDateFunction(endDate);

    const period = new Period();
    period.setEnd(periodFilter.getEnd());
    if (newStartDate < toDate(initialStartDateUnacast)) {
      period.setStart(initialStartDateUnacast);
    } else {
      period.setStart(toDatepd(newStartDate));
    }

    return period;
  }

  return periodFilter;
}

export function getPeriodBasedOnCadence(
  periodFilter: Period,
  cadence: Cadence | undefined,
  shorterLookback?: boolean,
): Period {
  if (!cadence) {
    return periodFilter;
  }

  const dailyLookback = shorterLookback ? 7 : 27; //DAYS
  const weeklyLookback = shorterLookback ? 4 * 7 : 3 * 7; //DAYS
  const monthlyLookback = shorterLookback ? 3 : 3; //MONTHS
  const quarterlyLookback = shorterLookback ? 3 : 3; //MONTHS
  const yearlyLookback = shorterLookback ? 3 : 12; //YEARS

  switch (cadence) {
    case Cadence.DAILY: {
      return newDateOrDefaultPeriodFilter(periodFilter, (endDate) => {
        endDate.setDate(endDate.getUTCDate() - dailyLookback);
        return endDate;
      });
    }
    case Cadence.WEEKLY: {
      return newDateOrDefaultPeriodFilter(periodFilter, (endDate) => {
        endDate.setDate(endDate.getUTCDay() - weeklyLookback);
        return endDate;
      });
    }
    case Cadence.MONTHLY: {
      return newDateOrDefaultPeriodFilter(periodFilter, (endDate) => {
        endDate.setMonth(endDate.getUTCMonth() - monthlyLookback);
        return endDate;
      });
    }
    case Cadence.QUARTERLY: {
      return newDateOrDefaultPeriodFilter(periodFilter, (endDate) => {
        endDate.setMonth(endDate.getUTCMonth() - quarterlyLookback);
        return endDate;
      });
    }
    case Cadence.YEARLY: {
      return newDateOrDefaultPeriodFilter(periodFilter, (endDate) => {
        endDate.setMonth(endDate.getUTCMonth() - yearlyLookback);
        return endDate;
      });
    }
    default: {
      return periodFilter;
    }
  }
}

export const stringDateToDate = (s: string): DatePb => {
  const dt = DateTime.fromISO(s);
  const d = new DatePb();
  d.setYear(dt.year);
  d.setMonth(dt.month);
  d.setDay(dt.day);
  return d;
};

export const toDatepd = (dt: Date): DatePb => {
  return new DatePb()
    .setYear(dt.getFullYear())
    .setMonth(dt.getMonth() + 1)
    .setDay(dt.getDate());
};

export const toDate = (dt: DatePb): Date => {
  const d = new Date();
  d.setUTCFullYear(dt.getYear(), dt.getMonth() - 1, dt.getDay());
  return d;
};

export const toDateAsIsoString = (dt: DatePb | undefined): string => {
  if (!dt) {
    return '';
  }

  return toDate(dt).toISOString().split('T')[0];
};

export const asIsoString = (dt: Date | undefined): string => {
  if (!dt) {
    return '';
  }

  return dt.toISOString().split('T')[0];
};

export const getDaysArray = (start: Date, end: Date): Date[] => {
  const dates = [] as Date[];
  for (let dt = new Date(start); dt <= end; dt.setDate(dt.getUTCDate() + 1)) {
    dates.push(new Date(dt));
  }
  return dates;
};

export const getQuarter = (backInMonths: number) => {
  // Truncate date to first day of quarter
  const date = new Date(Date.now());
  date.setMonth(date.getMonth() + backInMonths);
  date.setMonth(date.getMonth() - (date.getMonth() % 3));
  date.setDate(1);
  return date;
};

export const shortReadableDate = (date: Date): string => {
  const month = date.toLocaleString('default', { month: 'short' });
  return `${month} ${date.getDate()}, ${date.getFullYear()} `;
};

export const localReadableTime = (date: Date): string => {
  const userLocale =
    navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
  return date.toLocaleTimeString(userLocale || 'en-US', { timeZoneName: 'short' });
};
