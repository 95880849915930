import styled from 'styled-components';
import { BlackToWhite, Navy } from '@unacast-internal/unacast-ui/StyleGuide/Colors';

export const NavigationBarStyled = styled.div`
  grid-area: nav;
  z-index: 101;
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.15);
  background-color: ${Navy.Navy09};
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  .logo-area {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sub {
    margin-left: 1.75rem;
  }

  .coming-soon-header {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.012em;
    margin-bottom: 0.75rem;
    color: ${BlackToWhite.Gray01};
  }

  .coming-soon-item {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.012em;
    margin-bottom: 0.75rem;
    color: ${BlackToWhite.Gray02};
  }

  .separator {
    border-top: 1px;
    border-bottom: none;
    width: 186px;
    border-color: ${BlackToWhite.Gray01};
    border-top-style: solid;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .buttons-area {
    margin-top: auto;
    padding: 1.25rem 0;
    button:not(:last-child) {
      margin-bottom: 0.3125rem;
    }
  }
`;
