import { BlackToWhite, Navy } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import styled from 'styled-components';
import { Elevation2 } from '@unacast-internal/unacast-ui/StyleGuide/Elevation';

export const MadeToOrderReportCardStyled = styled.div`
  padding: ${(props: { isLargeCard?: boolean }) => (props.isLargeCard ? '2rem' : '0.75rem')};
  border-radius: 6px;
  box-sizing: border-box;
  background-color: ${BlackToWhite.White};
  ${Elevation2}
  min-width: 275px;

  .title-with-icon {
    display: flex;
    flex-direction: row;
  }

  .top-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .report-details {
    padding-left: 0.25rem;
  }

  .title-space {
    margin-left: 0.5rem;
    overflow-wrap: break-word;
    display: inline-block;
    word-break: break-word;
  }

  .details-item {
    margin-top: 0.75rem;
  }

  .metrics-section {
    margin-top: 1rem;
    padding-left: 0.25rem;
  }

  .action-area {
    display: flex;
    align-self: center;
    &__button {
      background-color: transparent;
      border: 0;
      padding: 0 1rem;
      &:not(:first-child) {
        border-left: 1px solid ${BlackToWhite.Gray04};
      }
      cursor: pointer;
      .action_text {
        color: ${Navy.Navy05};
        text-decoration: underline;
      }
      &:disabled {
        cursor: default;
        .action_text {
          color: ${BlackToWhite.Gray04};
          text-decoration: none;
        }
      }
    }
  }

  .large-report-label {
    display: inline-block;
    background-color: #FFA500; 
    color: #FFFFFF;          
    padding: 2px 8px;         
    border-radius: 4px;       
    margin-bottom: 8px;       
    font-size: 0.9em;         
    font-weight: bold;
    margin-left: 2rem;        
  }
`;
