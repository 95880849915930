import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { MarkerClusterer } from '@react-google-maps/api';

import { CoordinateToLatLng } from '../../../helpers/geography';
import mapMarkerPointIcon from '../../icons/MapMarkerPoint.svg';

import mapClusterIcon from '../../icons/MapCluster.svg';

import { CollectionPreviewMapStyled } from './CollectionPreviewMap.styles';
import { BlackToWhite } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { POIItem } from '@unacast-internal/unacat-js/unacast/byo/v1/poi_collection_service_pb';
import { GOOGLE_MAP_KEY } from '../../../constants';
import { googleLibraries } from '../../../pages/Layout';

type CollectionPreviewMapProps = {
  featureCollection: POIItem.AsObject[];
};

export const getLatLngBoundsForCoordinates = (
  coords: google.maps.LatLngLiteral[],
): google.maps.LatLngBounds => {
  const bounds = new google.maps.LatLngBounds();

  coords.forEach((place) => bounds.extend(place));

  return bounds; // Turf-types for GeoJSON is apparently incompatible with GeoJSON
};

const mapStyle = {
  width: '100%',
  height: '100%',
};

const googleMapOptions = {
  streetViewControl: false,
  mapId: '4cde2ee1e9b041f7',
  controlSize: 32,
  disableDefaultUI: true,
  zoomControl: false,
  draggable: false,
  mapTypeControl: false,
  minZoom: 2,
};

const clusterStyle = {
  url: mapClusterIcon,
  textColor: BlackToWhite.White,
  height: 46,
  width: 46,
  fontFamily: 'Lato',
  textSize: 16,
  fontWeight: 'bold',
};

/**
 * This map renders a list of POIFeatures as markers on a map
 * and renders a polygon when one of those is selected
 * @param MultiFeatureGoogleMapProps
 * @returns
 */
export const CollectionPreviewMap = ({
  featureCollection,
}: CollectionPreviewMapProps): React.ReactElement => {
  const { isLoaded } = useJsApiLoader({
    mapIds: ['4cde2ee1e9b041f7', 'b148e3cc084c77fc'],
    googleMapsApiKey: GOOGLE_MAP_KEY,
    libraries: googleLibraries.libraries,
  });

  //Only trigger a recalculation if the collection changes
  //we don't want the map bounds to change while someone is
  //editing or deleting, better experience
  const newMapBounds = useMemo(() => {
    if (isLoaded) {
      return getLatLngBoundsForCoordinates(
        featureCollection.map((item) => CoordinateToLatLng([item.centroidLon, item.centroidLat])),
      );
    }
  }, [featureCollection, isLoaded]);

  const [googleMap, setMap] = useState<google.maps.Map>();

  const SHOW_POLYGON_ZOOM_LEVEL = 14;

  const onLoad = useCallback(
    (map) => {
      if (newMapBounds) {
        map.fitBounds(newMapBounds);
        setMap(map);
      }
    },
    [newMapBounds],
  );

  const onUnmount = () => {
    setMap(undefined);
  };

  useEffect(() => {
    if (newMapBounds && googleMap) {
      googleMap.fitBounds(newMapBounds);
      setMap(googleMap);
    }
  }, [newMapBounds, googleMap]);

  return (
    <CollectionPreviewMapStyled>
      {isLoaded && newMapBounds && (
        <GoogleMap
          mapContainerStyle={mapStyle}
          zoom={5}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{
            ...googleMapOptions,
          }}
        >
          <MarkerClusterer
            maxZoom={SHOW_POLYGON_ZOOM_LEVEL}
            zoomOnClick={false}
            styles={[clusterStyle]}
            imagePath={mapClusterIcon}
            gridSize={40}
            minimumClusterSize={2}
          >
            {(clusterer) => (
              <>
                {featureCollection.map((location) => (
                  <Marker
                    key={location.poiId}
                    icon={mapMarkerPointIcon}
                    position={CoordinateToLatLng([location.centroidLon, location.centroidLat])}
                    clusterer={clusterer}
                  />
                ))}
              </>
            )}
          </MarkerClusterer>
        </GoogleMap>
      )}
    </CollectionPreviewMapStyled>
  );
};
