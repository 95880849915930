import React, { useContext, useState } from 'react';
import { Crumbs } from '@unacast-internal/unacast-ui/BreadCrumbs';

interface CrumbProps {
  setCrumbs: (crumbs: Crumbs[]) => void;
  crumbs: Crumbs[];
}
export const BreadCrumbContext = React.createContext({} as CrumbProps);
export const BreadCrumbContextConsumer = BreadCrumbContext.Consumer;

export const BreadCrumbProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [crumbs, setCrumbs] = useState<Crumbs[]>([]);

  return (
    <BreadCrumbContext.Provider value={{ setCrumbs: setCrumbs, crumbs: crumbs }}>
      {children}
    </BreadCrumbContext.Provider>
  );
};

export const useBreadCrumbs = () => useContext(BreadCrumbContext);
