import { BlackToWhite, Success } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { Body } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { UnaLinearProgress } from '@unacast-internal/unacast-ui/UnaLinearProgress';

const LoadingSnackBarStyled = styled.div`
  background-color: ${(props: { color: string }) => props.color};
  border-radius: 6px;
  overflow: hidden;
  box-sizing: border-box;
  .message-padding {
    padding: 1rem;
  }
`;

const LoadingSnackBar = forwardRef<
  HTMLDivElement,
  { key: string | number; children: string | React.ReactNode; color?: string }
>((props, ref) => {
  return (
    <LoadingSnackBarStyled key={props.key} ref={ref} color={props.color || Success.Success01}>
      <Body color={BlackToWhite.White} className="message-padding">
        {props.children}
      </Body>
      <UnaLinearProgress></UnaLinearProgress>
    </LoadingSnackBarStyled>
  );
});

LoadingSnackBar.displayName = 'LoadingSnackBar';

export default LoadingSnackBar;
