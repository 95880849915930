export const importExternalScript = (resourceUrl: string): void => {
  const script = document.createElement('script');
  script.src = resourceUrl;
  script.async = true;
  document.body.appendChild(script);
};

export const removeExternalScript = (resourceUrl: string): void => {
  const script = document.createElement('script');
  script.src = resourceUrl;
  script.async = true;
  document.body.removeChild(script);
};

interface MyWindow extends Window {
  hj: {
    q: unknown[];
    (): void;
  };
  _hjSettings: {
    hjid: string;
    hjsv: string;
  };
}

declare let window: MyWindow;

export const initializeHotjar = (hotjarId: string): void => {
  const sv = '6';
  window.hj =
    window.hj ||
    function (...args) {
      (window.hj.q = window.hj.q || []).push(args);
    };
  window._hjSettings = { hjid: hotjarId, hjsv: sv };
  importExternalScript(`https://static.hotjar.com/c/hotjar-${hotjarId}.js?sv=${sv}`);
};
