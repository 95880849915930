import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { AnimateVariants } from '../CreateReportPage/CreateReportPage.styles';
import { useParams } from 'react-router-dom';
import { Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { CollectionDetailsPageStyled } from './CollectionDetailsPage.styles';
import { useGetCollectionHook } from '../../dataManagement/useGetCollections';
import {
  PoiCollectionDetails,
  StoredPoiCollection,
} from '../../../unacatInteraction/PoiCollectionService';
import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { CollectionDetailsCard } from '../../components/CollectionDetailsCard/CollectionDetailsCard';
import { accountCanPublish } from '../../utils/featureToggle';
import { ConfirmationDialog } from '../../components/Modals/ConfirmationDialog';
import { usePublishCollectionVersionHook } from '../../dataManagement/usePublishCollectionVersion';
import { UnaLinearProgress } from '@unacast-internal/unacast-ui/UnaLinearProgress';
import { useBreadCrumbs } from '../../../contexts/BreadCrumbContext';
import { useDeleteCollectionHook } from '../../dataManagement/useDeleteCollection';
import { useMadeToOrderNavigation } from '../../utils/madeToOrderNavigation';
import { ReportType } from '@unacast-internal/unacat-js/unacast/byo/v1/byo_service_pb';

export const MAX_INPUT_CHARACTERS = 250;
export const MIN_INPUT_CHARACTERS = 1;

/**
 * CreateReportPage Handles retreiving data from the user to initate report creation
 * @param reportDraft
 * @returns
 */
export const CollectionDetailsPage = (): React.ReactElement => {
  const { setCrumbs } = useBreadCrumbs();
  const { collectionId } = useParams<{ collectionId: string }>();
  const [showPublishDialog, setShowPublishDialog] = useState<boolean>(false);
  const [publishState, triggerPublishCollection] = usePublishCollectionVersionHook();
  const [collectionToDelete, setCollectionToDelete] = useState<PoiCollectionDetails>();
  const [deleteCollectionState, deleteCollection] = useDeleteCollectionHook();
  const navTo = useMadeToOrderNavigation();

  const { enqueueSnackbar } = useSnackbar();

  const onClickPublish = () => {
    setShowPublishDialog(true);
  };

  const onClickConfirmPublish = () => {
    setShowPublishDialog(false);
    triggerPublishCollection(collectionId);
  };

  const doCreateReport = (reportType: ReportType) => {
    triggerPublishCollection(collectionId).then((res) => {
      if (res.status === 'succeeded') {
        navTo.createReportFromStoredVersion(res.data.collectionVersionId, reportType);
      } else {
        enqueueSnackbar('There was an error creating your report', {
          variant: 'error',
        });
      }
    });
  };

  const [collectionState, getCollection] = useGetCollectionHook();

  const [collectionDetails, setCollectionDetails] = useState<StoredPoiCollection>();

  useEffect(() => {
    if (deleteCollectionState.status === 'succeeded') {
      enqueueSnackbar('Collection Deleted', { variant: 'success' });
      navTo.collectionsPage();
    } else if (deleteCollectionState.status === 'failed') {
      enqueueSnackbar(deleteCollectionState.error.message, {
        variant: 'error',
      });
    }
  }, [deleteCollectionState, enqueueSnackbar, navTo]);

  useEffect(() => {
    setCrumbs([
      { text: 'Home', url: '/' },
      { text: 'Data', url: '/c/rwg' },
      { text: 'Custom Locations', url: '/made-to-order' },
      { text: 'Collections', url: '/made-to-order/collections' },
      { text: 'Details', url: window.location.pathname },
    ]);
  }, [setCrumbs]);

  useEffect(() => {
    if (collectionState.status === 'initial') {
      getCollection(collectionId);
    } else if (collectionState.status === 'succeeded') {
      setCollectionDetails(collectionState.data);
    } else if (collectionState.status === 'failed') {
      enqueueSnackbar('There was an error getting your collection', {
        variant: 'error',
      });
    }
  }, [collectionId, enqueueSnackbar, collectionState, getCollection]);

  const getCollectionActions = (): Record<string, () => void> | undefined => {
    //Will need to check if collection has been published or not
    if (
      accountCanPublish() &&
      collectionDetails &&
      collectionDetails.featureCollection.length > 0
    ) {
      return {
        'Create OneTime-Report': () => doCreateReport(ReportType.ONE_TIME),
        'Create Reccuring Report': () => doCreateReport(ReportType.RECURRING),
        Delete: () => setCollectionToDelete(collectionDetails.details),
        // Publish: onClickPublish,
      };
    }
    if (collectionDetails)
      return {
        Delete: () => setCollectionToDelete(collectionDetails.details),
      };
  };

  return (
    <CollectionDetailsPageStyled>
      <AnimatePresence>
        <motion.div
          initial={'hidden'}
          animate={'show'}
          exit={'hidden'}
          variants={AnimateVariants}
          key="card"
        >
          {collectionDetails ? (
            <CollectionDetailsCard
              collectionDetails={collectionDetails}
              menuActions={getCollectionActions()}
            ></CollectionDetailsCard>
          ) : (
            <UnaLinearProgress></UnaLinearProgress>
          )}
        </motion.div>
        {accountCanPublish() && (
          <motion.div
            initial={'hidden'}
            animate={'show'}
            exit={'hidden'}
            variants={AnimateVariants}
            className="details-card"
            key="data-card"
          >
            <Heading type="h2" className="header-spacing">
              Data
            </Heading>
            <div className="action-prompt">
              <Heading type="h3" className="prompt-spacing">
                You need a published collection to start getting data
              </Heading>
              <UnaButton styleType="secondary" disabled>
                Configure Data
              </UnaButton>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <ConfirmationDialog
        isOpen={showPublishDialog}
        onClose={() => setShowPublishDialog(false)}
        onConfirm={onClickConfirmPublish}
        detailText="*You will not be able to make changes to this collection later."
        text={`Are you sure you want to publish "${collectionDetails?.details.name}" now?`}
      ></ConfirmationDialog>
      <ConfirmationDialog
        isOpen={collectionToDelete !== undefined}
        onClose={() => setCollectionToDelete(undefined)}
        onConfirm={() => {
          collectionToDelete && deleteCollection(collectionToDelete.id);
          setCollectionToDelete(undefined);
        }}
        text={`Are you sure you wish to delete ${collectionToDelete?.name} and it's locations?`}
      ></ConfirmationDialog>
    </CollectionDetailsPageStyled>
  );
};
