import {
  BringYourOwnPOIService,
  POICollectionReference,
} from '../../unacatInteraction/BringYourOwnPOIService';

import { Location } from './poiCollectionStore';
import { POIGeometry__asMultiPolygon } from './featureConvertion';
import {
  authDecoratedFetchingHook,
  AuthParams,
  createAsyncFetchingHook,
} from '../../unacatInteraction/fetchingHook';

const usePublishPOIs = createAsyncFetchingHook(
  async (
    { authHeader, billingAccount }: AuthParams,
    pois: Location[],
  ): Promise<POICollectionReference> => {
    const service = new BringYourOwnPOIService(authHeader, billingAccount);
    const locations = pois.map((e) => {
      const mp = POIGeometry__asMultiPolygon(e.geometry);
      return { name: e.properties.name, coordinates: mp.coordinates };
    });
    return service.storePois(locations);
  },
);

export const usePOICollectionPublisher = authDecoratedFetchingHook(usePublishPOIs);
