import {
  Checkbox,
  Chip,
  FilledInput,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { Body } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import React, { useMemo, useState } from 'react';
import { toTitleCase } from '../../mappers/TextMapper';
import { useAvailableMetrics } from '../dataManagement/availableMetricsHook';
import { Market } from '../types';

type SelectorProps = {
  onChange: (
    metrics: {
      value: string;
      label: string;
    }[],
  ) => void;
  market: Market;
  selectedMetrics: {
    value: string;
    label: string;
  }[];
  helperText?: string;
};

//TODO: HANDLE ERROR WHEN SOMEONE SWITCHES MARKET BUT HAS VALUE FROM OTHER MARKET SELECTED
export const MetricSelector = ({
  onChange,
  market,
  selectedMetrics,
  helperText,
}: SelectorProps) => {
  const backendMetricList = useAvailableMetrics();
  const [allSelected, setAllSelected] = useState(false);

  const availableMetrics = useMemo((): { value: string; label: string }[] => {
    if (backendMetricList.status === 'succeeded') {
      const newAvailableMetrics =
        market === 'us' ? backendMetricList.data.us : backendMetricList.data.international;

      return newAvailableMetrics.map((metricID: string) => ({
        value: metricID,
        label: toTitleCase(metricID),
      }));
    }
    return [];
  }, [backendMetricList, market]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    if (value.includes('all')) {
      onChange(allSelected ? [] : availableMetrics);
      setAllSelected((v) => !v);
    } else {
      setAllSelected(false);

      onChange([...availableMetrics.filter((pm) => (value as string[]).includes(pm.value))]);
    }
  };
  return (
    <>
      <Body>Select Metrics</Body>
      <Select
        multiple
        value={selectedMetrics.map((m) => m.value) || []}
        onChange={handleChange}
        input={<FilledInput />}
        renderValue={(selected) =>
          availableMetrics
            .filter((pm) => selected.includes(pm.value))
            .map((pm) => <Chip label={pm.label} key={pm.value} />)
        }
      >
        {availableMetrics.map((metric) => (
          <MenuItem key={metric.value} value={metric.value}>
            <Checkbox checked={selectedMetrics.findIndex((sp) => sp.value === metric.value) > -1} />
            <ListItemText primary={metric.label} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
