import { useEffect } from 'react';
import { BringYourOwnPOIService } from '../../unacatInteraction/BringYourOwnPOIService';
import {
  AsyncState,
  authDecoratedFetchingHook,
  AuthParams,
  createAsyncFetchingHook,
} from '../../unacatInteraction/fetchingHook';

const useFetchedData = authDecoratedFetchingHook(
  createAsyncFetchingHook(async ({ authHeader, billingAccount }: AuthParams) => {
    const service = new BringYourOwnPOIService(authHeader, billingAccount);
    const metrics = await service.getAvailableMetrics();
    return {
      us: [...metrics.us].sort(),
      international: [...metrics.international].sort(),
    };
  }),
);

type AvailableMetrics = Record<'us' | 'international', string[]>;

export const useAvailableMetrics = (): AsyncState<AvailableMetrics> => {
  const [state, initFetch] = useFetchedData();
  useEffect(() => {
    initFetch();
  }, [initFetch]);
  return state;
};
