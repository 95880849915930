import { useCallback, useState } from 'react';
import { UNACOURIER_ACCESS } from '../constants';
import { useAuthorizationHeader } from '../contexts/AuthContext';
import { useCurrentBillingAccount } from '../contexts/BillingAccountContext';
import { useCurrentBillingAccounts } from '../contexts/UserContext';

export type AsyncState<T, E = Error> =
  | { status: 'initial' }
  | { status: 'pending' }
  | { status: 'failed'; error: E }
  | { status: 'succeeded'; data: T };

export type AsyncStateStatus = AsyncState<unknown>['status'];

export const AsyncStateMapper = <T1, T2, E>(
  s: AsyncState<T1, E>,
  map: (v: T1) => T2,
): AsyncState<T2, E> => {
  if (s.status === 'succeeded') {
    return { status: 'succeeded', data: map(s.data) };
  }
  return s;
};

export enum UnaCourierEndpoints {
  listSubscriptions = `/v1/subscriptions`,
  listOrders = '/v1/orders',
  deliverDataSubscription = '/v1/deliver',
  createDataSubscription = '/v1/createDataSubscription',
  getDatasets = '/v1/datasets',
  registerDeliveryTarget = '/v1/registerDeliveryTarget',
  listDeliveryTargets = '/v1/deliveryTargets',
  listLocationTypes = '/v1/getAvailableLocationSets',
  searchGeographies = '/v1/searchGeography',
  searchAddressComponentValues = '/v1/searchAddressComponentValues',
  getClients = '/v1/crm/clients',
  cancelDataSubscription = '/v1/cancelDataSubscription',
}

const getServiceEndpoint = (service) => {
  if (!UNACOURIER_ACCESS) {
    console.error('missing service environment variable');
  }
  return `${UNACOURIER_ACCESS}${service}`;
};

//Method defintion for a generic async that takes 'x' number of args and returns either a result or an error
export type AsyncFetchingHook<TResult, TArgs extends unknown[], E = Error> = () => [
  AsyncState<TResult, E>,
  (...args: TArgs) => Promise<AsyncState<TResult, E>>,
];

type URLSearchParamsInit = string[][] | Record<string, string> | string | URLSearchParams;

/**
 * Async function to handle serializing and initiating REST requests
 * to UnaCourier and catch errors sepecific to connecting to
 * the associated service
 *
 * @param  {AuthParams} auth auth params needed to access the service
 * @param  {UnaCourierEndpoints} service URL location of the service
 * @param  {URLSearchParamsInit} queryParams the query object to be serialized
 */
export const fetchFromUnaCourier = async <TResult>(
  auth: AuthParams,
  service: UnaCourierEndpoints,
  queryParams?: URLSearchParamsInit,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestBody?: any,
  isPost?: boolean,
): Promise<TResult> => {
  const getQueryString = (params?: URLSearchParamsInit) => {
    if (params) return `?${new URLSearchParams(queryParams).toString()}`;
    return '';
  };
  const resp = await fetch(`${getServiceEndpoint(service)}${getQueryString(queryParams)}`, {
    headers: { Authorization: auth.authHeader || '', 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody),
    method: requestBody || isPost ? 'POST' : 'GET',
  });
  // eslint-disable-next-line prettier/prettier, no-useless-escape
  const ErrorCodesRegex = new RegExp('[45]{1}dd');
  if (ErrorCodesRegex.test(resp.status.toString()) || resp.ok === false) {
    throw new Error(`There was an Error: ${resp.status}, while retreiving data from the server`);
  }
  let result;
  try {
    result = await resp.json();
  } catch {
    result = await resp.text();
  } finally {
    return result as TResult;
  }
};

export const createAsyncFetchingHook =
  <TResult, TArgs extends unknown[], E = Error>(
    method: (...args: TArgs) => Promise<TResult>,
  ): AsyncFetchingHook<TResult, TArgs, E> =>
  () => {
    const [state, setState] = useState<AsyncState<TResult, E>>({ status: 'initial' });
    const trigger = useCallback(
      (...args: TArgs) => {
        setState({ status: 'pending' });
        const resolve = method(...args).then(
          (data): AsyncState<TResult, E> => ({ status: 'succeeded', data }),
          (error): AsyncState<TResult, E> => ({ status: 'failed', error }),
        );
        resolve.then(setState);
        return resolve;
      },
      [setState],
    );
    return [state, trigger];
  };

export type AuthParams = { authHeader: string; billingAccount: string };

export const authDecoratedFetchingHook =
  <TResult, TRestArgs extends unknown[], E>(
    innerHook: AsyncFetchingHook<TResult, [AuthParams, ...TRestArgs], E>,
  ): AsyncFetchingHook<TResult, TRestArgs, E> =>
  () => {
    const authHeader = useAuthorizationHeader();
    const billingAccountFromURL = useCurrentBillingAccount();
    const billingAccountFromUser = useCurrentBillingAccounts()[0]?.id;
    const billingAccount = billingAccountFromURL || billingAccountFromUser;
    const [state, doCall] = innerHook();
    const doFetch = useCallback<(...args: TRestArgs) => Promise<AsyncState<TResult, E>>>(
      (...args) => {
        if (authHeader && billingAccount) {
          return doCall({ authHeader, billingAccount }, ...args);
        }
        return Promise.resolve({
          status: 'failed',
          error: new Error('Unable to obtain auth params') as unknown as E,
        });
      },
      [doCall, authHeader, billingAccount],
    );
    return [state, doFetch];
  };
