import { Body, Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { HyperLinkNormal } from '@unacast-internal/unacast-ui/StyleGuide/Navigation';
import React from 'react';
import styled from 'styled-components';

const NotFoundPageStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    margin-top: 8rem;
    width: 50%;
    display: flex;
    flex-direction: column;

    h3 {
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 5rem;
    }

    a {
      align-self: center;
    }
  }
`;

export const NotFoundPage = (): JSX.Element => {
  return (
    <NotFoundPageStyled>
      <div className="container">
        <Heading type="h3">Page not found</Heading>
        <Body>
          Either this page does not exist, or you do not have permission to view it. If you believe
          this is an error, please contact Unacast, or the person who shared this link with you.
        </Body>
        <HyperLinkNormal to="/">Go to the homepage</HyperLinkNormal>
      </div>
    </NotFoundPageStyled>
  );
};
