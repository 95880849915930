import {
  HyperLinkSmall,
  HyperLinkSmallExternal,
} from '@unacast-internal/unacast-ui/StyleGuide/Navigation';
import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { Crumbs, BreadCrumbs } from '@unacast-internal/unacast-ui/BreadCrumbs';
import { Info } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { Body, Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ChangeEmailDialog from '../components/login/ChangeEmailDialog';
import ResetPasswordDialog from '../components/login/ResetPasswordDialog';
import { useCurrentFirebaseUser } from '../contexts/UserContext';
import { getAuth, updateEmail } from '@firebase/auth';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';

const AccountPageStyled = styled.div`
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  a {
    text-decoration-color: ${Info.Info01};
  }

  .back-link {
    margin-top: 1rem;
    width: fit-content;
    a {
      text-decoration: none;
      font-size: 1rem;
      display: flex;
      align-items: center;
    }
  }
  .bottom-links {
    position: absolute;
    bottom: 2.5rem;
    display: flex;
    flex-direction: row;

    a {
      margin-right: 2rem;
    }
  }

  .spacing {
    margin-top: 1.5rem;
  }
`;

export const AccountPage = (): JSX.Element => {
  const user = useCurrentFirebaseUser();
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState<boolean>(false);
  const [showChangeEmailDialog, setShowChangeEmailDialog] = useState<boolean>(false);
  const history = useHistory();

  const crumbs: Crumbs[] = [
    { text: 'Home', url: '/' },
    { text: 'Account', url: window.location.pathname },
  ];

  const isManagedByGoogle = () => {
    let isManaged = true;
    user?.providerData.forEach((element) => {
      if (element?.providerId.includes('password')) {
        isManaged = false;
      }
    });

    return isManaged;
  };

  // const changeEmail = (pass, newEmail) => {
  //   let signIn;
  //   firebase.auth().fetchSignInMethodsForEmail(user?.email).then(res => signIn = res.)
  //   user?.reauthenticateWithCredential({})
  //   user.then(() => {
  //     var user = firebase.auth().currentUser;
  //     user.updateEmail(newEmail).then(() => {
  //       console.log("Email updated!");
  //     }).catch((error) => { console.log(error); });
  //   }).catch((error) => { console.log(error); });
  // }

  return (
    <AccountPageStyled>
      <BreadCrumbs crumbs={crumbs} />
      <div className="back-link">
        <HyperLinkSmallExternal onClick={() => history.goBack()}>
          <ArrowBackIcon /> &nbsp;Back
        </HyperLinkSmallExternal>
      </div>
      <div className="spacing">
        <Heading type="h1">{user?.displayName || user?.email}</Heading>
      </div>
      <div className="spacing">
        <Body>email</Body>
        <Heading type="h3">{user?.email}</Heading>
      </div>
      {/* {!isManagedByGoogle() && (
        <Body>
          <a href="#" onClick={() => {setShowChangeEmailDialog(true)}} >change email</a> Managed by Google
        </Body>
      )} */}
      {!isManagedByGoogle() && (
        <div className="spacing">
          <UnaButton styleType="secondary" onClick={() => setShowResetPasswordDialog(true)}>
            Reset Password
          </UnaButton>
        </div>
      )}
      <div className="bottom-links">
        <HyperLinkSmall to="/terms-and-conditions" target="_blank">
          terms and conditions
        </HyperLinkSmall>
        <HyperLinkSmallExternal target="_blank" href="https://www.unacast.com/privacy#policy">
          privacy policy
        </HyperLinkSmallExternal>
      </div>
      <ResetPasswordDialog
        open={showResetPasswordDialog}
        email={user?.email}
        onClose={() => setShowResetPasswordDialog(false)}
        onCloseAll={() => {
          setShowResetPasswordDialog(false);
        }}
      />
      <ChangeEmailDialog
        open={showChangeEmailDialog}
        email={user?.email || ''}
        onClose={() => setShowChangeEmailDialog(false)}
        onCloseAll={() => {
          setShowChangeEmailDialog(false);
        }}
        onSubmitChange={async (newEmail) => {
          const auth = getAuth();
          if (auth.currentUser) await updateEmail(auth.currentUser, newEmail);
        }}
      />
    </AccountPageStyled>
  );
};
