import { Body, Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { HyperLinkNormal } from '@unacast-internal/unacast-ui/StyleGuide/Navigation';
import React from 'react';
import styled from 'styled-components';

const ErrorPageStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    margin-top: 8rem;
    width: 50%;
    display: flex;
    flex-direction: column;

    h3 {
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 5rem;
    }

    a {
      align-self: center;
    }
  }
`;

export const ErrorPage = ({
  error,
  resetError,
}: {
  error: Error;
  resetError: () => void;
}): JSX.Element => {
  return (
    <ErrorPageStyled>
      <div className="container">
        <Heading type="h3">We encountered an unknown error</Heading>
        <Body>
          We encountered an unknown {error.name || 'error'} with message: {error.message}. <br />
          An alert is sent to the engineering team. <br />
          If this persists, please contact Unacast, or the person who shared this link with you.
        </Body>
        <HyperLinkNormal onClick={resetError} to="/">
          Go to the homepage
        </HyperLinkNormal>
      </div>
    </ErrorPageStyled>
  );
};
