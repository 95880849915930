import React from 'react';
import { UnacastIcon } from '@unacast-internal/unacast-ui/StyleGuide/UnaIcons/UnacastIcon';
import { HyperLinkNormal } from '@unacast-internal/unacast-ui/StyleGuide/Navigation';
import { Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { BlackToWhite, Primary } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { useMadeToOrderNavigation } from '../../utils/madeToOrderNavigation';
import { OptionsItem } from '../OptionsItem';
import { SideBarStyled } from './SideBarStyled';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { accountCanPublish } from '../../utils/featureToggle';

export type SideBarSection = 'reports' | 'collections';

type MadeToOrderSideBarProps = {
  activeSection: SideBarSection;
};

export const MadeToOrderSideBar: React.FC<MadeToOrderSideBarProps> = ({ activeSection }) => {
  const navTo = useMadeToOrderNavigation();
  const canPublish = accountCanPublish();

  return (
    <SideBarStyled>
      <div className="logo-area">
        <HyperLinkNormal to={`/`}>
          <UnacastIcon width={140} />
        </HyperLinkNormal>
      </div>
      <Heading type="h3" className="side-header-spacing">
        Custom Locations
      </Heading>
      <div className="option-spacing">
        <OptionsItem
          key={'Collections'}
          isActive={'collections' === activeSection}
          text={'Collections (IN PROGRESS)'}
          onClick={() => navTo.collectionsPage()}
          icon={
            <StorefrontIcon
              htmlColor={'collections' === activeSection ? Primary.Primary01 : BlackToWhite.Black01}
            ></StorefrontIcon>
          }
        />
      </div>
      {canPublish && (
        <div className="option-spacing">
          <OptionsItem
            key={'Reports'}
            isActive={'reports' === activeSection}
            text={'Reports'}
            onClick={() => navTo.homePage()}
            icon={
              <InsertChartIcon
                htmlColor={'reports' === activeSection ? Primary.Primary01 : BlackToWhite.Black01}
              ></InsertChartIcon>
            }
          />
        </div>
      )}

      <hr />
    </SideBarStyled>
  );
};
