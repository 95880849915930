import styled from 'styled-components';

export const ChangeEmailDialogStyled = styled.div`
  padding: 1rem;

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
