import centroid from '@turf/centroid';
import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { Modal } from '@unacast-internal/unacast-ui/Organisms';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  POICollection_fromLocationRecord,
  POIFeature__fromPOIItem,
  validateFeature,
} from '../../../helpers/geography';
import {
  withAddedFeature,
  withoutFeature,
  withUpdatedFeature,
} from '../../dataManagement/POICollectionStoreConnected/poiCollectionActions';
import { isPOIFeature, POICollection, POIFeature } from '../../types';
import { AddNewCollectionFromFile } from '../CollectionOnboarding/AddNewCollectionFlow';
import { EmptyCollectionPrompt } from '../EmptyCollectionPrompt';
import { MultiFeatureGoogleMap } from '../MultiFeatureGoogleMap/MultiFeatureGoogleMap';
import { NewLocationModal } from '../Modals/NewLocationModal';
import { SampleFileModal } from '../SampleFileModal/SampleFileModal';
import AddIcon from '@mui/icons-material/Add';
import { POIManagementMapStyled } from './POICollectionManagerMap.styles';
import { UploadPOICollectionButton } from '../UploadPOICollectionButton';
import { POIError, StoredPoiCollection } from '../../../unacatInteraction/PoiCollectionService';
import { usePOICollectionStoreSimple } from '../../dataManagement/POICollectionStoreConnected/poiCollectionStoreSimple';
import { LocationUploaderProgress } from '../LocationUploaderProgress';
import { LocationErrorList } from '../LocationErrors/LocationErrorList';
import { usePOIManagementServices } from './POIManagementServiceHooks';
import { FeatureToggleHelper } from '../../../components/FeatureToggle';
import { useDownloadCollectionAsGeoJsonHook } from '../hooks/useCollectionDownloadHandler';

type ModalType = 'ADD_FEATURE' | 'SHOW_FORMAT_TYPE';

/**
 *  Interactions for managing a collection of Points of Interest
 * COUPLED TO <POICollectionStoreProvider></POICollectionStoreProvider>
 * @param collectionName
 * @returns
 */

export const POIManagementMap = ({ collection }: { collection: StoredPoiCollection }) => {
  const [locations, updatePOICollection, idFromStore] = usePOICollectionStoreSimple();
  const [deletedFeatures, setDeletedFeatures] = useState<POIFeature[]>();
  const [mapCenter, setMapCenter] = useState<google.maps.LatLng>();
  //backend ID used for lookup
  const [selectedFeatureId, setSelectedfeatureId] = useState<string>();
  const [selectedFeature, setSelectedFeature] = useState<POIFeature>();
  const [percentLocationsUploaded, setPercentLocationsUploaded] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [downloadStatus, download] = useDownloadCollectionAsGeoJsonHook();
  const { isFeatureToggleActive } = FeatureToggleHelper();

  const { addPOI, addPOIs, addAddresses, getPOIItem, deletePOIItem, updatePOIItem } =
    usePOIManagementServices();

  //--------------------------------
  //-----UPLOAD ERROR HANDLING------
  //--------------------------------
  const [collectionErrors, setCollectionErrors] = useState<Record<string, POIError>>();
  const [selectedError, setSelectedError] = useState<string>();

  const [modalType, setModalType] = useState<ModalType>();
  const [currentFileName, setCurrentFileName] = useState<string>();
  const [fileToParse, setFileToParse] = useState<ArrayBuffer>();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (downloadStatus.status === 'failed') {
      enqueueSnackbar(downloadStatus.error.message, { variant: 'error' });
    } else if (downloadStatus.status === 'succeeded') {
      enqueueSnackbar('Download complete!', { variant: 'success' });
    }
  }, [downloadStatus, enqueueSnackbar]);

  const appendFile = (result: ArrayBuffer, fileName: string) => {
    setCurrentFileName(fileName);
    setFileToParse(result);
  };
  const cancelUpload = () => {
    setCurrentFileName(undefined);
    setFileToParse(undefined);
  };

  useEffect(() => {
    if (!selectedFeatureId) {
      setSelectedFeature(undefined);
      return;
    }
    getPOIItem(collection.details.id, selectedFeatureId)
      .then((res) => {
        if (res) {
          POIFeature__fromPOIItem(res.toObject()).then((convFeat) => {
            setSelectedFeature(convFeat);
          });
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(`Error getting detailed geometry for'${selectedFeatureId}'`, {
          variant: 'error',
        });
      });
  }, [collection.details.id, enqueueSnackbar, getPOIItem, selectedFeatureId]);

  const mapCollection = useMemo((): POICollection | undefined => {
    return Object.values(locations).length > 0
      ? POICollection_fromLocationRecord(locations)
      : undefined;
  }, [locations]);

  const getArrayIndexFromStep = (step, steps, size) => {
    if (step === steps) {
      //indicates loop is at end and should grab the last index;
      return -1;
    }
    //subtract 1 to represent array index
    return (step - 1) * size + size;
  };

  const onFileParsed = (features: POIFeature[]) => {
    const featuresToBeAdded: POIFeature[] = [];
    features.forEach((feat) => {
      if (locations[feat.id] || locations[feat.properties.name]) {
        setCollectionErrors((last) => {
          return {
            ...last,
            [feat.properties.name]: {
              name: feat.properties.name,
              message: 'An Item with this ID already exists',
              severity: 'error',
              feature: feat,
            },
          };
        });
      } else {
        featuresToBeAdded.push(feat);
      }
    });
    extendCurrentCollection(featuresToBeAdded);
  };

  //Used explicitely for file uploads
  const extendCurrentCollection = useCallback(
    async (features: POIFeature[]) => {
      const addressFeatures: Array<{ name: string; address: string }> = [];
      const otherFeatures: POIFeature[] = [];
      features.forEach((f) => {
        if (f.geometry.type === 'Point' && f.geometry.properties.address !== undefined) {
          addressFeatures.push({ name: f.properties.name, address: f.geometry.properties.address });
        } else {
          otherFeatures.push(f);
        }
      });

      if (addressFeatures.length > 0) {
        const res = await addAddresses(collection.details.id, addressFeatures);
        // TODO: Indicate that collection has pending addresses
      }

      const STEP_SIZE = 300;
      const steps = Math.ceil(otherFeatures.length / STEP_SIZE);
      //handle how to aggregate and display errors

      if (steps > 1) {
        setIsUploading(true);
      }
      //perhaps start several in parallel
      for (let step = 1; step <= steps; step++) {
        const featureSubSet = otherFeatures.slice(
          (step - 1) * STEP_SIZE,
          getArrayIndexFromStep(step, steps, STEP_SIZE),
        );

        const res = await addPOIs(collection.details.id, featureSubSet);
        // eslint-disable-next-line no-loop-func
        if (!res) {
          //show error
          return;
        }
        res.toObject().poisList.forEach((poi) => {
          const updateFeat = featureSubSet.find((f) => f.properties.name === poi.name);
          if (updateFeat) {
            updateFeat.id = poi.poiId;
            updateFeat.properties.centroid = [
              parseFloat(poi.centroid?.lon || '0'),
              parseFloat(poi.centroid?.lat || '0'),
            ];
          }
        });
        //on large lists, how to do a quick lookup to asign centroid and properID to item
        //or
        //convert return to POIFeature

        updatePOICollection((f) => featureSubSet.reduce(withAddedFeature, f));
        setPercentLocationsUploaded((step / steps) * 100);
      }
      //When finished, reset the map bounds to the new list
      setIsUploading(false);
      setPercentLocationsUploaded(0);
      enqueueSnackbar('Locations finished uploading!', { variant: 'success' });
    },
    [addPOIs, collection.details.id, enqueueSnackbar, updatePOICollection],
  );

  const saveNewFeature = useCallback(
    async (feature: POIFeature) => {
      try {
        const resp = await addPOI(collection.details.id, feature);
        if (!resp) {
          //show error
          return;
        }
        const addedItem = resp.getPoisList()[0].toObject();
        feature.id = addedItem.poiId;
        feature.properties.centroid = [
          parseFloat(addedItem.centroid?.lon || '0'),
          parseFloat(addedItem.centroid?.lat || '0'),
        ];
        updatePOICollection((f) => withAddedFeature(f, feature));
        setModalType(undefined);
        setDeletedFeatures(undefined);
        enqueueSnackbar(`'${feature.id}' successfully added to collection`, {
          variant: 'success',
        });
      } catch (err) {
        enqueueSnackbar(`'${feature.id}' not added to collection`, {
          variant: 'error',
        });
      }
    },
    [addPOI, collection.details.id, enqueueSnackbar, updatePOICollection],
  );

  const onAddNewFeature = useCallback(
    (f: POIFeature): boolean => {
      if (!isPOIFeature(f) || locations[f.properties.name]) {
        return false;
      }
      saveNewFeature(f);
      return true;
    },
    [locations, saveNewFeature],
  );

  //TODO:
  //currently no way to handle 'undo' as the items on the collection level do not contain detailed geometry
  const onDelete = () => {
    if (deletedFeatures) {
      enqueueSnackbar(
        `${deletedFeatures[0].properties.name} has been removed from your collection.`,
        {
          variant: 'success',
        },
      );
    }
  };

  const onDeleteFeature = async (): Promise<void> => {
    if (locations && selectedFeature) {
      await deletePOIItem(collection.details.id, [selectedFeature?.id])
        .then(() => {
          const deletingFeature = locations[selectedFeature.properties.name];
          //BECAUSE NAME IS THE CLIENT IDENTIFIER
          updatePOICollection((f) => withoutFeature(f, deletingFeature.properties.name));

          // part of the 'undo' feature that needs to be evaluated
          setDeletedFeatures(deletingFeature ? [deletingFeature] : undefined);
          setSelectedFeature(undefined);
        })
        .catch((err) => {
          enqueueSnackbar(`There was an error while deleting your items`, {
            variant: 'error',
          });
        });
    }
  };

  const onDeleteCollection = async (): Promise<void> => {
    if (locations) {
      const newDeletedFeatures = Object.values(locations);

      //TEMP until a 'clearCollection' endpoint exists
      try {
        await deletePOIItem(
          collection.details.id,
          newDeletedFeatures.map((f) => f.id),
        );
      } catch (err) {
        console.error(err);
        enqueueSnackbar(`There was an error while deleting your items`, {
          variant: 'error',
        });
      }

      //Creates a new, empty collection
      updatePOICollection(() => ({}));
      setDeletedFeatures(newDeletedFeatures);
    }
  };

  const onEditLocation = useCallback(
    async (feature: POIFeature) => {
      const staleFeature = selectedFeature;

      try {
        await updatePOIItem(collection.details.id, feature.id, feature);
        feature.properties.centroid = centroid(feature).geometry.coordinates;

        updatePOICollection((f) => withUpdatedFeature(f, feature));

        //If a new name has been established for the location, the old item needs to be removed
        if (staleFeature && staleFeature?.properties.name !== feature.properties.name) {
          updatePOICollection((f) => withoutFeature(f, staleFeature.properties.name));
        }
        setSelectedFeature(feature);
        enqueueSnackbar(`Location ${feature?.properties.name} edited successfully`, {
          variant: 'success',
        });
        setModalType(undefined);
      } catch (err) {
        console.error(err);
        enqueueSnackbar(`Error updating: '${feature.properties.name}'`, {
          variant: 'error',
        });
      }
    },
    [selectedFeature, updatePOIItem, collection.details.id, updatePOICollection, enqueueSnackbar],
  );

  useEffect(onDelete, [closeSnackbar, deletedFeatures, enqueueSnackbar]);

  const collectionActions = () => {
    if (isFeatureToggleActive('canDownloadCollection')) {
      return {
        Download: () => download(collection.details.id),
        // 'Clear Locations': () => onDeleteCollection(),
      };
    }

    return undefined;
  };

  const onUpdateFeature = useCallback(
    (f: POIFeature) => {
      if (f.properties.name !== selectedFeature?.properties.name && locations[f.properties.name]) {
        enqueueSnackbar('Name already in use', { variant: 'error' });
        return false;
      }
      const errors = validateFeature(f.properties.name, f);
      if (errors && errors[f.properties.name].severity === 'error') {
        enqueueSnackbar(errors[f.properties.name].message, { variant: 'error' });
        return false;
      }

      onEditLocation(f);
      return true;
    },
    [enqueueSnackbar, locations, onEditLocation, selectedFeature?.properties.name],
  );

  const modals = useMemo(
    (): Record<ModalType, JSX.Element> => ({
      ADD_FEATURE: (
        <NewLocationModal
          onSave={onAddNewFeature}
          onClose={() => setModalType(undefined)}
          defaultMapCenter={mapCenter}
        />
      ),
      SHOW_FORMAT_TYPE: <SampleFileModal isOpen onClose={() => setModalType(undefined)} />,
    }),
    [onAddNewFeature, mapCenter],
  );

  return (
    <POIManagementMapStyled>
      <>
        <div className="format-info-link">
          <UnaButton
            onClick={() => setModalType('SHOW_FORMAT_TYPE')}
            styleType="info"
            className="highlight-button"
          >
            *Read more about Supported Files
          </UnaButton>
        </div>
        <div className="map-with-errors">
          <div className="temp-map-area">
            {mapCollection ? (
              <>
                <MultiFeatureGoogleMap
                  onEditFeature={onUpdateFeature}
                  deleteFeature={onDeleteFeature}
                  featureCollection={mapCollection}
                  collectionName={collection.details.id} // unused in this case
                  setMapCenter={setMapCenter}
                  topLevelActions={collectionActions()}
                  setSelected={(feat) => setSelectedfeatureId(feat?.id || undefined)}
                  defaultSelectedFeatureId={selectedFeatureId}
                  defaultSelectedFeature={selectedFeature}
                />
                <div className="map-add-button">
                  {/* TODO: EXTRACT UPLOAD BUTTON TO COMPONENT */}
                  <UploadPOICollectionButton
                    onComplete={appendFile}
                    text={locations ? 'Append Location file' : 'Add Location File'}
                  ></UploadPOICollectionButton>

                  <UnaButton
                    onClick={() => setModalType('ADD_FEATURE')}
                    icon={<AddIcon />}
                    iconPos="end"
                  >
                    Add Location
                  </UnaButton>
                </div>
              </>
            ) : (
              <EmptyCollectionPrompt
                onComplete={appendFile}
                onDrawClick={() => setModalType('ADD_FEATURE')}
              ></EmptyCollectionPrompt>
            )}
            <LocationUploaderProgress
              show={isUploading}
              progressValue={percentLocationsUploaded}
            ></LocationUploaderProgress>
          </div>
          {collectionErrors && (
            <LocationErrorList
              collectionErrors={collectionErrors}
              selectedError={selectedError}
              setSelectedError={setSelectedError}
            ></LocationErrorList>
          )}
        </div>
      </>

      <Modal
        isOpen={modalType !== undefined}
        onClose={() => {
          setModalType(undefined);
        }}
      >
        {modalType ? modals[modalType] : <></>}
      </Modal>
      {fileToParse && currentFileName && (
        <AddNewCollectionFromFile
          onComplete={(newColl: POICollection) => {
            onFileParsed(newColl.features);
            setFileToParse(undefined);
            setCurrentFileName(undefined);
          }}
          fileToParse={fileToParse}
          fileName={currentFileName}
          onCancelParsing={cancelUpload}
        />
      )}
    </POIManagementMapStyled>
  );
};
