import React, { useState } from 'react';
import { Body } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { BlackToWhite, Primary, Warning } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

type ErrorStyleProps = {
  position: Position;
  hasAction?: boolean;
};

const ErrorListStyled = styled(motion.div)`
  position: absolute;
  ${({ position }: ErrorStyleProps) => {
    let compositString = '';
    const { top, bottom, left, right } = position;
    compositString += top ? `top:${top}px;` : '';
    compositString += bottom ? `bottom:${bottom}px;` : '';

    compositString += right ? `right:${right}px;` : '';

    compositString += left ? `left:${left}px;` : '';

    return compositString;
  }}
  background-color: ${Warning.Warning01};
  border-radius: 6px;
  //TODO: MOVE STANDARD BOX SHADOWS TO UNACAST_UI
  box-shadow: 0 0 2px rgba(40, 41, 61, 0.04), 0 4px 4px rgba(80, 148, 175, 0.16);
  min-width: 32px;
  min-height: 32px;
  max-height: 300px;

  .error-icon {
    position: absolute;
    top: 0.2rem;
    ${({ position }: ErrorStyleProps) => {
      if (position.left) {
        return `left: .25rem;`;
      } else if (position.right) {
        return `right: .25rem;`;
      }
    }}
    &:hover {
      cursor: pointer;
    }
  }

  .error-list {
    margin-top: 2rem;

    padding: 0 0.5rem 1rem 0.5rem;
    &.scrollable {
      max-height: 250px;
      overflow-y: scroll;
    }

    ${({ hasAction }: ErrorStyleProps) =>
      hasAction &&
      `.error-action:hover {
      cursor: pointer;
    }`}
  }
`;

const ErrorListVariants = {
  expanded: {
    opacity: 1,
    x: 0,
    height: 'auto',
    width: 'fit-content',
  },
  closed: {
    opacity: 1,
    x: 0,
    height: 32,
    width: 32,
    transition: { delay: 0.2, type: 'linear', duration: 0.5 },
  },
};

type Position = { top?: number; right?: number; bottom?: number; left?: number };

//TODO: ADD POSITION AS PARAM
export const MapErrorSummary = ({
  position,
  errors,
  isExpanded = false,
  onClickError,
}: {
  position: Position;
  isExpanded?: boolean;
  errors?: Record<string, string[]>;
  onClickError?: (id: string) => void;
}) => {
  const [showErrors, setShowErrors] = useState<boolean>(isExpanded);
  const { left, right } = position;

  const iconStartX = left ? -32 : right ? 32 : undefined;
  return (
    <AnimatePresence>
      {errors && (
        <ErrorListStyled
          initial={{ opacity: 0, x: iconStartX, height: 32, width: 32 }}
          transition={{ type: 'linear' }}
          variants={ErrorListVariants}
          whileTap={{ scale: 0.9 }}
          animate={showErrors ? 'expanded' : 'closed'}
          exit={{ opacity: 0, x: iconStartX, height: 32, width: 32 }}
          position={position}
          hasAction={onClickError !== undefined}
        >
          <AnimatePresence>
            {showErrors && (
              <motion.div
                initial={{ opacity: 0 }}
                exit={{ opacity: 0, transition: { delay: 0 } }}
                animate={{ opacity: 1, transition: { delay: 0.3 } }}
                className={showErrors ? 'scrollable error-list' : 'error-list'}
              >
                {Object.keys(errors)
                  .slice(0, Object.keys(errors).length > 20 ? 20 : undefined)
                  .map((key) => (
                    <span
                      className="error-action"
                      onClick={() => {
                        onClickError && onClickError(key);
                      }}
                      key={key}
                    >
                      <Body color={BlackToWhite.White}>{`${key}: ${errors[key]}`}</Body>
                    </span>
                  ))}
              </motion.div>
            )}
          </AnimatePresence>
          <div onClick={() => setShowErrors((prev) => !prev)} className="error-icon">
            <AnimatePresence>
              {showErrors ? (
                <motion.div
                  key="show"
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { delay: 0.2 } }}
                >
                  <ExpandLessIcon htmlColor={BlackToWhite.White}></ExpandLessIcon>
                </motion.div>
              ) : (
                <motion.div
                  key="hide"
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { delay: 0.2 } }}
                >
                  <PriorityHighIcon htmlColor={BlackToWhite.White}></PriorityHighIcon>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </ErrorListStyled>
      )}
    </AnimatePresence>
  );
};
