import {
  BringYourOwnPOIService,
  ReportForCreation,
} from '../../unacatInteraction/BringYourOwnPOIService';
import {
  authDecoratedFetchingHook,
  AuthParams,
  createAsyncFetchingHook,
} from '../../unacatInteraction/fetchingHook';

const useFetchReport = createAsyncFetchingHook(
  async ({ authHeader, billingAccount }: AuthParams, r: ReportForCreation): Promise<void> => {
    const service = new BringYourOwnPOIService(authHeader, billingAccount);
    //Missing ClientName and ReportName
    await service.createReportFromCollectionReference(r);
  },
);

export const usePrepareReport = authDecoratedFetchingHook(useFetchReport);
