import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { GetCollectionDetails } from '../components/GetCollectionDetails';
import { useCreateCollectionHook } from '../dataManagement/useCreateCollection';
import { Market } from '../types';
import { useMadeToOrderNavigation } from '../utils/madeToOrderNavigation';
import { AnimateVariants } from './CreateReportPage/CreateReportPage.styles';
import { UnaLinearProgress } from '@unacast-internal/unacast-ui/UnaLinearProgress';
import { useSnackbar } from 'notistack';

const NewCollectionPageStyled = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 400px;
`;

export const NewCollectionPage = () => {
  const [collectionState, createCollection] = useCreateCollectionHook();
  const navTo = useMadeToOrderNavigation();
  const { enqueueSnackbar } = useSnackbar();

  const onComplete = (n: string, m: Market) => {
    createCollection(n, m);
  };

  useEffect(() => {
    if (collectionState.status === 'succeeded')
      navTo.collectionDetails(collectionState.data.collectionId);
    else if (collectionState.status === 'failed') {
      enqueueSnackbar('There was an error creating your collection. Refresh and try again.', {
        variant: 'error',
      });
    }
  }, [collectionState, enqueueSnackbar, navTo]);

  return (
    <NewCollectionPageStyled
      initial={'hidden'}
      animate={'show'}
      exit={'hidden'}
      variants={AnimateVariants}
      className="market-prompt-placement"
    >
      {collectionState.status === 'initial' ? (
        <GetCollectionDetails onComplete={onComplete}></GetCollectionDetails>
      ) : (
        <UnaLinearProgress></UnaLinearProgress>
      )}
    </NewCollectionPageStyled>
  );
};
