import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { StoredCollectionVersion } from '../../../unacatInteraction/BringYourOwnPOIService';
import { UnaLinearProgress } from '@unacast-internal/unacast-ui/UnaLinearProgress';
import { useStoredCollection } from '../../dataManagement/storedCollectionHook';

type Props = { renderProp: (r: StoredCollectionVersion) => JSX.Element };
export const GetStoredCollectionContainer = ({ renderProp }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { versionId } = useParams<{ versionId: string }>();

  const storedCollectionState = useStoredCollection(versionId);

  useEffect(() => {
    if (storedCollectionState.status === 'failed') {
      enqueueSnackbar(storedCollectionState.error.message, { variant: 'error' });
    }
  }, [storedCollectionState, enqueueSnackbar]);

  return storedCollectionState.status === 'succeeded' ? (
    renderProp(storedCollectionState.data)
  ) : (
    <UnaLinearProgress></UnaLinearProgress>
  );
};
