import { SplitButton } from '@unacast-internal/unacast-ui/Inputs/SplitButton/SplitButton';
import { HyperLinkOrange } from '@unacast-internal/unacast-ui/StyleGuide/Navigation';
import { Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { motion } from 'framer-motion';
import React, { useEffect, useMemo } from 'react';
import { useMadeToOrderNavigation } from '../../utils/madeToOrderNavigation';
import { ReportListPageStyled } from './ReportListPageStyled';
import { useReportList } from '../../dataManagement/reportListHook';
import { DraftReportList } from '../../components/DraftReportList/DraftReportList';
import { MadeToOrderReportList } from '../../components/MadeToOrderReportList/MadeToOrderReportList';
import { useGetDraftReportsHook } from '../../dataManagement/useGetDraftReportsHook';
import { ReportType } from '@unacast-internal/unacat-js/unacast/byo/v1/byo_service_pb';
import { useBreadCrumbs } from '../../../contexts/BreadCrumbContext';

export const ReportListPage = () => {
  const { setCrumbs } = useBreadCrumbs();
  const [reportsState, refreshList] = useReportList();
  const [draftsState, getDraftList] = useGetDraftReportsHook();

  useEffect(() => {
    setCrumbs([
      { text: 'Home', url: '/' },
      { text: 'Data', url: '/c/rwg' },
      { text: 'Custom Locations', url: '/made-to-order' },
    ]);
  }, [setCrumbs]);

  useEffect(() => {
    getDraftList();
  }, [getDraftList]);

  useEffect(() => {
    refreshList();
  }, [refreshList]);

  const navTo = useMadeToOrderNavigation();

  //TODO: UI FOR ERROR RETREIVING LISTS

  const showReportPrompt = useMemo(() => {
    return (
      reportsState.status === 'succeeded' &&
      reportsState.data.length < 0 &&
      draftsState.status === 'succeeded' &&
      draftsState.data.length < 0
    );
  }, [reportsState, draftsState]);

  return (
    <ReportListPageStyled>
      <div className="header-row">
        <Heading type="h3">Reports</Heading>
        <SplitButton
          options={{
            'New One-Time Report': () => navTo.createReport(ReportType.ONE_TIME),
            'New Recurring Report': () => navTo.createReport(ReportType.RECURRING),
          }}
          styleType="secondary"
        ></SplitButton>
      </div>
      {showReportPrompt ? (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 1, type: 'easeIn', duration: 0.6 } }}
          className="centered"
        >
          <Heading className="line-height">
            No Reports here <br />
            Start by
            <HyperLinkOrange to="/made-to-order/create" className="large-text">
              Creating a new Report
            </HyperLinkOrange>
          </Heading>
        </motion.div>
      ) : (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.6 } }}>
          <MadeToOrderReportList></MadeToOrderReportList>

          {draftsState.status === 'succeeded' && draftsState.data.length > 0 && (
            <>
              <Heading type="h3" className="drafts-heading">
                Drafts
              </Heading>
              <DraftReportList></DraftReportList>
            </>
          )}
        </motion.div>
      )}
      {/* ----------------------------- */}
    </ReportListPageStyled>
  );
};
