import { UnacastIcon } from '@unacast-internal/unacast-ui/StyleGuide/UnaIcons/UnacastIcon';
import { Body } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useCurrentUserLoggedInStatus } from '../contexts/UserContext';
import { getAuth, sendEmailVerification } from '@firebase/auth';

export const EmailVerificationFailedPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px 5rem;

  .info {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

export type LocationState = {
  emailForVerification: string;
};

const EmailVerificationFailedPage = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const loggedIn = useCurrentUserLoggedInStatus();
  const who = location.state?.emailForVerification || 'you';

  useEffect(() => {
    if (loggedIn) history.push('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  const resendEmail = async () => {
    const auth = getAuth();
    if (auth.currentUser) {
      const didSend = await sendEmailVerification(auth.currentUser).then(
        () => true,
        () => false,
      );
      history.push({
        pathname: didSend ? '/email-verification-sent' : '/email-verification-failed',
        state: { emailForVerification: location.state?.emailForVerification },
      });
    }
  };

  return (
    <EmailVerificationFailedPageStyled>
      <UnacastIcon />
      <div className="info">
        <Body>
          Failed to verify your email! Please make sure that you have verified the email sent to{' '}
          {who}. If you have not received an email please check your different inboxes or retry. If
          this error remains, please{' '}
          <a style={{ padding: 0 }} color="secondary" href="mailto:portal@unacast.com">
            contact us
          </a>
        </Body>
      </div>
      <UnaButton styleType="primary" onClick={resendEmail}>
        Resend validation email
      </UnaButton>
    </EmailVerificationFailedPageStyled>
  );
};

export default EmailVerificationFailedPage;
