import { POICollection } from '../../types';
import { LocationRecord, Location } from './poiCollectionStoreSimple';

export const withAddedFeature = (current: LocationRecord, feature: Location): LocationRecord => {
  if (current[feature.properties.name] !== undefined) {
    // eslint-disable-next-line no-console
    console.warn(
      new Error('A feature with id ' + feature.properties.name + ' already exist in collection'),
    );
  }
  return { ...current, [feature.properties.name]: feature };
};

export const withUpdatedFeature = (current: LocationRecord, feature: Location): LocationRecord => {
  return { ...current, [feature.properties.name]: feature };
};

export const withoutFeature = (current: LocationRecord, id: string): LocationRecord => {
  const { [id]: removed, ...remaining } = current;
  return remaining;
};

export const FeatureRecord_asCollection = (r: LocationRecord): POICollection => ({
  type: 'FeatureCollection',
  features: Object.values(r),
});
