import { Dialog } from '@mui/material';
import React, { useState } from 'react';
import GoogleAuthForm from '../GoogleAuthForm';
import { LoginDialogStyled } from './LoginDialogStyled';

interface Props {
  open: boolean;
  onClose: () => void;
  preType: 'signup' | 'login';
}

const LoginDialog = ({ open, onClose, preType }: Props): JSX.Element => {
  const [type, setType] = useState<'signup' | 'login'>(preType);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setType('login');
        onClose();
      }}
      style={{ outline: 'none' }}
    >
      <LoginDialogStyled>
        <GoogleAuthForm callBack={onClose} type={type} />
      </LoginDialogStyled>
    </Dialog>
  );
};

export default LoginDialog;
