import centroid from '@turf/centroid';
import { maxBy, minBy } from 'lodash';
import { wktToGeoJSON } from '../helpers/geography';
import { Feature } from '@unacast-internal/unacat-js/unacast/maps/v1/feature_pb';
import {
  AddressComponent,
  ComponentKind,
} from '@unacast-internal/unacat-js/unacast/maps/v1/address_component_pb';
import { Layer } from '@unacast-internal/unacat-js/unacast/v2/maps/layer_pb';
import {
  Dimension,
  DimensionFilter,
  DimensionValue,
} from '@unacast-internal/unacat-js/unacast/v2/metric/dimension_pb';
import {
  MetricVersion,
  MetricVersionSpec,
  ValueKind,
  ValueSpec,
} from '@unacast-internal/unacat-js/unacast/v2/metric/metric_pb';
import { MetricValue } from '@unacast-internal/unacat-js/unacast/metric/v1/metric_value_pb';
import { getValueSpec } from './MetricSpecMapper';
import { toDateAsIsoString } from './PeriodMapper';
import { toTitleCase } from './TextMapper';
import { DataSchema, DataSchemaColumn } from '../components/hooks/getDefaultSchemaHook';

export enum ColumnUsage {
  Content = 0,
  Link = 1,
}

export type Column = {
  name: string;
  displayName?: string;
  idColumn: boolean;
  columnUsage: ColumnUsage;
  type: string;
  description?: string;
  value: (value: MetricValue, dimensionValues: DimensionValue[]) => string | number | undefined;
  componentKind?: ComponentKind;
  getFromFeature?: boolean;
  isIncludedInExport: boolean;
  documentationUrl?: string;
  sourceColumn?: string;
};

const toLowerSnakeCase = (string) => {
  return string
    .toLowerCase()
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .join('_');
};

function getTypeBasedOnValueKind(valueKind: ValueKind | undefined) {
  switch (valueKind) {
    case ValueKind.CATEGORY:
      return 'string';
    case ValueKind.COUNT:
      return 'int';
    case ValueKind.NUMBER:
      return 'float (max four decimals)';
  }

  return 'string';
}

const getDataColumnsFromSchema = (s: DataSchema): DataSchemaColumn[] => {
  const start = s.observationStartColumn;
  const end = s.observationEndColumn;

  if (start === undefined || end === undefined) {
    throw new Error('Invariant');
  }

  return [
    ...s.featureColumnsList,
    ...s.relatedFeatureColumnsList,
    ...s.addressComponentColumnsList,
    ...s.dimensionColumnsList,
    start,
    end,
    ...s.valuesColumnsList,
  ];
};

export const getIsIncludedMap = (s: DataSchema): Record<string, boolean> => {
  const map: Record<string, boolean> = {};
  getDataColumnsFromSchema(s).forEach((c) => {
    map[c.defaultName] = map[c.defaultName] || c.isIncluded;
  });
  return map;
};

export const getColumns = (metric?: MetricVersion): Column[] => {
  const spec = metric?.getSpec() || new MetricVersionSpec();
  const layer = metric?.getLayer() || new Layer();
  const relatedLayer = metric?.getRelatedLayer() || new Layer();
  const dimensions = metric?.getDimensionsList() || [];
  const layerRelationshipName: string | undefined =
    spec.getLayerRelationshipName().length > 0 ? spec.getLayerRelationshipName() : undefined;
  const relatedLayerRelationshipName: string | undefined =
    spec.getRelatedLayerRelationshipName().length > 0
      ? spec.getRelatedLayerRelationshipName()
      : undefined;

  const featureColumns = getFeatureDetailColumnsFromAddressComponents(layer, layerRelationshipName);
  const featureGeoColumns = getFeatureGeoColumns(layer);
  const relatedFeatureColumns = getRelatedFeatureDetailColumnsFromAddressComponents(
    relatedLayer,
    relatedLayerRelationshipName,
  );

  const dimensionColumns = getDimensionColumns(metric?.getId(), spec, dimensions);
  const supportingValuesColumns = getSupportingValuesColumns(spec);

  const coreValueSpec = getValueSpec(spec);

  // Super duper hack to let the new Migration Patterns v3 dataset have their feature id column be location-id
  // while not breaking any existing datasets.
  let locationIdColumnName = `${layer.toObject().spec?.featureDisplayName || 'location'}_id`;
  locationIdColumnName = `${
    layerRelationshipName ? layerRelationshipName + ' ' : ''
  }${locationIdColumnName}`;
  if (featureColumns.find((column) => column.name === toLowerSnakeCase(locationIdColumnName))) {
    locationIdColumnName = 'location_id';
  }

  return [
    {
      name: toLowerSnakeCase(
        `${layerRelationshipName ? layerRelationshipName + ' ' : ''}${locationIdColumnName}`,
      ),
      displayName: layerRelationshipName || 'Location ID',
      idColumn: true,
      columnUsage: ColumnUsage.Link,
      type: 'string',
      description: `The geographical identification of a value in the data set.${
        layer.toObject().spec?.featureDisplayName && locationIdColumnName === 'location_id'
          ? ` In this instance it refers to the ${layer.toObject().spec?.featureDisplayName} ID.`
          : ''
      }`,
      value: (value: MetricValue) => value.getMapFeatureV2()?.getFeatureId(),
      isIncludedInExport: true,
      sourceColumn: 'feature.feature_id.value',
    },
    {
      name: 'location_name',
      displayName: 'Location Name',
      idColumn: false,
      columnUsage: ColumnUsage.Content,
      type: 'string',
      description: 'The name of the location.',
      value: (value: MetricValue) => value.getMapFeatureV2()?.getName(),
      isIncludedInExport: false,
      sourceColumn: 'feature.feature.display_name',
    },
    ...featureColumns,
    ...featureGeoColumns,
    ...relatedFeatureColumns,
    ...dimensionColumns,
    {
      name: 'observation_start_date',
      displayName: 'Observation Start Date',
      idColumn: false,
      columnUsage: ColumnUsage.Content,
      type: 'string (yyyy-mm-dd)',
      description: 'The start date for the period this observation took place.',
      value: (value: MetricValue) => toDateAsIsoString(value.getObservationPeriod()?.getStart()),
      isIncludedInExport: true,
      sourceColumn: 'observation_period.start.value',
    },
    {
      name: 'observation_end_date',
      displayName: 'Observation End Date',
      idColumn: false,
      columnUsage: ColumnUsage.Content,
      type: 'string (yyyy-mm-dd)',
      description: 'The end date for the period this observation took place.',
      value: (value: MetricValue) => toDateAsIsoString(value.getObservationPeriod()?.getEnd()),
      isIncludedInExport: true,
      sourceColumn: 'observation_period.end.value',
    },
    {
      name: toLowerSnakeCase(coreValueSpec?.getName() || 'value'),
      displayName:
        String(coreValueSpec?.getDisplayName()).toUpperCase() ||
        String(coreValueSpec?.getName()).toUpperCase(),
      idColumn: false,
      columnUsage: ColumnUsage.Content,
      type: getTypeBasedOnValueKind(coreValueSpec?.getValueKind()),
      description: coreValueSpec?.getDescription(),
      value: (value: MetricValue) => getValue(coreValueSpec?.getValueKind(), value),
      isIncludedInExport: true,
      sourceColumn: 'metric_value.' + coreValueSpec?.getName() + '.value',
    },
    ...supportingValuesColumns,
  ];
};

export const getRelatedFeatureDetailColumnsFromAddressComponents = (
  relatedLayer: Layer,
  relatedLayerRelationshipName = 'related',
): Column[] => {
  let addressComponentArray: Column[] = [];

  // Sorting on kind to get the Taxonomy kind on top
  const sorted = relatedLayer
    ?.getAddressComponentsList()
    .sort((a, b) => (a.getKind() < b.getKind() ? 1 : b.getKind() < a.getKind() ? -1 : 0));

  addressComponentArray = sorted.reduce((arr: Column[], ac: AddressComponent) => {
    const hasIDCol = [ComponentKind.MAP, ComponentKind.TAXONOMY].includes(ac.getKind());
    if (hasIDCol) {
      arr.push({
        name: toLowerSnakeCase(
          `${relatedLayerRelationshipName}_${
            ac.getShortName() ? ac.getShortName() : ac.getDisplayName()
          }_id`,
        ),
        displayName: `${toTitleCase(relatedLayerRelationshipName)} ${
          ac.getShortName() ? ac.getShortName() : ac.getDisplayName()
        } ID`,
        idColumn: true,
        columnUsage: ColumnUsage.Content,
        type: 'string',
        description: ac.getDescription() && 'ID of ' + ac.getDescription(),
        componentKind: ac.getKind(),
        value: (value: MetricValue) =>
          value
            .getRelatedMapFeature()
            ?.getAddressComponentsList()
            .find((acv) => acv.getComponent() === ac.getComponent())
            ?.getValue(),
        isIncludedInExport: true,
        sourceColumn: 'related_address_components.' + ac.getComponent() + '.value',
      });
    }

    arr.push({
      name: `${relatedLayerRelationshipName}_${toLowerSnakeCase(
        ac.getShortName() ? ac.getShortName() : ac.getDisplayName(),
      )}`,
      displayName: `${toTitleCase(relatedLayerRelationshipName)} ${
        ac.getShortName() ? ac.getShortName() : ac.getDisplayName()
      }`,
      idColumn: false,
      columnUsage: ColumnUsage.Content,
      type: ac.getKind() === ComponentKind.COUNT ? 'int' : 'string',
      componentKind: ac.getKind(),
      description:
        ac.getDescription() && hasIDCol ? ac.getDescription() : 'Name of ' + ac.getDescription(),
      value: (value: MetricValue) => {
        const v = value
          .getRelatedMapFeature()
          ?.getAddressComponentsList()
          .find((acv) => acv.getComponent() === ac.getComponent())
          ?.getDisplayName();
        return ac.getKind() === ComponentKind.COUNT ? v && Number.parseInt(v, 10) : v;
      },
      isIncludedInExport: true,
      sourceColumn:
        'related_address_components.' + ac.getComponent() + (hasIDCol ? '.display_name' : '.value'),
    });

    return arr;
  }, addressComponentArray);

  // Super duper hack to let the new Migration Patterns v3 dataset have their feature id column be location-id
  // while not breaking any existing datasets.
  let relatedLocationIdColumnName = `${
    relatedLayer.toObject().spec?.featureDisplayName || 'location'
  }_id`;

  if (
    addressComponentArray.find(
      (column) =>
        column.name ===
        toLowerSnakeCase(
          `${
            relatedLayerRelationshipName ? relatedLayerRelationshipName + ' ' : ''
          }${relatedLocationIdColumnName}`,
        ),
    )
  ) {
    relatedLocationIdColumnName = 'location_id';
  }

  if (relatedLayerRelationshipName === 'trade_area') {
    relatedLocationIdColumnName = 'location_id';
  }

  if (relatedLayer != null && relatedLayer.getId() !== '') {
    addressComponentArray = [
      {
        name: toLowerSnakeCase(
          `${
            relatedLayerRelationshipName ? relatedLayerRelationshipName + ' ' : ''
          }${relatedLocationIdColumnName}`,
        ),
        displayName: `${relatedLayerRelationshipName}_${
          relatedLayer.toObject().spec?.featureDisplayName || 'Location ID'
        }`,
        idColumn: true,
        columnUsage: ColumnUsage.Content,
        type: 'string',
        description: 'The geographical identification of a related value in the data set.',
        value: (value: MetricValue) => value.getRelatedMapFeature()?.getFeatureId(),
        isIncludedInExport: true,
        sourceColumn: 'related_feature.related_feature_id.value',
      },
      ...addressComponentArray,
    ];
  }

  return addressComponentArray;
};

export const getFeatureDetailColumnsFromAddressComponents = (
  layer: Layer,
  layerRelationshipName?: string,
): Column[] => {
  // Sorting on kind to get the Taxonomy kind on top
  const sorted = layer
    ?.getAddressComponentsList()
    .sort((a, b) => (a.getKind() < b.getKind() ? 1 : b.getKind() < a.getKind() ? -1 : 0));

  return sorted.reduce((arr: Column[], ac: AddressComponent) => {
    const hasIDCol = [ComponentKind.MAP, ComponentKind.TAXONOMY].includes(ac.getKind());
    if (hasIDCol) {
      arr.push({
        name: toLowerSnakeCase(
          `${layerRelationshipName ? layerRelationshipName + '_' : ''}${
            ac.getShortName() ? ac.getShortName() : ac.getDisplayName()
          } ID`,
        ),
        displayName: `${ac.getShortName() ? ac.getShortName() : ac.getDisplayName()} ID`,
        idColumn: true,
        columnUsage: ColumnUsage.Content,
        type: 'string',
        description:
          ac.getDescription() && ac.getIdDisplayName()
            ? `${ac.getDescription()} ${ac.getIdDisplayName()}`
            : 'ID of ' + ac.getDescription(),
        componentKind: ac.getKind(),
        value: (value: MetricValue) =>
          value
            .getMapFeatureV2()
            ?.getAddressComponentsList()
            .find((acv) => acv.getComponent() === ac.getComponent())
            ?.getValue(),
        isIncludedInExport: true,
        sourceColumn: 'address_components.' + ac.getComponent() + '.value',
      });
    }

    arr.push({
      name: toLowerSnakeCase(
        `${layerRelationshipName ? layerRelationshipName + '_' : ''}${
          ac.getShortName() ? ac.getShortName() : ac.getDisplayName()
        }`,
      ),
      displayName: ac.getShortName() ? ac.getShortName() : ac.getDisplayName(),
      idColumn: false,
      columnUsage: ColumnUsage.Content,
      type: ac.getKind() === ComponentKind.COUNT ? 'int' : 'string',
      componentKind: ac.getKind(),
      description:
        ac.getDescription() && hasIDCol ? 'Name of ' + ac.getDescription() : ac.getDescription(),
      isIncludedInExport: true,
      value: (value: MetricValue) => {
        const v = value
          .getMapFeatureV2()
          ?.getAddressComponentsList()
          .find((acv) => acv.getComponent() === ac.getComponent())
          ?.getDisplayName();
        return ac.getKind() === ComponentKind.COUNT ? v && Number.parseInt(v, 10) : v;
      },
      sourceColumn:
        'address_components.' + ac.getComponent() + (hasIDCol ? '.display_name' : '.value'),
    });

    return arr;
  }, []);
};

export const getFeatureGeoColumns = (layer: Layer): Column[] => {
  return [
    {
      name: 'feature_geo',
      displayName: 'Feature Geography',
      idColumn: false,
      columnUsage: ColumnUsage.Content,
      type: 'string',
      description: 'Geographic representation of this feature',
      isIncludedInExport: false,
      getFromFeature: true,
      value: (value: MetricValue) => {
        return value.toObject().mapFeatureV2?.geo;
      },
    },
    {
      name: 'feature_centroid_lat',
      displayName: 'Feature Centroid Latitude',
      idColumn: false,
      columnUsage: ColumnUsage.Content,
      type: 'string',
      description: "Latitude value of this feature's centroid coordinate",
      isIncludedInExport: false,
      getFromFeature: true,
      value: (value: MetricValue) => {
        const geo = value.toObject().mapFeatureV2?.geo;
        return geo ? centroid(wktToGeoJSON(geo)).geometry?.coordinates[1] : '';
      },
    },
    {
      name: 'feature_centroid_lon',
      displayName: 'Feature Centroid Longitude',
      idColumn: false,
      columnUsage: ColumnUsage.Content,
      type: 'string',
      description: "Longitude value of this feature's centroid coordinate",
      isIncludedInExport: false,
      getFromFeature: true,
      value: (value: MetricValue, dimensionValues: DimensionValue[]) => {
        const geo = value.toObject().mapFeatureV2?.geo;
        return geo ? centroid(wktToGeoJSON(geo)).geometry?.coordinates[0] : '';
      },
    },
  ];
};

export const getDimensionColumns = (
  metricId: string | undefined,
  spec: MetricVersionSpec,
  dimensions: Array<Dimension>,
): Column[] => {
  return dimensions.reduce((arr: Column[], dim: Dimension) => {
    let dimName = dim.getDimensionId();

    if (spec.getLayerId() === 'unacast_retail_poi_202302') {
      switch (dim.getDimensionId()) {
        case 'dynamic_trade_areas_type':
          dimName = 'trade_area_type';
          break;
        case 'patterns_hour':
          dimName = 'hour';
          break;
      }
    }
    arr.push({
      name: toLowerSnakeCase(`${dimName} ID`),
      displayName: `${dim.getDisplayName()}`,
      idColumn: true,
      columnUsage: ColumnUsage.Content,
      type: 'string',
      description: dim.getDescription() && 'ID of ' + dim.getDescription(),
      isIncludedInExport: true,
      value: (value: MetricValue) => {
        const dimValue = value
          .getDimensionsList()
          .find((findDimValue) => findDimValue.getDimensionId() === dim.getDimensionId());

        if (dimValue) {
          return dimValue.getValue();
        }

        return '';
      },
      sourceColumn: 'dimension.' + dim.getDimensionId() + '.value',
    });

    arr.push({
      name: toLowerSnakeCase(`${dimName}`),
      displayName: `${dim.getDisplayName()}`,
      idColumn: false,
      columnUsage: ColumnUsage.Content,
      type: 'string',
      description: dim.getDescription() && 'Name of ' + dim.getDescription(),
      isIncludedInExport: true,
      value: (value: MetricValue) => {
        const dimValue = value
          .getDimensionsList()
          .find((findDimValue) => findDimValue.getDimensionId() === dim.getDimensionId());
        if (dimValue) {
          return dimValue.getDisplayName();
        }

        return '';
      },
      sourceColumn: 'dimension.' + dim.getDimensionId() + '.display_name',
    });

    return arr;
  }, []);
};

export const getSupportingValuesColumns = (spec: MetricVersionSpec): Column[] => {
  return spec
    .getValuesList()
    .filter((filterSpec) => filterSpec.getSupportingValue())
    .reduce((arr: Column[], svSpec: ValueSpec) => {
      arr.push({
        name: toLowerSnakeCase(`${svSpec.getName()}`),
        displayName: svSpec.getDisplayName() ? svSpec.getDisplayName() : svSpec.getName(),
        idColumn: true,
        columnUsage: ColumnUsage.Content,
        type: getTypeBasedOnValueKind(svSpec.getValueKind()),
        description: svSpec?.getDescription(),
        isIncludedInExport: true,
        sourceColumn: 'metric_value.' + svSpec.getName() + '.value',
        value: (value: MetricValue) => {
          const svValue = value
            .getSupportingValuesList()
            .find((sv) => sv.getName() === svSpec.getName());

          if (svValue !== undefined) {
            return getValueFromMetricValueValue(svSpec.getValueKind(), svValue);
          }

          return '';
        },
      });

      return arr;
    }, []);
};

export const getOldestValue = (values: MetricValue[]): MetricValue | undefined =>
  minBy(values, (value) => toDateAsIsoString(value.getObservationPeriod()?.getStart()));

export const getLatestValue = (values: MetricValue[]): MetricValue | undefined =>
  maxBy(values, (value) => toDateAsIsoString(value.getObservationPeriod()?.getStart()));

export const toCSV = (
  columns: Column[],
  metricValues: MetricValue[],
  dimensionValues: DimensionValue[],
  features: Map<string, Feature>,
): Record<string, unknown>[] | undefined => {
  if (metricValues.length === 0) {
    return;
  }
  const usedColumnns = columns.filter((c) => c.isIncludedInExport);
  const hasColumnsFromFeature = usedColumnns.some((c) => c.getFromFeature);

  return metricValues.map((value: MetricValue) => {
    if (hasColumnsFromFeature) {
      value
        .getMapFeatureV2()
        ?.setGeo(features.get(value.getMapFeatureV2()?.getFeatureId() || '')?.getGeo() || '');
    }
    return usedColumnns.reduce((row, column) => {
      return { ...row, [column.name]: column.value(value, dimensionValues) };
    }, {});
  });
};

export const toDimensionValue = (
  filter: DimensionFilter,
  dimensionLists: { [p: string]: DimensionValue.AsObject[] },
): DimensionValue => {
  const dimensionValue = new DimensionValue();
  dimensionValue.setDimensionId(filter.getDimensionId());
  dimensionValue.setValue(filter.getValuesList()[0]);
  const displayName = dimensionLists[filter.getDimensionId()].find(
    (dim) => dim.value === dimensionValue.getValue(),
  )?.displayName;
  if (displayName !== undefined) {
    dimensionValue.setDisplayName(displayName);
  }
  return dimensionValue;
};

export const toDimensionValues = (
  selectedDimensionFilter: { [p: string]: DimensionFilter },
  dimensionLists: { [p: string]: DimensionValue.AsObject[] },
): DimensionValue[] => {
  if (
    Object.values(selectedDimensionFilter).length === 0 ||
    Object.values(dimensionLists).length === 0
  ) {
    return [];
  }

  return Object.values(selectedDimensionFilter).map((filter) => {
    return toDimensionValue(filter, dimensionLists);
  });
};

export const getValue = (
  valueKind: ValueKind | undefined,
  row: MetricValue,
): string | number | undefined => {
  const value = row.getValue();
  if (valueKind && value) {
    return getValueFromMetricValueValue(valueKind, value);
  }

  return '';
};

export const getFeatureDisplayName = (value: MetricValue): string => {
  if (value) {
    const displayField = value.getMapFeatureV2()?.getLayerId() || '';

    return (
      value
        .getMapFeatureV2()
        ?.getAddressComponentsList()
        .find((addressComponent) => addressComponent.getComponent() === displayField)
        ?.getDisplayName() ||
      `${value
        .getMapFeatureV2()
        ?.getAddressComponentsList()
        .find((addressComponent) => addressComponent.getComponent().includes('brand'))
        ?.getDisplayName()}, ${value
        .getMapFeatureV2()
        ?.getAddressComponentsList()
        .find((addressComponent) => addressComponent.getComponent().includes('street'))
        ?.getDisplayName()}`
    );
  }
  return '';
};

export const getFeatureDisplayNameByFeature = (feature: Feature.AsObject): string => {
  if (feature) {
    const displayField = feature.layerId || '';

    return (
      feature.addressComponentsList.find(
        (addressComponent) => addressComponent.component === displayField,
      )?.displayName ||
      `${
        feature?.addressComponentsList.find((addressComponent) =>
          addressComponent.component.includes('brand'),
        )?.displayName
      }, ${
        feature?.addressComponentsList.find((addressComponent) =>
          addressComponent.component.includes('street'),
        )?.displayName
      }`
    );
  }
  return '';
};

export const getValueFromMetricValueValue = (
  valueKind: ValueKind,
  row: MetricValue.Value,
): number | string | undefined => {
  switch (valueKind) {
    case ValueKind.NUMBER:
      const numberAsString = row.getNumber().toFixed(4);
      if (numberAsString) {
        return parseFloat(numberAsString);
      }
      return '';
    case ValueKind.COUNT:
      return row.getCount();
    case ValueKind.CATEGORY:
      return row.getCategory();
  }
};

export const getNumberFormatted = (num: number | string | undefined): string => {
  if (num === undefined) return '';
  return new Intl.NumberFormat('en-US').format(Number(num));
};

export const numberWithCommas = (num: number): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
