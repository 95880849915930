import { parse } from 'json2csv';
import { getColumns, toCSV } from '../../mappers/MetricValueMapper';
import { BringYourOwnPOIService, PoiRegion } from '../../unacatInteraction/BringYourOwnPOIService';
import {
  AsyncFetchingHook,
  authDecoratedFetchingHook,
  AuthParams,
  createAsyncFetchingHook,
} from '../../unacatInteraction/fetchingHook';
import { downloadMetricVersionAsCSV, downloadURL } from '../utils/downloadBlob';
import { FileDownloadService } from 'src/unacatInteraction/FileDownloadService';

const useFetchReport = createAsyncFetchingHook(
  async (
    { authHeader, billingAccount }: AuthParams,
    reportId: string,
    region: PoiRegion,
    metricId: string,
  ): Promise<{ csv: string; metricId: string }> => {
    const service = new BringYourOwnPOIService(authHeader, billingAccount);
    if (metricId === '__validations') {
      const { csv } = await service.getReportValidationCSV(reportId);
      return { csv, metricId };
    }
    // TODO: For all metrics when backend supports it
    if (metricId === 'traffic_trends_month' && region === 'international') {
      const { metricVersion, values } = await service.getReportMetricById(reportId, metricId);
      const csv = parse(toCSV(getColumns(metricVersion), values, [], new Map()));
      return { csv, metricId };
    }
    return service.getReportCSVByMetricId(reportId, metricId);
  },
);

const useDownloadMetric = createAsyncFetchingHook(
  async (
    { authHeader, billingAccount }: AuthParams,
    byoMetric: string,
    unacatRef: { catalogId: string; metricId: string },
    fileName: string,
  ): Promise<string[]> => {
    const service = new FileDownloadService(authHeader, billingAccount);
    return service.downloadMetric(byoMetric, unacatRef, fileName);
  },
);

const productionMethodMetricProduce = [
  'foot_traffic_week_202306',
  'foot_traffic_month_202306',
  'foot_traffic_week_202401',
  'foot_traffic_month_202401',
  'trade_areas_quarterly_202309',
  'trade_areas_quarterly_202401',
  'demographics_quarterly_202309',
  'demographics_quarterly_202401',
  'foot_traffic_week_tue_mon_202306',
  'spatialai_personalive_quarterly_202310',
  'spatialai_personalive_quarterly_202401',
  'trade_areas_zip_quarterly',
  'trade_areas_quarterly_202401_r2',
  'trade_areas_zip_quarterly_r2',
  'demographics_quarterly_202401_r2',
  'spatialai_personalive_quarterly_202401_r2',
];

export const useFetchAndDownloadReport = () => {
  const [, fetchReport] = useFetchReportHook();
  const [, downloadMetric] = useDownloadMetricHook();
  const fetchAndDownload = async (reportId, region, byoMetric, unacatRef) => {
    if (productionMethodMetricProduce.includes(byoMetric)) {
      const resp = await downloadMetric(unacatRef.catalogId, unacatRef.metricId, byoMetric);

      if (resp.status === 'succeeded') {
        resp.data.forEach((url, i) => {
          downloadURL(url, byoMetric + '.csv');
        });
      }

      return resp;
    }
    const resp = await fetchReport(reportId, region, byoMetric);
    if (resp.status === 'succeeded') {
      downloadMetricVersionAsCSV(resp.data.csv, resp.data.metricId);
    }
    return resp;
  };
  return fetchAndDownload;
};

type initArgs = [reportId: string, region: PoiRegion, metricId: string];
type ReportGen = AsyncFetchingHook<{ csv: string; metricId: string }, initArgs>;
export const useFetchReportHook: ReportGen = authDecoratedFetchingHook(useFetchReport);
type DownloadGen = AsyncFetchingHook<
  string[],
  [metricId: string, unacatRef: { catalogId: string; metricId: string }, fileName: string]
>;
export const useDownloadMetricHook: DownloadGen = authDecoratedFetchingHook(useDownloadMetric);
