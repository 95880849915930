import styled from 'styled-components';
import { BlackToWhite, Primary } from '@unacast-internal/unacast-ui/StyleGuide/Colors';

export const AuthPageStyled = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .left-pane {
    position: relative;
    background-color: ${Primary.Primary01};
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ul {
      margin-top: 60px !important;
      width: 420px;
      list-style-type: none;
      padding: 0;
      margin: 0;
      color: white;
      .sub-header {
        margin-top: 1.5rem;
        &-text {
          font-weight: 300;
        }
      }
    }
    .use-case-markdown {
      h1 {
        color: ${BlackToWhite.White};
      }
    }

    .bg-svg {
      position: absolute;
      bottom: 0;
      left: 4rem;

      svg {
        position: absolute;
        bottom: 0;
        height: 600px;
        width: 600px;
        transform-origin: bottom left;
        transform: scale(0.7);
        @media (max-height: 1075px) {
          transform: scale(0.5);
        }
        @media (max-height: 900px) {
          transform: scale(0.3);
        }
        @media (max-height: 600px) {
          display: none;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    height: 100%;
    min-height: 100vh;
    width: 50%;
    align-items: center;
    justify-content: center;
  }
`;
