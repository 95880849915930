import styled from 'styled-components';

export const DraftReportStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .card-container {
    margin-right: 2rem;
    margin-top: 2rem;
    width: 30%;
  }
`;
