import { Success, Warning } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { CircularProgress } from '@mui/material';
import { LightTooltip } from '@unacast-internal/unacast-ui/LightTooltip/LightTooltip';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { ReportStatus } from '../../unacatInteraction/BringYourOwnPOIService';

const getToolTipTextByStatus = (status: ReportStatus) => {
  switch (status) {
    case ReportStatus.SUCCEEDED:
      return 'Reports ready for download';
    case ReportStatus.DELAYED:
      return `Report metric production is delayed`;
    case ReportStatus.FAILED:
      return `Report creation failed`;
    case ReportStatus.EXPIRED:
      return 'This report has expired';
    case ReportStatus.PENDING:
      return 'Awaiting creation';
    case ReportStatus.RUNNING:
      return 'Processing your report';
    default:
      return '';
  }
};

type ReportStatusIconProps = { status: ReportStatus; size?: 'large'; tooltipText?: string };
export const ReportStatusIcon = ({ status, size, tooltipText }: ReportStatusIconProps) => {
  const getIcon = () => {
    switch (status) {
      case ReportStatus.SUCCEEDED:
        return (
          <CheckCircleIcon
            style={{ color: Success.Success01 }}
            fontSize={size === 'large' ? 'large' : 'medium'}
          ></CheckCircleIcon>
        );
      case ReportStatus.FAILED:
        return (
          <ErrorIcon
            style={{ color: Warning.Warning01 }}
            fontSize={size === 'large' ? 'large' : 'medium'}
          ></ErrorIcon>
        );
      case ReportStatus.DELAYED:
        return (
          <ErrorIcon
            style={{ color: '#FFA500' }}
            fontSize={size === 'large' ? 'large' : 'medium'}
          ></ErrorIcon>
        );
      case ReportStatus.EXPIRED:
        return (
          <AccessTimeFilledIcon
            style={{ color: Warning.Warning01 }}
            fontSize={size === 'large' ? 'large' : 'medium'}
          ></AccessTimeFilledIcon>
        );
      case ReportStatus.PENDING:
        return <CircularProgress size="1.5rem"></CircularProgress>;
      case ReportStatus.RUNNING:
        return <CircularProgress size="1.5rem"></CircularProgress>;
      default:
        return <UnpublishedIcon></UnpublishedIcon>;
    }
  };
  return (
    <LightTooltip title={tooltipText || getToolTipTextByStatus(status)} placement="top">
      {getIcon()}
    </LightTooltip>
  );
};
