import { Polygon, Circle } from '@react-google-maps/api';
import { Info, Primary, Warning } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import React from 'react';
import { geoJsonToPolygonWithHoles } from '../../helpers/geography';

import { POIFeature } from '../types';

export const PolygonStyle = {
  fillColor: Primary.Primary01,
  fillOpacity: 0.5,
  strokeColor: Primary.Primary01,
  strokeOpacity: 0.7,
};

export const PreviewPolygonStyle = {
  fillColor: Primary.Primary01,
  fillOpacity: 0.3,
  strokeColor: Primary.Primary01,
  strokeOpacity: 0.5,
};

export const EditPolygonStyle = {
  fillColor: Info.Info01,
  fillOpacity: 0.5,
  strokeColor: Info.Info01,
  strokeOpacity: 0.7,
};

export const ErrorPolygonStyle = {
  fillColor: Warning.Warning01,
  fillOpacity: 0.5,
  strokeColor: Warning.Warning01,
  strokeOpacity: 0.7,
};

export const WarningPolygonStyle = {
  fillColor: '#F1C40F',
  fillOpacity: 0.5,
  strokeColor: '#F1C40F',
  strokeOpacity: 0.7,
};

export const statusStyles = {
  warning: WarningPolygonStyle,
  error: ErrorPolygonStyle,
  ok: PolygonStyle,
  edit: EditPolygonStyle,
  preview: PreviewPolygonStyle,
};

type MapPolygonProps = {
  feature: POIFeature;
  onClick?: (e?: google.maps.MapMouseEvent) => void;
  onMouseEnter?: (e?: Event) => void;
  onMouseLeave?: (e?: Event) => void;
  isEditable?: boolean;
  polygonStatus?: 'warning' | 'error' | 'ok' | 'preview';
};

export const GeoJsonToMapShape = ({
  feature,
  onMouseEnter,
  onMouseLeave,
  onClick,
  isEditable,
  polygonStatus = 'ok',
}: MapPolygonProps): React.ReactElement => {
  switch (feature.geometry.type) {
    case 'Polygon':
      return (
        <Polygon
          options={statusStyles[polygonStatus]}
          onClick={onClick}
          paths={geoJsonToPolygonWithHoles(feature.geometry.coordinates)}
          editable={isEditable}
        ></Polygon>
      );
    case 'MultiPolygon':
      return (
        <>
          {feature.geometry.coordinates.map((place, i) => (
            <Polygon
              key={i}
              options={statusStyles[polygonStatus]}
              onClick={onClick}
              paths={geoJsonToPolygonWithHoles(place)}
              editable={isEditable}
            ></Polygon>
          ))}
        </>
      );
    case 'Point':
      return (
        <Circle
          radius={feature.geometry.properties.radius}
          options={statusStyles[polygonStatus]}
          onClick={onClick}
          editable={isEditable}
          center={{ lat: feature.geometry.coordinates[1], lng: feature.geometry.coordinates[0] }}
        ></Circle>
      );
    default:
      return <></>;
  }
};
