import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { TextInput } from '@unacast-internal/unacast-ui/Inputs';
import { Warning } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { Body, Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { MultiSelect } from '../MultiSelect';

const DeliveryModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 380px;
  align-items: center;
  overflow: hidden;

  .error-text {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
  }

  .header-spacing {
    text-align: center;
  }

  .delivery-targets {
    min-width: 250px;
  }

  .input-area {
    width: 80%;
    margin-top: 2rem;
    .spacing {
      margin-bottom: 1.5rem;
    }
    .MuiTextField-root {
      margin-top: 0;
    }
  }

  .delivery-button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
  }

  .metric-selector {
    max-width: 500px;
    width: 80%;

    .position-fix {
      margin-top: -1.5rem;
    }
  }
`;

const DEFAULT_PROJECTID = 'uc-unacat';
const DEFAULT_DATASETID = 'export';

export const DeliveryDetailsModal = ({
  onCancel,
  onConfirm,
  errorText,
  metrics,
}: {
  onCancel: () => void;
  onConfirm: (metrics: string[]) => void;
  errorText?: string;
  metrics: string[];
}) => {
  const [deliveryProjectId, setDeliveryProjectId] = useState<string>(DEFAULT_PROJECTID);
  const [deliveryDatasetId, setDeliveryDatasetId] = useState<string>(DEFAULT_DATASETID);

  const [selectedMetrics, setSeletedMetrics] = useState<string[]>(metrics);

  const projectErrorText = useMemo(() => {
    if (deliveryProjectId.length === 0) {
      return 'Project Id cannot be empty';
    }

    if (deliveryProjectId.includes(' ')) {
      return 'Project Id cannot contain spaces';
    }

    return undefined;
  }, [deliveryProjectId]);

  const datasetErrorText = useMemo(() => {
    if (deliveryDatasetId.length === 0) {
      return 'Project Id cannot be empty';
    }

    if (deliveryDatasetId.includes(' ')) {
      return 'Project Id cannot contain spaces';
    }

    return undefined;
  }, [deliveryDatasetId]);

  const canConfirm = useMemo(
    () => !projectErrorText && !datasetErrorText && selectedMetrics.length > 0,
    [projectErrorText, datasetErrorText, selectedMetrics],
  );

  return (
    <DeliveryModalStyled>
      {errorText && (
        <Body color={Warning.Warning01} type="small01" className="error-text">
          {errorText}
        </Body>
      )}
      <div className="modal-header">
        <Heading type="h2" className="header-spacing">
          Where do you want your report sent?
        </Heading>
      </div>
      <div className="input-area">
        <TextInput
          className="spacing"
          label="Project Id"
          type="text"
          value={deliveryProjectId}
          onChange={(e) => {
            setDeliveryProjectId(e.currentTarget.value);
          }}
          disabled={true}
          error={projectErrorText !== undefined}
          helperText={`Only this project available for now.`}
        ></TextInput>
        <TextInput
          className="spacing"
          label="Dataset Id"
          type="text"
          value={deliveryDatasetId}
          onChange={(e) => {
            setDeliveryDatasetId(e.currentTarget.value);
          }}
          disabled={true}
          helperText={`Only this dataset available for now.`}
          error={!!datasetErrorText}
        ></TextInput>
      </div>
      <div className="metric-selector">
        <MultiSelect
          options={metrics}
          label="Select which metrics you want delivered"
          onChange={(e) => setSeletedMetrics(e.target.value as string[])}
          value={selectedMetrics}
        ></MultiSelect>
        {selectedMetrics.length === 0 && (
          <Body type="small02" color={Warning.Warning01} className="position-fix">
            You must select at least one metric
          </Body>
        )}
      </div>
      <div className="delivery-button">
        <UnaButton styleType="secondary" onClick={onCancel}>
          Cancel
        </UnaButton>
        <UnaButton onClick={() => onConfirm(selectedMetrics)} disabled={!canConfirm}>
          Send
        </UnaButton>
      </div>
    </DeliveryModalStyled>
  );
};
