import React, { useEffect, useMemo, useState } from 'react';
import { useContext } from 'react';
import { DeliverySourceType } from '@unacast-internal/unacat-js/unacast/v2/catalog/data_delivery_service_pb';
import {
  DataListingColumn,
  DataProductListing,
  TargetKind,
  Visibility,
} from '@unacast-internal/unacat-js/unacast/catalog/v1/data_product_listing_pb';
import { AuthContext } from './AuthContext';
import {
  DataListingAccess,
  ListDataProductListingsRequest,
  ListDataProductListingsResponse,
} from '@unacast-internal/unacat-js/unacast/catalog/v1/data_product_listing_service_pb';
import { DataProductListingService } from 'src/unacatInteraction/dataProductListingService.server';
import { SanityBlock, SanityKeyed } from 'sanity-codegen';
import { LeanMetric } from '@unacast-internal/unacat-js/unacast/v2/metric/metric_pb';
import { Industry, Solution } from 'src/helpers/industrySolution';
import { getMetricTags } from 'src/helpers/metricHelpers';
import { useActiveMetrics } from './MetricsContext';
import { useActiveUserProductsForCurentBACC } from './derivedContextValues';
import { useBillingAccountContext } from './BillingAccountContext';
import { SubscriptionAccessKind } from '@unacast-internal/unacat-js/unacast/subscription/v1/subscription_status_pb';

export type DataProduct = {
  name: string;
  layerId?: string;
  displayName: string;
  data: DataSource[];
  access?: DataListingAccess.AsObject;
  defaultStartDate?: string;
  filtersList?: FilterClause[];
  availableFilters?: DataProductListingFilterDefinition[];
  shortDescription?: string;
  description?: Array<SanityKeyed<SanityBlock>>;
  visibility?: Visibility;
};

export type FilterClause = {
  fieldName: string;
  operator: string;
  value: string;
  valuesList: string[];
};

export type DataProductListingFilterDefinition = {
  ref: string;
  label: string;
  kind: TargetKind;
};

export type DataSource = {
  displayName: string;
  name: string;
  deliverySourceId: string;
  deliverySourceType: DeliverySourceType;
  deliveryColumnsList: Array<DataListingColumn.AsObject>;
  catalogId: string;
  visibility: Visibility;
  includedByDefault?: boolean;
  shortDescription?: string;
  description?: Array<SanityKeyed<SanityBlock>>;
  access?: DataListingAccess.AsObject;
};

// Still here to support legacy
type Products = {
  legacyProducts: {
    active: string[];
    available: string[];
  };
  industries: {
    active: Partial<Record<Industry, Set<Solution>>>;
    available: Partial<Record<Industry, Set<Solution>>>;
  };
};

/**
 * @description This function goes through all metrics and creates an Object with all product names and their solutions,
 * these products are separated by state ('active' if the user has a subscription for this product, or  otherwise 'available').
 * Moreover on the industry, we also include the solution array to use it on the front page.
 * */
const getProducts = (
  metrics: LeanMetric[],
  activeUserProducts: Partial<Record<Industry, Solution[]>>,
): Products => {
  const activeProducts = new Set<string>();
  const activeIndustries: Partial<Record<Industry, Set<Solution>>> = {};
  const availableProducts = new Set<string>();
  const availableIndustries: Partial<Record<Industry, Set<Solution>>> = {};
  for (const metric of metrics) {
    const { legacyProducts, industries, solutions } = getMetricTags(metric);

    // TODO: Simplify context-creation as there is no need for a divide between active and available products
    legacyProducts.forEach((el) => {
      // For legacy-produsts it's sufficient to have anything present in the user-objects.
      activeUserProducts[el] ? activeProducts.add(el) : availableProducts.add(el);
    });

    industries.forEach((industry) => {
      // Industry-products are broken into solutions. The industry-solution must be available for the product to count
      const activeSolutions: Solution[] = (activeUserProducts[industry] || []).filter(
        (s: Solution) => solutions.includes(s),
      ); // TODO: Figure what to do with the remaining solutions

      if (activeSolutions.length > 0) {
        if (activeIndustries[industry]) {
          activeSolutions.forEach((s) => activeIndustries[industry]?.add(s));
        } else {
          activeIndustries[industry] = new Set(activeSolutions);
        }
      } else {
        if (availableIndustries[industry]) {
          solutions.forEach((solution) => availableIndustries[industry]?.add(solution));
        } else {
          availableIndustries[industry] = new Set(solutions);
        }
      }
    });
  }
  return {
    legacyProducts: {
      active: Array.from(activeProducts),
      available: Array.from(availableProducts),
    },
    industries: {
      active: activeIndustries,
      available: availableIndustries,
    },
  };
};
export function fromDataProductListingResponseProtoToDataProducts(
  listDataProductListingsResponse: ListDataProductListingsResponse.AsObject,
): DataProduct[] {
  // Convert the array to a Map
  const accessMap = new Map(listDataProductListingsResponse.dataListingAccessesMap);
  return listDataProductListingsResponse.dataProductListingsList.map((dataProduct) =>
    fromDataProductListingProtoToDataProduct(dataProduct, accessMap),
  );
}

export function fromDataProductListingProtoToDataProduct(
  dataProductListing: DataProductListing.AsObject,
  accessMap: Map<string, DataListingAccess.AsObject>,
): DataProduct {
  const description: Array<SanityKeyed<SanityBlock>> =
    dataProductListing.description !== '' ? JSON.parse(dataProductListing.description) : [];

  let highestDataListingAccess: DataListingAccess.AsObject = {
    accessKind: SubscriptionAccessKind.ACCESS_UNSPECIFIED,
  };

  const data: DataSource[] = dataProductListing.dataListingsList.map((dataListing) => {
    const dataListingDescription: Array<SanityKeyed<SanityBlock>> =
      dataListing.description !== '' ? JSON.parse(dataListing.description) : [];
    const resourceShortName = dataProductListing.name + '.' + dataListing.name;
    const dataListingAccess = accessMap.get(resourceShortName);
    if (
      dataListingAccess?.accessKind &&
      dataListingAccess?.accessKind > highestDataListingAccess.accessKind
    ) {
      highestDataListingAccess = dataListingAccess;
    }

    return {
      displayName: dataListing.displayName,
      name: dataListing.name,
      deliverySourceId: dataListing.sourceId,
      deliverySourceType: dataListing.sourceType,
      deliveryColumnsList: dataListing.deliveryColumnsList,
      catalogId: dataListing.catalogId,
      includedByDefault: dataListing.includedByDefault,
      shortDescription: dataListing.shortDescription,
      description: dataListingDescription,
      deliveryColumns: dataListing.deliveryColumnsList || [],
      access: dataListingAccess,
      visibility: dataListing.visibility,
    };
  });

  return {
    name: dataProductListing.name,
    displayName: dataProductListing.displayName,
    layerId: dataProductListing.layerId,
    defaultStartDate: dataProductListing.defaultStartDate,
    data: data,
    filtersList: dataProductListing.filtersList,
    availableFilters: dataProductListing.availableFiltersList,
    shortDescription: dataProductListing.shortDescription,
    description: description,
    visibility: dataProductListing.visibility,
    access: highestDataListingAccess,
  };
}

interface ProductsProps {
  dataProducts: DataProduct[];
  products: Products;
}

const ProductsContext = React.createContext<ProductsProps>({
  dataProducts: [],
  products: getProducts([], {}),
});

export const useDataProducts = (): DataProduct[] => useContext(ProductsContext).dataProducts;

const useFetchDataProducts = (): DataProduct[] => {
  const { authorizationToken } = useContext(AuthContext);
  const { currentBillingAccount } = useBillingAccountContext();

  const [dataProducts, setDataProducts] = useState<DataProduct[]>([]);

  const listAndSetDataProducts = async (at) => {
    const dataProductListingService = new DataProductListingService(at);
    const listDataProductsRequest = new ListDataProductListingsRequest();
    listDataProductsRequest.setBillingContext(currentBillingAccount || '');

    const listDataProductListingsResponse = await dataProductListingService.listDataProductListings(
      listDataProductsRequest.toObject(),
    );

    const dps: DataProduct[] = fromDataProductListingResponseProtoToDataProducts(
      listDataProductListingsResponse.toObject(),
    );
    setDataProducts(dps);
  };

  useEffect(() => {
    if (!authorizationToken) {
      return;
    }
    listAndSetDataProducts(authorizationToken);
  }, [authorizationToken]);

  return dataProducts;
};
export const useAvailableProducts = (): string[] =>
  useContext(ProductsContext).products.legacyProducts.available;

export const useActiveProducts = (): string[] =>
  useContext(ProductsContext).products.legacyProducts.active;

// TODO: Simplify context-creation as there is no need for a divide between active and available products
export const useKnownProducts = (): string[] => {
  const prods = useContext(ProductsContext).products.legacyProducts;
  return [...prods.active, ...prods.available];
};

export const useAvailableIndustries = (): string[] =>
  Object.keys(useContext(ProductsContext).products.industries.available);

export const useActiveIndustries = (): string[] =>
  Object.keys(useContext(ProductsContext).products.industries.active);

export const useAvailableIndustriesWithSolutions = (): Record<string, Set<string>> =>
  useContext(ProductsContext).products.industries.available;

export const useActiveIndustriesWithSolutions = (): Record<string, Set<string>> =>
  useContext(ProductsContext).products.industries.active;

export const ProductsProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const metrics = useActiveMetrics();
  const activeUserProducts = useActiveUserProductsForCurentBACC();
  const products = useMemo(
    () => getProducts(metrics, activeUserProducts),
    [metrics, activeUserProducts],
  );
  const dataProducts = useFetchDataProducts();
  return (
    <ProductsContext.Provider value={{ dataProducts, products }}>
      {children}
    </ProductsContext.Provider>
  );
};
