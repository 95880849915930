import { Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { UnaLinearProgress } from '@unacast-internal/unacast-ui/UnaLinearProgress';
import styled from 'styled-components';
import { BlackToWhite } from '@unacast-internal/unacast-ui/StyleGuide/Colors';

const LoaderStyled = styled(motion.div)`
  z-index: 5;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${BlackToWhite.Black01}50;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  .progress-section {
    text-align: center;
    border-radius: 6px;
    background-color: ${BlackToWhite.White};
    width: 60%;
    box-sizing: border-box;
    padding: 1.5rem;
  }
`;

export const LocationUploaderProgress = ({ show, progressValue }) => {
  return (
    <AnimatePresence>
      {show && (
        <LoaderStyled
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { delay: 0.3 } }}
          className="upload-loader"
        >
          <div className="progress-section">
            <Heading type="h3">Please wait while we upload your locations...</Heading>
            <UnaLinearProgress variant="determinate" value={progressValue}></UnaLinearProgress>
          </div>
        </LoaderStyled>
      )}
    </AnimatePresence>
  );
};
