import {
  BringYourOwnPOIService,
  Draft,
  DraftInfo,
} from '../../unacatInteraction/BringYourOwnPOIService';
import {
  authDecoratedFetchingHook,
  AuthParams,
  createAsyncFetchingHook,
} from '../../unacatInteraction/fetchingHook';
import { parseISO } from 'date-fns';

const useGetDraftReports = createAsyncFetchingHook(
  async ({
    authHeader,
    billingAccount,
  }: AuthParams): Promise<
    {
      id: string;
      info: DraftInfo;
    }[]
  > => {
    const service = new BringYourOwnPOIService(authHeader, billingAccount);
    const drafts = await service.getDraftReportList();
    const parsedDrafts = drafts.map((draft) => {
      return { id: draft.id, info: JSON.parse(draft.info) };
    });

    parsedDrafts.forEach((draft) => {
      draft.info.startDate = parseISO(draft.info.startDate);
      draft.info.endDate = parseISO(draft.info.endDate);
      draft.info.createdTime = parseISO(draft.info.createdTime);
    });
    return parsedDrafts;
  },
);

export const useGetDraftReportsHook = authDecoratedFetchingHook(useGetDraftReports);

const useGetDraftDetails = createAsyncFetchingHook(
  async ({ authHeader, billingAccount }: AuthParams, draftId: string): Promise<Draft> => {
    const service = new BringYourOwnPOIService(authHeader, billingAccount);
    try {
      const draftDetails = await service.getDraftReport(draftId);

      return draftDetails;
    } catch (e) {
      throw e;
    }
  },
);

export const useGetDraftDetailsHook = authDecoratedFetchingHook(useGetDraftDetails);
