import {
  PoiCollectionDetails,
  PoiCollectionService,
  StoredPoiCollection,
} from '../../unacatInteraction/PoiCollectionService';
import {
  authDecoratedFetchingHook,
  AuthParams,
  createAsyncFetchingHook,
} from '../../unacatInteraction/fetchingHook';

const useGetCollections = createAsyncFetchingHook(
  async ({ authHeader, billingAccount }: AuthParams): Promise<PoiCollectionDetails[]> => {
    const service = new PoiCollectionService(authHeader, billingAccount);
    return await service.getCollectionsBasedOnBillingContext(billingAccount);
  },
);

const useGetCollection = createAsyncFetchingHook(
  async (
    { authHeader, billingAccount }: AuthParams,
    collectionId: string,
  ): Promise<StoredPoiCollection> => {
    const service = new PoiCollectionService(authHeader, billingAccount);
    return await service.getPoiCollection(collectionId);
  },
);

export const useGetCollectionsHook = authDecoratedFetchingHook(useGetCollections);
export const useGetCollectionHook = authDecoratedFetchingHook(useGetCollection);
