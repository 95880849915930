import { Body } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { UnacastIcon } from '@unacast-internal/unacast-ui/StyleGuide/UnaIcons/UnacastIcon';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useCurrentUserLoggedInStatus } from '../contexts/UserContext';
import EmailSentImage from '../icons/EmailSentImage';

export const EmailVerificationSentPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px 5rem;

  .info {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

export type LocationState = {
  emailForVerification: string;
};

const EmailVerificationSentPage = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const loggedIn = useCurrentUserLoggedInStatus();
  const who = location.state?.emailForVerification || 'you';

  useEffect(() => {
    if (loggedIn) history.push('/c/rwg');
  }, [loggedIn, history]);

  return (
    <EmailVerificationSentPageStyled>
      <UnacastIcon />
      <EmailSentImage height={200} />
      <div className="info">
        <Body>
          We need you to verify your email. An email will be sent to {who} shortly.
          <br />
          Please check your spam folder if you cannot locate the verification email.
        </Body>
      </div>
    </EmailVerificationSentPageStyled>
  );
};

export default EmailVerificationSentPage;
