import Wkt from 'wicket';
import { area, bbox } from '@turf/turf';
import { latLng, latLngBounds, LatLngBounds } from 'leaflet';
import { Feature, FeatureCollection } from 'geojson';
import { PolygonFromPoint } from '../MadeToOrder/dataManagement/featureConvertion';
import { POICollection, POIFeature, POIGeometry, Position } from '../MadeToOrder/types';
import { Primary } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { LocationRecord } from '../MadeToOrder/dataManagement/poiCollectionStore';
import {
  POIItem,
  POIGeometry as GRPCPOIGeometry,
} from '@unacast-internal/unacat-js/unacast/byo/v1/poi_collection_service_pb';
import { POIError } from '../unacatInteraction/PoiCollectionService';

export const getBounds = (geog: FeatureCollection | Feature): LatLngBounds => {
  const box = bbox(geog);
  return latLngBounds(latLng(box[1], box[0]), latLng(box[3], box[2]));
};

export const getLatLngBoundsForFeatures = (
  fc: FeatureCollection | Feature,
): google.maps.LatLngBounds => {
  const ltlngBounds = getBounds(fc);
  const bounds = new google.maps.LatLngBounds(
    ltlngBounds.getSouthWest(),
    ltlngBounds.getNorthEast(),
  );
  return bounds; // Turf-types for GeoJSON is apparently incompatible with GeoJSON
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const wktToGeoJSON = (geog: string): any => {
  const wkt = new Wkt.Wkt();
  return wkt.read(geog).toJson();
};

export const LatLngToPoint = (lngLat: [number, number]): { x: number; y: number } => {
  const width = 256;
  const height = 256;
  return {
    x: (lngLat[0] + 180) * (width / 360),
    y:
      height / 2 -
      (height * Math.log(Math.tan(Math.PI / 4 + (lngLat[1] * Math.PI) / 180 / 2))) / (2 * Math.PI),
  };
};

export const geoJsonToPolygonWithHoles = (
  polygonWithHoles: Position[][],
): { lat: number; lng: number }[][] => {
  const tempArr: { lat: number; lng: number }[][] = [];
  tempArr.push(polygonWithHoles[0].map((coords) => CoordinateToLatLng(coords)));

  //Any subsequent coordinate arrays signify a hole and thus need to be
  // reversed for google maps to render the polygon correctly

  if (polygonWithHoles.length > 1) {
    polygonWithHoles
      .slice(1)
      .map((arr) => tempArr.push(arr.map((coords) => CoordinateToLatLng(coords)).reverse()));
  }

  return tempArr;
};

export const CoordinateToLatLng = (coord: Position): { lat: number; lng: number } => {
  return {
    lat: coord[1],
    lng: coord[0],
  };
};

export const LatLngToPosition = (latlng: { lat: number; lng: number }): Position => {
  return [latlng.lng, latlng.lat];
};

export const LatLngStringToPosition = (latlng: { lat: string; lon: string }): Position => {
  return [parseFloat(latlng.lon), parseFloat(latlng.lat)];
};

const PolygonStyle = {
  fillColor: Primary.Primary01,
  fillOpacity: 0.5,
  strokeColor: Primary.Primary01,
  strokeOpacity: 0.7,
};

export const getBoundsFromPaths = (paths) => {
  const bounds = new google.maps.LatLngBounds();
  if (paths.length) {
    paths.forEach((path) => path.forEach((point) => bounds.extend(point)));
  } else {
    paths.forEach((point) => bounds.extend(point));
  }
  return bounds;
};

export const wktToGooglePaths = (geo: string) => {
  const wkt = new Wkt.Wkt();
  const jsonGeo = wkt.read(geo).toJson();
  if (jsonGeo.type === 'MultiPolygon') {
    const paths: google.maps.LatLng[][] = [];

    //Does not handle multipolygons with holes
    for (let i = 0; i < jsonGeo.coordinates.length; i++) {
      for (let j = 0; j < jsonGeo.coordinates[i].length; j++) {
        const path: google.maps.LatLng[] = [];
        for (let k = 0; k < jsonGeo.coordinates[i][j].length; k++) {
          const ll = new google.maps.LatLng(
            jsonGeo.coordinates[i][j][k][1],
            jsonGeo.coordinates[i][j][k][0],
          );
          path.push(ll);
        }
        paths.push(path);
      }
    }

    return paths;
  }
  return geoJsonToPolygonWithHoles(jsonGeo.coordinates as Position[][]);
};

export const geoJsonToGooglePath = (jsonGeo: POIGeometry) => {
  if (jsonGeo.type === 'Point') {
    return new google.maps.Circle({
      radius: jsonGeo.properties.radius,
      center: CoordinateToLatLng(jsonGeo.coordinates),
      ...PolygonStyle,
    });
  }
  if (jsonGeo.type === 'MultiPolygon') {
    const paths: google.maps.LatLng[][] = [];

    //Does not handle multipolygons with holes
    for (let i = 0; i < jsonGeo.coordinates.length; i++) {
      for (let j = 0; j < jsonGeo.coordinates[i].length; j++) {
        const path: google.maps.LatLng[] = [];
        for (let k = 0; k < jsonGeo.coordinates[i][j].length; k++) {
          const ll = new google.maps.LatLng(
            jsonGeo.coordinates[i][j][k][1],
            jsonGeo.coordinates[i][j][k][0],
          );
          path.push(ll);
        }
        paths.push(path);
      }
    }

    return new google.maps.Polygon({
      paths: paths,
      ...PolygonStyle,
    });
  }
  return new google.maps.Polygon({
    paths: geoJsonToPolygonWithHoles(jsonGeo.coordinates as Position[][]),
    ...PolygonStyle,
  });
};

export const googleShapeToGeoJson = (
  shape:
    | google.maps.Polygon
    | google.maps.Circle
    | google.maps.Marker
    | google.maps.Polyline
    | google.maps.Rectangle
    | undefined,
): POIGeometry => {
  if (shape instanceof google.maps.Circle) {
    return {
      type: 'Point',
      coordinates: [
        (shape as google.maps.Circle).getCenter()?.lng() || 0,
        (shape as google.maps.Circle).getCenter()?.lat() || 0,
      ],
      properties: {
        radius: (shape as google.maps.Circle).getRadius(),
      },
    };
  }

  const coords: Position[][] = [];

  const mapPath = (shape as google.maps.Polygon)
    .getPath()
    .getArray()
    .map((latlng): Position => {
      return [latlng.lng(), latlng.lat()];
    });

  //First needs to equal last
  if (mapPath[0] !== mapPath[-1]) {
    mapPath.push(mapPath[0]);
  }

  coords.push(mapPath);

  return {
    type: 'Polygon',
    coordinates: coords,
  };
};

const MAX_AREA_SQM_CUSTOM_POI = 5000000;
const MIN_AREA_SQM_CUSTOM_POI = 1000;

export const MAX_RADIUS_CUSTOM_POI = Math.floor(Math.sqrt(MAX_AREA_SQM_CUSTOM_POI / Math.PI));
export const MIN_RADIUS_CUSTOM_POI = Math.ceil(Math.sqrt(MIN_AREA_SQM_CUSTOM_POI / Math.PI));

export const validateGeometryFeatures = (
  features: POIFeature[],
): Record<string, string[]> | undefined => {
  const errors = {};
  features.forEach((feature) => {
    const locationArea = getPOIArea(feature);

    if (locationArea > MAX_AREA_SQM_CUSTOM_POI) {
      const errorText = `Area too large, Max size: ${MAX_AREA_SQM_CUSTOM_POI}m²,you entered: ${locationArea.toFixed()}m²`;
      errors[feature.id] = errors[feature.id] ? [...errors[feature.id], errorText] : [errorText];
    } else if (locationArea < MIN_AREA_SQM_CUSTOM_POI) {
      const errorText = `Area too small, Min size: ${MIN_AREA_SQM_CUSTOM_POI}m², you entered: ${locationArea.toFixed()}m²`;
      errors[feature.id] = errors[feature.id] ? [...errors[feature.id], errorText] : [errorText];
    }
  });

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const validateFeature = (
  id: string,
  feature: POIFeature,
): Record<string, POIError> | undefined => {
  const poiError: POIError = {
    name: feature.properties.name,
    feature: feature,
    message: '',
    severity: 'warning',
  };
  const locationArea = getPOIArea(feature);
  let errorText;

  if (locationArea > MAX_AREA_SQM_CUSTOM_POI) {
    errorText = `Area too large, Max size: ${MAX_AREA_SQM_CUSTOM_POI}m²,you entered: ${locationArea.toFixed()}m²`;
    poiError.severity = 'error';
  } else if (locationArea < MIN_AREA_SQM_CUSTOM_POI) {
    errorText = `Your Area (${locationArea.toFixed()}m²) is smaller than recommended (${MIN_AREA_SQM_CUSTOM_POI}m²)`;
    poiError.severity = 'warning';
  }

  poiError.message = errorText;
  return errorText ? { [id]: poiError } : undefined;
};

export const getPOIArea = (feat: POIFeature) => {
  const localFeat = { ...feat };
  if (localFeat.geometry.type === 'Point') {
    localFeat.geometry = PolygonFromPoint(localFeat.geometry);
  }
  return area(localFeat);
};

export const POICollection_fromLocationRecord = (poi: LocationRecord): POICollection => {
  const collection: POICollection = {
    type: 'FeatureCollection',
    features: Object.values(poi),
  };
  return collection;
};

//-----------------------------------
//-----------To Support goejson from unacast backend
//-----------------------------------

export const POIFeature__fromPOIItem = async (item: POIItem.AsObject): Promise<POIFeature> => {
  const geo = item.geometryData;
  const feature: POIFeature = {
    type: 'Feature',
    id: item.poiId,
    geometry: (geo && POIGeometry__fromGRPCPOIGeometry(geo)) || {
      type: 'Point',
      coordinates: [item.centroidLon, item.centroidLat],
      properties: { radius: 20 },
    },
    properties: { name: item.name, centroid: [item.centroidLon, item.centroidLat] },
  };

  return feature;
};

export const POIGeometry__fromGRPCPOIGeometry = (
  poi: GRPCPOIGeometry.AsObject,
): POIGeometry | undefined => {
  //Check the type of Geometry sent from api (pointWradius | MultiPoly | Poly)
  if (poi.circle) {
    return {
      type: 'Point',
      coordinates: poi.circle.centroid ? LatLngStringToPosition(poi.circle.centroid) : [90, 90],
      properties: { radius: poi.circle.radius },
    };
  } else if (poi.multiPolygon) {
    return {
      type: 'MultiPolygon',
      coordinates: poi.multiPolygon.polygonsList.map((poly) =>
        poly.ringsList.map((ring) =>
          ring.coordinatesList.map((coord) => LatLngStringToPosition(coord)),
        ),
      ),
    };
  } else if (poi.polygon) {
    return {
      type: 'Polygon',
      coordinates: poi.polygon.ringsList.map((ring) =>
        ring.coordinatesList.map((coord) => LatLngStringToPosition(coord)),
      ),
    };
  }
  return undefined;
};
