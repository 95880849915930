import { usePolygonCreationService } from '../../unacatInteraction/services';
import { POIFeature } from '../types';
import { parseAddressCSVFile } from './addressCSVTranslator';
import { initCSV } from './csvOperations';
import { parseGeoJSONFile } from './geoJSONTranslator';
import { parseGeometryCSVFile } from './geometryCSVTranslator';
import { LatLonItem_asPointWithRadius, parseLatLongItemsFromCSVFile } from './latLongCSVTranslator';
import { parseShapeFileToGeoJson } from './shapefileTranslator';

/**
 * To allow the parsers to get input from the user on how to handle missing properties in the
 * data, we're handling cases of known recoverable partial-states explicitly.
 * Parsers will throw errors when they occur inrecoverable states.
 */
type FileTypes = 'geoJson' | 'csv' | 'shapefile' | undefined;
export type ParseResponse =
  | { status: 'error'; type: FileTypes; message: string }
  | { status: 'success'; type: FileTypes; features: POIFeature[] }
  | {
      status: 'partial';
      type: FileTypes;
      missing: 'radius';
      provide: (radius: number) => ParseResponse;
    }
  | {
      status: 'partial';
      type: FileTypes;
      missing: 'latLon_useLookup';
      hasRadius: boolean;
      provide: (useLookup: boolean, radius?: number) => Promise<ParseResponse>;
    };

export const usePOIFileHandler: () => (
  content: ArrayBuffer,
  fileName: string,
) => Promise<ParseResponse> = () => {
  const polygonCreationService = usePolygonCreationService();
  return async (content: ArrayBuffer, fileName: string): Promise<ParseResponse> => {
    const enc = new TextDecoder('utf-8');
    if (fileName.toLowerCase().endsWith('.geojson') || fileName.toLowerCase().endsWith('.json')) {
      const decodeFile = enc.decode(content);
      return parseGeoJSONFile(decodeFile);
    }
    if (fileName.endsWith('.csv')) {
      const decodeFile = enc.decode(content);
      const [headers, lineGen] = initCSV(decodeFile);
      // Lat-lon may use a lookup-service to get parcel data for polygon creation, or just have it be a circle around the point
      if (headers.lat !== undefined && headers.lon !== undefined) {
        return {
          status: 'partial',
          missing: 'latLon_useLookup',
          hasRadius: headers.radius !== undefined,
          provide: async (useLookup: boolean, defaultRadius = 30) => {
            const latLons = parseLatLongItemsFromCSVFile(headers, lineGen);
            if (!useLookup) {
              return {
                status: 'success',
                features: latLons.map((v) => LatLonItem_asPointWithRadius(v, defaultRadius)),
                type: 'csv',
              };
            }
            if (polygonCreationService === null) {
              throw new Error('polygonCreationService is not available');
            }
            try {
              const features = await polygonCreationService.getPolygonsForLatLons(latLons);
              return { status: 'success', features, type: 'csv' };
            } catch (err) {
              return { status: 'error', message: err.message, type: 'csv' };
            }
          },
          type: 'csv',
        };
      }
      if (headers.geometry !== undefined || headers.geom) {
        return parseGeometryCSVFile(headers, lineGen);
      }
      if (headers.address !== undefined) {
        return parseAddressCSVFile(headers, lineGen);
      }
      throw new Error('Unknown CSV-file format');
    }
    if (fileName.endsWith('.shp')) {
      return parseShapeFileToGeoJson(content, fileName);
    }
    throw new Error('Invalid file suffix');
  };
};
