import { TextInput } from '@unacast-internal/unacast-ui/Inputs';
import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { UnaLinearProgress } from '@unacast-internal/unacast-ui/UnaLinearProgress';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { emailPasswordLogin, emailPasswordSignUp } from '../../auth/firebase';
import { CustomLoginContext } from '../../contexts/CustomLoginContext';
import { getIndustrySolutionFromPath } from '../../helpers/industrySolution';
import { LoginEmailStyled } from './LoginEmailStyled';
import ResetPasswordDialog from './ResetPasswordDialog';
import { getAuth, sendEmailVerification } from '@firebase/auth';
import { useQueryState } from 'react-router-use-location-state';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export const emailRegexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const customLoginErrors: Record<string, string> = {
  requiredEmail: 'E-mail is required',
  formatEmail: 'The format of your email is not valid',
  requiredPassword: 'Password is required',
  shortPassword: 'Password is too short, needs at least 8 characters',
  requiredFullName: 'Full name is required',
  requiredCompanyName: 'Company name is required',
};

export type EmailLoginProps = {
  type: 'login' | 'signup';
  onClose: () => void | undefined;
  gotoAfter?: string;
};

const EmailLogin = ({ onClose, type, gotoAfter }: EmailLoginProps): JSX.Element => {
  const {
    inputEmail,
    setInputEmail,
    inputPassword,
    setInputPassword,
    inputFirstName,
    setInputFirstName,
    inputLastName,
    setInputLastName,
    inputCompany,
    setInputCompany,
  } = useContext(CustomLoginContext);
  const [loading, setLoading] = useState(false);
  const [errorEmail, setErrorEmail] = useState<string | null>(null);
  const [errorPassword, setErrorPassword] = useState<string | null>(null);
  const [errorFirstName, setErrorFirstName] = useState<string | null>(null);
  const [errorLastName, setErrorLastName] = useState<string | null>(null);
  const [errorCompanyName, setErrorCompanyName] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState<boolean>(false);
  const history = useHistory();
  const [medium] = useQueryState('utm_medium', '');
  const [source] = useQueryState('utm_source', '');
  const [campaign] = useQueryState('utm_campaign', '');
  const utmFields = {
    medium,
    source,
    campaign,
  };

  const initialProductSetup: { industry: string; solution: string } | undefined = useMemo(
    () => getIndustrySolutionFromPath(gotoAfter || ''),
    [gotoAfter],
  );

  const isSignup = type === 'signup';

  useEffect(() => {
    setEmailError('');
    setErrorCompanyName('');
    setErrorFirstName('');
    setErrorLastName('');
    setErrorPassword(null);
  }, [type]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onInputEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputEmail && setInputEmail(event.target.value);
  };

  const onInputPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputPassword && setInputPassword(event.target.value);
    if (isSignup) validatePassword(event.target.value);
  };

  const onInputFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputFirstName && setInputFirstName(event.target.value);
  };

  const onInputLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputLastName && setInputLastName(event.target.value);
  };

  const onInputCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputCompany && setInputCompany(event.target.value);
  };

  const onBlurEmail = () => {
    validateEmail(inputEmail);
  };

  const setEmailError = (message: string) => {
    setErrorEmail(message);
    setErrorPassword(message);
  };

  const validateEmail = (email: string) => {
    let isValid = false;
    if (email && email.length === 0) {
      setErrorEmail(customLoginErrors['requiredEmail']);
    } else if (!emailRegexp.test(email)) {
      setErrorEmail(customLoginErrors['formatEmail']);
    } else {
      setErrorEmail('');
      isValid = true;
    }
    return isValid;
  };

  const validatePassword = (password: string) => {
    let isValid = false;
    if (password.length === 0) {
      setErrorPassword(customLoginErrors['requiredPassword']);
    } else if (password.length < 8) {
      setErrorPassword(customLoginErrors['shortPassword']);
    } else {
      setErrorPassword(null);
      isValid = true;
    }
    return isValid;
  };

  const epSignUp = async () => {
    const response = await emailPasswordSignUp(
      inputEmail,
      inputPassword,
      inputFirstName,
      inputLastName,
      inputCompany,
      utmFields,
      initialProductSetup,
    );
    setLoading(false);
    if (response.status === 'error') {
      const res = response.error as { code: string };
      switch (res.code) {
        case 'auth/email-already-in-use':
          await epLogin();
          break;
        case 'auth/invalid-email':
          setEmailError(`Email address ${inputEmail} is invalid.`);
          break;
        default:
          setEmailError(`Something went wrong. Please try again.`);
          setErrorPassword(`Something went wrong. Please try again.`);
          setInputPassword('');
          break;
      }
    } else {
      const { currentUser } = getAuth();
      if (currentUser) {
        const didSend = await sendEmailVerification(currentUser, {
          url: window.location.origin + (gotoAfter || '/'),
        }).then(
          () => true,
          () => false,
        );
        history.push({
          pathname: didSend ? '/email-verification-sent' : '/email-verification-failed',
          state: { emailForVerification: currentUser.email },
        });
      } else {
        history.push({ pathname: gotoAfter || '/' });
      }
    }
  };

  const epLogin = async () => {
    await emailPasswordLogin(inputEmail, inputPassword).then((res) => {
      if (res && res.status === 'error') {
        switch (res.error.code) {
          case 'auth/invalid-email':
            setEmailError(`Email address ${inputEmail} is invalid.`);
            break;
          case 'auth/too-many-requests':
            setEmailError(`Too many attempts, try again later.`);
            break;
          case 'auth/wrong-password':
            setEmailError(`Email or password is wrong.`);
            break;
          case 'auth/user-not-found':
            setEmailError(`This user was not found, try signing up first`);
            break;
          default:
            setEmailError(`Something went wrong. Please try again.`);
            setErrorPassword(`Something went wrong. Please try again.`);
            setInputPassword('');
            break;
        }
        setLoading(false);
      } else {
        setInputPassword('');
        setInputEmail('');
        setInputCompany('');
        setInputFirstName('');
        setInputLastName('');
        setLoading(false);
        history.push({ pathname: '/' });
        onClose();
      }
    });
  };

  const onClickMainButton = () => {
    if (validateEmail(inputEmail) && validatePassword(inputPassword)) {
      setLoading(true);
      isSignup ? epSignUp() : epLogin();
    }
  };

  if (loading) return <UnaLinearProgress />;

  return (
    <LoginEmailStyled>
      <div className="min-height-fix">
        <TextInput
          value={inputEmail}
          fullWidth
          label="Email"
          onChange={onInputEmailChange}
          onBlur={() => onBlurEmail()}
          helperText={errorEmail ? errorEmail : ''}
          error={Boolean(errorEmail)}
          type="email"
        />
      </div>
      <div className="min-height-fix">
        <TextInput
          id="password"
          fullWidth
          type={showPassword ? 'text' : 'password'}
          value={inputPassword}
          label={isSignup ? 'Create password' : 'Your password'}
          onChange={onInputPasswordChange}
          helperText={errorPassword ? errorPassword : ''}
          error={Boolean(errorPassword)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      {isSignup && (
        <>
          <div className="min-height-fix">
            <TextInput
              value={inputFirstName}
              fullWidth
              label="First Name"
              onChange={onInputFirstNameChange}
              helperText={errorFirstName ? errorFirstName : ''}
              error={Boolean(errorFirstName)}
              type="text"
            />
          </div>
          <div className="min-height-fix">
            <TextInput
              value={inputLastName}
              fullWidth
              label="Last Name"
              onChange={onInputLastNameChange}
              helperText={errorLastName ? errorLastName : ''}
              error={Boolean(errorLastName)}
              type="text"
            />
          </div>
          <div className="min-height-fix">
            <TextInput
              value={inputCompany}
              fullWidth
              label="Company name"
              onChange={onInputCompanyChange}
              helperText={errorCompanyName ? errorCompanyName : ''}
              error={Boolean(errorCompanyName)}
              type="text"
            />
          </div>
        </>
      )}

      <div className="actions">
        <UnaButton onClick={onClickMainButton} fullWidth={true}>
          {isSignup ? 'Sign up' : 'Log in'}
        </UnaButton>
        {!isSignup && (
          <UnaButton
            styleType="tertiary"
            fullWidth
            onClick={() => setShowResetPasswordDialog(true)}
          >
            Forgot password?
          </UnaButton>
        )}
      </div>

      <ResetPasswordDialog
        open={showResetPasswordDialog}
        email={inputEmail}
        onClose={() => setShowResetPasswordDialog(false)}
        onCloseAll={() => {
          setShowResetPasswordDialog(false);
          onClose();
        }}
      />
    </LoginEmailStyled>
  );
};

export default EmailLogin;
