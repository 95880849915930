import Cookies from 'universal-cookie';
import {
  importExternalScript,
  initializeHotjar,
  removeExternalScript,
} from '../helpers/importExternalScriptHelper';
import CookieConsent from 'react-cookie-consent';
import React from 'react';
import TagManager from 'react-gtm-module';
import styled from 'styled-components';
import { BlackToWhite, Primary } from '@unacast-internal/unacast-ui/StyleGuide/Colors';

const CookieConsentContent = styled.div`
  color: ${BlackToWhite.White};

  .banner {
    background: ${BlackToWhite.Black01};
  }

  .link {
    color: ${Primary.Primary01};
  }

  .accept-button {
    background: ${Primary.Primary01} !important;
    color: ${BlackToWhite.White} !important;
    border-radius: 3px !important;
    font-weight: bolder !important;
  }

  .decline-button {
    background: ${BlackToWhite.Gray03} !important;
    color: ${BlackToWhite.White} !important;
    border-radius: 3px !important;
    font-weight: bolder !important;
  }
`;

const cookies = new Cookies();
let isScriptInjected = false;

const unacastPortalCookieConsent = 'unacastPortalCookieConsent';

const setCookies = (
  googleTagManagerID: string | undefined,
  hubspotResourceScript: string | undefined,
  hotjarId: string | undefined,
) => {
  if (isScriptInjected) return;
  isScriptInjected = true;
  // Setting Google Analytics Tracking
  if (googleTagManagerID) {
    TagManager.initialize({ gtmId: googleTagManagerID });
  }

  // Setting Hubspot Tracking
  hubspotResourceScript && importExternalScript(hubspotResourceScript);

  // Setting Hotjar Tracking
  hotjarId && initializeHotjar(hotjarId);
};

export const isConsentBannerVisible = (): boolean =>
  cookies.get(unacastPortalCookieConsent) === undefined;

const UnaCookieConsent = ({
  googleTagManagerID,
  hubspotResourceScriptPath,
  hotjarId,
}: {
  googleTagManagerID?: string;
  hubspotResourceScriptPath?: string;
  hotjarId?: string;
}): JSX.Element => {
  if (
    cookies.get(unacastPortalCookieConsent) &&
    cookies.get(unacastPortalCookieConsent) === 'true'
  ) {
    setCookies(googleTagManagerID, hubspotResourceScriptPath, hotjarId);
    return <></>;
  }

  return (
    <CookieConsentContent>
      <CookieConsent
        location="bottom"
        buttonText="Accept cookies"
        cookieName={unacastPortalCookieConsent}
        containerClasses={'banner'}
        expires={150}
        enableDeclineButton
        setDeclineCookie={true}
        buttonClasses={'accept-button'}
        declineButtonClasses={'decline-button'}
        declineButtonText={'Decline cookies'}
        onAccept={() => {
          setCookies(googleTagManagerID, hubspotResourceScriptPath, hotjarId);
        }}
        onDecline={() => {
          //Removing Hubspot Tracking
          try {
            hubspotResourceScriptPath && removeExternalScript(hubspotResourceScriptPath);
          } catch (e) {
            // ignoring if the removal of script fails
          }
          console.error('Use of cookies is declined');
        }}
      >
        {`This website uses cookies to improve your user experience. `}
        <br />
        <a
          href="https://www.unacast.com/privacy"
          target="_blank"
          className="link"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
        {` about cookies used by this website.`}
      </CookieConsent>
    </CookieConsentContent>
  );
};

export default UnaCookieConsent;
