import { MoreVert } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import React from 'react';
import { Denim, Primary } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { Body } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import styled from 'styled-components';

const OptionsItemStyled = styled.div`
  height: 60px;
  box-sizing: border-box;
  padding: 1rem;
  display: flex;

  border-radius: 6px;
  justify-content: space-between;
  align-items: center;

  ${(props: { isActive: boolean }) => props.isActive && 'background-color: white;'}

  &:hover {
    background-color: white;
    cursor: pointer;
  }

  .more-options {
    cursor: pointer;
  }
`;

export const OptionsItem = ({
  text,
  isActive = false,
  menuActions,
  onClick,
  icon,
}: {
  text: string;
  isActive?: boolean;
  menuActions?: { [key: string]: () => void };
  onClick?: () => void;
  icon?: React.ReactElement;
}): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <OptionsItemStyled isActive={isActive} onClick={onClick}>
      <Body color={isActive ? Primary.Primary01 : Denim.Denim01}>{text}</Body>
      {icon}
      {menuActions && (
        <>
          <span className="more-options" onClick={handleMenuClick}>
            <MoreVert />
          </span>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {Object.keys(menuActions).map((key) => {
              return (
                <MenuItem
                  onClick={() => {
                    menuActions[key]();
                    handleClose();
                  }}
                  key={key}
                >
                  {key}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      )}
    </OptionsItemStyled>
  );
};
