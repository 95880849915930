import sanityClient from '@sanity/client';
import { useEffect } from 'react';
import {
  Explanation,
  Industry,
  Product,
  SampleFileShowcase,
  Solution,
} from '../../types/SanityContentSchema';

import { AsyncState, createAsyncFetchingHook } from '../../unacatInteraction/fetchingHook';

export type {
  Explanation as ExplanationContent,
  Solution as SolutionContent,
  Product as ProductContent,
};

const cmsClient = sanityClient({
  projectId: '5at45mo0',
  dataset: 'production', //TODO add staging dataset and env
  apiVersion: '2021-11-17', // use current UTC date - see "specifying API version"!
  useCdn: false, // `false` if you want to ensure fresh data
});

export const serializers = {
  types: {
    code: (props) => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
};

const useCmsData = createAsyncFetchingHook((query: string) => cmsClient.fetch(query));
const useCmsDataWithParams = createAsyncFetchingHook(
  (query: string, params: { [key: string]: unknown }) => cmsClient.fetch(query, params),
);
export const useFetchedSolution = createAsyncFetchingHook((industry: string, solution: string) => {
  const query = `*[_type == "industry" && IndustryName == $industry] {solutions}`;

  return cmsClient
    .fetch(query, { industry })
    .then(([row]) => row.solutions.find((sol) => sol.key.includes(solution)));
});

export const useFetchSolution = (
  industry: string,
  solution: string,
): AsyncState<Solution, Error> => {
  const [state, triggerFetch] = useFetchedSolution();

  useEffect(() => {
    if (industry && solution) {
      triggerFetch(industry, solution);
    }
  }, [industry, solution, triggerFetch]);

  return state;
};

//return industry type
export const useFetchIndustries = (): AsyncState<Industry[], Error> => {
  const [state, triggerFetch] = useCmsData();

  const query = `*[_type == "industry"] {solutions, shortDesciption, industryDescription, IndustryName}`;

  useEffect(() => {
    triggerFetch(query);
  }, [query, triggerFetch]);

  return state;
};

export const useFetchProducts = (): AsyncState<Product[], Error> => {
  const [state, triggerFetch] = useCmsData();
  const query = `*[_type == "product" ] {title, key, shortDescription, description, product, slug}`;

  useEffect(() => {
    triggerFetch(query);
  }, [query, triggerFetch]);

  return state;
};

export const useFetchProduct = (productName: string): AsyncState<Product[], Error> => {
  const [state, triggerFetch] = useCmsDataWithParams();
  const query = `*[_type == "product" && key == $productKey] {title, key , shortDescription, description, product, slug}`;

  useEffect(() => {
    const params = { productKey: formatProductName(productName) };
    triggerFetch(query, params);
  }, [productName, query, triggerFetch]);

  return state;
};

export const useFetchExplanations = (): AsyncState<Explanation[], Error> => {
  const [state, triggerFetch] = useCmsData();
  const query = `*[_type == "explanation"] {componentTitle, componentSubtitle, componentTooltip, furtherExplanation}`;

  useEffect(() => {
    triggerFetch(query);
  }, [query, triggerFetch]);

  return state;
};

export const useFetchSampleDocs = (): AsyncState<SampleFileShowcase, Error> => {
  const [state, triggerFetch] = useCmsData();
  const query = `*[_type == "sampleFileShowcase"] {
    fileType,
    fileDescription,
    "SampleFileURL": SampleFile.asset->url
  }`;

  useEffect(() => {
    if (state.status !== 'succeeded') {
      triggerFetch(query);
    }
  }, [query, state.status, triggerFetch]);

  return state;
};

export const formatProductName = (productName: string): string => {
  return productName.toLowerCase().replace(/ /g, '-');
};

export const formatComponentTitle = (componentTitle: string | undefined): string => {
  if (!componentTitle || componentTitle === '') return '';
  return componentTitle
    .split('-')
    .map((t) => t.charAt(0).toUpperCase() + t.substring(1))
    .join(' ');
};
