import { BlackToWhite, Primary } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import styled from 'styled-components';
import { Elevation1 } from '@unacast-internal/unacast-ui/StyleGuide/Elevation';

export const MultiFeatureGoogleMapStyled = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 6px;
  position: relative;
  .map-search {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 4;
  }
  .details-position {
    position: absolute;
    top: 76px;
    left: 16px;
    z-index: 4;
  }

  .search-input-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 2rem;
    align-items: center;
  }

  .search-input {
    width: 300px;
    align-self: start;
  }

  .overflow-actions-container {
    position: absolute;
    top: 1rem;
    right: 1rem;
    ${Elevation1}
    background-color: ${Primary.Primary04};
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }
`;
