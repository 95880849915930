import { BlackToWhite } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const SampleFileModalStyled = styled.span`
  .file-info-icon {
    padding-left: 0.25rem;
    width: 18px;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const FileModalContentStyled = styled(motion.div)`
  width: 600px;
  height: 90vh;
  height: calc(100vh - 5rem);
  max-height: 800px;
  box-sizing: border-box;
  padding: 2rem 1rem;
  .sample-file-info {
    padding: 1rem 2rem;
    background-color: ${BlackToWhite.White};
  }

  .modal-header {
    margin-bottom: 1rem;
  }
  .modal-description {
    margin-bottom: 2rem;
  }

  .content-container {
    position: relative;
  }

  .file-content {
    margin-top: 1.5rem;
    background-color: ${BlackToWhite.Gray05};
    position: relative;
    border-radius: 6px;
    padding: 1rem;
    max-height: 450px;
    overflow-y: scroll;
    word-wrap: normal;
    overflow-x: hidden;
    font-family: 'Courier New', Courier, monospace;
    white-space: pre;

    .react-json-view {
      background-color: ${BlackToWhite.Gray05} !important;
    }
  }
  .download-area {
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${BlackToWhite.White};
    border-bottom-left-radius: 6px;
    width: 34px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 2px rgb(40 41 61 / 4%), 0 4px 4px rgb(80 148 175 / 16%);
    .download-icon {
      font-size: 22px;
      color: ${BlackToWhite.Black01};
    }
  }
`;
