import { useContext } from 'react';
import { DimensionFilter } from '@unacast-internal/unacat-js/unacast/metric/v1/dimension_pb';
import { DimensionContext } from '../contexts/DimensionContext';
import {
  Cadence,
  Metric,
  ValueKind,
} from '@unacast-internal/unacat-js/unacast/metric/v1/metric_pb';
import { capitalize } from '../helpers/capitalize';

export const getValueKindText = (v: ValueKind | undefined): string => {
  switch (v) {
    case ValueKind.NUMBER:
      return 'Number';
    case ValueKind.CATEGORY:
      return 'Category';
    case ValueKind.COUNT:
      return 'Count';
    default:
      return '';
  }
};

export const getCadenceText = (c: Cadence | undefined): string | undefined => {
  switch (c) {
    case Cadence.DAILY:
      return 'Daily';
    case Cadence.WEEKLY:
      return 'Weekly';
    case Cadence.MONTHLY:
      return 'Monthly';
    case Cadence.QUARTERLY:
      return 'Quarterly';
    case Cadence.YEARLY:
      return 'Yearly';
  }
};

export const toTitleCase = (str: string, seperator = '-'): string => {
  if (!str) {
    return '';
  }
  return str.toLowerCase().split(seperator).map(capitalize).join(' ');
};

export function GetDisplayNameByValue(dimId: string, value: string): string | undefined {
  const { dimensionLists } = useContext(DimensionContext);
  return dimensionLists[dimId].find((dim) => dim.value === value)?.displayName;
}

export function dimensionText(metric: Metric, dimensionFilter: DimensionFilter[]): string {
  if (!dimensionFilter || dimensionFilter.length === 0 || !metric) {
    return '';
  }

  const dimensionValuesText = dimensionFilter
    .map(
      (dimValues) =>
        `${getDimensionDisplayName(metric, dimValues.getDimensionId())}: '${dimValues
          .getValuesList()
          .map((value) => {
            return GetDisplayNameByValue(dimValues.getDimensionId(), value);
          })}'`,
    )
    .join(' and ');

  return ` with ${dimensionValuesText}`;
}

export function getDimensionDisplayName(metric: Metric, dimensionId: string): string {
  const dimension = metric.getDimensionsList().find((dim) => dim.getDimensionId() === dimensionId);
  if (dimension) {
    return dimension.getDisplayName();
  }

  return toTitleCase(dimensionId.replace(/_/g, ' '));
}
