import React, { useContext, useEffect, useState } from 'react';
import { getAuthorizationHeaderIfLoggedIn } from '../rpc';
import { useCurrentUserLoggedInStatus } from './UserContext';
interface AuthProps {
  authorizationToken?: string;
  refreshAuthToken: () => void;
  setAuthHeader: (auth: string) => void;
}
export const AuthContext = React.createContext({} as AuthProps);
export const useAuthorizationHeader = (): string | undefined =>
  useContext(AuthContext).authorizationToken;

export const AuthProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const loggedIn = useCurrentUserLoggedInStatus();
  const [authHeader, setAuthHeader] = useState<string>('');

  //TODO: how to check here if the token has recently been refreshed?
  //also how long to we want to keep this alive?
  // -as long as the tab is open?
  // - or do we want it to stop refreshing if the user is inactive? or rather extend the bearer expiration?
  // refreshes every 30min
  useEffect(() => {
    const interval = setInterval(() => {
      refreshAuthToken();
    }, 1800000);

    return () => clearInterval(interval);
  }, []);

  const refreshAuthToken = () => {
    getAuthorizationHeaderIfLoggedIn().then((response) => {
      response && setAuthHeader(response.Authorization);
    });
  };

  useEffect(() => {
    if (!loggedIn) {
      setAuthHeader('');
    }
    refreshAuthToken();
  }, [loggedIn]);

  return (
    <AuthContext.Provider
      value={{
        authorizationToken: authHeader,
        refreshAuthToken: refreshAuthToken,
        setAuthHeader: setAuthHeader,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
