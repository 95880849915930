import { Body, Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import React, { useState } from 'react';
import { ChangeEmailDialogStyled } from './ChangeEmailDialogStyled';
import { Close as CloseIcon } from '@mui/icons-material';
import { Dialog, IconButton, TextField } from '@mui/material';

const ChangeEmailDialog = ({
  open,
  onClose,
  email,
  onCloseAll,
  onSubmitChange,
}: {
  open: boolean;
  onClose: () => void;
  email: string;
  onCloseAll: () => void;
  onSubmitChange: ((newEmail: string) => Promise<void>) | undefined;
}): JSX.Element => {
  const [input, setInput] = useState(email);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const onCloseDialogs = () => {
    setShowSuccess(false);
    setShowError(false);
    onCloseAll();
  };

  const onSubmit = () => {
    onSubmitChange &&
      onSubmitChange(input).then(() => {
        setShowSuccess(true);
      });
  };

  if (showSuccess || showError)
    return (
      <Dialog open={open} onClose={onCloseDialogs}>
        <ChangeEmailDialogStyled>
          {showError && (
            <IconButton
              style={{ position: 'absolute', right: 20 }}
              onClick={onCloseDialogs}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          )}
          <Heading type="h4">{showSuccess ? 'Email Changed!' : 'Email was invalid'}</Heading>
          <Body>
            {showSuccess
              ? 'We’we sent you an email that allows you to revoke the email address change, in order to protect you from account hijacking.'
              : `Your email was invalid, try using another one.`}
          </Body>
          {showSuccess && <UnaButton onClick={onCloseDialogs}>OK</UnaButton>}
        </ChangeEmailDialogStyled>
      </Dialog>
    );

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-update-email">
      <ChangeEmailDialogStyled>
        <Heading type="h4">Want to change your email?</Heading>
        <Body>
          An email will be sent to the original email address (if it was set) that allows to revoke
          the email address change, in order to protect them from account hijacking.
        </Body>
        <TextField
          autoFocus
          required
          value={input}
          margin="dense"
          onChange={(e) => setInput(e.target.value)}
          label="email address"
          type="email"
          variant="outlined"
          fullWidth
        />
        <div className="actions">
          <UnaButton onClick={onSubmit}>Send Update Email</UnaButton>
          <UnaButton onClick={onClose}>Cancel</UnaButton>
        </div>
      </ChangeEmailDialogStyled>
    </Dialog>
  );
};

export default ChangeEmailDialog;
