import { IconButton, TextField } from '@mui/material';
import React, { useState } from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import EditIcon from '@mui/icons-material/Edit';
import { Success, Warning } from '@unacast-internal/unacast-ui/StyleGuide/Colors';

export const TextUpdateField = ({
  defaultValue,
  onSubmitName,
}: {
  defaultValue: string;
  onSubmitName: (val: string) => boolean;
}) => {
  const [canEditName, setCanEditName] = useState<boolean>(false);
  const [value, setValue] = useState<string>(defaultValue);
  const [nameErr, setNameErr] = useState<string>();

  const onClickUpdate = () => {
    if (onSubmitName) {
      if (!onSubmitName(value)) {
        setNameErr('There was an error changing your name');
      } else {
        setCanEditName(false);
        setNameErr(undefined);
      }
    }
  };

  const getButtonColor = () => {
    if (!value || value.trim() === '') return Warning.Warning01;
    if (value === defaultValue) {
      return Success.Success01;
    }
    return '#F1C40F';
  };

  return (
    <TextField
      value={value}
      type="text"
      label={''}
      onChange={(evt) => setValue(evt.currentTarget.value)}
      className="input-width"
      variant="standard"
      error={!!nameErr}
      disabled={!canEditName}
      InputProps={{
        endAdornment: (
          <IconButton
            aria-label="submit-button"
            component="label"
            disabled={canEditName && (!value || value.trim() === '' || value === defaultValue)}
          >
            {canEditName ? (
              <CheckCircleRoundedIcon
                htmlColor={getButtonColor()}
                onClick={onClickUpdate}
              ></CheckCircleRoundedIcon>
            ) : (
              <EditIcon onClick={() => setCanEditName(true)}></EditIcon>
            )}
          </IconButton>
        ),
      }}
    ></TextField>
  );
};
