export const apiUrl = (): string => {
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  }

  //return 'https://data-portal-api.unacastapis.com/api/';
  return 'http://localhost:8080/api/';
};

export const domainUrl = (): string => {
  if (process.env.REACT_APP_DOMAIN) {
    return process.env.REACT_APP_DOMAIN;
  }

  return 'http://localhost:3000';
};

export const contentUrl = (): string => {
  return 'https://raw.githubusercontent.com/unacast/rwg-content/main';
};

export const isProd = (): boolean => {
  return Boolean(process.env.REACT_APP_ENV === 'prod');
};

export const getCurrentEnv = (): string => process.env.REACT_APP_ENV || 'local';

export const SESSION_TOKEN_KEY = 'unacast-data-portal-session-key';
