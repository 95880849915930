import { postExternal } from './ajax';
import { errorHandler } from '../helpers/errorHandler';

const submitHubspotForm = async (
  portalID: string,
  formID: string,
  fieldMap: Record<string, string>,
): Promise<JSON | null> => {
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalID}/${formID}`;

  const fields = Object.entries(fieldMap).map(([name, value]) => ({
    objectTypeId: '0-1',
    name: name,
    value: value,
  }));

  //to extract hutk and add to formBody - https://community.hubspot.com/t5/APIs-Integrations/400-error-when-submit-form-with-an-empty-hutk/m-p/529635
  const hutk = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1');

  const formBody = {
    fields: fields,
    legalConsentOptions: {
      // Include this object when GDPR options are enabled
      consent: {
        consentToProcess: true,
        text: 'I agree to allow Unacast to store and process my personal data.',
        communications: [
          {
            value: false, //set it to false unless we implement a checkbox agreing for marketing consent or picked from cookies
            subscriptionTypeId: 999,
            text: 'I agree to receive marketing communications from Unacast.',
          },
        ],
      },
    },
  };

  if (hutk && !/^\s*$/.test(hutk)) {
    formBody['context'] = {
      hutk: hutk, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
      pageUri: 'unacast.com',
      pageName: 'Unacast website',
    };
  }

  try {
    return await postExternal(url, formBody);
  } catch (error) {
    console.error('submitHubspotForm -> error', error);
    errorHandler(error);
    return null;
  }
};

export const submitNewUserForm = (
  email: string,
  firstName: string,
  lastName: string,
  company: string,
  utm_source: string,
  utm_medium: string,
  utm_campaign: string,
): Promise<JSON | null> => {
  const fieldMap = {
    email,
    firstName,
    lastName,
    company,
    utm_source,
    utm_medium,
    utm_campaign,
    accessed_data_portal: 'true',
  };
  // Sending to 474803, the new UnacastGravy-portal
  return submitHubspotForm('474803', 'a912f1d0-aeaf-4097-ae23-205681ec29bd', fieldMap);
};

export const submitContactUsForm = (
  email: string,
  firstname: string,
  company: string,
  comments: string,
  leadsource: string,
): Promise<JSON | null> => {
  const fieldMap = { email, firstname, company, comments, leadsource };
  // Sending to 2931123 which is the old Unacast-portal (should change when re-activated?)
  return submitHubspotForm('2931123', 'f638adf1-3289-4239-bb69-81bdef87330b', fieldMap);
};

export const submitFeedbackForm = (
  rating: string,
  feedback: string,
  email: string,
  firstname: string,
  company: string,
  comments = '',
): Promise<unknown> => {
  const fieldMap = { email, firstname, company, rating, feedback, comments };
  // Sending to 2931123 which is the old Unacast-portal (should change when re-activated?)
  return submitHubspotForm('2931123', 'be7a98a6-2882-437a-8b75-51fb2a95ba5f', fieldMap);
};
