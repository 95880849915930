import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import React, { useEffect, useState } from 'react';
import { useMadeToOrderNavigation } from '../utils/madeToOrderNavigation';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import { AnimatePresence, motion } from 'framer-motion';
import { useGetCollectionsHook } from '../dataManagement/useGetCollections';
import { UnaLinearProgress } from '@unacast-internal/unacast-ui/UnaLinearProgress';
import { CollectionCard } from '../components/CollectionCard/CollectionCard';
import { useSnackbar } from 'notistack';
import { PoiCollectionDetails } from '../../unacatInteraction/PoiCollectionService';
import { UnaAlert } from '@unacast-internal/unacast-ui/UnaAlert';
import { useBreadCrumbs } from '../../contexts/BreadCrumbContext';

const CollectionsPageStyled = styled(motion.div)`
  .header-row {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .center-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media only screen and (max-width: 1200px) {
      & {
        justify-content: space-evenly;
      }
    }
  }

  .card-container {
    margin-top: 2rem;
    width: 30%;
    margin-right: 5%;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 1200px) {
    .card-container {
      margin-top: 2rem;
      width: 45%;
      margin-right: 0;
      /* &:nth-child(2n) {
        margin-right: 0;
      } */
    }
  }

  @media only screen and (max-width: 960px) {
    .card-container {
      margin-top: 2rem;
      width: 100%;
      margin-right: 0;
    }
  }
`;

export const CollectionsPage = () => {
  const navTo = useMadeToOrderNavigation();
  const { setCrumbs } = useBreadCrumbs();
  const [collectionsState, getCollectionsList] = useGetCollectionsHook();
  const [collections, setCollections] = useState<PoiCollectionDetails[]>();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setCrumbs([
      { text: 'Home', url: '/' },
      { text: 'Data', url: '/c/rwg' },
      { text: 'Custom Locations', url: '/made-to-order' },
      { text: 'Collections', url: '/made-to-order/collections' },
    ]);
  }, [setCrumbs]);

  useEffect(() => {
    if (collectionsState.status === 'initial') {
      getCollectionsList();
    } else if (collectionsState.status === 'succeeded') {
      setCollections(collectionsState.data);
    } else if (collectionsState.status === 'failed') {
      enqueueSnackbar(collectionsState.error.message, {
        variant: 'error',
      });
    }
  }, [collectionsState, enqueueSnackbar, getCollectionsList]);

  return (
    <CollectionsPageStyled
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.3, type: 'easeIn' } }}
    >
      <UnaAlert title="This page and features are still experimental" severity="warning"></UnaAlert>

      <div className="header-row">
        <Heading type="h3">Collections</Heading>
        <UnaButton
          icon={<AddIcon></AddIcon>}
          onClick={() => navTo.newCollection()}
          className="add-button"
        >
          Start New Collection
        </UnaButton>
      </div>
      <div className="center-list">
        <AnimatePresence>
          {collections ? (
            collections
              .sort((c1, c2) => c2.createdTime.getTime() - c1.createdTime.getTime())
              .map((c) => (
                <motion.div
                  key={c.id}
                  layout
                  className="card-container"
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0, transition: { delay: 0.3, type: 'easeIn' } }}
                  exit={{ opacity: 0 }}
                  transition={{
                    type: 'easeIn',
                  }}
                >
                  <CollectionCard
                    name={c.name}
                    id={c.id}
                    itemCount={c.itemCount}
                    createdBy={c.createdBy}
                    createdTime={c.createdTime || new Date()}
                    cardAction={(id) => id && navTo.collectionDetails(id)}
                  ></CollectionCard>
                </motion.div>
              ))
          ) : (
            <UnaLinearProgress></UnaLinearProgress>
          )}
        </AnimatePresence>
      </div>
    </CollectionsPageStyled>
  );
};
