import { Market, POIFeature } from './../MadeToOrder/types';
import {
  AddPoisBasedOnGeometryRequest,
  AddPoisResponse,
  Address,
  AddAddressResponse,
  AddPoisBasedOnAddressRequest,
  CreateNewCollectionRequest,
  GetCollectionRequest,
  GetCollectionResponse,
  GetPOICollectionRequest,
  GetPOICollectionResponse,
  GetPOIItemRequest,
  NewBasedOnGeometry,
  PoiCollectionData,
  POIItem,
  RemovePoiRequest,
  UpdatePoiRequest,
  DeleteCollectionRequest,
  PublishCollectionVersionResponse,
  PublishCollectionVersionRequest,
  CollectionAsGeoJSONRequest,
} from '@unacast-internal/unacat-js/unacast/byo/v1/poi_collection_service_pb';
import { getPoiCollectionServicePromiseClient } from './../rpc/index';
import { convertToRegion } from './BringYourOwnPOIService';
import { POIGeometry_asGRPCPOIGeometry } from '../MadeToOrder/dataManagement/featureConvertion';

export type PoiCollectionDetails = {
  id: string;
  createdBy: string;
  name: string;
  createdTime: Date;
  lastPublishTime?: Date;
  itemCount: number;
};

export type StoredPoiCollection = {
  details: PoiCollectionDetails;
  featureCollection: POIItem.AsObject[];
};

export type POIError = {
  name: string;
  message: string;
  severity: 'warning' | 'error';
  feature?: POIFeature;
};

const PoiCollectionDetails__fromPoiCollectionData = (
  collectionDeets: PoiCollectionData,
): PoiCollectionDetails => {
  return {
    id: collectionDeets.getCollectionId(),
    createdBy: collectionDeets.getCreatedBy(),
    name: collectionDeets.getName(),
    createdTime: new Date(collectionDeets.getCreatedTime() * 1000),
    lastPublishTime: new Date(collectionDeets.getLastPublishTime() * 1000),
    itemCount: collectionDeets.getItemCount(),
  };
};

const StoredPoiCollection__fromGetPOICollectionResponse = (
  resp: GetPOICollectionResponse,
): StoredPoiCollection | undefined => {
  const collectionDetails = resp.getCollection();
  if (collectionDetails) {
    const details = PoiCollectionDetails__fromPoiCollectionData(collectionDetails);
    return {
      details: details,
      featureCollection: resp.getItemsList().map((poi) => poi.toObject()),
    };
  }
  return undefined;
};

/**
 * The POI Collection Service handles adapting the grpc endpoints to
 * accept and return internal javascript objects
 */
export class PoiCollectionService {
  constructor(private authHeader: string, private billingAccountID: string) {}

  private getHeaders = () => ({ Authorization: this.authHeader });

  public async createNewCollection(
    name: string,
    market: Market,
  ): Promise<{ collectionId: string }> {
    const poiService = getPoiCollectionServicePromiseClient();
    const collRequest = new CreateNewCollectionRequest()
      .setBillingContext(this.billingAccountID)
      .setName(name)
      .setRegion(convertToRegion(market));
    const response = await poiService.createNewCollection(collRequest, this.getHeaders());

    return { collectionId: response.getCollectionId() };
  }

  public async collectionAsGeoJSON(collectionId: string): Promise<{ collectionJson: string }> {
    const poiService = getPoiCollectionServicePromiseClient();
    const collJsonRequest = new CollectionAsGeoJSONRequest()
      .setBillingContext(this.billingAccountID)
      .setCollectionId(collectionId);
    const response = await poiService.collectionAsGeoJSON(collJsonRequest, this.getHeaders());

    return { collectionJson: new TextDecoder().decode(response.getGeoJson() as Uint8Array) };
  }

  public async addPoisBasedOnGeometry(
    collectionId: string,
    pois: NewBasedOnGeometry[],
  ): Promise<AddPoisResponse> {
    const poiService = getPoiCollectionServicePromiseClient();
    const addGeoRequest = new AddPoisBasedOnGeometryRequest()
      .setBillingContext(this.billingAccountID)
      .setCollectionId(collectionId)
      .setPoisList(pois);
    try {
      const response = await poiService
        .addPoisBasedOnGeometry(addGeoRequest, this.getHeaders())
        .catch((err) => {
          throw err;
        });
      return response;
    } catch (err) {
      throw err;
    }
  }

  public async ingestAddresses(collectionId: string, addresses: Address[]): Promise<void> {
    const poiService = getPoiCollectionServicePromiseClient();
    const request = new AddPoisBasedOnAddressRequest()
      .setBillingContext(this.billingAccountID)
      .setCollectionId(collectionId)
      .setAddressesList(addresses);
    await poiService.ingestAddresses(request, this.getHeaders());
  }

  //receive internal object and return internal object
  // public async addPoisBasedOnLatLon(
  //   collectionId: string,
  //   pois: NewBasedOnLatLon[],
  //   fallbackRadius?: number,
  // ): Promise<AddPoisResponse> {
  //   const poiService = getPoiCollectionServicePromiseClient();
  //   const addLatLngRequest = new AddPoisBasedOnLatLonRequest()
  //     .setCollectionId(collectionId)
  //     .setPoisList(pois);
  //   fallbackRadius && addLatLngRequest.setFallbackRadius(fallbackRadius);
  //   const response = await poiService.addPoisBasedOnLatLon(addLatLngRequest, this.getHeaders());

  //   return response;
  // }

  //create local object based on PoiColectionDetails
  public async getCollectionsBasedOnBillingContext(
    billingAccount?: string,
  ): Promise<PoiCollectionDetails[]> {
    const poiService = getPoiCollectionServicePromiseClient();
    const collRequest = new GetCollectionRequest().setBillingContext(
      billingAccount || this.billingAccountID,
    );
    const response: GetCollectionResponse = await poiService.getCollectionsBasedOnBillingContext(
      collRequest,
      this.getHeaders(),
    );

    return response
      .getCollectionsList()
      .map((collectionDeets: PoiCollectionData) =>
        PoiCollectionDetails__fromPoiCollectionData(collectionDeets),
      );
  }

  public async getPoiItem(collectionId: string, poiId: string): Promise<POIItem> {
    const poiService = getPoiCollectionServicePromiseClient();
    const poiItemRequest = new GetPOIItemRequest()
      .setBillingContext(this.billingAccountID)
      .setPoiId(poiId)
      .setCollectionId(collectionId);
    const response = await poiService.getPOIItem(poiItemRequest, this.getHeaders());

    return response;
  }

  public async getPoiCollection(collectionId: string): Promise<StoredPoiCollection> {
    const poiService = getPoiCollectionServicePromiseClient();
    const poiCollectionRequest = new GetPOICollectionRequest()
      .setBillingContext(this.billingAccountID)
      .setCollectionId(collectionId);

    const response = await poiService.getPOICollection(poiCollectionRequest, this.getHeaders());
    const convertedColletion = StoredPoiCollection__fromGetPOICollectionResponse(response);
    if (convertedColletion) {
      return convertedColletion;
    }

    throw new Error('Failed retreiving collection details');
  }

  //temp response type just to handle success
  public async removePoi(collectionId: string, poiId: string): Promise<boolean> {
    const poiService = getPoiCollectionServicePromiseClient();
    const removePoiRequest = new RemovePoiRequest()
      .setBillingContext(this.billingAccountID)
      .setCollectionId(collectionId)
      .setPoiId(poiId);
    try {
      await poiService.removePoi(removePoiRequest, this.getHeaders());
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  public async updatePoi(
    collectionId: string,
    poiId: string,
    updatedFeature: POIFeature,
  ): Promise<void> {
    const poiService = getPoiCollectionServicePromiseClient();
    const updatePoiRequest = new UpdatePoiRequest()
      .setBillingContext(this.billingAccountID)
      .setCollectionId(collectionId)
      .setPoiId(poiId)
      .setName(updatedFeature.properties.name)
      .setGeometry(POIGeometry_asGRPCPOIGeometry(updatedFeature.geometry));
    await poiService.updatePoi(updatePoiRequest, this.getHeaders());
  }

  public async deleteCollection(collectionId: string): Promise<void> {
    const poiService = getPoiCollectionServicePromiseClient();
    const deleteCollectionRequest = new DeleteCollectionRequest()
      .setBillingContext(this.billingAccountID)
      .setCollectionId(collectionId);
    await poiService.deleteCollection(deleteCollectionRequest, this.getHeaders());
  }

  public async addPoisBasedOnAddress(
    collectionId: string,
    addresses: Address[],
  ): Promise<AddAddressResponse> {
    const poiService = getPoiCollectionServicePromiseClient();
    const addAddressRequest = new AddPoisBasedOnAddressRequest()
      .setBillingContext(this.billingAccountID)
      .setAddressesList(addresses)
      .setCollectionId(collectionId);
    return await poiService.addPoisBasedOnAddress(addAddressRequest, this.getHeaders());
  }

  public async publishCollectionVersion(
    collectionId: string,
  ): Promise<PublishCollectionVersionResponse.AsObject> {
    const poiService = getPoiCollectionServicePromiseClient();
    const publishCollectionVersionRequest = new PublishCollectionVersionRequest()
      .setBillingContext(this.billingAccountID)
      .setCollectionId(collectionId);

    const response = await poiService.publishCollectionVersion(
      publishCollectionVersionRequest,
      this.getHeaders(),
    );

    return response.toObject();
  }
}
