import { StatusCode } from 'grpc-web';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { errorHandler } from '../helpers/errorHandler';

interface ErrorProps {
  logError: (error: Error, showError: boolean, errorInfo?: string) => void;
}
export const ErrorHandlerContext = React.createContext({} as ErrorProps);
export const ErrorHandlerContextConsumer = ErrorHandlerContext.Consumer;

export const ErrorHandlerProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const logError = (error: Error, showError: boolean, errorInfo?: string) => {
    switch (StatusCode[error.name]) {
      case StatusCode.UNAUTHENTICATED:
        history.push('/inactive');
        break;
      case StatusCode.PERMISSION_DENIED:
        history.push('/page-not-found');
        break;
      default:
        break;
    }

    errorHandler(error, errorInfo);
    showError && enqueueSnackbar(error.message, { variant: 'error' });
  };

  return (
    <ErrorHandlerContext.Provider value={{ logError: logError }}>
      {children}
    </ErrorHandlerContext.Provider>
  );
};
