import { BlackToWhite, Navy } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import styled from 'styled-components';
import { Elevation2 } from '@unacast-internal/unacast-ui/StyleGuide/Elevation';

export const CollectionDetailsCardStyled = styled.div`
  padding: 2rem;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: ${BlackToWhite.White};
  ${Elevation2}
  min-width: 275px;

  .action-prompt {
    display: flex;
    flex-direction: column;
    align-items: center;
    .prompt-spacing {
      margin-bottom: 1rem;
    }
  }

  .title-with-icon {
    display: flex;
    flex-direction: row;
  }

  .top-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .title-space {
    overflow-wrap: break-word;
    display: inline-block;
    word-break: break-word;
  }

  .details-item {
    margin-top: 0.75rem;
  }

  .map-preview-container {
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    margin-top: 1rem;
    .edit-button {
      position: absolute;
      top: 1rem;
      right: 1rem;
      ${Elevation2}
    }
  }

  .action-area {
    display: flex;
    align-self: center;
    &__button {
      background-color: transparent;
      border: 0;
      padding: 0 1rem;
      &:not(:first-child) {
        border-left: 1px solid ${BlackToWhite.Gray04};
      }
      cursor: pointer;
      .action_text {
        color: ${Navy.Navy05};
        text-decoration: underline;
      }
      &:disabled {
        cursor: default;
        .action_text {
          color: ${BlackToWhite.Gray04};
          text-decoration: none;
        }
      }
    }
  }
`;
