import {
  AddPoisResponse,
  Address,
  NewBasedOnGeometry,
} from '@unacast-internal/unacat-js/unacast/byo/v1/poi_collection_service_pb';
import { Feature_asNewBasedOnGeometry } from '../../dataManagement/featureConvertion';
import { POIFeature } from '../../types';
import { PoiCollectionService } from '../../../unacatInteraction/PoiCollectionService';

import { useCallback, useContext } from 'react';
import { AuthContext } from '../../../contexts/AuthContext';
import { useCurrentBillingAccount } from '../../../contexts/BillingAccountContext';

export const usePOIManagementServices = () => {
  const { authorizationToken } = useContext(AuthContext);
  const billingAccount = useCurrentBillingAccount();

  const addPOI = async (
    collectionId: string,
    poiFeature: POIFeature,
  ): Promise<AddPoisResponse | undefined> => {
    if (!authorizationToken || !billingAccount) {
      return;
    }

    const newItem = Feature_asNewBasedOnGeometry(poiFeature);
    if (!newItem) {
      return;
    }

    const service = new PoiCollectionService(authorizationToken, billingAccount);
    return await service.addPoisBasedOnGeometry(collectionId, [newItem]);
  };

  const addAddresses = async (
    collectionId: string,
    addresses: Array<{ name: string; address: string }>,
  ) => {
    if (!authorizationToken || !billingAccount || addresses.length === 0) {
      return;
    }

    const newItems = addresses.map((a) => new Address().setName(a.name).setAddress(a.address));

    const service = new PoiCollectionService(authorizationToken, billingAccount);
    return await service.ingestAddresses(collectionId, newItems);
  };

  const addPOIs = async (
    collectionId: string,
    poiFeatures: POIFeature[],
  ): Promise<AddPoisResponse | undefined> => {
    if (!authorizationToken || !billingAccount || poiFeatures.length === 0) {
      return;
    }

    let parseError = false;

    const newItems = poiFeatures.map((poi) => {
      const convertedItem = Feature_asNewBasedOnGeometry(poi);
      if (convertedItem) {
        return convertedItem;
      }

      //when the conversion method returns undefined, it indicates that an error
      //occurred during the conversion
      parseError = true;
      return new NewBasedOnGeometry();
    });
    //So return undefined to indicate that the array conversion failed
    if (parseError) {
      return;
    }

    const service = new PoiCollectionService(authorizationToken, billingAccount);
    return await service.addPoisBasedOnGeometry(collectionId, newItems).catch((err) => {
      throw err;
    });
  };

  const getPOIItem = useCallback(
    async (collectionId, poiID) => {
      if (!authorizationToken || !billingAccount) {
        return;
      }
      const service = new PoiCollectionService(authorizationToken, billingAccount);
      return await service.getPoiItem(collectionId, poiID);
    },
    [authorizationToken, billingAccount],
  );

  const deletePOIItem = async (collectionId, poiId: string[]) => {
    if (!authorizationToken || !billingAccount) {
      return;
    }
    const service = new PoiCollectionService(authorizationToken, billingAccount);
    await Promise.all(poiId.map((id: string) => service.removePoi(collectionId, id)));
  };

  const updatePOIItem = async (collectionId, poiID, feature: POIFeature) => {
    if (!authorizationToken || !billingAccount) {
      return;
    }
    const service = new PoiCollectionService(authorizationToken, billingAccount);
    await service.updatePoi(collectionId, poiID, feature);
  };

  return { addPOI, addPOIs, addAddresses, getPOIItem, deletePOIItem, updatePOIItem };
};
