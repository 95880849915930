import styled from 'styled-components';

export const CollectionCardStyled = styled.div`
  box-sizing: border-box;

  min-width: 275px;
  overflow: hidden;

  .title-with-icon {
    display: flex;
    flex-direction: row;
  }

  .top-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .report-details {
    padding-left: 0.25rem;
    .details-item {
      margin-top: 0.75rem;
    }
    .details-count {
      margin-top: 0.5rem;
    }
  }

  .title-space {
    margin-left: 0.5rem;
    overflow-wrap: break-word;
    display: inline-block;
    word-break: break-word;
  }

  .collection-preview-items {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
`;
