import { Navy } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import styled from 'styled-components';
import { Elevation1 } from '@unacast-internal/unacast-ui/StyleGuide/Elevation';

export const POIManagementMapStyled = styled.div`
  .format-info-link {
    width: 100%;
    display: flex;
    cursor: pointer;
    margin-top: 1rem;
    .highlight-button {
      ${Elevation1}
      margin-bottom: .5rem;
    }
  }
  .map-with-errors {
    display: flex;
    height: 40rem;

    .error-header {
      margin-bottom: 0.75rem;
    }

    .error-area {
      padding-left: 1rem;
    }

    .error-list {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      min-width: 200px;
      box-sizing: border-box;
      height: 100%;
      padding-left: 1rem;
      padding-right: 0.5rem;
    }
  }
  .temp-map-area {
    margin-bottom: 2rem;
    width: 100%;
    position: relative;

    .map-search {
      position: absolute;
      top: 1rem;
      left: 1rem;
      z-index: 4;
    }

    .map-add-button {
      position: absolute;
      top: 1rem;
      right: 68px;
      display: flex;
      & > *:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
`;
