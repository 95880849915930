import React, { createContext, useContext, useMemo, useState } from 'react';
import { POIFeature } from '../types';

export type Location = POIFeature & { id: string };
export type LocationRecord = Record<string, Location>;

export type CollectionDict = { [id: string]: LocationRecord | undefined };
export type CollectionUpdater = (
  collectionID: string,
  updateFunc: (c: Readonly<LocationRecord>) => LocationRecord | undefined,
) => void;

export type CollectionDeleter = (collectionID: string) => void;

const POICollectionStoreContext = createContext<[CollectionDict, CollectionUpdater]>([
  {},
  () => undefined,
]);

export const POICollectionStoreProvider: React.FC = ({ children }) => {
  //hook to get list of collections for billing account
  const [collections, setCollections] = useState<CollectionDict>({});
  const updateCollection = useMemo(
    (): CollectionUpdater => (collectionID, updateFunc) => {
      setCollections((values) => {
        const { [collectionID]: current, ...rest } = values;
        const nextCurrent = updateFunc(current || {});
        return nextCurrent ? { ...rest, [collectionID]: nextCurrent } : rest;
      });
    },
    [setCollections],
  );

  const P = POICollectionStoreContext.Provider;
  return <P value={[collections, updateCollection]}>{children}</P>;
};

export const usePOICollectionStore = (): [CollectionDict, CollectionUpdater] => {
  return useContext(POICollectionStoreContext);
};
