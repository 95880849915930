import React from 'react';
import { Elevation2 } from '@unacast-internal/unacast-ui/StyleGuide/Elevation';
import styled from 'styled-components';
import { Body } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { AnimatePresence, motion } from 'framer-motion';
import { BlackToWhite, Warning } from '@unacast-internal/unacast-ui/StyleGuide/Colors';

const LocationErrorCardStyled = styled(motion.div)`
  .title-row {
    display: flex;
    .title-spacing {
      margin-left: 0.5rem;
    }
    word-break: break-all;
  }
  margin-bottom: 0.5rem;
  border-radius: 6px;
  padding: 0.5rem;
  max-width: 225px;
  &&:hover {
    cursor: pointer;
  }

  ${Elevation2}
`;

const errorColors = {
  warningCollapsed: '#f1c40f75',
  warning: '#f1c40f',
  error: Warning.Warning01, //I know this is confusing
  errorCollapsed: Warning.Warning01 + '75',
};

const warningCardVariants = {
  show: { opacity: 1, x: 0, backgroundColor: errorColors.warning, transition: { type: 'easeIn' } },
  hide: { opacity: 0, x: 30 },
  collapsed: {
    opacity: 1,
    x: 0,
    backgroundColor: errorColors.warningCollapsed,
    transition: { type: 'easeIn' },
  },
};

const errorCardVariants = {
  show: {
    opacity: 1,
    x: 0,
    backgroundColor: errorColors.error,
    color: '#fff',
    transition: { type: 'easeIn' },
  },
  hide: { opacity: 0, x: 30 },
  collapsed: {
    opacity: 1,
    x: 0,
    backgroundColor: errorColors.errorCollapsed,
    transition: { type: 'easeIn' },
  },
};

type LocationErrorCardProps = {
  id: string;
  issueMessages: string[] | string;
  severity: 'warning' | 'error';
  isExpanded?: boolean;
  onClick?: () => void;
  issueAction?: Record<string, string>;
};

export const LocationErrorCard = ({
  id,
  issueMessages,
  severity,
  isExpanded,
  onClick,
  issueAction,
}: LocationErrorCardProps) => {
  const variants = {
    error: errorCardVariants,
    warning: warningCardVariants,
  };
  return (
    <AnimatePresence>
      <LocationErrorCardStyled
        onClick={onClick}
        initial={'hide'}
        animate={isExpanded ? 'show' : 'collapsed'}
        exit={'hide'}
        variants={variants[severity]}
        transition={{
          type: 'linear',
        }}
        whileTap={{ boxShadow: 'none', scale: 0.975, transition: { type: 'linear' } }}
        layout
      >
        <div className="title-row">
          <WarningRoundedIcon htmlColor={BlackToWhite.White}></WarningRoundedIcon>
          <Body
            className="title-spacing"
            color={severity === 'error' ? BlackToWhite.White : undefined}
          >
            <b>{id}</b>
          </Body>
        </div>
        {isExpanded && (
          <>
            <Body type="small02" color={severity === 'error' ? BlackToWhite.White : undefined}>
              {issueMessages}
            </Body>
            {issueAction && (
              <UnaButton styleType="info" isBorderless>
                {Object.keys(issueAction)[0]}
              </UnaButton>
            )}
          </>
        )}
      </LocationErrorCardStyled>
    </AnimatePresence>
  );
};
