import { downloadBlob } from '../../utils/downloadBlob';
import {
  authDecoratedFetchingHook,
  AuthParams,
  createAsyncFetchingHook,
} from '../../../unacatInteraction/fetchingHook';
import { PoiCollectionService } from '../../../unacatInteraction/PoiCollectionService';

const useDownloadCollectionAsGeoJson = createAsyncFetchingHook(
  async ({ authHeader, billingAccount }: AuthParams, collectionId: string): Promise<boolean> => {
    try {
      const service = new PoiCollectionService(authHeader, billingAccount);
      //TODO: BUG, lat and lng are flipped
      const file = await service.collectionAsGeoJSON(collectionId);
      const blob = new Blob([file.collectionJson], { type: 'application/json' });
      downloadBlob(blob, collectionId + '.geojson');
      return true;
    } catch (err) {
      throw err;
    }
  },
);

export const useDownloadCollectionAsGeoJsonHook = authDecoratedFetchingHook(
  useDownloadCollectionAsGeoJson,
);
