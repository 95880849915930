import { Navy } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import styled from 'styled-components';

export const ReportCollectionManagerMapStyled = styled.div`
  .format-info-link {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: 1rem;
    &__text {
      color: ${Navy.Navy05};
      text-decoration: underline;
    }
  }
  .temp-map-area {
    margin-bottom: 2rem;
    width: 100%;
    height: 32.5rem;
    position: relative;

    .map-search {
      position: absolute;
      top: 1rem;
      left: 1rem;
      z-index: 4;
    }

    .map-add-button {
      position: absolute;
      top: 1rem;
      right: 68px;
      display: flex;
      & > *:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
`;
