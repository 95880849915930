/**
 * All the errors that we don't want Sentry to report, either because it is
 * irrelevant or that we have no control over the error because it is the clients browser issues.
 */

const ignoreErrorList = [
  'TypeError: Failed to fetch', // https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447
  'TypeError: NetworkError when attempting to fetch resource.',
  'TypeError: The request timed out.',
  'TypeError: Network request failed',
  'TypeError: The network connection was lost.',
  'TypeError: Type error',
  'TypeError: cancelled', // https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios
  'TypeError: Preflight response is not successful',
  'Non-Error promise rejection captured', //https://github.com/getsentry/sentry-javascript/issues/3440
  'AbortError: The operation was aborted.', //https://stackoverflow.com/questions/53039068/aborterror-the-operation-was-aborted-error-when-adjusting-html-5-video-cur
];

export default ignoreErrorList;
