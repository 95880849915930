export const ACCESS_TOKEN =
  'pk.eyJ1IjoicGF1bG55bHVuZC11bmFjYXN0IiwiYSI6ImNrOThhMGZuMzAwbzMzbnAyZGd6eHoyZ3AifQ.AgeeOpKv1nQL1Tqd9o5ocg';

export const mobileMaxWidth = '600px';
export const tabletMaxWidth = '960px';
export const mediumMaxWidth = '1200px';
export const largeMaxWidth = '1440px';

export const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

//GOTCHA ENV VARS NEED TO START WITH REACT_APP
export const MAPBOX_ACCESS_TOKEN =
  process.env.REACT_APP_MAPBOX ||
  'pk.eyJ1IjoidW5hY2FzdC1leHBsb3JlIiwiYSI6ImNrdDJub2J6MjA2aDYydm1qY3gxOGp5Z3EifQ.oWTIWKkKHt623wX7yyeWiw';

export const GOOGLE_MAP_KEY =
  process.env.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyBOSjmoADJ58a0M0XHaMWQRlOfTOIeKTiM';

export const UNACOURIER_ACCESS = process.env.REACT_APP_UNACOURIER_URL_BASE;

export type AWSRegionsType = { label: string; value: string }[];
export const AWSRegions: AWSRegionsType = [
  {
    label: 'us-east-1',
    value: 'us-east-1',
  },
  {
    label: 'us-east-2',
    value: 'us-east-2',
  },
  {
    label: 'us-west-1',
    value: 'us-west-1',
  },
  {
    label: 'us-west-2',
    value: 'us-west-2',
  },
  {
    label: 'ca-central-1',
    value: 'ca-central-1',
  },
  {
    label: 'eu-west-1',
    value: 'eu-west-1',
  },
  {
    label: 'eu-central-1',
    value: 'eu-central-1',
  },
  {
    label: 'eu-west-2',
    value: 'eu-west-2',
  },
  {
    label: 'eu-west-3',
    value: 'eu-west-3',
  },
  {
    label: 'eu-north-1',
    value: 'eu-north-1',
  },
  {
    label: 'ap-northeast-1',
    value: 'ap-northeast-1',
  },
  {
    label: 'ap-northeast-2',
    value: 'ap-northeast-2',
  },
  {
    label: 'ap-southeast-1',
    value: 'ap-southeast-1',
  },
  {
    label: 'ap-southeast-2',
    value: 'ap-southeast-2',
  },
  {
    label: 'ap-south-1',
    value: 'ap-south-1',
  },
  {
    label: 'sa-east-1',
    value: 'sa-east-1',
  },
  {
    label: 'us-gov-west-1',
    value: 'us-gov-west-1',
  },
  {
    label: 'us-gov-east-1',
    value: 'us-gov-east-1',
  },
];
