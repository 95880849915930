import { BlackToWhite, Navy } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import React from 'react';

import styled from 'styled-components';
import { Body, Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
const ReportMetricCardStyled = styled.div`
  padding: 1rem;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: ${BlackToWhite.White};
  box-shadow: 0 0 6px rgb(40 41 61 / 4%), 0 6px 10px rgb(80 148 175 / 16%);
  min-width: 275px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .metric-header {
    text-transform: capitalize;
  }

  .action-area {
    display: flex;
    align-self: center;
    &__button {
      background-color: transparent;
      border: 0;
      padding: 0 1rem;
      &:not(:first-child) {
        border-left: 1px solid ${BlackToWhite.Gray04};
      }
      cursor: pointer;
      .action_text {
        color: ${Navy.Navy05};
        text-decoration: underline;
      }
      &:disabled {
        cursor: default;
        .action_text {
          color: ${BlackToWhite.Gray04};
          text-decoration: none;
        }
      }
    }
  }
`;

type ReportMetricCardProps = {
  id: string;

  menuActions?: Record<string, () => void>;
};

export const ReportMetricCard = ({
  id,
  menuActions,
}: ReportMetricCardProps): React.ReactElement => {
  const getCardActions = () => {
    if (menuActions) {
      return (
        <div className="action-area">
          {Object.keys(menuActions).map((key) => {
            return (
              <button
                onClick={() => {
                  menuActions[key]();
                }}
                key={key}
                className="action-area__button"
              >
                <Body className="action_text"> {key}</Body>
              </button>
            );
          })}
        </div>
      );
    }
  };

  return (
    <ReportMetricCardStyled>
      <Heading type="h3" className="metric-header">
        {id.split('_').join(' ')}
      </Heading>
      {menuActions && getCardActions()}
    </ReportMetricCardStyled>
  );
};
