import { History } from 'history';
import { useHistory } from 'react-router-dom';

//MAIN
export const HOME_PATH = '/c/rwg';
export const DATA_PAGE_PATH = '/c/:catalogId';
export const MADE_TO_ORDER_PATH = '/made-to-order';
export const ACCOUNT_PATH = '/account';
export const API_KEY_GENERATOR_PATH = '/api-key';
export const TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const CLIENT_LANDING_PAGE = '/accounts/:accountId';
//OPERATIONS
export const CLIENTS_PATH = '/ops/:catalogId/clients';
export const CLIENT_CREATE_PATH = '/ops/:catalogId/client/create';
export const CLIENT_DETAILS_PATH = '/ops/:catalogId/clients/:billingAccountId';
export const SALES_OPS_PATH = '/ops/:catalogId/sales';
export const DATA_OPS_PATH = '/ops/:catalogId/data';
export const DATA_DELIVERY_SHIPMENTS_PATH =
  '/ops/:catalogId/client/:billingAccountId/deliveries/:orderId/';
export const DATA_DELIVERY_ORDERS_PATH = '/ops/:catalogId/orders';
//AUTH
export const LOGIN_PATH = '/login';
export const SIGNUP_PATH = '/signup';
export const EMAIL_HANDLER_PATH = '/email-handler';
export const EMAIL_VERIFICATION_FAILED = '/email-verification-failed';
export const EMAIL_VERIFICATION_SEND = '/email-verification-sent';
export const EMAIL_VERIFICATION_NEEDED = '/email-verification-needed';
//ERRORS
export const NOT_FOUND_PATH = '/page-not-found';
export const INVITE_ONLY_PATH = '/invite-only';
export const INACTIVE_PATH = '/inactive';
//EXTERNAL REDIRECT
export const INSIGHT_SAAS = '/insights-saas';

class UnacastNowNavigation {
  public constructor(private history: History, private catalogId: string) { }
  public main = {
    homePage: () => {
      this.history.push(HOME_PATH);
    },
    dataPage: () => {
      this.history.push(DATA_PAGE_PATH);
    },
    madeToOrder: () => {
      this.history.push(MADE_TO_ORDER_PATH);
    },
    account: () => {
      this.history.push(ACCOUNT_PATH);
    },
    apiKeyGenerator: () => {
      this.history.push(API_KEY_GENERATOR_PATH);
    },
    termsAndConditions: () => {
      this.history.push(TERMS_AND_CONDITIONS);
    },
  };
  public auth = {
    signup: () => {
      this.history.push(SIGNUP_PATH);
    },
    login: () => {
      this.history.push(LOGIN_PATH);
    },
    emailHandler: () => {
      this.history.push(EMAIL_HANDLER_PATH);
    },
    emailVerificationFailed: () => {
      this.history.push(EMAIL_VERIFICATION_FAILED);
    },
    emailVerificationSend: () => {
      this.history.push(EMAIL_VERIFICATION_SEND);
    },
    emailVerificationNeeded: () => {
      this.history.push(EMAIL_VERIFICATION_NEEDED);
    },
  };
  public operations = {
    accessManagement: () => {
      window.open(`https://access.admin.unacast.com`, '_blank');
    },

    clientCreate: () => {
      this.history.push(`/ops/${this.catalogId}/client/create`);
    },

    clientDetails: (billingAccountId: string) => {
      this.history.push(`/ops/${this.catalogId}/clients/${billingAccountId}`);
    },

    dataOPS: () => {
      this.history.push(`/ops/${this.catalogId}/data`);
    },
    clientShipments: (orderId: string, billingContext: string) => {
      this.history.push(`/ops/${this.catalogId}/client/${billingContext}/deliveries/${orderId}`);
    },
    orders: (billingContext?: string) => {
      this.history.push(
        `/ops/${this.catalogId}/orders${billingContext ? '?billingAccounts=' + billingContext : ''
        }`,
      );
    },
  };
  public errors = {
    notFound: () => {
      this.history.push(NOT_FOUND_PATH);
    },
    inviteOnly: () => {
      this.history.push(INVITE_ONLY_PATH);
    },
    inactive: () => {
      this.history.push(INACTIVE_PATH);
    },
  };
}

export const useNowNavigation = (catalogId: string): UnacastNowNavigation => {
  const history = useHistory();
  return new UnacastNowNavigation(history, catalogId);
};
