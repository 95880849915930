import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryState } from 'react-router-use-location-state';
import styled from 'styled-components';
import {
  executePasswordReset,
  initiatePasswordReset,
  loginEmail,
  verifyEmail,
} from '../auth/firebase';
import { ErrorText } from '@unacast-internal/unacast-ui/ErrorText';
import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { TextInput } from '@unacast-internal/unacast-ui/Inputs';
import { CircularProgress } from '@mui/material';
const PageWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 16px;
`;

const VerifyEmailPage: React.FC<{ code: string; continueUrl: string }> = ({
  code,
  continueUrl,
}) => {
  const [error, setErrorMessage] = useState<null | string>(null);

  useEffect(() => {
    verifyEmail(code).then(
      () => {
        window.location.href =
          continueUrl; /* Need to load user again. TODO: Notifiy UserContext using event */
      },
      (e) =>
        setErrorMessage(
          e.code === 'auth/invalid-action-code'
            ? 'Your request to verify your email has expired or the link has already been used. Log in to resend validation email.'
            : `${e.message} (${e.code})`,
        ),
    );
  }, [code, continueUrl]);

  const content = error ? <ErrorText text={error} /> : <CircularProgress />;

  return (
    <PageWrapper>
      <h2>Verifying email address.</h2>
      {content}
    </PageWrapper>
  );
};

type PasswordPageStep =
  | { step: 'loading' }
  | { step: 'entering_password'; email: string; password: string }
  | { step: 'successfully_saved_info'; email: string; password: string }
  | { step: 'error'; errorMessage: string };
const ResetPasswordPage: React.FC<{ code: string; continueUrl: string }> = ({
  code,
  continueUrl,
}) => {
  const history = useHistory();
  const [currentStep, setStep] = useState<PasswordPageStep>({ step: 'loading' });

  const handleError = (e: { code: string; message: string }) => {
    const errorMessage =
      e.code === 'auth/invalid-action-code'
        ? 'Your request to reset your password has expired or the link has already been used'
        : `${e.message} (${e.code})`;
    setStep({ step: 'error', errorMessage });
  };

  useEffect(() => {
    initiatePasswordReset(code).then(
      (email) => setStep({ step: 'entering_password', email, password: '' }),
      handleError,
    );
  }, [code, setStep]);

  let content: React.ReactNode = null;
  if (currentStep.step === 'loading') {
    content = <CircularProgress />;
  } else if (currentStep.step === 'entering_password') {
    const doSave = () => {
      executePasswordReset(code, currentStep.password).then(
        () =>
          setStep({
            step: 'successfully_saved_info',
            email: currentStep.email,
            password: currentStep.password,
          }),
        handleError,
      );
    };
    content = (
      <>
        <p>Set new password for {currentStep.email}</p>
        <TextInput
          label="New password"
          type="password"
          autoComplete="new-password"
          onChange={(e) => {
            setStep({ ...currentStep, password: e.target.value });
          }}
        />
        <br />
        <UnaButton onClick={doSave}>Save</UnaButton>
      </>
    );
  } else if (currentStep.step === 'successfully_saved_info') {
    const doLogin = () =>
      loginEmail(currentStep.email, currentStep.password).then(
        () => (window.location.href = continueUrl),
      );
    content = (
      <>
        <p>Password successfully updated for {currentStep.email}</p>
        <UnaButton onClick={doLogin}>Continue to Unacast</UnaButton>
      </>
    );
  } else if (currentStep.step === 'error') {
    content = <ErrorText text={currentStep.errorMessage} />;
  }

  return (
    <PageWrapper>
      <h2>Reset password</h2>
      {content}
    </PageWrapper>
  );
};

export const EmailHandlerPage: React.FC = () => {
  const [mode] = useQueryState('mode', '');
  const [oobCode] = useQueryState('oobCode', '');
  const [continueUrl] = useQueryState('continueUrl', '/');

  switch (mode) {
    case 'verifyEmail':
      return <VerifyEmailPage code={oobCode} continueUrl={continueUrl} />;
    case 'resetPassword':
      return <ResetPasswordPage code={oobCode} continueUrl={continueUrl} />;
    case 'recoverEmail': // TODO
    default:
      return (
        <PageWrapper>
          <h2>{mode}</h2>
          <p>Not supported</p>
        </PageWrapper>
      );
  }
};
