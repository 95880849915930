import { DownloadMetricRequest } from '@unacast-internal/unacat-js/unacast/catalog/v1/file_download_service_pb';
import { getFileDownloadServicePromiseClient } from 'src/rpc';

export class FileDownloadService {
  constructor(private authHeader: string, private billingAccountID: string) {}

  private getHeaders = () => ({ Authorization: this.authHeader });

  public async downloadMetric(catalogId, metricId, fileName): Promise<string[]> {
    const fileDownloadService = getFileDownloadServicePromiseClient();

    const request = new DownloadMetricRequest()
      .setBillingContext(this.billingAccountID)
      .setCatalogId(catalogId)
      .setMetricId(metricId)
      .setFileName(fileName);

    const response = await fileDownloadService.downloadMetric(request, this.getHeaders());

    return response.getFileUrlsList();
  }
}
