import {
  CatalogServiceClient,
  CatalogServicePromiseClient,
} from '@unacast-internal/unacat-js/unacast/catalog/v1/catalog_service_grpc_web_pb';
import { SubscriptionServicePromiseClient } from '@unacast-internal/unacat-js/unacast/subscription/v1/subscription_service_grpc_web_pb';
import {
  CatalogServiceClient as CatalogServiceClientV2,
  CatalogServicePromiseClient as CatalogServicePromiseClientV2,
} from '@unacast-internal/unacat-js/unacast/v2/catalog/catalog_service_grpc_web_pb';

import {
  ByoServiceClient,
  ByoServicePromiseClient,
} from '@unacast-internal/unacat-js/unacast/byo/v1/byo_service_grpc_web_pb';

import { PoiCollectionServicePromiseClient } from '@unacast-internal/unacat-js/unacast/byo/v1/poi_collection_service_grpc_web_pb';

import { getAuth } from '@firebase/auth';
import {
  QueryServiceClient,
  QueryServicePromiseClient,
} from '@unacast-internal/unacat-js/unacast/catalog/v1/query_service_grpc_web_pb';
import { FileDownloadServicePromiseClient } from '@unacast-internal/unacat-js/unacast/catalog/v1/file_download_service_grpc_web_pb';
import {
  IamServiceClient,
  IamServicePromiseClient,
} from '@unacast-internal/unacat-js/unacast/iam/v1/iam_service_grpc_web_pb';
import { LensServicePromiseClient } from '@unacast-internal/unacat-js/unacast/metric/v1/lens_service_grpc_web_pb';
import { MetricOperatorServicePromiseClient } from '@unacast-internal/unacat-js/unacast/operator/v1/metric_operator_service_grpc_web_pb';
import {
  DataDeliveryServiceClient,
  DataDeliveryServicePromiseClient,
} from '@unacast-internal/unacat-js/unacast/v2/catalog/data_delivery_service_grpc_web_pb';

import { MetricServicePromiseClient } from '@unacast-internal/unacat-js/unacast/v2/metric/metric_service_grpc_web_pb';
import { StorageAccessServicePromiseClient } from '@unacast-internal/unacat-js/unacast/v2/access/storage_access_grpc_web_pb';
import { errorHandler } from '../helpers/errorHandler';
import { DataProductListingServicePromiseClient } from '@unacast-internal/unacat-js/unacast/catalog/v1/data_product_listing_service_grpc_web_pb';

const unacatProductionAPIUrl = 'http://localhost:8811';
const byoPoiProductionAPIUrl = 'https://byo-poi-server-snr3asztcq-uk.a.run.app';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;
const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => null);

function getUnacatAPI() {
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  }

  return unacatProductionAPIUrl;
}

function getByoPoiAPI() {
  return process.env.REACT_APP_BYO_POI_API_URL || byoPoiProductionAPIUrl;
}

const bringYourOwnPoiService = new ByoServiceClient(getByoPoiAPI(), null, null);
const dataDeliveryService = new DataDeliveryServiceClient(getUnacatAPI(), null, null);
const catalogService = new CatalogServiceClient(getUnacatAPI(), null, null);
const catalogServiceV2 = new CatalogServiceClientV2(getUnacatAPI(), null, null);
const catalogPromiseServiceV2 = new CatalogServicePromiseClientV2(getUnacatAPI(), null, null);
const metricService = new MetricOperatorServicePromiseClient(getUnacatAPI(), null, null);
const metricServiceV2 = new MetricServicePromiseClient(getUnacatAPI(), null, null);
const catalogPromiseService = new CatalogServicePromiseClient(getUnacatAPI(), null, null);
const queryPromiseService = new QueryServicePromiseClient(getUnacatAPI(), null, null);
const queryCallbackService = new QueryServiceClient(getUnacatAPI(), null, null);
const subscriptionServicePromiseClient = new SubscriptionServicePromiseClient(
  getUnacatAPI(),
  null,
  null,
);
const bringYourOwnPoiServicePromiseClient = new ByoServicePromiseClient(getByoPoiAPI(), null, null);
const poiCollectionServicePromiseClient = new PoiCollectionServicePromiseClient(
  getByoPoiAPI(),
  null,
  null,
);

const dataDeliveryPromiseClient = new DataDeliveryServicePromiseClient(getUnacatAPI(), null, null);
const lensServicePromiseClient = new LensServicePromiseClient(getUnacatAPI(), null, null);
const iamServiceClient = new IamServiceClient(getUnacatAPI(), null, null);
const iamServicePromiseClient = new IamServicePromiseClient(getUnacatAPI(), null, null);
const storageAccessServicePromiseClient = new StorageAccessServicePromiseClient(
  getUnacatAPI(),
  null,
  null,
);
const fileDownloadPromiseClient = new FileDownloadServicePromiseClient(getUnacatAPI(), null, null);
const dataProductListingService = new DataProductListingServicePromiseClient(
  getUnacatAPI(),
  null,
  null,
);

enableDevTools([
  catalogService,
  catalogServiceV2,
  catalogPromiseService,
  queryPromiseService,
  subscriptionServicePromiseClient,
  iamServicePromiseClient,
  metricServiceV2,
  bringYourOwnPoiServicePromiseClient,
  poiCollectionServicePromiseClient,
  bringYourOwnPoiService,
  dataDeliveryPromiseClient,
  dataDeliveryService,
  storageAccessServicePromiseClient,
]);

export const getIamServiceClient = (): IamServiceClient => {
  return iamServiceClient;
};

export const getMetricPromiseService = (): MetricOperatorServicePromiseClient => {
  return metricService;
};

export const getIamServicePromiseClient = (): IamServicePromiseClient => {
  return iamServicePromiseClient;
};

export const getCatalogServiceClient = (): CatalogServiceClient => {
  return catalogService;
};

export const getCatalogServiceV2Client = (): CatalogServiceClientV2 => {
  return catalogServiceV2;
};

export const getCatalogServiceV2PromiseClient = (): CatalogServicePromiseClientV2 => {
  return catalogPromiseServiceV2;
};

export const getCatalogServicePromiseClient = (): CatalogServicePromiseClient => {
  return catalogPromiseService;
};

export const getQueryServicePromiseClient = (): QueryServicePromiseClient => {
  return queryPromiseService;
};

export const getQueryServiceCallbackClient = (): QueryServiceClient => {
  return queryCallbackService;
};

export const getSubscriptionServicePromiseClient = (): SubscriptionServicePromiseClient => {
  return subscriptionServicePromiseClient;
};

export const getLensServicePromiseClient = (): LensServicePromiseClient => {
  return lensServicePromiseClient;
};

export const getBringYourOwnPoiServicePromiseClient = (): ByoServicePromiseClient => {
  return bringYourOwnPoiServicePromiseClient;
};

export const getPoiCollectionServicePromiseClient = (): PoiCollectionServicePromiseClient => {
  return poiCollectionServicePromiseClient;
};

export const getDataDeliveryPromiseClient = (): DataDeliveryServicePromiseClient => {
  return dataDeliveryPromiseClient;
};

export const getFileDownloadServicePromiseClient = (): FileDownloadServicePromiseClient => {
  return fileDownloadPromiseClient;
};

export const getMetricServiceV2Client = (): MetricServicePromiseClient => {
  return metricServiceV2;
};

export const getCurrentUserEmail = async (): Promise<string | null | undefined> => {
  return getAuth().currentUser?.email;
};

export const getStorageAccessServicePromiseClient = (): StorageAccessServicePromiseClient => {
  return storageAccessServicePromiseClient;
};

export const getDataProductListingServiceClient = (): DataProductListingServicePromiseClient => {
  return dataProductListingService;
};

export const getAuthorizationHeaderIfLoggedIn = (): Promise<
  { Authorization: string } | undefined
> => {
  async function getToken() {
    return getAuth()
      .currentUser?.getIdToken(true)
      .then((idToken) => idToken)
      .catch(errorHandler);
  }

  return getToken().then((token) => {
    return token ? { Authorization: 'bearer ' + token } : undefined;
  });
};

export const getAuthorizationHeaderIfLoggedInAsync = async (): Promise<
  { Authorization: string } | undefined
> => {
  async function getToken() {
    return getAuth()
      .currentUser?.getIdToken(true)
      .then((idToken) => idToken)
      .catch(errorHandler);
  }

  const token = await getToken();
  return token ? { Authorization: 'bearer ' + token } : undefined;
};
