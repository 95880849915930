import { errorHandler } from '../helpers/errorHandler';

export function postExternal(url: string, data: Record<string, unknown>): Promise<JSON | null> {
  return execExternal('POST', url, data);
}

async function execExternalResponse(url: string, method: string, data: Record<string, unknown>) {
  return fetch(url, {
    method: method,
    body: JSON.stringify(data),
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  });
}

async function execExternal(method: string, url: string, data: Record<string, unknown>) {
  return execExternalResponse(url, method, data)
    .then((response) => handleHttpError(response, url))
    .then(function (response) {
      if (response.status === 200) {
        return response.json();
      }
      return null;
    })
    .catch(function (error) {
      // do not print error when is due to a user aborted request
      if (error.code !== 20) {
        console.error('execExternal -> error', error);
        errorHandler(error);
      }
      return null;
    });
}

const handleHttpError = async function (response: Response, url: string) {
  if (!response.ok) {
    const errorContent = await response.json();
    throw Error('Unknown error on ' + url + ', error message ' + errorContent.meta?.body?.error);
  } else if (response.status === 403) {
    throw Error('No access to ' + url + ', Error message ' + response.statusText);
  } else if (response.status === 404) {
    return response.text().then((error: string) => {
      if (error !== '') {
        throw Error(error + '. Error message ' + response.statusText);
      } else {
        throw Error('Failing on ' + url + ', with error message ' + response.statusText);
      }
    });
  }

  return response;
};
