import { Modal } from '@unacast-internal/unacast-ui/Organisms/Modal';

import React, { useContext, useEffect, useState } from 'react';
import { ParseResponse, usePOIFileHandler } from '../../inputTranslators/fileTranslatorHook';
import { POICollection } from '../../types';

import { useSnackbar } from 'notistack';
import { ErrorHandlerContext } from '../../../contexts/ErrorHandlerContext';
import { ParsingFileLoader } from '../ParsingFileLoader';
import { GetPolygonLookupPreference } from './GetPolygonLookupPreference';

type AddNewCollectionProps = {
  onComplete: (collection: POICollection) => void;
  fileName: string;
  onCancelParsing?: () => void;
  fileToParse: ArrayBuffer;
};

export const AddNewCollectionFromFile = ({
  onComplete,
  fileName,
  fileToParse,
  onCancelParsing,
}: AddNewCollectionProps): React.ReactElement => {
  const [useParcelLookup, setUseParcelLookup] = useState(true);
  const [radius, setFallbackRadius] = useState(20);
  const [isProcessing, setIsProcessing] = useState<boolean>();
  const [parseResponse, setParsedResponse] = useState<ParseResponse>();
  const [isOpen, setIsOpen] = useState(true);
  const fileParser = usePOIFileHandler();

  const { logError } = useContext(ErrorHandlerContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function Parse() {
      if (parseResponse) {
        return;
      }
      try {
        const parsed = await fileParser(fileToParse, fileName);

        setParsedResponse(parsed);
      } catch (e) {
        console.error(e.message);
        logError({ message: 'Something went wrong when parsing your file\n', name: e.name }, true);
      }
    }
    Parse();
  }, [fileName, fileParser, fileToParse, logError, parseResponse]);

  const provideUserPreferenceLookup = async () => {
    if (parseResponse?.status === 'partial' && parseResponse.missing === 'latLon_useLookup') {
      setIsProcessing(true);
      const tempResp = await parseResponse.provide(useParcelLookup, radius);
      setParsedResponse(tempResp);
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    if (parseResponse?.status === 'success') {
      onComplete({ type: 'FeatureCollection', features: parseResponse.features });
      enqueueSnackbar('Processing your locations...', {
        variant: 'info',
      });
      setIsOpen(false);
    }

    if (parseResponse?.status === 'error') {
      logError(
        new Error(`Sorry, there was an error processing your locations: ${parseResponse.message}`),
        true,
      );
    }
  }, [enqueueSnackbar, logError, onComplete, parseResponse]);

  const closeModal = () => {
    setIsOpen(false);
    setParsedResponse(undefined);
    setIsProcessing(false);
    onCancelParsing && onCancelParsing();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      {isProcessing ? (
        <ParsingFileLoader fileName={fileName}></ParsingFileLoader>
      ) : (
        <GetPolygonLookupPreference
          key={1}
          onCancel={closeModal}
          setFallbackRadius={setFallbackRadius}
          onConfirm={provideUserPreferenceLookup}
          useParcelLookup={useParcelLookup}
          setUseParcelLookup={setUseParcelLookup}
          hasRadius={
            parseResponse?.status === 'partial' &&
            parseResponse.missing === 'latLon_useLookup' &&
            parseResponse.hasRadius
          }
          radius={radius}
        ></GetPolygonLookupPreference>
      )}
    </Modal>
  );
};
