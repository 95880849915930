import { Body } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import React from 'react';
import { POIError } from '../../../unacatInteraction/PoiCollectionService';
import { LocationErrorCard } from './LocationErrorCard/LocationErrorCard';

export const LocationErrorList = ({
  collectionErrors,
  selectedError,
  setSelectedError,
}: {
  collectionErrors: Record<string, POIError>;
  selectedError?: string;
  setSelectedError: (id: string) => void;
}) => {
  return (
    <div className="error-area">
      {/* TODO: can be some sort of pagination in the future */}
      <Body className="error-header">{`${
        Object.keys(collectionErrors).length > 20 ? '20 of' : ''
      } ${Object.keys(collectionErrors).length} issues:`}</Body>
      <div className="error-list">
        {Object.keys(collectionErrors)
          .slice(0, Object.keys(collectionErrors).length > 20 ? 20 : undefined)
          .map((key) => (
            <LocationErrorCard
              key={key}
              id={key}
              severity={collectionErrors[key].severity}
              issueMessages={collectionErrors[key].message}
              onClick={() => setSelectedError(key)}
              isExpanded={key === selectedError}
            ></LocationErrorCard>
          ))}
      </div>
    </div>
  );
};
