/**
 * Type-declarations for the subset of GeoJSON that we'll accept as a collection of Points of Interest (POIs)
 * in the "Bring your own POIs"-contexts
 */

// Compatible with GeoJSON.Position
export type Position = [number, number];

// Compatible with GeoJSON.Point
// NB: Also doubles as a POIGeometry for addresses. Makes sense to use the Point-type for compatibility.
export type PointWithRadius = {
  type: 'Point';
  coordinates: Position; // If set to [0,0], address has not been resolved.
  properties: { radius: number; address?: string };
};

// Compatible with GeoJSON.Polygon
export type Polygon = {
  type: 'Polygon';
  coordinates: Position[][];
};

export type MultiPolygon = {
  type: 'MultiPolygon';
  coordinates: Position[][][];
};

export type POIGeometry = PointWithRadius | Polygon | MultiPolygon;

// Compatible with GeoJSON.Feature
export type POIFeature = {
  type: 'Feature';
  geometry: POIGeometry;
  id: string;
  properties: { name: string; centroid?: number[] };
};

// Compatible with GeoJSON.FeatureCollection
export type POICollection = {
  type: 'FeatureCollection';
  features: POIFeature[];
};

/**
 * Validator-functions to do safe type-validating of anything (typically a parsed json-value) to POICollection
 */
const isPosition = (obj: unknown): obj is Position =>
  Array.isArray(obj) && obj.length === 2 && obj.every((e) => typeof e === 'number');

const isPointWithRadius = (obj: unknown): obj is PointWithRadius =>
  typeof obj === 'object' &&
  obj !== null &&
  obj['type'] === 'Point' &&
  isPosition(obj['coordinates']) &&
  typeof obj['properties'] === 'object' &&
  typeof obj['properties'].radius === 'number';

const isPolygon = (obj: unknown): obj is Polygon =>
  typeof obj === 'object' &&
  obj !== null &&
  obj['type'] === 'Polygon' &&
  Array.isArray(obj['coordinates']) &&
  obj['coordinates'].length >= 1 &&
  obj['coordinates'].every((ring) => ring.every(isPosition));

const isMultiPolygon = (obj: unknown): obj is MultiPolygon =>
  typeof obj === 'object' &&
  obj !== null &&
  obj['type'] === 'MultiPolygon' &&
  Array.isArray(obj['coordinates']) &&
  obj['coordinates'].length >= 1 &&
  obj['coordinates'].every((polygon) => polygon.every((ring) => ring.every(isPosition)));

export const isPOIGeometry = (obj: unknown): obj is POIGeometry =>
  isPointWithRadius(obj) || isPolygon(obj) || isMultiPolygon(obj);

export const isPOIFeature = (obj: unknown): obj is POIFeature =>
  typeof obj === 'object' &&
  obj !== null &&
  isPOIGeometry(obj['geometry']) &&
  typeof obj['id'] === 'string' &&
  typeof obj['properties'] === 'object' &&
  typeof obj['properties'].name === 'string';

export const isPOICollection = (obj: unknown): obj is POICollection =>
  typeof obj === 'object' &&
  obj !== null &&
  obj['type'] === 'FeatureCollection' &&
  Array.isArray(obj['features']) &&
  obj['features'].every(isPOIFeature) &&
  obj['features'].every(
    (e, i, arr) => arr.findIndex((c) => c.properties.name === e.properties.name) === i, // Only distinct names
  );

export type Market = 'us' | 'international';

export type Address = { name: string; address: string; radius: number };
