import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { UnaLinearProgress } from '@unacast-internal/unacast-ui/UnaLinearProgress';
import { Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';

const LoaderStyled = styled(motion.div)`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
`;

export const ParsingFileLoader = ({ fileName }) => {
  return (
    <LoaderStyled initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Heading type="h3">Calculating geofences for your &apos;{fileName}&apos; locations</Heading>
      <UnaLinearProgress></UnaLinearProgress>
    </LoaderStyled>
  );
};
