import styled from 'styled-components';
import { Elevation2 } from '@unacast-internal/unacast-ui/StyleGuide/Elevation';

export const MadeToOrderReportListStyled = styled.div`

.search-input{
  ${Elevation2}
      background-color: white;
      border-radius: 6px;
      padding: 1rem 1.75rem;
      width: 30%;

      .MuiFormControl-root{
        margin-top: 0;
        margin-bottom: 0;
      }

}
  .input-spacing {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    .filter-group {
      width: fit-content;
      display: flex;
      ${Elevation2}
      background-color: white;
      border-radius: 6px;
      padding-left: 1rem;
      height: 65px;
    }

    .checkbox-group {
      display: flex;
      align-items: center;
      margin-right: 1.25rem;
    }
  }
  .reports {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media only screen and (max-width: 1200px) {
      & {
        justify-content: space-evenly;
      }
    }

    .card-container {
      margin-top: 2rem;
      width: 30%;
      margin-right: 5%;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @media only screen and (max-width: 1200px) {
       .card-container {
      margin-top: 2rem;
      width: 45%;
      margin-right: 0;
      /* &:nth-child(2n) {
        margin-right: 0;
      } */
    } }

    @media only screen and (max-width: 960px) {
       .card-container {
      margin-top: 2rem;
      width: 100%;
      margin-right: 0;
      /* &:nth-child(2n) {
        margin-right: 0;
      } */
    } }


  .large-report-label {
    display: inline-block;
    background-color: #FFA500; 
    color: #FFFFFF;          
    padding: 2px 8px;         
    border-radius: 4px;       
    margin-bottom: 8px;       
    font-size: 0.9em;         
    font-weight: bold;
    margin-left: 2rem;        
  }

`;