import { Navy } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { UnaLinearProgress } from '@unacast-internal/unacast-ui/UnaLinearProgress';
import React, { Suspense, useContext, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { lazyWithRetry } from '../components/lazyWithRetry';
import styled from 'styled-components';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import { PrivateRoute } from '../components/PrivateRoute';
import TopBar from '../components/TopBar';
import { industryType, solutionType } from '../helpers/industrySolution';
import { AccountPage } from './AccountPage';
import ApiKeyPage from './ApiKeyPage';
import { EmailHandlerPage } from './EmailHandlerPage';
import EmailVerificationFailedPage from './EmailVerificationFailedPage';
import EmailVerificationNeededPage from './EmailVerificationNeededPage';
import EmailVerificationSentPage from './EmailVerificationSentPage';
import { InactiveUserPage } from './InactiveUserPage';
import InviteOnlyPage from './InviteOnlyPage';
import AuthPage from './LoginPage/AuthPage';
import { NotFoundPage } from './NotFoundPage';
import { TermsAndConditionsPage } from './TermsAndConditionsPage';
import UnaCookieConsent from './UnaCookieConsent';
import { MadeToOrder } from '../MadeToOrder/pages/MadeToOrderRoutes';
import { useJsApiLoader } from '@react-google-maps/api';
import { Libraries } from '@googlemaps/js-api-loader';
import { GOOGLE_MAP_KEY } from '../constants';
import { ErrorHandlerContext } from '../contexts/ErrorHandlerContext';
import { ProductsProvider } from '../contexts/ProductsContext';
import { MetricsProvider } from '../contexts/MetricsContext';
import {
  ACCOUNT_PATH,
  API_KEY_GENERATOR_PATH,
  DATA_OPS_PATH,
  DATA_PAGE_PATH,
  EMAIL_HANDLER_PATH,
  EMAIL_VERIFICATION_FAILED,
  EMAIL_VERIFICATION_NEEDED,
  EMAIL_VERIFICATION_SEND,
  HOME_PATH,
  INACTIVE_PATH,
  INVITE_ONLY_PATH,
  LOGIN_PATH,
  MADE_TO_ORDER_PATH,
  INSIGHT_SAAS,
  NOT_FOUND_PATH,
  SIGNUP_PATH,
  TERMS_AND_CONDITIONS,
  CLIENT_LANDING_PAGE,
} from '../components/hooks/useNowNavigation';

const MetricListingPage = lazyWithRetry(() => import('./MetricListingPage/MetricListingPage'));
const MetricDetailPage = lazyWithRetry(() => import('./MetricDetailPage/MetricDetailPage'));
const DataPage = lazyWithRetry(() => import('./DataPage/DataPage'));
const DataOpsPage = lazyWithRetry(() => import('./DataOpsPage'));
const IndustryPage = lazyWithRetry(() => import('./IndustryPage/IndustryPage'));
const AccountLandingPage = lazyWithRetry(() => import('./AccountLandingPage/AccountLandingPage'));
const DataDetailPage = lazyWithRetry(() => import('./DataDetailsPage/DataDetailPage'));
const DataListingPage = lazyWithRetry(() => import('./DataListingPage/DataListingPage'));

export const LayoutStyled = styled.div`
  height: calc(100% - 128px);

  .sub-layout {
    min-height: 100%;
    width: 100%;
    display: grid;
    grid-template-areas:
      'nav head head'
      'nav main main'
      'nav main main';
    grid-template-rows: 6.5rem 1fr 4rem;
    grid-template-columns: 20% 1fr;

    .page-content {
      overflow-x: hidden;
      background-color: ${Navy.Navy10};
      grid-area: main;
      box-sizing: border-box;
    }

    header {
      grid-area: head;
    }
  }

  .normal-layout {
    min-height: 100vh;
    background-color: ${Navy.Navy10};
  }
`;
export abstract class googleLibraries {
  public static libraries: Libraries = ['places', 'drawing'];
}

const Layout = (): JSX.Element => {
  const { logError } = useContext(ErrorHandlerContext);

  const { loadError } = useJsApiLoader({
    mapIds: ['4cde2ee1e9b041f7', 'b148e3cc084c77fc'],
    googleMapsApiKey: GOOGLE_MAP_KEY,
    libraries: googleLibraries.libraries,
  });

  useEffect(() => {
    if (loadError) {
      logError(loadError, true);
    }
  }, [loadError, logError]);

  const IndustryRoutes = () => (
    <Suspense fallback={<UnaLinearProgress />}>
      <Switch>
        <Route
          exact
          path={`/c/:catalogId/:industry(${industryType})/:solution(${solutionType})/:metricId`}
        >
          <MetricDetailPage />
        </Route>
        <Route
          exact
          path={[
            `/c/:catalogId/:industry(${industryType})/:solution(${solutionType})`,
            `/c/:catalogId/:industry(${industryType})`,
          ]}
        >
          <Suspense fallback={<UnaLinearProgress />}>
            <IndustryPage />
          </Suspense>
        </Route>
      </Switch>
    </Suspense>
  );

  const MetricRoutes = () => (
    <Suspense fallback={<UnaLinearProgress />}>
      <Switch>
        <Route exact path={['/c/:catalogId/product/:product/:metricId']}>
          <Suspense fallback={<UnaLinearProgress />}>
            <MetricDetailPage />
          </Suspense>
        </Route>
        <Route path="/c/:catalogId/product/smartmobility">
          <Redirect to="/c/rwg/product/visitation" />
        </Route>
        <Route path="/c/:catalogId/product/:product">
          <MetricListingPage />
        </Route>
        <Route path="/c/:catalogId/metrics">
          <MetricListingPage />
        </Route>
      </Switch>
    </Suspense>
  );

  const PortalRoutes = () => (
    <MetricsProvider>
      <ProductsProvider>
        <Switch>
          <Route exact path={DATA_PAGE_PATH}>
            <div className="normal-layout">
              <TopBar isSubPage={false} />

              <DataPage />
            </div>
          </Route>
          <Route path="/c/:catalogId/*">
            <div className="sub-layout">
              <NavigationBar />
              <TopBar isSubPage={true} />
              <div className="page-content">
                <Switch>
                  <Route path={[`/c/:catalogId/:industry(${industryType})`]}>
                    <IndustryRoutes />
                  </Route>
                  <Route path={['/c/:catalogId/metrics', '/c/:catalogId/product']}>
                    <MetricRoutes />
                  </Route>
                </Switch>
              </div>
            </div>
          </Route>
          <Route exact path="*">
            <Redirect to={NOT_FOUND_PATH} />
          </Route>
        </Switch>
      </ProductsProvider>
    </MetricsProvider>
  );

  const OpsRoutes = () => {
    return (
      <Switch>
        <Route path={DATA_OPS_PATH}>
          <DataOpsPage />
        </Route>
      </Switch>
    );
  };

  return (
    <LayoutStyled>
      <UnaCookieConsent
        googleTagManagerID="GTM-P6TJ898"
        hubspotResourceScriptPath="//js.hsforms.net/forms/embed/v2.js"
        hotjarId="2628853"
      />
      <Switch>
        {/* Pages with no Navigation components */}
        <Route exact path={TERMS_AND_CONDITIONS}>
          <TermsAndConditionsPage />
        </Route>
        <Route exact path={EMAIL_VERIFICATION_NEEDED}>
          <EmailVerificationNeededPage />
        </Route>
        <Route exact path={EMAIL_VERIFICATION_SEND}>
          <EmailVerificationSentPage />
        </Route>
        <Route exact path={EMAIL_VERIFICATION_FAILED}>
          <EmailVerificationFailedPage />
        </Route>
        <Route exact path={LOGIN_PATH}>
          <AuthPage type="login" />
        </Route>
        <Route exact path={SIGNUP_PATH}>
          <AuthPage type="signup" />
        </Route>
        <Route exact path={EMAIL_HANDLER_PATH}>
          <EmailHandlerPage />
        </Route>

        <Route path={MADE_TO_ORDER_PATH}>
          <PrivateRoute>
            <MadeToOrder />
          </PrivateRoute>
        </Route>

        {/* Pages with a navigation bar and top bar */}
        <Route path="/c/:catalogId">
          <Suspense fallback={<UnaLinearProgress />}>
            <PrivateRoute>
              <PortalRoutes />
            </PrivateRoute>
          </Suspense>
        </Route>

        {/* Pages with only a top bar */}
        <Route path="/">
          <div className="normal-layout">
            <Switch>
              <Route exact path={ACCOUNT_PATH}>
                <TopBar isSubPage={false} />
                <AccountPage />
              </Route>
              <Route exact path={INACTIVE_PATH}>
                <TopBar isSubPage={false} />
                <InactiveUserPage />
              </Route>
              <Route exact path={NOT_FOUND_PATH}>
                <TopBar isSubPage={false} />
                <NotFoundPage />
              </Route>
              <Route exact path={API_KEY_GENERATOR_PATH}>
                <TopBar isSubPage={false} />
                <ApiKeyPage />
              </Route>
              <Route exact path={INVITE_ONLY_PATH}>
                <TopBar isSubPage={false} />
                <InviteOnlyPage />
              </Route>
              <PrivateRoute>
                <Suspense fallback={<UnaLinearProgress />}>
                  <Switch>
                    <Route path="/ops/:catalogId">
                      <Suspense fallback={<UnaLinearProgress />}>
                        <TopBar isSubPage={false} />
                        <OpsRoutes />
                      </Suspense>
                    </Route>
                    <Route exact path={['', LOGIN_PATH, SIGNUP_PATH, '/explore']}>
                      <Redirect to={HOME_PATH} />
                    </Route>
                    <Route
                      path={INSIGHT_SAAS}
                      render={() => (window.location.href = 'https://app.unacast.com')}
                    />
                    <ProductsProvider>
                      <Route exact path={CLIENT_LANDING_PAGE}>
                        <TopBar isSubPage={false} />
                        <AccountLandingPage />
                      </Route>
                      <Route path="/data-products/:dataProductName">
                        <div className="normal-layout">
                          <TopBar isSubPage={true} showLogo={true} />
                          <div className="page-content">
                            <Route exact path="/data-products/:dataProductName">
                              <DataListingPage />
                            </Route>
                            <Route exact path="/data-products/:dataProductName/data/:dataName">
                              <DataDetailPage />
                            </Route>
                          </div>
                        </div>
                      </Route>
                    </ProductsProvider>
                    <Route exact path="*">
                      <Redirect to={NOT_FOUND_PATH} />
                    </Route>
                  </Switch>
                </Suspense>
              </PrivateRoute>
            </Switch>
          </div>
        </Route>
      </Switch>
    </LayoutStyled>
  );
};

export default Layout;
