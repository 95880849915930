import {
  BigQueryDestination,
  BigQueryTableConfig,
  CreateDataDeliveryRequest,
  CreateDataDeliveryResponse,
  DataDeliveryStatus,
  DeliverySourceType,
  DestinationConfig,
  DestinationConfigType,
} from '@unacast-internal/unacat-js/unacast/v2/catalog/data_delivery_service_pb';
import { getDataDeliveryPromiseClient } from '../rpc';
import { AlertColor } from '@mui/material';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  ErrorOutline as ErrorOutlineIcon,
  Loop as LoopIcon,
  Schedule as ScheduleIcon,
} from '@mui/icons-material';

export const iconByStatus: Record<DataDeliveryStatus, JSX.Element> = {
  [DataDeliveryStatus.SUCCESS]: <CheckCircleOutlineIcon />,
  [DataDeliveryStatus.ERROR]: <ErrorOutlineIcon />,
  [DataDeliveryStatus.STARTED]: <LoopIcon />,
  [DataDeliveryStatus.NOT_STARTED]: <ScheduleIcon />,
  [DataDeliveryStatus.NOT_SET]: <ScheduleIcon />,
};

export const severityByStatus: Record<DataDeliveryStatus, AlertColor> = {
  [DataDeliveryStatus.SUCCESS]: 'success',
  [DataDeliveryStatus.ERROR]: 'error',
  [DataDeliveryStatus.STARTED]: 'info',
  [DataDeliveryStatus.NOT_STARTED]: 'info',
  [DataDeliveryStatus.NOT_SET]: 'info',
};

export const messageByStatus: Record<DataDeliveryStatus, string> = {
  [DataDeliveryStatus.SUCCESS]: 'Successfully Delivered',
  [DataDeliveryStatus.ERROR]: 'error',
  [DataDeliveryStatus.STARTED]: 'Delivery In Progress',
  [DataDeliveryStatus.NOT_STARTED]: 'Pending Delivery Start',
  [DataDeliveryStatus.NOT_SET]: 'Status Not Set',
};

export type DataDeliveryShipment = {
  id: string;
  order_id: string;
  filename: string;
  deliveryStatus: DataDeliveryStatus;
  errorMessage: string;
  creationTime: Date;
  updatedTime: Date;
};

export const GetDeliveryConfigTypeName = (type: DestinationConfigType) => {
  const [configName] =
    Object.entries(DestinationConfigType).find(([_, value]) => value === type) || [];

  return configName;
};

/**
 * @deprecated The method should be replaced by  UnaCourier service
 */
export class DataDeliveryService {
  constructor(private authHeader: string, private billingAccountID: string) {}

  private getHeaders = () => ({ Authorization: this.authHeader });

  public CreateDataDeliveryForBQ = (
    catalogId: string,
    tableId: string,
    metricId: string,
    storageAccessId: string,
  ): Promise<CreateDataDeliveryResponse> => {
    const dataDeliveryService = getDataDeliveryPromiseClient();

    const deliveryRequest: CreateDataDeliveryRequest = new CreateDataDeliveryRequest()
      .setBillingContext(this.billingAccountID)
      .setCatalogId(catalogId)
      .setDeliverySourceId(metricId)
      .setDeliverySourceType(DeliverySourceType.METRIC)
      .setDestination(
        new DestinationConfig()
          .setBigQueryTableConfig(new BigQueryTableConfig().setTableName(tableId))
          .setStorageAccessId(storageAccessId),
      );
    return dataDeliveryService.createDataDelivery(deliveryRequest, this.getHeaders());
  };
}
