import React from 'react';

import { Body, Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { CreationDetails } from '../CreationDetails';

import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { BlackToWhite } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { POIItem } from '@unacast-internal/unacat-js/unacast/byo/v1/poi_collection_service_pb';

import { CollectionPreviewMap } from '../CollectionPreviewMap/CollectionPreviewMap';
import { CollectionDetailsCardStyled } from './CollectionDetailsCard.styles';
import { POIFeature__fromPOIItem } from '../../../helpers/geography';
import { StoredPoiCollection } from '../../../unacatInteraction/PoiCollectionService';
import { POICollection } from '../../types';
import { useMadeToOrderNavigation } from '../../utils/madeToOrderNavigation';
import { collection } from '@firebase/firestore';
import { UnaAlert } from '@unacast-internal/unacast-ui/UnaAlert';

type CollectionDetailsCardProps = {
  collectionDetails: StoredPoiCollection;
  menuActions?: Record<string, () => void>;
};

const FeatureCollection__fromPOIItems = async (
  items: POIItem.AsObject[],
): Promise<POICollection> => {
  const convertedItems = await Promise.all(items.map((i) => POIFeature__fromPOIItem(i)));

  return {
    type: 'FeatureCollection',
    features: convertedItems,
  };
};

export const CollectionDetailsCard = ({
  collectionDetails,
  menuActions,
}: CollectionDetailsCardProps): React.ReactElement => {
  const navTo = useMadeToOrderNavigation();

  const getCardActions = () => {
    if (menuActions) {
      return (
        <div className="action-area">
          {Object.keys(menuActions).map((key) => {
            return (
              <button
                onClick={() => {
                  menuActions[key]();
                }}
                key={key}
                className="action-area__button"
              >
                <Body className="action_text"> {key}</Body>
              </button>
            );
          })}
        </div>
      );
    }
  };

  return (
    <CollectionDetailsCardStyled>
      <div className="top-row">
        <div className="title-with-icon">
          {/* will be added back once status exists on a collection
          <ReportStatusIcon status={status} size={'large'}></ReportStatusIcon> */}
          <Heading type={'h2'} className="title-space">
            {collectionDetails.details.name}
          </Heading>
        </div>
        {getCardActions()}
      </div>
      <div className="report-details">
        {collectionDetails.details.createdTime && (
          <>
            <div className="details-item">
              <CreationDetails
                createdBy={collectionDetails.details.createdBy}
                creationDate={collectionDetails.details.createdTime}
              ></CreationDetails>
            </div>
            <div className="details-item">
              <Body type="small01" color={BlackToWhite.Gray03}>
                <b>Locations:</b> {collectionDetails.details.itemCount}
              </Body>
            </div>
          </>
        )}
        {collectionDetails.details.itemCount < 1 ? (
          <div className="action-prompt">
            <Heading type="h3" className="prompt-spacing">
              Your collection is empty, start adding items to it
            </Heading>
            <UnaButton
              styleType="secondary"
              onClick={() => navTo.editCollection(collectionDetails.details.id)}
            >
              Start Adding Locations
            </UnaButton>
          </div>
        ) : (
          <>
            {collectionDetails.featureCollection &&
            collectionDetails.featureCollection.length > 0 ? (
              <div className="map-preview-container">
                <CollectionPreviewMap
                  featureCollection={collectionDetails.featureCollection}
                ></CollectionPreviewMap>
                <UnaButton
                  styleType="primary"
                  onClick={() => navTo.editCollection(collectionDetails.details.id)}
                  className="edit-button"
                >
                  Edit Locations
                </UnaButton>
              </div>
            ) : (
              <div className="map-preview-container">
                <UnaAlert severity="warning" title="Map preview not available">
                  Map preview and edit not available for this collection as it contains more than
                  1,000 locations.
                </UnaAlert>
              </div>
            )}
          </>
        )}
      </div>
    </CollectionDetailsCardStyled>
  );
};
