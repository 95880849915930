import { AnimatePresence, motion } from 'framer-motion';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { ReportStatus } from '../../../unacatInteraction/BringYourOwnPOIService';
import { useDeleteDraftReportHook } from '../../dataManagement/useDeleteDraftReportHook';
import { useGetDraftReportsHook } from '../../dataManagement/useGetDraftReportsHook';
import { MadeToOrderReportCard } from '../MadeToOrderReportCard/MadeToOrderReportCard';
import { DraftReportStyled } from './DraftReportList.styles';
import { UnaLinearProgress } from '@unacast-internal/unacast-ui/UnaLinearProgress';
import { useMadeToOrderNavigation } from '../../utils/madeToOrderNavigation';

export const DraftReportList = () => {
  const [draftsState, getDraftList] = useGetDraftReportsHook();
  const [draftDeleteState, deleteDraft] = useDeleteDraftReportHook();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navTo = useMadeToOrderNavigation();

  useEffect(() => {
    getDraftList();
  }, [getDraftList]);

  useEffect(() => {
    if (draftDeleteState.status === 'succeeded') {
      getDraftList();
    } else if (draftDeleteState.status === 'failed') {
      enqueueSnackbar('There was an error deleting your draft.', { variant: 'error' });
    }
  }, [draftDeleteState, enqueueSnackbar, getDraftList]);

  const draftsList = useMemo(() => {
    return draftsState.status === 'succeeded' ? (
      draftsState.data.map((draft) => (
        <motion.div
          key={draft.id}
          layout
          className="card-container"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.5, type: 'easeIn' } }}
          exit={{ opacity: 0 }}
          transition={{
            type: 'easeIn',
          }}
        >
          <MadeToOrderReportCard
            id={draft.info.reportName || draft.id}
            status={ReportStatus.UNDEFINED}
            locationCount={draft.info.collectionLength}
            {...draft.info}
            menuActions={{
              'Continue Draft': () => {
                navTo.continueDraft(draft.id, draft.info.reportType);
              },
              'Delete Draft': () => {
                deleteDraft(draft.id);
              },
            }}
          ></MadeToOrderReportCard>
        </motion.div>
      ))
    ) : (
      <UnaLinearProgress></UnaLinearProgress>
    );
  }, [draftsState]);

  return (
    <DraftReportStyled>
      <AnimatePresence>{draftsList}</AnimatePresence>
    </DraftReportStyled>
  );
};
