import { HexHomeIcon } from '@unacast-internal/unacast-ui/StyleGuide/UnaIcons/HexHomeIcon';
import { BlackToWhite } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { UnacastIcon } from '@unacast-internal/unacast-ui/StyleGuide/UnaIcons/UnacastIcon';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useQueryState } from 'react-router-use-location-state';
import GoogleAuthForm from '../../components/GoogleAuthForm';
import { AuthPageStyled } from './AuthPageStyled';
import { useFetchSolution } from '../../components/hooks/useSanityCMSClient';
import { capitalize } from '../../helpers/capitalize';

const textAnimationVariants = {
  hidden: { opacity: 0, x: 20 },
  show: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.8,
      delayChildren: 0.8,
      staggerChildren: 0.8,
    },
  },
};

const item = {
  hidden: { opacity: 0, x: 20 },
  show: { opacity: 1, x: 0, transition: { type: 'linear', duration: 0.4 } },
};

const itemVertical = {
  hidden: { opacity: 0 },
  show: { opacity: 1, transition: { type: 'linear', duration: 0.4 } },
};
const loginVariants = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

const AuthPage = ({ type }: { type: 'signup' | 'login' }): JSX.Element => {
  const [queryNext] = useQueryState('next', '');
  const gotoAfter = queryNext === '' ? undefined : queryNext;
  const [, , , industry, solution] = (gotoAfter || '').split('/'); // ´/c/:catalog/:industry/:solution
  const [authText, setAuthText] = useState<string>();
  const solutionContentState = useFetchSolution(industry, solution);

  useEffect(() => {
    document.title = `${capitalize(type)} | Unacast Now`;
    return () => {
      document.title = `Unacast Now`;
    };
  }, [type]);

  useEffect(() => {
    if (gotoAfter === '/api-key') {
      setAuthText('# Explore our data through APIs by using our Python Client');
    }
    if (solutionContentState.status === 'succeeded') {
      setAuthText(solutionContentState.data?.signUpText);
    }
  }, [gotoAfter, solutionContentState]);
  return (
    <AuthPageStyled>
      <div className="left-pane">
        <div>
          <motion.div variants={loginVariants} initial={'hidden'} animate={'show'}>
            <UnacastIcon dotColor={BlackToWhite.Black01} />
          </motion.div>
          <motion.ul variants={textAnimationVariants} initial="hidden" animate="show">
            {!authText ? (
              <>
                <motion.li variants={item} key="1">
                  <Heading type="XLH1" color={'white'}>
                    Clean. Reliable.
                  </Heading>
                </motion.li>
                <motion.li variants={item} key="2">
                  <Heading type="XLH1" color={'white'}>
                    Decision-Ready
                  </Heading>
                </motion.li>
                <motion.li variants={item} key="3">
                  <Heading type="XLH1" color={'white'}>
                    location data.
                  </Heading>
                </motion.li>
                <motion.li variants={itemVertical} key="4" className="sub-header">
                  <Heading type="h2" color={'white'} className="sub-header-text">
                    For decision-makers
                    <br />
                    and their data scientist peers.
                  </Heading>
                </motion.li>
              </>
            ) : (
              <motion.li variants={item} key="1">
                <Heading type="XLH1">{authText}</Heading>
              </motion.li>
            )}
          </motion.ul>
        </div>
        <div className="bg-svg">
          <HexHomeIcon />
        </div>
      </div>
      <motion.div variants={loginVariants} initial={'hidden'} animate={'show'} className="content">
        <div className="auth-form">
          <GoogleAuthForm type={type} gotoAfter={gotoAfter}></GoogleAuthForm>
        </div>
      </motion.div>
    </AuthPageStyled>
  );
};

export default AuthPage;
