import React, { useState } from 'react';

type Context = {
  inputEmail: string;
  setInputEmail: React.Dispatch<React.SetStateAction<string>>;
  inputPassword: string;
  setInputPassword: React.Dispatch<React.SetStateAction<string>>;
  inputFirstName: string;
  setInputFirstName: React.Dispatch<React.SetStateAction<string>>;
  inputLastName: string;
  setInputLastName: React.Dispatch<React.SetStateAction<string>>;
  inputCompany: string;
  setInputCompany: React.Dispatch<React.SetStateAction<string>>;
};

export const CustomLoginContext = React.createContext({} as Context);

export const CustomLoginProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [inputEmail, setInputEmail] = useState<string>('');
  const [inputPassword, setInputPassword] = useState<string>('');
  const [inputFirstName, setInputFirstName] = useState<string>('');
  const [inputLastName, setInputLastName] = useState<string>('');
  const [inputCompany, setInputCompany] = useState<string>('');

  return (
    <CustomLoginContext.Provider
      value={{
        inputEmail,
        setInputEmail,
        inputPassword,
        setInputPassword,
        inputFirstName,
        setInputFirstName,
        inputLastName,
        setInputLastName,
        inputCompany,
        setInputCompany,
      }}
    >
      {children}
    </CustomLoginContext.Provider>
  );
};
