import { BlackToWhite } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const AnimateVariants = {
  hidden: { y: 100, opacity: 0 },
  show: {
    y: 0,
    opacity: 1,
    transition: { type: 'easeIn', delay: 0.3, duration: 0.6 },
  },
};

export const CreateReportPageStyled = styled(motion.div)`
  .market-prompt-placement {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 400px;
    justify-content: center;
  }
  .create-content {
    margin: 0.9375rem 0;
    background-color: ${BlackToWhite.White};
    box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(80, 148, 175, 0.16);
    border-radius: 6px;
    padding: 2.1875rem;
    box-sizing: border-box;

    .poi-collection-header {
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 1rem;
      .MuiSvgIcon-root {
        font-size: 4rem;
      }
    }

    .actions {
      display: flex;
      gap: 2rem;
      justify-content: flex-end;
    }
  }
`;
