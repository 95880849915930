import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ReportDetailsPageStyled = styled(motion.div)`
  .metric-list {
    margin-top: 2rem;
  }
  .metric-card-spacing {
    margin-top: 1rem;
  }

  .metric-row {
    display: flex;
    justify-content: space-between;
  }
`;
