/* eslint-disable react/display-name */

import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useCurrentUserLoggedInStatus } from '../../contexts/UserContext';
import ContactUsDialog from '../../pages/MetricListingPage/ContactUsDialog';
import { NavigationBarStyled } from './NavigationBarStyled';
import { RetailIcon } from '@unacast-internal/unacast-ui/StyleGuide/UnaIcons/RetailIcon';
import { UnacastIcon } from '@unacast-internal/unacast-ui/StyleGuide/UnaIcons/UnacastIcon';
import { HyperLinkNormal } from '@unacast-internal/unacast-ui/StyleGuide/Navigation';
import { ExpandableNavItem } from '@unacast-internal/unacast-ui/ExpandableNavItem';
import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { LayersTwoTone as LayersTwoToneIcon, Lock } from '@mui/icons-material';
import { useDataProducts } from 'src/contexts/ProductsContext';
import { DeliverySourceType } from '@unacast-internal/unacat-js/unacast/v2/catalog/data_delivery_service_pb';
import { SubscriptionAccessKind } from '@unacast-internal/unacat-js/unacast/subscription/v1/subscription_status_pb';
import { ExpandableNavItem as DatasetExpandableNavItem } from '../ExpandableNavItem';

const NavigationBar = (): JSX.Element => {
  const [isContactUsOpen, setIsContactUsOpen] = useState<boolean>(false);
  const loggedIn = useCurrentUserLoggedInStatus();
  //TODO: find a way to handle params in navbar
  const { catalogId, dataProductName } = useParams<{
    catalogId: string;
    dataProductName: string;
  }>();
  const history = useHistory();
  const path = history.location.pathname.split('/');

  const dataProducts = useDataProducts();
  const dataProduct = dataProducts.find((product) => product.name === dataProductName);

  function getDefaultActive(href: string, pathPos: number) {
    return href === path[pathPos];
  }

  function getIconBasedOnProduct(productName: string) {
    const iconStyle = { height: '36px', width: '36px' };
    switch (productName) {
      case 'retail':
        return (color: string) => <RetailIcon color={color} size={36} />;
      default:
        return () => <LayersTwoToneIcon style={iconStyle} color="inherit" />;
    }
  }

  return (
    <NavigationBarStyled>
      {loggedIn && (
        <div className="logo-area">
          <HyperLinkNormal to={`/`}>
            <UnacastIcon width={140} />
          </HyperLinkNormal>
        </div>
      )}
      {!dataProduct ? (
        <>
          <ExpandableNavItem
            text={'Venues'}
            href={`/c/${catalogId ? catalogId : 'rwg'}/product/${'visitation'}`}
            icon={getIconBasedOnProduct('visitation')}
            defaultActive={getDefaultActive('visitation', 4)}
          />
          <ExpandableNavItem
            text={'Neighborhoods'}
            href={`/c/${catalogId ? catalogId : 'rwg'}/product/${'neighborhood package'}`}
            icon={getIconBasedOnProduct('neighborhood package')}
            defaultActive={getDefaultActive('neighborhood package', 4)}
          />
        </>
      ) : (
        <>
          {dataProduct.data
            .filter((dataset) =>
              [DeliverySourceType.DATASET, DeliverySourceType.METRIC].includes(
                dataset.deliverySourceType,
              ),
            )
            .map((dataset) => {
              const hasAccess =
                dataset.access?.accessKind &&
                dataset.access?.accessKind > SubscriptionAccessKind.DESCRIPTIVE;
              return (
                <>
                  <DatasetExpandableNavItem
                    key={dataset.name}
                    text={dataset.displayName}
                    href={`/data-products/${dataProductName}/data/${dataset.name}`}
                    icon={() => (!hasAccess ? <Lock color={'disabled'} /> : <></>)}
                    disabled={!hasAccess}
                  />
                </>
              );
            })}
        </>
      )}

      {false && ( // Temp removal of buttons
        <div className="buttons-area">
          <UnaButton onClick={() => setIsContactUsOpen(true)} fullWidth>
            Contact us
          </UnaButton>
          <UnaButton onClick={() => null} styleType="secondary" fullWidth>
            Search FAQ
          </UnaButton>
          <ContactUsDialog open={isContactUsOpen} handleClose={() => setIsContactUsOpen(false)} />
        </div>
      )}
    </NavigationBarStyled>
  );
};

export default NavigationBar;
