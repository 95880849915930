import { UnaGoogleButton } from '@unacast-internal/unacast-ui/GoogleButton';
import { BlackToWhite } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { Body, Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { HyperLink, HyperLinkOrange } from '@unacast-internal/unacast-ui/StyleGuide/Navigation';
import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { login } from '../auth/firebase';
import EmailLogin from './login/LoginEmail';

const FormStyled = styled.div`
  box-sizing: border-box;
  width: 400px;
  height: 100vh;
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  justify-items: center;
  .inputs {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .privacy {
    margin-top: auto;
    display: flex;
    margin-bottom: 2rem;
  }

  .spacing {
    margin-top: 2rem;
  }
`;

const loginVariants = {
  hidden: { opacity: 0 },
  show: { opacity: 1, transition: { type: 'linear', duration: 0.4 } },
};

const doReload = () => {
  window.location.reload();
};

const GoogleAuthFormSignup = ({
  callBack,
  gotoAfter,
}: {
  callBack?: () => void;
  gotoAfter?: string;
}): JSX.Element => (
  <motion.div variants={loginVariants} initial={'hidden'} animate={'show'}>
    <FormStyled>
      <div className="inputs">
        <div className="auth-form-header">
          <Heading type="h3">
            <b>Create your account</b>
          </Heading>
        </div>

        <EmailLogin onClose={callBack || doReload} gotoAfter={gotoAfter} type={'signup'} />
        <div className="spacing">
          {`Already have an account? `}
          <HyperLinkOrange
            to={'/login' + (gotoAfter ? '?next=' + encodeURIComponent(gotoAfter) : '')}
          >
            Log in.
          </HyperLinkOrange>
        </div>
      </div>
      <div className="privacy">
        <label htmlFor="terms">
          <Body color={BlackToWhite.Gray02} type="small01">
            {`By signing up you confirm that you have read and agreed to our `}
            <HyperLink to="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </HyperLink>
            .
          </Body>
        </label>
      </div>
    </FormStyled>
  </motion.div>
);

const GoogleAuthFormLogin = ({
  callBack,
  gotoAfter,
}: {
  callBack?: () => void;
  gotoAfter?: string;
}): JSX.Element => (
  <motion.div variants={loginVariants} initial={'hidden'} animate={'show'}>
    <FormStyled>
      <div className="inputs">
        <UnaGoogleButton onClick={() => login().then(callBack)} fullWidth>
          Log In with Google
        </UnaGoogleButton>

        <span className="spacing">
          <Body type="small02">or, Log In with email and password</Body>
        </span>

        <EmailLogin onClose={callBack || doReload} gotoAfter={gotoAfter} type={'login'} />
        <div className="spacing">
          <HyperLinkOrange
            to={'/signup' + (gotoAfter ? '?next=' + encodeURIComponent(gotoAfter) : '')}
          >
            No Account? Create one.
          </HyperLinkOrange>
        </div>
      </div>
    </FormStyled>
  </motion.div>
);

const GoogleAuthForm = ({
  type,
  ...props
}: {
  type: 'signup' | 'login';
  callBack?: () => void;
  gotoAfter?: string;
}): JSX.Element => {
  if (type === 'signup') {
    return <GoogleAuthFormSignup {...props} />;
  }
  return <GoogleAuthFormLogin {...props} />;
};

GoogleAuthForm.propTypes = {};

export default GoogleAuthForm;
