import React, { useEffect, useState } from 'react';
import { SearchAutoComplete } from './SearchAutoComplete';
import { POIFeature } from '../types';

type Props = {
  changeHandler: (val: unknown[]) => void;
  features: POIFeature[];
};

const getNamesAndIDs = (features: POIFeature[]): string[] => {
  return features.map((feat) => feat.properties.name || feat.id);
};

export const SearchAutoCompletePOIs = ({ changeHandler, features }: Props): JSX.Element => {
  const [autoOptions, setAutoOptions] = useState<unknown[]>(features);

  useEffect(() => {
    if (features) setAutoOptions(getNamesAndIDs(features));
  }, [features]);

  if (!features) return <></>;

  const onInputChange = (inputValue: string) => {
    if (!inputValue) setAutoOptions(getNamesAndIDs(features));
    else {
      const filteredFeatures = features.filter(
        (feat) =>
          feat.id.toLowerCase().includes(inputValue.toLowerCase()) ||
          feat.properties.name.toLowerCase().includes(inputValue.toLowerCase()),
      );
      setAutoOptions(getNamesAndIDs(filteredFeatures));
    }
  };

  const onChange = (val) => {
    if (!val) changeHandler([]);
    else {
      changeHandler([val]);
    }
  };
  return (
    <SearchAutoComplete
      placeholder="Search by ID or Name"
      changeHandler={onChange}
      onInputChange={onInputChange}
      autoOptions={autoOptions}
    />
  );
};
