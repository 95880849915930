import { BlackToWhite } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { Body } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import React from 'react';
import { ReportType } from '../../unacatInteraction/BringYourOwnPOIService';

export const ReportTimeRangeText = ({
  reportType,
  startDate,
  endDate,
  color = BlackToWhite.Gray03,
  type = 'small02',
}: {
  reportType: ReportType;
  startDate: Date;
  endDate?: Date;
  color?: string;
  type?: 'body' | 'small01' | 'small02' | 'bodyCopy02';
}) => {
  if (reportType === ReportType.ONE_TIME && startDate && endDate) {
    return (
      <Body type={type} color={color}>
        <b>between </b>
        {`${startDate.toLocaleDateString('en-US')} and ${endDate.toLocaleDateString('en-US')}`}
      </Body>
    );
  }

  return (
    <Body type={type} color={color}>
      <b>starting from </b>
      {`${startDate.toLocaleDateString('en-US')}`}
    </Body>
  );
};
