import {
  Cadence,
  Metric,
  MetricVersion,
  LeanMetric,
  LeanMetricVersion,
} from '@unacast-internal/unacat-js/unacast/v2/metric/metric_pb';
import { SubscriptionAccessKind } from '@unacast-internal/unacat-js/unacast/subscription/v1/subscription_status_pb';
import { Lens } from '@unacast-internal/unacat-js/unacast/v2/metric/lens_pb';
import { Industry, isIndustry, isSolution, Solution } from './industrySolution';
import { AvailabilityKind } from '@unacast-internal/unacat-js/unacast/unatype/availability_pb';

const isMetricOnActiveProducts = (m: Metric, activeUserProducts: Record<string, string[]>) => {
  if (!Object.keys(activeUserProducts).length) return false;
  const tagList = m.toObject().spec?.tagsList || [];
  const industries = tagList.find((tag) => tag.type === 'industry')?.valuesList || [];
  for (const tag of tagList) {
    if (tag.type === 'product') {
      for (const product of tag.valuesList) {
        if (activeUserProducts[product]) return true;
      }
    }
    if (!industries.length) return false;
    if (tag.type === 'solution') {
      for (const solution of tag.valuesList) {
        for (const industry of industries) {
          if ((activeUserProducts[industry] || []).includes(solution)) return true;
        }
      }
    }
  }
  return false;
};

export const hasSubscriptionToMetricVersion = (metricVersion: MetricVersion): boolean => {
  const currentAccessKind = metricVersion.getYourSubscription()?.getCurrentAccessKind();
  return currentAccessKind ? currentAccessKind >= SubscriptionAccessKind.FREE : false;
};

export const isGlobalFreeTrialLens = (lens: Lens): boolean =>
  lens.getBillingAccountId() === 'global_free_trial';

export const getMetricVersionUrl = (
  m: LeanMetricVersion,
  catalogId: string,
  product: string,
): string => {
  return `/c/${catalogId}/product/${product}/${m.getId()}`;
};

export const getLinkableVersion = (
  m: LeanMetric,
  errorLogger: (error: Error, showError: boolean, errorInfo?: string) => void,
): LeanMetricVersion => {
  const publicVersions = m.getMetricVersionsList().filter((mv) => {
    //TODO this should be done by using lifecycle, but as we don't have that we'll pull one that is public
    return /catalog\..*\.public/.test(mv.getListing());
  });

  let versionToUse;
  const versionNumbers = getVersionNumbers(publicVersions);
  if (versionNumbers.length === 1) {
    versionToUse = versionNumbers[0];
  } else if (versionNumbers.length > 1) {
    const err = new Error(
      `There are more than one public metrics versions for ${m.getId()}, versions ${publicVersions
        .map((mv) => mv.getId())
        .join(',')}`,
    );
    errorLogger(err, false);
    versionNumbers.sort((a, b) => b.localeCompare(a));
    versionToUse = versionNumbers[0];
  } else {
    const err = new Error(
      `There are no public metrics versions for ${m.getId()}, versions ${m
        .getMetricVersionsList()
        .map((mv) => mv.getId())
        .join(',')}. Returning a non-public version`,
    );
    errorLogger(err, false);
    versionToUse = m.getMetricVersionsList()[0].getVersionSpec()?.getVersion();
  }

  // Picking the cadence with the lowest resolution as default as this list is sorted
  const cadences = getCadences(m.getMetricVersionsList(), versionToUse);
  const cadenceToUse = cadences[0];

  const metricToUse = findMetricVersion(m, versionToUse, cadenceToUse);
  return metricToUse ? metricToUse : m.getMetricVersionsList()[0];
};

const duplicateVersionPredicate = (version: MetricVersion, index: number, self: MetricVersion[]) =>
  index ===
  self.findIndex(
    (otherVersion) =>
      otherVersion.getVersionSpec()?.getVersion() === version.getVersionSpec()?.getVersion(),
  );

const deprecated = (version: MetricVersion) => {
  return version.getListing() !== 'catalog.rwg.deprecated';
};

const nonSubscriptionToVersion = (version: MetricVersion) =>
  !!version.getYourSubscription()?.getBillingAccountId();

export const getVersionNumbers = (metricVersionList: LeanMetricVersion[]): string[] => {
  return (metricVersionList || [])
    .filter(duplicateVersionPredicate)
    .filter(nonSubscriptionToVersion)
    .filter(deprecated)
    .map((v) => v.getVersionSpec()?.getVersion() || '');
};

export const getCadences = (metricVersionList: LeanMetricVersion[], version: string): Cadence[] => {
  const cadences = (metricVersionList || [])
    .filter((v) => v.getVersionSpec()?.getVersion() === version)
    .sort((a, b) => {
      return (
        (b.getSpec()?.getCadence()?.valueOf() || 0) - (a.getSpec()?.getCadence()?.valueOf() || 1)
      );
    })
    .map((v) => v.getSpec()?.getCadence() || Cadence.CADENCE_UNSPECIFIED);
  return Array.from(new Set(cadences));
};

export const findMetricVersion = (
  m: LeanMetric,
  version: string,
  cadence: Cadence | undefined,
): LeanMetricVersion | undefined => {
  return m
    .getMetricVersionsList()
    .find(
      (v) => v.getVersionSpec()?.getVersion() === version && v.getSpec()?.getCadence() === cadence,
    );
};

export const isMetricAccessible =
  (activeUserProducts: Record<string, string[]>) =>
  (m: Metric): boolean =>
    activeUserProducts &&
    isMetricOnActiveProducts(m, activeUserProducts) &&
    m.getMetricVersionsList().some(hasSubscriptionToMetricVersion) &&
    m.getMetricVersionsList().some(mv => mv.getListing() === 'catalog.rwg.public');

export const metricWithAccess = (metrics: LeanMetric[]): LeanMetric[] =>
  metrics.filter((m) => m.getMetricVersionsList().some(hasSubscriptionToMetricVersion))
    .filter((m) => m.getMetricVersionsList().some(mv => mv.getListing() === 'catalog.rwg.public'));

export const getMetricTags = (
  metric: LeanMetric,
): { legacyProducts: string[]; industries: Industry[]; solutions: Solution[] } => {
  const tags = metric.getSpec()?.getTagsList();
  const legacyProducts = (
    tags?.find((tag) => tag.getType() === 'product')?.getValuesList() || []
  ).filter((p) => !['deprecated', 'all metrics', 'foot traffic'].includes(p));
  const industries = [];
  const solutions = [];
  return { legacyProducts, industries, solutions };
};

export const getMetricPackages = (metric: LeanMetric): string[] => {
  const { legacyProducts, industries, solutions } = getMetricTags(metric);
  const products = solutions?.flatMap((solution) =>
    industries?.flatMap((industry) => `${industry} ${solution}`),
  );
  return [...legacyProducts, ...products];
};
