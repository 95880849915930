import { UnaButton } from '@unacast-internal/unacast-ui/Button';
import { RadioButtonGroup, TextInput } from '@unacast-internal/unacast-ui/Inputs';
import { BlackToWhite, Primary } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { Elevation2 } from '@unacast-internal/unacast-ui/StyleGuide/Elevation';
import { Body, Heading } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import { useMemo, useState } from 'react';

import styled from 'styled-components';
import { FeatureToggleHelper } from '../../components/FeatureToggle';
import { Market } from '../types';

const PromptStyled = styled.div`
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  background-color: ${BlackToWhite.White};
  border-radius: 6px;
  ${Elevation2}
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 650px;

  .header-spacing {
    margin-bottom: 2rem;
  }

  .spacing {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }

  .collection-name {
    width: 100%;
  }

  .action-area {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .step-indicator {
    display: flex;
    justify-content: space-around;
    width: 50%;
    padding-bottom: 1rem;
    .indicator {
      width: 30%;
      height: 8px;
      background-color: ${BlackToWhite.Gray04};
      border-radius: 6px;
      transition: background-color 0.5s linear;
    }

    .active {
      background-color: ${Primary.Primary01};
    }
  }
`;

const MARKET_OPTIONS = [
  {
    value: 'international',
    label: 'International',
  },
  {
    value: 'us',
    label: 'United States',
  },
];

export const GetCollectionDetails = ({
  onComplete,
}: {
  onComplete: (n: string, m: Market) => void;
}) => {
  const { isFeatureToggleActive } = FeatureToggleHelper();

  const [market, setMarket] = useState<Market>('international');
  const [name, setName] = useState<string>();
  const [nameError, setNameError] = useState<string>();
  const [step, setStep] = useState<number>(0);
  const lastStep = useMemo(() => {
    return isFeatureToggleActive('byoMarketSelector') ? 1 : 0;
  }, [isFeatureToggleActive]);

  const onChangeName = (newName: string) => {
    setName(newName);
    if (newName === undefined || newName.trim().length < 1) {
      setNameError('Name is required.');
    } else {
      setNameError(undefined);
    }
  };

  const formAction = () => {
    if (step !== lastStep) {
      nameError === undefined && setStep(step + 1);
    } else {
      nameError === undefined && name && onComplete(name.trim(), market);
    }
  };

  const stepContent = () => {
    switch (step) {
      case 0:
        return (
          <div className="collection-name">
            <TextInput
              label={'Collection Name'}
              value={name}
              type="text"
              required={true}
              helperText={nameError || '*required'}
              error={nameError !== undefined}
              onChange={(e) => onChangeName(e.currentTarget.value)}
            ></TextInput>
          </div>
        );
      case 1:
        return (
          <>
            <Heading type="h2">Where are your locations?</Heading>
            <Body type="small01">*Your region determines the types of insights we can provide</Body>
            <div className="spacing">
              <RadioButtonGroup
                legend=""
                selectedValue={market}
                onChange={(newMarket) => {
                  setMarket(newMarket.value as Market);
                }}
                radioValues={MARKET_OPTIONS}
              />
            </div>
          </>
        );
    }
  };

  const stepIndicators = () => {
    const indicators: JSX.Element[] = [];
    for (let index = 0; index <= lastStep; index++) {
      indicators.push(<div className={step === index ? 'active indicator' : 'indicator'}></div>);
    }

    return indicators;
  };

  return (
    <PromptStyled>
      {lastStep > 0 && <div className="step-indicator">{stepIndicators()}</div>}
      <Heading type="h2" className="header-spacing">
        Start a new Location Set
      </Heading>
      {stepContent()}

      <div className="action-area">
        <UnaButton onClick={formAction} disabled={nameError !== undefined}>
          {step === lastStep ? 'Confirm' : 'Next'}
        </UnaButton>
        {step > 0 && (
          <UnaButton onClick={() => setStep(step - 1)} styleType="secondary">
            Back
          </UnaButton>
        )}
      </div>
    </PromptStyled>
  );
};
