import { useState } from 'react';
import { useQueryState } from 'react-router-use-location-state';
import { getQuarter } from '../../../mappers/PeriodMapper';
import { ReportType } from '../../../unacatInteraction/BringYourOwnPOIService';
import { ReportDraft } from '../../pages/CreateReportPage/CreateReportPage';
import { Market } from '../../types';

export const useReportDraftState = (reportDraft?: ReportDraft) => {
  const [reportType] = useQueryState<ReportType>('reportType', ReportType.ONE_TIME);
  const [reportName, setReportName] = useState<string>(reportDraft?.reportName || '');
  const [clientName, setClientName] = useState<string>(reportDraft?.clientName || '');
  const [selectedMetrics, setSelectedMetrics] = useState<{ value: string; label: string }[]>(
    reportDraft?.metrics.map((metric: string) => {
      return { label: metric, value: metric };
    }) || [],
  );

  const [startDate, setStartDate] = useState<Date>(reportDraft?.startDate || getQuarter(-3));
  const [endDate, setEndDate] = useState<Date>(reportDraft?.endDate || getQuarter(0));
  const [market, setMarket] = useState<Market>();

  return {
    reportDraftValues: {
      reportType,
      reportName,
      clientName,
      selectedMetrics,
      startDate,
      endDate,
      market,
    },
    reportDraftSetters: {
      setReportName,
      setClientName,
      setSelectedMetrics,
      setStartDate,
      setEndDate,
      setMarket,
    },
  };
};
