import { useAuthorizationHeader } from '../contexts/AuthContext';
import { useCurrentBillingAccount } from '../contexts/BillingAccountContext';
import { PolygonCreationService } from './PolygonCreationService';

export const usePolygonCreationService = (): PolygonCreationService | null => {
  const authHeader = useAuthorizationHeader();
  const billingAccount = useCurrentBillingAccount();
  if (authHeader !== undefined && billingAccount !== null) {
    return new PolygonCreationService(authHeader, billingAccount);
  }
  return null;
};
