import { BlackToWhite } from '@unacast-internal/unacast-ui/StyleGuide/Colors';
import { Body } from '@unacast-internal/unacast-ui/StyleGuide/Typography';
import React from 'react';

type CreationDetailsProps = { creationDate: Date; createdBy?: string; clientName?: string };

export const CreationDetails: React.FC<CreationDetailsProps> = ({
  creationDate,
  createdBy,
  clientName,
}) => {
  return (
    <Body type="small01" color={BlackToWhite.Gray03}>
      <b>{`created on `}</b>
      {creationDate.toLocaleDateString('en-US')}
      <b>{` @ `}</b>
      {creationDate.getHours() + ':' + creationDate.getMinutes().toString().padStart(2, '0')}
      {createdBy && (
        <>
          <br />
          <b>{` by `}</b>
          {createdBy}
        </>
      )}
      {clientName && (
        <>
          <br />
          <b>{` for `}</b>
          {clientName}
        </>
      )}
    </Body>
  );
};
